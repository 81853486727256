import { LineSvgProps, ResponsiveLine, LineLayerType } from "@nivo/line";
import { Scale } from "@nivo/scales";
import React from "react";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { EFelaLayerHeight, FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { EGraphicContrast, IFelaTheme } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { AxisProps } from "@nivo/axes";
import { EPageSizeRange } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { ETextTypographyType } from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";

export function getChartTheme({ dark, styles }: IFelaTheme, isSmall: boolean = true): any {
  const textColor = dark ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.8)";
  const crossHair = dark ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)";
  const lightLineColor = dark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)";
  const backgroundColor = dark ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.9)";

  return {
    crosshair: {
      line: {
        stroke: crossHair,
        strokeWidth: "0.15em",
      },
    },
    grid: {
      line: {
        stroke: lightLineColor,
      },
    },
    axis: {
      line: {
        stroke: lightLineColor,
        fill: textColor,
      },
      domain: {
        line: {
          // stroke: "rgba(255, 50, 50, 1)",
        },
      },
      legend: {
        text: {
          fill: textColor,
          fontFamily: styles.Font.family[ETextTypographyType.mono],
        },
      },
      ticks: {
        line: {
          // stroke: "rgba(255, 50, 50, 1)",
        },
        text: {
          fill: textColor,
        },
      },
    },
    tooltip: {
      // table: {
      //   color: textColor,
      // },
      container: {
        fontFamily: styles.Font.family[ETextTypographyType.mono],
        background: backgroundColor,
        color: textColor,
      },
      basic: {
        fontFamily: styles.Font.family[ETextTypographyType.mono],
        color: textColor,
      },
    },
    labels: {
      text: {
        fontFamily: styles.Font.family[ETextTypographyType.mono],
        fontWeight: "bold",
        fontSize: isSmall ? 10 : 13,
        fill: lightLineColor,
        textAnchor: "middle",
        dominantBaseline: "central",
        transform: isSmall ? "translateX(2%) translateY(3%) rotate(70deg)" : undefined,
        // transform: "rotate(0, 100, 50)",
        // transformOrigin: "center",
      },
    },
  };
}

export function getChartMargin(isSmall: boolean) {
  return { top: 20, right: isSmall ? 30 : 15, bottom: isSmall ? 40 : 25, left: 50 };
}

const CustomSymbol: React.FC<any> = ({ size, color, borderWidth, borderColor, ...rest }) => {
  return (
    <g>
      <circle fill={color} r={size / 2.5} strokeWidth={borderWidth} stroke={borderColor} />
      {/*<circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />*/}
    </g>
  );
};

export const NivoLayerDashedLine = function NivoLayerDashedLine({ series, lineGenerator, xScale, yScale }) {
  return series.map(({ id, data, color, lineStyle }) => {
    // const dashRand = Math.floor(Math.random() * 6) + 6;

    return (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: d.data.y ? yScale(d.data.y) : null,
          })),
        )}
        fill="none"
        stroke={color}
        style={
          lineStyle != null
            ? lineStyle
            : {
                strokeWidth: 2,
              }
        }
      />
    );
  });
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MyResponsiveLine: React.FC<
  LineSvgProps & {
    yScaleExtras?: Partial<Scale>;
    xScaleExtras?: Partial<Scale>;
    axisBottomExtras?: Partial<AxisProps>;
    axisLeftExtras?: Partial<AxisProps>;
    tickLimit?: number;
  }
> = ({ yScaleExtras, axisLeftExtras, axisBottomExtras, xScaleExtras, tickLimit = 14, ...props }) => {
  const { UIStore } = PSC_VibescoutSpecial.useStores();
  const [theme, pageSize] = UIStore.useState((s) => [s.theme, s.pageSize] as const);

  const isSmall = pageSize <= EPageSizeRange.mobile;

  const tickSkip = Math.ceil(props.data[0].data.length / tickLimit);
  const tickValues: any[] = [];

  console.log(props.data);

  for (let i = props.data[0].data.length - 1; i >= 0; i -= tickSkip) {
    tickValues.push(props.data[0].data[i].x);
  }

  // console.log(tickValues);

  // axisBottomExtras.tickValues = tickValues;

  return (
    <ResponsiveLine
      theme={getChartTheme(theme, isSmall)}
      enableSlices={"x"}
      margin={getChartMargin(isSmall)}
      xScale={{ type: "point", ...xScaleExtras } as any}
      yScale={{ type: "linear", min: 0, max: "auto", stacked: false, reverse: false, ...yScaleExtras } as any}
      /*tooltip={({ point: { data, serieColor } }) => {
        return (
          <FelaFlexLayer
            borderNeutralColorString={serieColor}
            borderWidth={"0.2em"}
            align={"center"}
            height={EFelaLayerHeight.HIGH}
            shadow
            padding={"0.5em 0.8em"}
            radius={0.5}
          >
            <FelaText
              colorHex={serieColor}
              typography={ETextTypographyType.mono}
              weight={"bold"}
              contrast={EGraphicContrast.HIGH}
            >
              {data.x}&nbsp;
            </FelaText>
            <FelaText contrast={EGraphicContrast.HIGH} typography={ETextTypographyType.mono}>
              {data.y}
            </FelaText>
          </FelaFlexLayer>
        );
      }}*/
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: isSmall ? 40 : 0,
        legendOffset: 36,
        legendPosition: "middle",
        tickValues,
        ...axisBottomExtras,
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: "middle",
        ...axisLeftExtras,
      }}
      layers={
        [
          "grid",
          "markers",
          "axes",
          "areas",
          "crosshair",
          NivoLayerDashedLine,
          "points",
          "slices",
          "mesh",
          "legends",
        ] as LineLayerType[]
      }
      pointSymbol={CustomSymbol}
      // curve={"cardinal" as any}
      curve={"monotoneX" as any}
      colors={({ color }) => {
        return color;
      }}
      lineWidth={2}
      pointColor={{ theme: "background" }}
      pointSize={7}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      {...props}
    />
  );
};
