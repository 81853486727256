import * as React from "react";
import { FunctionComponent, useRef, useState } from "react";
import { Properties } from "csstype";
import { TFelaRule } from "./types";
import { IFelaComponentBaseProps } from "./FelaCommon";
import { FelaText, IFelaRuleTextBasics, weightToTextWeightType } from "./FelaText";
import { ETextSize, ETextTypographyType, ETextWeightType } from "../config/VibescoutFrontendStyleConfig";
import { useFelaCache } from "../../fela/FelaCache";

export enum ETextInputType {
  INPUT = "INPUT",
  NUMBER = "NUMBER",
  TEXT_AREA = "TEXT_AREA",
}

export interface IFelaTextInputProps extends IFelaRuleTextBasics {
  value?: string;
  initialValue?: string;
  onChange?: any;
  onChangeText?: (text: string) => void;
  onChangeTextDelayed?: (text: string) => void;
  delay?: number;
  onPressEnter?: () => void;
  type?: ETextInputType;
  fullWidth?: boolean;
  placeHolder?: string;
  rounded?: boolean;
  padding?: string;
  minHeight?: any;
  onFocus?: any;
  onBlur?: any;
  disabled?: boolean;
  inputRef?: React.LegacyRef<any>;
  trackTextWidth?: boolean;
  trackMinWidth?: number;
  trackPadding?: number;
  inputType?: string;
}

export const ruleKeysFelaTextInput: (keyof IFelaTextInputProps)[] = [
  "fontSize",
  "size",
  "fullWidth",
  "rounded",
  "minHeight",
  "typography",
  "weight",
  "transform",
  "padding",
  "disabled",
];

export const FelaRuleTextInput: TFelaRule<IFelaTextInputProps> = ({
  theme,
  fontSize,
  size,
  fullWidth = false,
  rounded = false,
  minHeight,
  typography = ETextTypographyType.sansSerif,
  weight,
  transform,
  padding = "0.5em",
  disabled,
}) => {
  const shadowColor = `rgba(${theme.styles.Themes[theme.currentId].shadow.base[0]}, ${
    theme.styles.Themes[theme.currentId].shadow.intensity + 0.15
  })`;

  const fontWeight: ETextWeightType = weightToTextWeightType[weight ?? ETextWeightType.regular];

  /*
      theme.dark ? theme.styles.RgbColors.darkBack.shadow : theme.styles.RgbColors.lightBack.shadow
    */

  return {
    background: !disabled ? "white" : "rgba(150, 150, 150, 0.2)",
    borderRadius: rounded ? "10em" : "0.2em",
    boxShadow: `inset 0 0.1em 0.2em 0.1em rgba(${theme.styles.Colors.lightBack.shadow[0]}, ${theme.dark ? 0.8 : 0.3})`,
    // border: "none",
    boxSizing: "border-box",
    border: `1px solid ${disabled ? "rgba(150, 150, 150, 0.4)" : shadowColor}`,
    width: fullWidth ? "100%" : "auto",
    fontFamily: theme.styles.Font.family[typography],
    fontWeight:
      typography === ETextTypographyType.inherit ? "inherit" : theme.styles.Font.weights[typography][fontWeight],
    color: disabled
      ? theme.styles.Themes[theme.currentId].RgbColors.fore.regular
      : theme.styles.Themes[theme.currentId].RgbColors.fore.extra,
    fontSize: fontSize ? fontSize : size ? theme.styles.Font.sizes[size] : theme.styles.Font.sizes[ETextSize.content],
    minHeight,
    padding,
    textTransform: transform,
    "::placeholder": {
      color: theme.styles.RgbColors.lightFore.lig,
    },
    ":focus": {
      color: theme.styles.RgbColors.lightFore.dar,
    },
  } as Properties;
};

export type TFelaTextInputProps = IFelaTextInputProps &
  IFelaComponentBaseProps<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>;

export const FelaTextInput: FunctionComponent<TFelaTextInputProps> = ({
  value,
  onChange,
  onFocus,
  onBlur,
  onClick,
  type = ETextInputType.INPUT,
  placeHolder,
  cssExtra = [],
  style = {},
  rootProps = {},
  onChangeText,
  onChangeTextDelayed,
  onPressEnter,
  inputRef,
  trackTextWidth = false,
  trackMinWidth = 120,
  trackPadding = 25,
  delay = 200,
  initialValue,
  inputType,
  id,
  ...props
}) => {
  const { inlineCss, cachedClass } = useFelaCache();

  const textChangeTimeout = useRef<any>(null);

  const [internalText, setInternalText] = useState(initialValue ?? "");

  function textChange(useText) {
    if (value == null) {
      setInternalText(useText);
    }

    if (onChangeText) {
      onChangeText(useText);
    }

    if (onChangeTextDelayed) {
      clearTimeout(textChangeTimeout.current);

      textChangeTimeout.current = setTimeout(() => {
        onChangeTextDelayed(useText);
      }, delay);
    }
  }

  let onChangeFunc;

  if (onChange) {
    onChangeFunc = onChange;
  } else {
    onChangeFunc = (event) => textChange(event.target.value);
  }

  let onKeyDown;

  if (onPressEnter) {
    onKeyDown = (e) => {
      if (e.key === "Enter") {
        onPressEnter();
      }
    };
  }

  // let textWidth: undefined | number = undefined;
  let measureText = <></>;

  if (type !== ETextInputType.TEXT_AREA && trackTextWidth) {
    const measureTextRef = useRef<HTMLDivElement>();

    if (measureTextRef.current) {
      measureTextRef.current.textContent = value || "";
      // textWidth = measureTextRef.current.clientWidth;
      style.width = `${Math.max(measureTextRef.current.clientWidth + trackPadding, trackMinWidth)}px`;
    } else {
      style.width = `calc(${(value?.length ?? 1) * 0.5}em + ${trackMinWidth}px)`;
    }

    measureText = (
      <FelaText
        style={{ height: "1px", visibility: "hidden", position: "absolute" }}
        size={props.size}
        rootProps={{
          ref: (ref) => {
            measureTextRef.current = ref ?? undefined;
          },
        }}
      >
        {value}
      </FelaText>
    );
  }

  const { disabled = false } = props;

  const { className, ...restRootProps } = rootProps;
  const finalClassName = `${className ?? ""} ${cachedClass(
    FelaRuleTextInput,
    props,
    ruleKeysFelaTextInput,
  )} ${inlineCss(cssExtra)}`;

  if (type === ETextInputType.INPUT) {
    return (
      <>
        {measureText}
        <input
          type={inputType ?? "text"}
          ref={inputRef}
          disabled={disabled}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          style={style}
          placeholder={placeHolder}
          value={value ?? internalText}
          onChange={onChangeFunc}
          onKeyDown={onKeyDown}
          className={finalClassName}
          id={id}
          name={id}
          {...restRootProps}
        />
      </>
    );
  } else if (type === ETextInputType.NUMBER) {
    return (
      <>
        {measureText}
        <input
          ref={inputRef}
          disabled={disabled}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          style={style}
          placeholder={placeHolder}
          value={value ?? internalText}
          type={"number"}
          onKeyDown={onKeyDown}
          onChange={onChangeFunc}
          className={finalClassName}
          id={id}
          name={id}
          {...restRootProps}
        />
      </>
    );
  }

  return (
    <textarea
      ref={inputRef}
      disabled={disabled}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      style={style}
      placeholder={placeHolder}
      onChange={onChangeFunc}
      onKeyDown={onKeyDown}
      value={value ?? internalText}
      className={finalClassName}
      id={id}
      name={id}
      {...(restRootProps as any)}
    />
  );
};
