import { TVibescoutSpecialResolver } from "../ASN_VibescoutSpecial";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { PostAsyncActions } from "../asyncActions/PostAsyncActions";
import { PSC_VibescoutSpecial } from "../PSC_VibescoutSpecial";
import { WildcardUtilsClient } from "@gt/gt-frontend/build/utils/WildcardUtilsClient";
import { wildcard } from "../../wildcard/VibescoutSpecial_WildcardClient";
import {
  ILockdownGeoInfoWithDates,
  LockdownLevelsGeoUtils,
} from "@vs/core/build/data/models/covid19/lockdownLevels/lockdownLevelsGeo/LockdownLevelsGeoUtils";
import { EGSLockdownLevelValue } from "@vs/core/build/data/models/covid19/lockdownLevels/lockdownLevelsGeo/LockdownLevelsGeoSheetModels";
import _ from "lodash";
import { LockdownLevelsGeoStatic } from "@vs/core/build/data/models/covid19/lockdownLevels/lockdownLevelsGeo/LockdownLevelsGeoStatic";
import { IOGetOldPostsForLatLonInput } from "@vs/core/build/data/models/oldVibescout/oldPost/elasticsearch/actions/_getOldPostsForLatLon";
import { CovidAsyncActions } from "../stores/CovidStore/CovidAsyncActions";
import {
  IOQueryForPlaceNodesInput,
  IOQueryForPlaceNodesOutput,
} from "@vs/core/build/data/models/tempNodes/actions/_queryForAreaPlaceNodes";
import { ETempNodePlaceType } from "@vs/core/build/data/models/tempNodes/TempNodeModels";
import { IElasticPropertyGeoPoint } from "@gt/services/build/elasticsearch/ElasticSearchTypes";
import { throwOrPayload } from "@gt/common-utils/build/taskFunction/TaskFunctionUtils/_throwOrPayload";
import getDistance from "geolib/es/getDistance";
import { successResult, TAsyncActionResult } from "pullstate";

const getPageData = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.pageData_LocalityScout, "Get Locality Scout Page Data"),
  {
    postActionHook: ({ stores, result }) => {
      if (!result.error) {
        const levels = LockdownLevelsGeoUtils.sortAndFilterForCurrentGeo(
          result.payload.lockdownGeoInfo,
          result.payload.geoLevel,
        );

        let currentDateTime = new Date().getTime();

        let [previousLevel, currentLevel, nextLevel] = levels.reduce(
          (acc, cur) => {
            if (cur.lockdown.dateTime < currentDateTime) {
              if (acc[1]) {
                // set previous
                acc[0] = acc[1];

                /*if (acc[0].lockdown.date === acc[1].lockdown.date) {
                  acc[0] = undefined;
                }*/
              }

              // set current
              acc[1] = cur;
            }

            if (cur.lockdown.dateTime > currentDateTime && acc[2] == null) {
              // set next
              acc[2] = cur;
            }

            return acc;
          },
          [undefined, undefined, undefined] as [
            ILockdownGeoInfoWithDates | undefined,
            ILockdownGeoInfoWithDates | undefined,
            ILockdownGeoInfoWithDates | undefined,
          ],
        );

        /*if (currentLevel) {
          nextLevel = {
            ...currentLevel,
            lockdown: {
              ...currentLevel.lockdown,
              date: "2020-06-20",
              dateTime: new Date("2020-06-20T:00:00:00").getTime(),
              lvl: EGSLockdownLevelValue.LVL_3_ADV,
            },
          };
        }*/

        if (previousLevel?.lockdown?.date === currentLevel?.lockdown?.date) {
          previousLevel = undefined;
        }

        if (currentLevel?.lockdown?.date === nextLevel?.lockdown?.date) {
          currentLevel = nextLevel;
        }

        // console.log({ previousLevel, currentLevel, nextLevel });

        stores.LocalityStore.update((s) => {
          s.covid.lockdownLevels.selectedLvl =
            LockdownLevelsGeoStatic.funnelLevelValueToBasicLevelValue[
              currentLevel?.lockdown.lvl ?? EGSLockdownLevelValue.IGNORE
            ];
          s.covid.lockdownLevels.previous = previousLevel;
          s.covid.lockdownLevels.current = currentLevel;
          s.covid.lockdownLevels.next = nextLevel;
          s.icons = _.keyBy(result.payload.icons, "_key");
        });
      }
    },
  },
);

const queryForAreaPlaceNodes = PSC_VibescoutSpecial.createAsyncAction<
  TQueryPlaceNodesInputs,
  IOQueryForPlaceNodesOutput
>(async ({ gl, lat, lon, nSubType }, stores) => {
  const result = await WildcardUtilsClient.wrapTaskFunctionEndpoint(
    wildcard.queryForAreaPlaceNodes,
    "Query For Area Places",
  )({ nSubType, gl }, stores);

  if (!result.error) {
    if (lat && lon) {
      const newItems: any[] = result.payload.items.map((cinema) => {
        (cinema as any).distanceTo = getDistance({ lat, lon }, cinema.latLon);
        return cinema;
      });

      result.payload = {
        items: newItems.sort((cA, cB) => {
          return cA.distanceTo - cB.distanceTo;
        }),
      };
    }
  }

  return result;
  /*const {} = await WildcardUtilsClient.wrapTaskFunctionEndpoint(
    wildcard.queryForAreaPlaceNodes,
    "Query For Area Places",
  );*/
});

export const PageLocalityScoutAsyncActions = {
  getPageData,
  queryForAreaPlaceNodes,
};

function getDefaultPostsForLatLonArguments({
  lat,
  lon,
}: Pick<IOGetOldPostsForLatLonInput, "lat" | "lon">): IOGetOldPostsForLatLonInput {
  return { lat, lon, groupSize: 15 };
}

type TQueryPlaceNodesInputs = IOQueryForPlaceNodesInput & Partial<IElasticPropertyGeoPoint>;

function getDefaultCinemasForGeoLevelArguments({
  gl,
  latLon,
}: {
  gl: string;
  latLon: IElasticPropertyGeoPoint | undefined;
}): TQueryPlaceNodesInputs {
  return {
    gl,
    nSubType: ETempNodePlaceType.CINEMA,
    lat: latLon?.lat,
    lon: latLon?.lon,
  };
}

export const PageLocalityScoutStatic = {
  getDefaultPostsForLatLonArguments,
  getDefaultCinemasForGeoLevelArguments,
};

export const _navLocalityScout: TVibescoutSpecialResolver = {
  paths: ["/za/city/:city", "/za/city/:city/suburb/:suburb", "/za/city/:city/suburb/:suburb/area/:area"],
  resolve: async ({ ctx, info, state: { instance } }) => {
    const { city, suburb, area } = info.params;
    const geoLvlShortId = GeoLevelCompressUtils.convertUrlToCompressed({
      continentOrCountry: "za",
      cityPart: city!,
      suburbPart: suburb,
      subSuburbPart: area,
    });

    const pageData = await instance.runAsyncAction(getPageData, { geoLvlShort: geoLvlShortId }, { respectCache: true });

    // console.log(pageData);

    if (!pageData.error) {
      const { geoLevel } = pageData.payload;
      console.log(`Initiating next actions on page data`);

      await Promise.all([
        instance.runAsyncAction(
          queryForAreaPlaceNodes,
          getDefaultCinemasForGeoLevelArguments({ gl: geoLevel.id, latLon: geoLevel.latLon }),
          {
            respectCache: true,
          },
        ),
        instance.runAsyncAction(
          PostAsyncActions.getPostsForLatLon,
          getDefaultPostsForLatLonArguments(geoLevel.latLon),
          { respectCache: true },
        ),
        instance.runAsyncAction(
          CovidAsyncActions.getBasicStatsForGeoLevel,
          { glIds: geoLevel.allIds },
          { respectCache: true },
        ),
      ]);

      const {
        covid: {
          lockdownLevels: { current },
        },
      } = instance.stores.LocalityStore.getRawState();

      instance.stores.UIStore.update((s) => {
        s.pageMeta = {
          shareUrl: `https://www.vibescout.com${info.pathname}`,
          title: `Things to do in ${geoLevel.name}`,
          metaDescription: `What lockdown level are we at currently in ${geoLevel.name}? What are the latest municipal Covid19 Stats and what do they mean? What are the things you can and can’t do? | UPDATED DAILY`,
          metaTitle: current
            ? `${geoLevel.name} Lockdown ${
                LockdownLevelsGeoStatic.levelTypeMetaObject[current.lockdown.lvl].name
              } | Things You Can & Can't Do`
            : `Things to do in ${geoLevel.name} | Vibescout`,
        };
      });
    }
  },
};
