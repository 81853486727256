import { EFelaLayerHeight, FelaFlexLayer } from "../fela/FelaLayers";
import { FelaText } from "../fela/FelaText";
import { EGraphicContrast, IFelaComponentBaseProps } from "../fela/FelaCommon";
import { FelaButton } from "../fela/FelaButton";
import React, { FunctionComponent, ReactElement, useMemo } from "react";
import { useFela } from "react-fela";
import { TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { EForeContrast, ETextSize } from "../config/VibescoutFrontendStyleConfig";
import { FelaIconWrap } from "../fela/FelaIconWrap";
import { FiAlertTriangle } from "react-icons/fi";

export enum EModalFeedbackState {
  NONE = "NONE",
  LOADING = "LOADING",
  ERROR = "ERROR",
  ALERT = "ALERT",
  CONFIRMATION = "CONFIRMATION",
}

export type TModalOnCloseFunction = (fromState: EModalFeedbackState, positiveConfirmation?: boolean) => void;

export interface ICPSimpleModalComponent {
  onClose?: TModalOnCloseFunction;
  feedbackState?: EModalFeedbackState;
  // fadeTimeout?: number;
  message: string;
  customHeader?: string;
  isFloating?: boolean;
  buttonText?: string;
  cancelText?: string;
  // show: boolean;
}

interface IModalBlueprint {
  color: TColorRgbArray;
  header: string;
  buttonSection: ReactElement | null;
}

export const SimpleModalComponent: FunctionComponent<ICPSimpleModalComponent & IFelaComponentBaseProps> = ({
  onClose,
  message,
  style,
  className,
  cssExtra = [],
  feedbackState,
  customHeader,
  isFloating = true,
  buttonText,
  cancelText,
}) => {
  const { theme } = useFela() as any;

  // const delayedState = useRef<{
  //   message: string;
  //   feedbackState: EModalFeedbackState | null;
  // }>({ message: "", feedbackState: null });

  const { color, header, buttonSection }: IModalBlueprint = useMemo((): IModalBlueprint => {
    if (feedbackState === EModalFeedbackState.ERROR) {
      return {
        color: theme.styles.Colors.neutral.error,
        header: `An error occurred`,
        buttonSection: (
          <FelaFlexLayer align={"flex-end"} justify={"flex-end"} height={EFelaLayerHeight.MID} padding={"0.75em"}>
            <FelaButton
              onClick={() => {
                // delayedState.current.message = message;
                // delayedState.current.feedbackState = EModalFeedbackState.ERROR;

                if (onClose) {
                  onClose(EModalFeedbackState.ERROR, true);
                }
              }}
              margin={"0"}
              color={theme.styles.Colors.neutral.error}
            >
              <span className="text">{buttonText ?? "Continue"}</span>
            </FelaButton>
          </FelaFlexLayer>
        ),
      };
    } else if (feedbackState === EModalFeedbackState.LOADING) {
      return { color: theme.styles.Colors.neutral.accent, header: `Loading`, buttonSection: null };
    } else if (feedbackState === EModalFeedbackState.CONFIRMATION) {
      return {
        color: theme.styles.Colors.neutral.primary,
        header: `Confirm`,
        buttonSection: (
          <FelaFlexLayer align={"flex-end"} justify={"space-between"} height={EFelaLayerHeight.MID} padding={"0.75em"}>
            <FelaButton
              color={theme.dark ? theme.styles.Colors.lightFore.mid : theme.styles.Colors.darkFore.mid}
              onClick={() => {
                // delayedState.current.message = message;
                // delayedState.current.feedbackState = EModalFeedbackState.CONFIRMATION;

                if (onClose) {
                  onClose(EModalFeedbackState.CONFIRMATION, false);
                }
              }}
              margin={"0 1em 0 0"}
            >
              <span className="text">{cancelText ?? "Cancel"}</span>
            </FelaButton>
            <FelaButton
              onClick={() => {
                // delayedState.current.message = message;
                // delayedState.current.feedbackState = EModalFeedbackState.CONFIRMATION;

                if (onClose) {
                  onClose(EModalFeedbackState.CONFIRMATION, true);
                }
              }}
              margin={"0"}
              color={theme.styles.Colors.neutral.primary}
            >
              <span className="text">{buttonText ?? "Okay"}</span>
            </FelaButton>
          </FelaFlexLayer>
        ),
      };
    }

    return {
      color: theme.styles.Colors.neutral.primary,
      header: `Feedback`,
      buttonSection: (
        <FelaFlexLayer align={"flex-end"} justify={"flex-end"} height={EFelaLayerHeight.MID} padding={"0.75em"}>
          <FelaButton
            onClick={() => {
              // delayedState.current.message = message;
              // delayedState.current.feedbackState = EModalFeedbackState.ALERT;

              if (onClose) {
                onClose(EModalFeedbackState.ALERT, true);
              }
            }}
            margin={"0"}
            color={theme.styles.Colors.neutral.primary}
          >
            <span className="text">{buttonText ?? "Okay"}</span>
          </FelaButton>
        </FelaFlexLayer>
      ),
    };
  }, [feedbackState]);

  return (
    <FelaFlexLayer
      className={className}
      style={style}
      {...(isFloating && {
        cssExtra: [
          {
            border: `0.1em solid rgb(${
              theme.dark ? theme.styles.Colors.darkBack.shadow[0] : theme.styles.Colors.lightBack.shadow[0]
            })`,
            maxWidth: "20em",
          },
          ...cssExtra,
        ],
        extraSoftShadow: true,
        shadow: true,
      })}
      align={"stretch"}
      direction={"column"}
    >
      <FelaFlexLayer
        height={EFelaLayerHeight.LOW}
        cssExtra={[{ padding: "0.5em 0.75em" }]}
        direction={"row"}
        justify={"flex-start"}
        align={"center"}
        gap={0.5}
      >
        {(feedbackState === EModalFeedbackState.ERROR || feedbackState === EModalFeedbackState.ALERT) && (
          <FelaIconWrap color={color} noPadding noBackground size={ETextSize.small}>
            <FiAlertTriangle />
          </FelaIconWrap>
        )}
        <FelaText
          overflowWrap={"break-word"}
          fontSize={0.8}
          weight={"bold"}
          align={"center"}
          spacing={"0.1em"}
          transform={"uppercase"}
          contrast={EGraphicContrast.MID}
          color={color}
        >
          {customHeader !== undefined ? customHeader : header}
        </FelaText>
      </FelaFlexLayer>
      <FelaFlexLayer padding={"0.5em 0.75em"}>
        <FelaText contrast={EForeContrast.extra} size={ETextSize.small} whiteSpace={"pre-wrap"}>
          {/*{delayedState.current.message.length > 0 ? delayedState.current.message : message}*/}
          {message}
        </FelaText>
      </FelaFlexLayer>
      {buttonSection}
    </FelaFlexLayer>
  );
};
