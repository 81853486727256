import { createPullstateCore, registerInDevtools } from "pullstate";
import { UIStore } from "./stores/UIStore/UIStore";
import { CovidStore } from "./stores/CovidStore/CovidStore";
import { OldPostPageStore } from "./stores/OldPostStore/OldPostStore";
import { LocalityStore } from "./stores/LocalityStore/LocalityStore";
import { TimeUtils } from "@gt/common-utils/build/general/TimeUtils";
import { LocationStore } from "./stores/LocationStore/LocationStore";
import { StoreLocalStorage } from "@gt/gt-frontend/build/pullstate/utils/StoreLocalStorageUtil";

const stores = {
  UIStore,
  CovidStore,
  OldPostPageStore,
  LocalityStore,
  LocationStore,
};

export type TAllStores_VibescoutSpecial = typeof stores;

export const PSC_VibescoutSpecial = createPullstateCore(stores, {
  asyncActions: { defaultCachingSeconds: TimeUtils.CalculateSeconds.minutesInSeconds(30) },
});

registerInDevtools(stores);

export const LocalStorageCore_VibescoutSpecial = new StoreLocalStorage();
LocalStorageCore_VibescoutSpecial.addStore({
  key: "location-store",
  store: LocationStore,
  deepValues: ["checkedGeoPermission", "recentGeoLevels", "currentSetFrom", "permissionDenied"],
});

// LocalStorageCore_VibescoutSpecial.addStore({ key: "ui-store", store: UIStore, deepValues: ["theme.dark"] });
// LocalStorageCore_VibescoutSpecial.addStore({ key: "locality-store", store: LocalityStore, values: ["closedBlurb"] });
