import React from "react";
import { PSC_VibescoutSpecial } from "./state/PSC_VibescoutSpecial";
import { hot } from "react-hot-loader/root";
import { ThemeProvider } from "react-fela";
import { Redirect, Route, Switch } from "react-router";
import { ScrollToTopRouteWatcher } from "@gt/gt-frontend/build/react/react-router/utilityComponents/ScrollToTopRouteWatcher";
import { LocalityScoutPage } from "./sections/locality/LocalityScoutPage";
import { LandingPage } from "./sections/landing/LandingPage";
import { PostDisplayPage } from "./sections/posts/PostDisplayPage";
import { AsyncNavigationFeedback } from "./components/navigation/AsyncNavigationFeedback";
import { Vibescout_RoutesUnavailable } from "./state/ASN_VibescoutSpecial";
import { TemporarilyUnavailablePage } from "./sections/common/TemporarilyUnavailablePage";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { LocalityIndexPage } from "./sections/locality/LocalityIndexPage";
import { VibescoutHeader } from "./components/header/VibescoutHeader";
import { FelaCacheProvider } from "@gt/gt-frontend/build/react/fela/FelaCache";
import { CinemaPage } from "./sections/movies/CinemaPage";
import { ENavigationMetaPageType } from "@gt/gt-frontend/build/utils/NavigationUtils/AsyncNavigator";
import { MoviePage } from "./sections/movies/MoviePage";
import { EGradientType } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { LoadableComponents } from "./loadables/VibescoutSpecial_LoadableImports";

const App: React.FC = () => {
  const { UIStore } = PSC_VibescoutSpecial.useStores();
  const theme = UIStore.useState((s) => s.theme);

  console.log(theme);

  return (
    <ThemeProvider theme={theme}>
      <FelaCacheProvider themeHash={`${theme.currentId}${theme.pageSizeValue}${theme.isSmall}`} enabled={true}>
        <NavigationMetaComponent>
          <ScrollToTopRouteWatcher />
          <Switch>
            <Route path={"/za/cinemas/:brandId/:cinemaId"} exact component={CinemaPage} />
            <Route path={"/za/movies/:movieId"} exact component={MoviePage} />
            {Vibescout_RoutesUnavailable.map(({ paths }) =>
              paths.map((path) => <Route path={path} key={path} component={TemporarilyUnavailablePage} />),
            )}
            <Route path={"/"} exact component={LandingPage} />
            <Route path={"/locality-index/:cc"} exact component={LocalityIndexPage} />
            <Route path={"/locality-index/:cc/:st"} exact component={LocalityIndexPage} />
            <Route path={"/locality-index/:cc/:st/:mu"} exact component={LocalityIndexPage} />
            <Route path={"/locality-index/:cc/:st/:mu/:ci"} exact component={LocalityIndexPage} />
            <Route path={"/za/city/:city"} exact component={LocalityScoutPage} />
            <Route path={"/za/city/:city/suburb/:suburb"} exact component={LocalityScoutPage} />
            <Route path={"/za/city/:city/suburb/:suburb/area/:area"} exact component={LocalityScoutPage} />
            <Route path={"/za/post/:post"} exact component={PostDisplayPage} />
            <Route path={"/za/city/:city/post/:post"} exact component={PostDisplayPage} />
            <Route path={"/reports/coronavirus"} exact component={LoadableComponents.PageSpecial_Covid19} />
            <Route
              path={"/reports/coronavirus/:stateGeoLevelPart"}
              component={LoadableComponents.PageSpecial_Covid19State}
            />
            <Redirect path={"*"} to={"/"} />
          </Switch>
        </NavigationMetaComponent>
      </FelaCacheProvider>
    </ThemeProvider>
  );
};

const NavigationMetaComponent: React.FC = ({ children }) => {
  const { UIStore } = PSC_VibescoutSpecial.useStores();
  const [{ showTitleBar, fallbackSearchTerm, noSharing }, pageType, currentTheme] = UIStore.useState(
    (s) => [s.customPageMeta, s.pageMeta.pageType, s.theme.styles.Themes[s.theme.currentId]] as const,
  );

  console.log(`Should show title bar: ${showTitleBar}`);

  return (
    <FelaFlexLayer
      position={"relative"}
      // gradient={[dark ? StyleConstants.darkBackgroundGradient : StyleConstants.lightBackgroundGradient]}
      gradient={[
        {
          type: EGradientType.repeatingLinear,
          stops: currentTheme.Colors.deepSurfaceStops,
          def: "200deg",
        },
      ]}
      // height={ESurfaceHeight.background}
      direction={"column"}
      style={{ minHeight: "100vh" }}
      cssExtra={[
        {
          mobileOnly: {
            fontSize: "14px",
          },
          smallMobileOnly: {
            fontSize: "12px",
          },
          /* "> *": {
             // background: "red",
           },
           "> *::scrollbar-track": {
             shadow: "inset 0 0 3px 1px rgba(37, 47, 67, 1)",
             borderRadius: "0 10px 0 10px",
             width: "50px",
             height: "50px",
             overflow: "hidden",
             backgroundColor: "#a8b0bb",
           },
           "> *::scrollbar-thumb": {
             borderRadius: "0 10px 0 10px",
             boxShadow: "inset 0 0 6px rgba(49,53,58,0.3)",
             backgroundColor: "#3d4761",
             backgroundImage: "linear-gradient(to bottom right, #555f79, #252f43)",
           },*/
        },
      ]}
    >
      <AsyncNavigationFeedback />
      <VibescoutHeader noSharing={noSharing} show={showTitleBar}>
        {/*<FelaFlexbox
          zIndex={ZIndexes.higherBar}
          position={"absolute"}
          bottom={0}
          style={{ transform: "translateY(100%)" }}
          padding={1}
        >
          <FelaButton>
            <span>Back</span>
          </FelaButton>
        </FelaFlexbox>*/}
      </VibescoutHeader>
      {pageType === ENavigationMetaPageType.maintenance && (
        <TemporarilyUnavailablePage searchTerm={fallbackSearchTerm!} />
      )}
      {children}
    </FelaFlexLayer>
  );
};

export const VibescoutSpecialApp = hot(App);
