import { Redirect, RouteProps, useLocation, useParams } from "react-router";
import React from "react";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { Helmet } from "react-helmet";
import { optimizeColorForTextBackground } from "@gt/gt-frontend/build/utils/ColorUtils/OldVibescoutColorUtils";
import tinycolor from "tinycolor2";
import classnames from "classnames";
import { FiAlignJustify } from "react-icons/fi";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import { PostAsyncActions } from "../../state/asyncActions/PostAsyncActions";
import { OldPostUtils } from "@vs/core/build/data/models/oldVibescout/oldPost/OldPostUtils";
import { OldLinkOrPlainText } from "../old-vibescout/OldLinkOrPlainText";
import { OldVibescoutPost } from "../old-vibescout/OldVibescoutPost";
import { ReactImage } from "../old-vibescout/ReactImage";
import { PageErrorSection } from "../common/PageErrorSection";

function findPos(obj: any): number {
  let curtop = 0;
  if (obj?.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
  }
  return curtop;
}

export const PostDisplayPage: React.FC<RouteProps> = () => {
  const params = useParams<{ city?: string; post: string }>();
  const { pathname } = useLocation();

  const { OldPostPageStore } = PSC_VibescoutSpecial.useStores();
  const showPostIndexFloat = OldPostPageStore.useState((s) => s.showQuickJump);

  const postResult = PostAsyncActions.getPost.use({ id: params.post, ci: params.city?.split("_")[0], cc: "za" });

  const quickJumpClasses = classnames("floating-button post-quick-jump", {
    show: showPostIndexFloat,
  });

  return (
    <>
      {postResult.isFailure && <PageErrorSection />}
      {postResult.renderPayload(({ post: postData, redirectId }) => {
        if (postData) {
          return (
            <FelaFlexLayer direction={"column"}>
              <Helmet>
                <link type={"text/css"} rel={"stylesheet"} href={"/_static/vs-special/old-vibescout/css/entry.css"} />
              </Helmet>
              <div className="post-page-main-content" itemScope itemType="http://schema.org/Article">
                {/*{!this.props.UIStore.isMobileFromServer && (
          <FixedScrollIntoViewComponent>
            <PmpSpacePanel className={"left"} side={"left"} boxType={"fixed-side-large"} />
            <div className={"divider"} />
            <PmpSpacePanel className={"right"} side={"right"} boxType={"fixed-side-large"} />
          </FixedScrollIntoViewComponent>
        )}*/}
                <div key={postData.publishedUrl} className="title-block">
                  <PostTitleBlock
                    isBrochureEdge
                    className="brochure-edge brochure-edge-left"
                    titleImage={postData.titleImage}
                    titleText={postData.title}
                    subtitleText={postData.subtitle}
                  />
                  <PostTitleBlock
                    isMainTitleBlock
                    titleImage={postData.titleImage}
                    titleText={postData.title}
                    subtitleText={postData.subtitle}
                  />
                  <PostTitleBlock
                    isBrochureEdge
                    className="brochure-edge brochure-edge-right"
                    titleImage={postData.titleImage}
                    titleText={postData.title}
                    subtitleText={postData.subtitle}
                  />
                </div>
                <meta itemProp="dateModified" content={new Date(postData.dateLastPublished).toUTCString()} />
                <meta itemProp="datePublished" content={new Date(postData.dateFirstPublished).toUTCString()} />
                {/*<div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "0.5em" }}>
          <CoronavirusPanel />
        </div>*/}
                {/*<PmpSpacePanel side={"top"} boxType={"banner"} />*/}
                <div className="post-block">
                  {postData.titleImage.meta.imageCreditText && (
                    <div className="title-image-credit">
                      <OldLinkOrPlainText
                        className="image-credit-text"
                        url={postData.titleImage.meta.imageCreditUrl}
                        text={postData.titleImage.meta.imageCreditText}
                      />
                    </div>
                  )}
                  <OldVibescoutPost />
                </div>
                {/*<PmpSpacePanel side={"bottom"} boxType={"banner"} />*/}
                {/*<EmailSignUp type={CEmailSignUpType.POST_PAGE_SIGN_UP} />*/}
                <div className="post-floating-bottom-buttons">
                  <a
                    href="#post-quick-jump"
                    onClick={() => {
                      window.scroll(0, findPos(document.getElementById("post-quick-jump")));
                    }}
                    className={quickJumpClasses}
                  >
                    <div className="icon">
                      <FiAlignJustify />
                    </div>
                    <div className="text">QUICK</div>
                    <div className="text">JUMP</div>
                  </a>
                </div>
              </div>
            </FelaFlexLayer>
          );
        } else if (redirectId) {
          return <Redirect to={OldPostUtils.getNewUrlForOldPostId(redirectId)} />;
        }
      })}
    </>
  );
};

interface ICPPostTitleBlock {
  isBrochureEdge?: boolean;
  isMainTitleBlock?: boolean;
  className?: string;
  titleImage: any;
  titleText: string;
  subtitleText: string;
}

const PostTitleBlock: React.FC<ICPPostTitleBlock> = ({
  className,
  isBrochureEdge,
  isMainTitleBlock,
  subtitleText,
  titleImage,
  titleText,
}) => {
  // const titleImage = this.props.titleImage;

  // console.dir(titleImage);

  const hasTitleImage = titleImage && titleImage.url;

  if (hasTitleImage) {
    const [r, g, b] = JSON.parse(titleImage.meta.colorPalette)[titleImage.meta.preferredColorIndex || 0];

    const { color, isLight } = optimizeColorForTextBackground(null, tinycolor({ r, g, b })) as any;

    const rgbObject = color.toRgb();
    const rgbString = `${rgbObject.r}, ${rgbObject.g}, ${rgbObject.b}`;

    const titleTextBlockStyle = {
      backgroundColor: `rgba(${rgbString}, 0.9)`,
      background: `linear-gradient(to top, rgba(${rgbString}, 1) 0, rgba(${rgbString}, 1) 82%, rgba(${rgbString}, 0.4) 84%, rgba(${rgbString}, 0.4) 90%, rgba(${rgbString}, 0.2) 95%, rgba(${rgbString}, 0.1) 100%)`,
    };

    const titleImageStyles = {
      background: `rgba(${rgbString}, 0.9)`,
    };

    const categoryIconStyles: any = {};
    const titleTextStyles: any = {};
    const subtitleBlockStyles: any = {};

    if (!isLight) {
      const shadowColor = color.darken(10);
      shadowColor.setAlpha(0.9);

      titleTextStyles.textShadow = `0 1px 2px ${shadowColor.toRgbString()}, 0 2px 0 ${shadowColor.toRgbString()}`;
      subtitleBlockStyles.textShadow = `0 1px 2px ${shadowColor.toRgbString()}`;

      color.lighten(10);
    }

    const lighterBackground = color.lighten(8);

    // subtitleBlockStyles.background = `rgba(${rgbString}, 1)`;
    subtitleBlockStyles.background = lighterBackground.toRgbString();
    categoryIconStyles.fill = subtitleBlockStyles.background;
    // fill: `rgba(${rgbString}, 0.85)`,

    const titleBlockClasses = classnames("title-image-block", { "light-color-blend": isLight }, className);

    return (
      <div className={titleBlockClasses}>
        {titleImage && titleImage.url && (
          <ReactImage
            aspectRatio={2.15}
            noFade
            affectDominantColor={!isBrochureEdge}
            dominantColorRgbString={rgbString}
            dominantColorRgbArray={[r, g, b]}
            src={titleImage.url}
            alt="Title Image"
            metaHeight={titleImage.meta.height}
            metaWidth={titleImage.meta.width}
          />
        )}
        <div className="title-image-text-block" style={titleTextBlockStyle}>
          {isMainTitleBlock ? (
            <h1 itemProp="name headline" className="text" style={titleTextStyles}>
              {titleText}
            </h1>
          ) : (
            <span className="text" style={titleTextStyles}>
              {titleText}
            </span>
          )}
        </div>
        <div className="subtitle-block" style={subtitleBlockStyles}>
          {isMainTitleBlock ? (
            <span itemProp="description" className="text">
              {subtitleText}
            </span>
          ) : (
            <span className="text">{subtitleText}</span>
          )}
        </div>
      </div>
    );
  }

  return <div>NO TITLE IMAGE</div>;
};
