import tinyColor, { Instance } from "tinycolor2";
// import { TColorRgbArray } from "@vs/core/build/types/media/VibescoutImageTypes";

export type TColorRgbArray = [string, number, number, number];

export const C_COL_DARK_BACKGROUND_HEX = `#252529`;

const divideBy = 15;

function createTinyColorUISaturatedColor(tc: Instance) {
  let hsl = tc.toHsl();

  tc = tinyColor({ ...hsl, s: 75, l: 0.6 });

  hsl = tc.toHsl();

  const amountPassedDivide = (hsl.h + 360 + 25) % divideBy;
  const goUp = amountPassedDivide > (divideBy / 2);

  const newHue = Math.round(goUp ? (hsl.h + divideBy - amountPassedDivide) : (hsl.h - amountPassedDivide));

  tc = tinyColor({ ...hsl, h: newHue });

  const lightReadability = tinyColor.readability(tc, "#ffffff");

  tc.darken(Math.max((2 - lightReadability), 0) * 20);

  const darkReadability = tinyColor.readability(tc, C_COL_DARK_BACKGROUND_HEX);

  if (darkReadability < 3.7) {
    tc.lighten(10);
  }

  return tc;
}

const tinyColorToRgbArray = (col: Instance): TColorRgbArray => {
  const { r, g, b } = col.toRgb();
  return [`${r}, ${g}, ${b}`, r, g, b];
};

const createRgbArrayUISaturatedColor = (colorArray: TColorRgbArray): TColorRgbArray => {
  /*const { r, g, b }: { r: number; g: number; b: number } = createTinyColorUISaturatedColor(
    tinyColor({
      r: colorArray[1],
      g: colorArray[2],
      b: colorArray[3],
    })
  ).toRgb();*/
  return tinyColorToRgbArray(createTinyColorUISaturatedColor(tinyColor({
    r: colorArray[1],
    g: colorArray[2],
    b: colorArray[3],
  })));
  // return [`${r}, ${g}, ${b}`, r, g, b];
};

const lightnessLimit = 360;
const darknessLimit = 260;

// DON'T TOUCH
const lightnessArea = 765 - lightnessLimit;
const darknessArea = 765 - darknessLimit;
const lightnessRelativeArea = 255 / lightnessArea * 255;
const darknessRelativeArea = 255 / darknessArea * 255;

const onTheFlyRgbArrayUISaturatedColor = (colorArray: TColorRgbArray): TColorRgbArray => {
  // const str: string = colorArray[0];
  const rgb: number[] = colorArray.slice(1) as number[];

  const total: number = (rgb as number[]).reduce((accum: number, val: number) => accum + val, 0);

  let newRgb: number[] = rgb;

  if (total > lightnessLimit) {
    // GET 0 - 1 fraction for lightness amount and
    // multiply by the colour spectrum out of 255 (160 = (255 / 405) * 255)
    // that this lightness area falls into
    const lightnessAmount = (total - lightnessLimit) / lightnessArea * lightnessRelativeArea;
    // console.log(`Editing color total: ${total} - amount to reduce: ${lightnessAmount}`);
    newRgb = rgb.map(val => Math.round(Math.max(0, val - lightnessAmount)));
  }

  if (total < darknessLimit) {
    const darknessAmount = (1 - total / darknessLimit) * darknessRelativeArea;
    // console.log(`Editing color total: ${total} - amount to reduce: ${darknessAmount}`);
    newRgb = rgb.map(val => Math.round(Math.min(255, val + darknessAmount)));
  }

  // console.log(`rgb: (${str}) -> (${newRgb.join(", ")})`);

  return [newRgb.join(", "), newRgb[0], newRgb[1], newRgb[2]];
};

function darkenRgbArray(rgbArray: TColorRgbArray, amount: number): TColorRgbArray {
  const multiplier = 1 + (Math.abs(382 - (rgbArray[1] + rgbArray[2] + rgbArray[3])) / 382);

  // console.log(multiplier);

  const r = Math.max(rgbArray[1] - (amount * multiplier), 0);
  const g = Math.max(rgbArray[2] - (amount * multiplier), 0);
  const b = Math.max(rgbArray[3] - (amount * multiplier), 0);
  return [`${r}, ${g}, ${b}`, r, g, b];
}

function lightenRgbArray(rgbArray: TColorRgbArray, amount: number): TColorRgbArray {
  const r = Math.min(rgbArray[1] + amount, 255);
  const g = Math.min(rgbArray[2] + amount, 255);
  const b = Math.min(rgbArray[3] + amount, 255);
  return [`${r}, ${g}, ${b}`, r, g, b];
}

function desaturateRgbArray(rgbArray: TColorRgbArray, amount: number): TColorRgbArray {
  const average = Math.round((rgbArray[1] + rgbArray[2] + rgbArray[3]) / 3);
  const r = rgbArray[1] < average ? Math.min(average, rgbArray[1] + amount) : Math.max(average, rgbArray[1] - amount);
  const g = rgbArray[2] < average ? Math.min(average, rgbArray[2] + amount) : Math.max(average, rgbArray[2] - amount);
  const b = rgbArray[3] < average ? Math.min(average, rgbArray[3] + amount) : Math.max(average, rgbArray[3] - amount);
  return [`${r}, ${g}, ${b}`, r, g, b];
}

export const ColorUtils = {
  tinyColorToRgbArray,
  onTheFlyRgbArrayUISaturatedColor,
  createRgbArrayUISaturatedColor,
  createTinyColorUISaturatedColor,
  darkenRgbArray,
  lightenRgbArray,
  desaturateRgbArray,
};
