import { useEffect, useState } from "react";

export function useBrowserOnlyValues<T extends any>(createValues: () => T): T | undefined {
  const [values, setValues] = useState<T | undefined>(undefined);

  useEffect(() => {
    setValues(createValues());
  }, []);

  return values;
}
