import { differenceInDays, startOfDay } from "date-fns";
import React, { useState } from "react";
import { ArrayUtils } from "@gt/common-utils/build/datatypes/ArrayUtils";
import { PSC_VibescoutSpecial } from "../../../state/PSC_VibescoutSpecial";
import { EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { indexToMonthMMM, MovieUtils } from "../MovieUtils";
import { GoogleAnalyticsEventUtils } from "@gt/gt-frontend/build/analytics/GoogleAnalyticsEventUtils";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { HeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import { SimpleMovieTag } from "./MovieListItem";
import { useBrowserOnlyValues } from "@gt/gt-frontend/build/react/hooks/useBrowserOnlyValues";

interface ICPMovieShowtimes {
  scheduleTimes: any;
  cinemaBrandId: string;
  cinemaId: string;
  movieId: string;
  disableShowMoreClick?: boolean;
  defaultShowLimit?: number;
  bookNowUrl?: string;
}

export const MovieShowtimes = React.memo<ICPMovieShowtimes>(
  ({
    scheduleTimes,
    cinemaBrandId,
    disableShowMoreClick = false,
    defaultShowLimit = 1,
    bookNowUrl,
    cinemaId,
    movieId,
  }) => {
    const scheduleKeys = Object.keys(scheduleTimes);
    const todayStart = startOfDay(new Date());
    const todayTime = todayStart.getTime();

    const { UIStore } = PSC_VibescoutSpecial.useStores();
    const udi = UIStore.useState((s) => s.udi);
    const [showLimit, setShowLimit] = useState(defaultShowLimit);

    const onClient = useBrowserOnlyValues(() => {
      return true;
    });

    if (scheduleTimes && scheduleKeys.length > 0) {
      const screenBlobDayOffsets: number[] = [];
      let lowestOffset: number | null = null;
      let hasMoreDays = false;

      let screenBlobs = ArrayUtils.safeFmap(scheduleKeys, (screen) => {
        const [screenNumber, attributesString] = screen.split("___");
        const attributes = attributesString.split("---");
        let showingDays = 0;
        let currentDay: Date | null = null;
        const scheduledDays: Date[][] = [];
        let dayArray: Date[] = [];
        let prevTime = "";

        for (let i = 0; i < scheduleTimes[screen].length && showingDays < showLimit; i += 1) {
          const time = new Date(scheduleTimes[screen][i]);

          if (prevTime !== time.toString()) {
            if (todayTime < time.getTime()) {
              const thisTimesDay = startOfDay(time);

              if (currentDay != null && currentDay.getTime() !== thisTimesDay.getTime()) {
                showingDays += 1;
                scheduledDays.push(dayArray);
                hasMoreDays = true;
                dayArray = [];
              }

              if (showingDays < showLimit) {
                dayArray.push(time);
                prevTime = time.toString();
              }

              currentDay = thisTimesDay;
            }
          }
        }

        if (dayArray.length > 0) {
          scheduledDays.push(dayArray);
        }

        if (scheduledDays.length === 0) {
          return null;
        }

        screenBlobDayOffsets.push(differenceInDays(scheduledDays[0][0], todayStart));
        if (lowestOffset == null || screenBlobDayOffsets[screenBlobDayOffsets.length - 1] < lowestOffset) {
          lowestOffset = screenBlobDayOffsets[screenBlobDayOffsets.length - 1];
        }

        // <Tag key={attribute} active={false} id={attribute} name={attribute} size={EButtonSize.smallOptional} />

        return (
          <FelaFlexbox direction={"column"} key={screen}>
            <FelaFlexLayer height={ESurfaceHeight.deep} align={"center"} padding={"0.25em"} gap={0.5}>
              {attributes.map((attribute) => (
                <SimpleMovieTag key={attribute}>{attribute}</SimpleMovieTag>
              ))}
              <FelaText size={ETextSize.tiny}>
                {/^\d+$/.test(screenNumber) ? `SCREEN ${screenNumber}` : screenNumber.toUpperCase()}
              </FelaText>
            </FelaFlexLayer>
            <FelaFlexbox padding={0.5} direction={"column"}>
              {scheduledDays.map((dayTimes, index) => {
                return (
                  <FelaFlexbox
                    style={{
                      borderBottom: index !== scheduledDays.length - 1 ? "2px solid rgba(0,0,0,0.1)" : undefined,
                    }}
                    align={"center"}
                    key={dayTimes[0].toString()}
                  >
                    <FelaFlexbox align={"center"}>
                      <FelaText
                        contrast={EForeContrast.strong}
                        margin={"0 0.5em 0 0"}
                        size={ETextSize.small}
                        weight={ETextWeightType.bold}
                        transform={"uppercase"}
                      >
                        {MovieUtils.getWeekdayOrTodayTomorrow(dayTimes[0])}
                      </FelaText>
                      <FelaText
                        neutralColor={EAppColor.accent}
                        size={ETextSize.small}
                        className="text text-tiny"
                      >
                        {onClient
                          ? `${indexToMonthMMM[dayTimes[0].getMonth()]} ${dayTimes[0].getDate()}`
                          : `${indexToMonthMMM[dayTimes[0].getMonth()]} ...`}
                      </FelaText>
                    </FelaFlexbox>
                    <FelaFlexbox wrap={"wrap"} justify={"flex-end"} grow={1} align={"center"}>
                      {dayTimes.map((time) => {
                        return (
                          <FelaFlexLayer
                            height={ESurfaceHeight.highest}
                            radius={0.25}
                            margin={0.25}
                            padding={0.25}
                            key={time.toString()}
                            className="time text mar-05em-l"
                          >
                            {onClient ? (
                              <FelaText>
                                {`0${time.getHours()}`.slice(-2)}:{`0${time.getMinutes()}`.slice(-2)}
                              </FelaText>
                            ) : (
                              <FelaText>... : ...</FelaText>
                            )}
                          </FelaFlexLayer>
                        );
                      })}
                    </FelaFlexbox>
                  </FelaFlexbox>
                );
              })}
            </FelaFlexbox>
          </FelaFlexbox>
        );
      });

      if (showLimit === 1) {
        const screenBlobsPreLength = screenBlobs.length;

        screenBlobs = screenBlobs.filter((blob, index) => {
          return screenBlobDayOffsets[index] === lowestOffset;
        });

        if (screenBlobs.length < screenBlobsPreLength) {
          hasMoreDays = true;
        }
      }

      if (screenBlobs.length > 0) {
        const timesOpen = showLimit >= 100;

        return (
          <FelaFlexLayer
            clickable
            direction={"column"}
            position={"relative"}
            onClick={() => {
              if (!disableShowMoreClick) {
                GoogleAnalyticsEventUtils.runAnalyticEvent(
                  GoogleAnalyticsEventUtils.ANALYTICS_EVENT_TYPES.REGULAR_EVENT,
                  {
                    category: "Cinema Page",
                    action: `click-show-${showLimit < 100 ? "more" : "less"}-times`,
                    label: `${cinemaBrandId} : ${cinemaId} : ${movieId}`,
                  },
                );

                setShowLimit(showLimit < 100 ? 100 : 1);
              }
            }}
            // className={`${!disableShowMoreClick && "clickable"} movie-showtimes bg-blue-shadow col-white rel`}
          >
            <FelaFlexbox justify={"space-between"} align={"stretch"}>
              <FelaText
                weight={ETextWeightType.bold}
                size={ETextSize.smallHeader}
                margin={"0.25em 0.5em"}
                contrast={EForeContrast.extra}
              >
                SHOWTIMES
              </FelaText>
              {bookNowUrl && (
                <a
                  style={{ display: "flex" }}
                  href={bookNowUrl}
                  target={"_blank"}
                  onClick={() => {
                    GoogleAnalyticsEventUtils.runAnalyticEvent(
                      GoogleAnalyticsEventUtils.ANALYTICS_EVENT_TYPES.REGULAR_EVENT,
                      {
                        category: "Tickets",
                        action: `click-movie-book-now`,
                        label: `${cinemaBrandId}/${cinemaId}/${movieId}`,
                      },
                    );
                  }}
                >
                  <FelaFlexLayer
                    align={"center"}
                    grow={1}
                    radius={"0 0 0 0.25em"}
                    backgroundNeutralColor={EAppColor.accent}
                    pop
                    padding={"0.35em 0.55em"}
                    margin={"0 0 0.25em 0"}
                  >
                    <HeaderText forceTheme={EFrontendStyleConfigBaseTheme.DARK}>BOOK NOW</HeaderText>
                  </FelaFlexLayer>
                </a>
              )}
            </FelaFlexbox>
            {screenBlobs}
            {!disableShowMoreClick && (
              <FelaFlexLayer
                // backgroundNeutralColor={hasMoreDays ? EColorsNeutralName.go : undefined}
                height={hasMoreDays ? ESurfaceHeight.highest : ESurfaceHeight.low}
                shadow={hasMoreDays}
                extraSoftShadow={hasMoreDays}
                padding={"0.5em 0.75em"}
              >
                <FelaText
                  transform={"uppercase"}
                  weight={hasMoreDays ? ETextWeightType.black : undefined}
                  neutralColor={hasMoreDays && !timesOpen ? EAppColor.primary : undefined}
                  size={hasMoreDays ? ETextSize.content : ETextSize.small}
                  contrast={hasMoreDays && !timesOpen ? EForeContrast.strong : EForeContrast.subtle}
                >
                  {hasMoreDays
                    ? `${udi === EUDI.MOUSE ? "Click" : "Tap"} TO SEE ${!timesOpen ? "MORE" : "LESS"} TIMES`
                    : "No more times scheduled"}
                </FelaText>
              </FelaFlexLayer>
            )}
          </FelaFlexLayer>
        );
      }
    }

    return (
      <FelaFlexLayer
        height={ESurfaceHeight.deepest}
        padding={0.5}
        className="movie-showtimes pad-10em bg-blue-shadow col-white text fill-width"
      >
        <FelaText>Awaiting updates from cinema... Check back soon.</FelaText>
      </FelaFlexLayer>
    );
  },
);

/*{
  constructor(props) {
    super(props);

    this.today = startOfDay(new Date());
    this.todayTime = this.today.getTime();

    this.state = {
      showLimit: props.defaultShowLimit,
    };
  }

  showAll = () => {
    if (!disableShowMoreClick) {
      googleAnalyticsEvents.runAnalyticEvent(googleAnalyticsEvents.ANALYTICS_EVENT_TYPES.REGULAR_EVENT, {
        category: "Cinema Page",
        action: `click-show-${showLimit < 100 ? "more" : "less"}-times`,
        label: `${cinemaBrandId} : ${cinemaId} : ${movieId}`,
      });

      this.setState({
        showLimit: showLimit < 100 ? 100 : 1,
      });
    }
  };


}
*/
