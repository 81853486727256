import { NewsStoryBlock } from "../components/NewsStoryBlock";
import React from "react";

export const NewsStorySection = () => {
  return (
    <>
      <NewsStoryBlock
        doFollow={true}
        openBlank={true}
        story={{
          subTitle:
            "High-Quality & Affordable PPE Products. Simple Online Orders with Quick Delivery Anywhere in South Africa: Face Masks & Shields, Sanitizer, Thermometers, Gloves & More!",
          url: "https://justmedical.co.za",
          title: "Essential PPE Goods",
          titleImage: "/_static/vs-special/images/just_medical.jpeg",
        }}
      />
      <NewsStoryBlock
        doFollow={true}
        story={{
          subTitle:
            "President Cyril announced on 23rd April the National Lockdown will gradually be lifted in 5 levels, find out more about how this affects our work and personal lives as South Africans.",
          url: "https://www.vibescout.com/za/post/south-africa-lockdown-level-details-industry-conditions-rules",
          title: "New Phased Lockdown Levels 1 - 5, Conditions and Rules",
          titleImage:
            "https://storage.googleapis.com/vibescoutapp.appspot.com/vibescout/images/post/-M5cdlKXUmaMzgnteBO6/titleThumbnail.jpg",
        }}
      />
      <NewsStoryBlock
        doFollow={true}
        story={{
          url: "https://www.vibescout.com/za/post/covid-19-the-global-epidemic-south-africa-current-status",
          title: "Covid-19 South Africa | Symptoms, Prevention and Protection",
          titleImage:
            "https://storage.googleapis.com/vibescoutapp.appspot.com/vibescout/images/post/-M3KzPZnbQt0a6ky6Q2y/titleThumbnail.jpg",
        }}
      />
    </>
  );
};
