import React, { useEffect, useState } from "react";
import { PartyIcons } from "../../graphics/svg-icons/PartyIcons";
import {
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ETextSize,
  ETextTypographyType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { TFelaRule } from "@gt/gt-frontend/build/react/vibescout-ui/fela/types";
import { useFela } from "react-fela";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { useDelayedValue } from "@gt/gt-frontend/build/react/hooks/useDelayedValue";

export const loadingMessages = [
  "Cranking the Karaoke",
  "Hanging disco balls",
  "Mixing Margaritas",
  "Blowing balloons",
  "Saving the dates",
  "Calling foodtrucks",
  "Charging controllers",
  "Curating exhibitions",
  "Setting up stalls",
  "Decorating the venue",
  "Tapping the kegs",
];

export const loadingIcons = [
  <PartyIcons.Karaoke />,
  <PartyIcons.DiscoBall />,
  <PartyIcons.Cocktail />,
  <PartyIcons.Balloons />,
  <PartyIcons.Calendar />,
  <PartyIcons.IceCream />,
  <PartyIcons.GamePad />,
  <PartyIcons.Glasses />,
  <PartyIcons.Pizza />,
  <PartyIcons.EyeMask />,
  <PartyIcons.Beer />,
  // <PartyIcons.SunGlasses />,
];

interface IIconWithMessage {
  index: number;
  message: string;
  icon: React.ReactElement;
}

const iconsWithMessage: IIconWithMessage[] = loadingMessages.map((message, index) => ({
  message,
  icon: loadingIcons[index],
  index,
}));

const borderRadii = ["5em 1em 5em 1em", "1em 5em 1em 5em", "5em 1em 5em 1em", "1em 5em 1em 5em"];

const FelaRuleLoadingModal: TFelaRule<{ ind: number }> = ({ theme, ind }) => {
  let boxShadow = `0 0.2em 2em rgba(${
    theme.styles.Themes[theme.currentId].shadow.base[0]
  }, 0.25), 0 0.1em 0.33em rgba(${theme.styles.Themes[theme.currentId].shadow.base[0]}, 0.2)`;

  return {
    "> .icons": {
      "> div": {
        position: "absolute",
        width: "100%",
        height: "100%",
      },
      position: "relative",
      marginBottom: "0.5em",
      color: "white",
      fill: "white",
      shrink: 0,
      basis: "3.67em",
      width: "4em",
      height: "4em",
    },
    "> .text": {
      shrink: 0,
      basis: "1.5em",
    },
    padding: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "10em",
    height: "10em",
    overflow: "hidden",
    background: `rgba(${theme.styles.Colors.neutral.primary[0]}, 0.9)`,
    border: "2px solid white",
    borderRadius: borderRadii[ind],
    transition: "border-radius 1500ms ease-in-out",
    boxShadow,
  };
};

function FelaKeyFrame_IconSwing({ swingIn }) {
  return {
    "0%": {
      transform: swingIn ? "translateX(-200%)" : "translateX(0)",
      opacity: swingIn ? 0 : 1,
    },
    "75%": {
      transform: swingIn ? "translateX(0)" : "translateX(200%)",
      opacity: swingIn ? 1 : 0,
    },
    "100%": {
      transform: swingIn ? "translateX(0)" : "translateX(200%)",
      opacity: swingIn ? 1 : 0,
    },
  };
}

function FelaKeyFrame_Text() {
  return {
    "0%": {
      opacity: 0,
      transform: "translateX(-33%)",
    },
    "50%": {
      opacity: 1,
      transform: "translateX(0)",
    },
    "88%": {
      opacity: 1,
      transform: "translateX(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translateX(33%)",
    },
  };
}

const FelaKeyFrame_Layer: TFelaRule<{ op: boolean }> = ({ theme, op }) => {
  return {
    "0%": !op
      ? {
          transform: "translateY(40%)",
          opacity: 0,
          background: `rgba(${theme.styles.Colors.neutral.primary[0]}, 0.5)`,
        }
      : {
          opacity: 1,
          transform: "translateY(0)",
          background: `rgba(${theme.styles.Colors.neutral.primary[0]}, 0.9)`,
        },
    "40%": !op
      ? {
          transform: "translateY(0)",
          opacity: 0.8,
        }
      : {
          transform: "translateY(0%)",
          opacity: 1,
        },
    "85%": !op
      ? {
          transform: "translateY(0%)",
          opacity: 1,
        }
      : {
          transform: "translateY(0)",
          opacity: 0.8,
        },
    "100%": !op
      ? {
          transform: "translateY(0)",
          background: `rgba(${theme.styles.Colors.neutral.primary[0]}, 0.9)`,
        }
      : {
          transform: "translateY(40%)",
          opacity: 0,
          background: `rgba(${theme.styles.Colors.neutral.primary[0]}, 0.5)`,
        },
  };
};

const speed = 1618;

export const VibescoutLoadingModal = React.memo(() => {
  const [mesIndex, setMesIndex] = useState<number>(() => Math.floor(Math.random() * iconsWithMessage.length));

  const prevIndex = (mesIndex > 0 ? mesIndex - 1 : iconsWithMessage.length - 1) % iconsWithMessage.length;
  const curIndex = mesIndex % iconsWithMessage.length;
  const nextIndex = (mesIndex + 1) % iconsWithMessage.length;

  const { css, renderer, theme } = useFela({ ind: mesIndex % 4 }) as any;

  const [{ swingIn, swingOut, text, layer }] = useState(() => ({
    swingIn: renderer.renderKeyframe(FelaKeyFrame_IconSwing, { swingIn: true }),
    swingOut: renderer.renderKeyframe(FelaKeyFrame_IconSwing, { swingIn: false }),
    text: renderer.renderKeyframe(FelaKeyFrame_Text, {}),
    layer: renderer.renderKeyframe(FelaKeyFrame_Layer, { theme }),
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      setMesIndex((i) => i + 1);
    }, speed);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{ animation: `${layer} ${speed * 2}ms` }} className={css(FelaRuleLoadingModal)}>
      <div className={"icons"}>
        <div key={curIndex} style={{ animation: `${swingOut} ${speed}ms infinite` }} className={"current"}>
          {iconsWithMessage[curIndex].icon}
        </div>
        {/*<div className={"current"}>{iconsWithMessage[curIndex].icon}</div>*/}
        <div key={nextIndex} style={{ animation: `${swingIn} ${speed}ms infinite` }} className={"next"}>
          {iconsWithMessage[nextIndex].icon}
        </div>
      </div>
      <div className={"text"}>
        <FelaText
          key={curIndex}
          style={{ animation: `${text} ${speed}ms` }}
          align={"center"}
          size={ETextSize.small}
          typography={ETextTypographyType.mono}
          forceTheme={EFrontendStyleConfigBaseTheme.DARK}
          contrast={EForeContrast.strong}
        >
          {iconsWithMessage[nextIndex].message}
        </FelaText>
      </div>
    </div>
  );
});
