import * as StringUtils from "../datatypes/StringUtils";

const doubleDigitsPad = StringUtils.createPadder("0", 2);

export function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function getMonthDays(month, year) {
  return [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

export function createTodayDateString() {
  const today = new Date();
  return `${today.getUTCFullYear()}${doubleDigitsPad(today.getUTCMonth() + 1)}${doubleDigitsPad(today.getUTCDate())}`;
}

const TimePeriodMillis = {
  oneSecond: 1000,
  oneMinute: 60000,
  oneHour: 3600000,
  oneDay: 86400000,
};

const TimePeriodSeconds = {
  oneMinute: 60,
  oneHour: 3600,
  oneDay: 86400,
};

const TimePeriodMinutes = {
  oneHour: 60,
  oneDay: 1440,
};

const TimePeriodHours = {
  oneDay: 24,
};

const CalculateMillis = {
  secondsInMillis(seconds: number) {
    return TimePeriodMillis.oneSecond * seconds;
  },
  minutesInMillis(minutes: number) {
    return TimePeriodMillis.oneMinute * minutes;
  },
  hoursInMillis(hours: number) {
    return TimePeriodMillis.oneHour * hours;
  },
  daysInMillis(days: number) {
    return TimePeriodMillis.oneDay * days;
  },
};

const CalculateSeconds = {
  millisInSeconds(millis: number) {
    return millis / TimePeriodMillis.oneSecond;
  },
  minutesInSeconds(minutes: number) {
    return TimePeriodSeconds.oneMinute * minutes;
  },
  hoursInSeconds(hours: number) {
    return TimePeriodSeconds.oneHour * hours;
  },
  daysInSeconds(days: number) {
    return TimePeriodSeconds.oneDay * days;
  },
};

const CalculateMinutes = {
  millisInMinutes(millis: number) {
    return millis / TimePeriodMillis.oneMinute;
  },
  secondsInMinutes(seconds: number) {
    return seconds / TimePeriodSeconds.oneMinute;
  },
  hoursInMinutes(hours: number) {
    return hours * 60;
  },
  daysInMinutes(days: number) {
    return this.hoursInMinutes(24) * days;
  },
};

const CalculateHours = {
  /*millisInHours(millis: number) {
    return (millis / TimePeriodMillis.oneMinute);
  },
  secondsInHours(seconds: number) {
    return (seconds / TimePeriodSeconds.oneMinute);
  },*/
  minutesInHours(minutes: number) {
    return minutes / TimePeriodMinutes.oneHour;
  },
};

const CalculateDays = {
  hoursInDays(hours: number) {
    return hours / TimePeriodHours.oneDay;
  },
};

function getPracticalTimeString(value: number, unit: ETimeUnit): string {
  const allValues = getTimeValues(value, unit);

  const vals: IDisplayUnit[] = [];

  for (const orderAndUnits of timeUnitOrderAndUnits) {
    const val = allValues[orderAndUnits.unit];

    if (val != null) {
      vals.push({
        val,
        unit: orderAndUnits.fullUnit,
        pluralize: true,
      });
    }
  }

  return displayValues({ vals, pluralize: true, ignoreZeros: true });
}

export enum ETimeUnit {
  days = "d",
  hours = "h",
  minutes = "m",
  seconds = "s",
  milliseconds = "ms",
}

const timeUnitOrderAndUnits: { fullUnit: string; unit: ETimeUnit }[] = [
  { fullUnit: "day", unit: ETimeUnit.days },
  { fullUnit: "hour", unit: ETimeUnit.hours },
  { fullUnit: "minute", unit: ETimeUnit.minutes },
  { fullUnit: "second", unit: ETimeUnit.seconds },
  { fullUnit: "millisecond", unit: ETimeUnit.milliseconds },
];

const timeUnitInfo: {
  [key: string]: { max: number; next: ETimeUnit };
} = {
  [ETimeUnit.milliseconds]: { max: 1000, next: ETimeUnit.seconds },
  [ETimeUnit.seconds]: { max: 60, next: ETimeUnit.minutes },
  [ETimeUnit.minutes]: { max: 60, next: ETimeUnit.hours },
  [ETimeUnit.hours]: { max: 24, next: ETimeUnit.days },
  [ETimeUnit.days]: { max: -1, next: ETimeUnit.days },
};

interface IOGetTimeValuesOutput {
  [ETimeUnit.days]?: number;
  [ETimeUnit.hours]?: number;
  [ETimeUnit.minutes]?: number;
  [ETimeUnit.seconds]?: number;
  [ETimeUnit.milliseconds]?: number;
}

function getTimeValues(value, unit: ETimeUnit, cur?: IOGetTimeValuesOutput): IOGetTimeValuesOutput {
  if (cur == null) {
    cur = {};
  }

  const maxVal = timeUnitInfo[unit].max;

  if (maxVal > 0) {
    if (value >= maxVal) {
      const higherUnitVal = Math.floor(value / maxVal);
      cur = getTimeValues(higherUnitVal, timeUnitInfo[unit].next, cur);
    }

    cur[unit] = value % maxVal;
  } else {
    cur[unit] = value;
  }

  return cur;
}

interface IDisplayUnit {
  val: number;
  unit: string;
  pluralize?: boolean;
  ignoreZeros?: boolean;
}

interface IODisplayValuesInput {
  vals: IDisplayUnit[];
  ignoreZeros?: boolean;
  pluralize?: boolean;
}

function displayValues({
  vals,
  ignoreZeros: ignoreZerosOuter = true,
  pluralize: pluralizeOuter = true,
}: IODisplayValuesInput) {
  return vals
    .reduce((str, { val, unit, pluralize = pluralizeOuter, ignoreZeros = ignoreZerosOuter }) => {
      if (!ignoreZeros || val > 0) {
        if (val > 1 && pluralize) {
          str = `${str} ${val.toFixed(0)} ${unit}s`;
        } else {
          str = `${str} ${val.toFixed(0)} ${unit}`;
        }
      }
      return str;
    }, "")
    .trim();
}

function differenceMillis(timeOne: number | Date, timeTwo: number | Date): number {
  /*let a: number;

  if (typeof timeOne === "object") {
    timeOne.getTime();
  } else {
    a = timeOne;
  }

  let b: number;
  */

  return (
    (typeof timeTwo === "object" ? timeTwo.getTime() : timeTwo) -
    (typeof timeOne === "object" ? timeOne.getTime() : timeOne)
  );
}

export const TimeUtils = {
  differenceMillis,
  TimePeriodMillis,
  TimePeriodSeconds,
  CalculateMillis,
  CalculateSeconds,
  CalculateMinutes,
  createTodayDateString,
  isLeapYear,
  getMonthDays,
  getPracticalTimeString,
};
