import { EAppColor, ESurfaceHeight } from "../config/VibescoutFrontendStyleConfig";
import { EButtonSize, FelaButton } from "../fela/FelaButton";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { FelaFlexLayer } from "../fela/FelaLayers";
import React from "react";
import { FelaFlexbox } from "../fela/FelaFlexbox";

interface ICPShowMoreButton {
  onClick: () => void;
  buttonRef?: any;
  isOpen: boolean;
}

export const ButtonSpacer = ({ open }: { open: boolean }) => (
  <FelaFlexbox style={{ height: open ? "3.5em" : undefined }} gridColumn={"1 / -1"} />
);

export const ShowMoreButton = React.memo<ICPShowMoreButton>(({ buttonRef, onClick, isOpen }) => {
  return (
    <FelaFlexLayer
      zIndex={1}
      extraSoftShadow={isOpen}
      rootProps={{
        ref: buttonRef,
      }}
      style={{ pointerEvents: "all" }}
      backgroundOpacity={0.8}
      height={ESurfaceHeight.high}
      gradient={
        isOpen
          ? undefined
          : [
              {
                stops: [
                  { neutral: EAppColor.accent, opacity: isOpen ? 0.2 : 0, def: isOpen ? "0" : "15%" },
                  { neutral: EAppColor.accent, opacity: 0.5, def: isOpen ? "30%" : "51%" },
                  { height: ESurfaceHeight.high, opacity: 1, def: isOpen ? "31%" : "52%" },
                ],
              },
              {
                stops: [
                  { height: ESurfaceHeight.shadow, opacity: 0, def: "0%" },
                  { height: ESurfaceHeight.shadow, opacity: 0.5, def: "30%" },
                  { height: ESurfaceHeight.shadow, opacity: isOpen ? 0 : 1, def: "50%" },
                  { neutral: EAppColor.accent, opacity: isOpen ? 0 : 1, def: "51%" },
                ],
              },
            ]
      }
      // shadow
      // extraSoftShadow
      padding={isOpen ? 0 : "2em 0 0 0"}
      direction={"column"}
    >
      <FelaButton
        // margin={1}
        margin={"0.75em 0.6em"}
        size={EButtonSize.mainPageAction}
        neutralColor={EAppColor.accent}
        onClick={onClick}
      >
        {isOpen ? <FiMinusCircle /> : <FiPlusCircle />}
        <span>{isOpen ? "Show Less" : "Show More"}</span>
      </FelaButton>
    </FelaFlexLayer>
  );
});
