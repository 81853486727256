import { FelaFlexbox } from "../fela/FelaFlexbox";
import React, { useEffect, useRef, useState } from "react";
import { ZIndexes } from "../../../styling/VibescoutZIndexes";
import { useFela } from "react-fela";
import { EGraphicContrast, IFelaTheme } from "../fela/FelaCommon";
import { FelaIconWrap } from "../fela/FelaIconWrap";
import { FelaHeaderBar } from "../fela/FelaLayers";
import { FiX } from "react-icons/fi";
import { FelaText } from "../fela/FelaText";
import { EAppColor, ETextSize, ETextWeightType } from "../config/VibescoutFrontendStyleConfig";
import { FullAbsDiv } from "../common/FullAbsDiv";

export interface ICPForegroundFocusedBox {
  show: boolean;
  zIndex?: number;
  fadeTimeout?: number;
  showHeader?: boolean;
  header?: React.ReactElement;
  title?: string;
  onClose?: () => void;
  position?: "fixed" | "absolute";
  pushMaxWidth?: string;
}

export const ForegroundFadeInBox: React.FC<ICPForegroundFocusedBox> = ({
  children,
  show,
  zIndex = ZIndexes.foregroundFadeBox,
  fadeTimeout = 250,
  showHeader = false,
  header,
  title,
  onClose,
  position = "fixed",
  pushMaxWidth,
}) => {
  const { theme } = useFela<IFelaTheme>();
  const [lingeringShow, setLingeringShow] = useState(false);
  const timeoutRef = useRef<any>();
  const unmountedRef = useRef<boolean>(false);

  useEffect(() => {
    unmountedRef.current = false;
    if (show) {
      clearTimeout(timeoutRef.current);
      setLingeringShow(true);
      // openPortal();
    } else {
      timeoutRef.current = setTimeout(() => {
        if (!unmountedRef.current) {
          setLingeringShow(false);
        }
        // closePortal();
      }, fadeTimeout);
    }

    return () => {
      unmountedRef.current = true;
    };
  }, [show]);

  const shadowRgbColorString = theme.dark
    ? theme.styles.Colors.darkFore.dar[0]
    : theme.styles.Colors.darkBack.shadow[0];
  // const child = useMemo(() => children, []);
  // const child = children;
  // const shadowRgbColorString = theme.dark ? theme.styles.Colors.lightBack.shadow[0] : theme.styles.Colors.darkBack.shadow[0];
  // const shadowRgbColorString = theme.styles.Colors.darkBack.shadow[0];

  return (
    <FullAbsDiv style={{ pointerEvents: "none" }}>
      <FelaFlexbox
        align={"center"}
        justify={"center"}
        style={{
          zIndex,
          opacity: show ? 1 : 0,
          position,
          top: 0,
          left: 0,
          transition: `background ${fadeTimeout}ms ease-in-out, backdrop-filter ${fadeTimeout}ms ease-in-out, opacity ${fadeTimeout}ms ease-in-out`,
          background: `rgba(${shadowRgbColorString}, ${show ? `0.65` : `0`})`,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(2px)",
          overflow: "hidden",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            maxHeight: "calc(100%)",
            boxShadow: `0 0 1.5em 0.5em rgba(${shadowRgbColorString}, 0.7)`,
            overflow: "auto",
            transition: `transform ${fadeTimeout + 100}ms ease-in-out`,
            transform: `translateY(${show ? 0 : 35}%)`,
            pointerEvents: "all",
            width: pushMaxWidth ? pushMaxWidth : undefined,
            maxWidth: pushMaxWidth ? "100%" : undefined,
          }}
        >
          {(showHeader || header != null) && lingeringShow && (
            <FelaHeaderBar top={0} sticky zIndex={ZIndexes.higherBar} justify={"space-between"} gap={0.5}>
              <FelaFlexbox padding={"0.5em"} grow={1} align={"center"}>
                {title ? (
                  <FelaText
                    spacing={"0.08em"}
                    transform={"uppercase"}
                    neutralColor={EAppColor.primary}
                    weight={ETextWeightType.bold}
                    size={ETextSize.content}
                  >
                    {title}
                  </FelaText>
                ) : (
                  header
                )}
              </FelaFlexbox>
              <FelaIconWrap contrast={EGraphicContrast.HIGH} isButton hoverable onClick={onClose}>
                <FiX />
              </FelaIconWrap>
            </FelaHeaderBar>
          )}
          {show || lingeringShow ? children : null}
        </div>
      </FelaFlexbox>
    </FullAbsDiv>
  );
};
