import { TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { TObjectKeyEnumSet } from "@gt/common-utils/build/typescript/SpecialTypes";
import { EButtonSize } from "../fela/FelaButton";
import _ from "lodash";
import { IGradient, IGradientStop } from "../fela/FelaCommon";

export enum EAppColor {
  primary = "primary",
  accent = "accent",
  warning = "warning",
  error = "error",
  go = "go",
  loading = "loading",
}

export enum EPaletteColor {
  candy_pink = "candy_pink",
  watermelon_pink = "watermelon_pink",
  tomato_red = "tomato_red",
  steak_red = "steak_red",
  wine_red = "wine_red",
  patty_brown = "patty_brown",
  fire_orange = "fire_orange",
  orange = "orange",
  bun_beige = "bun_beige",
  banana_yellow = "banana_yellow",
  edam_yellow = "edam_yellow",
  lettuce_green = "lettuce_green",
  grass_green = "grass_green",
  aquamarine = "aquamarine",
  bubblegum_blue = "bubblegum_blue",
  blueberry_blue = "blueberry_blue",
}

export enum ESurfaceHeight {
  shadow = "shadow",
  deepest = "deepest",
  deep = "deep",
  low = "low",
  mid = "mid",
  upper = "upper",
  high = "high",
  highest = "highest",
}

export enum EForeContrast {
  subtle = "subtle",
  regular = "regular",
  extra = "extra",
  strong = "strong",
}

export enum ETextWeightType {
  light = "light",
  regular = "regular",
  bold = "bold",
  black = "black",
}

export enum ETextSize {
  huge = "huge",
  bigHeader = "bigHeader",
  smallHeader = "smallHeader",
  content = "content",
  small = "small",
  tiny = "tiny",
}

export enum EItalicStyleType {
  italic = "italic",
  oblique = "oblique",
}

export enum ETextTypographyType {
  serif = "serif",
  sansSerif = "sansSerif",
  mono = "mono",
  inherit = "inherit",
}

type TAppColors = TObjectKeyEnumSet<EAppColor, TColorRgbArray>;
type TAppColorsUnzipped = TObjectKeyEnumSet<EAppColor, string>;
type TPaletteColors = TObjectKeyEnumSet<EPaletteColor, TColorRgbArray>;
type TPaletteColorsUnzipped = TObjectKeyEnumSet<EPaletteColor, string>;

/*
const rainbowHexTest: TRainbowColorsRgb = {
  [EColorsRainbow.red]: "#ea5455",
  [EColorsRainbow.orange]: "#f07b3f",
  [EColorsRainbow.yellow]: "#ffd460",
  [EColorsRainbow.green]: "#61dc33",
  [EColorsRainbow.blue]: "#559afa",
  [EColorsRainbow.indigo]: "#9664f1",
  [EColorsRainbow.violet]: "#e54dd1",
};
*/

const backgroundFlowColors = {
  one: "#ff7774",
  two: "#ffbe37",
  three: "#3cc5f3",
  four: "#b667ff",
};

// type TRainbowColorsRgb = TObjectKeyEnumSet<EColorsRainbow, string>;
// type TNamedColorsRgb = TObjectKeyEnumSet<EColorsNamed, string>;

/*const colorsNamedHexTest: TNamedColorsRgb = {
  [EColorsNamed.bread]: "#ffce74",
};*/

/*const rainbowHexTest: TRainbowColorsRgb = {
  [EColorsRainbow.red]: "#ea5455",
  [EColorsRainbow.orange]: "#f07b3f",
  [EColorsRainbow.yellow]: "#ffd460",
  [EColorsRainbow.green]: "#61dc33",
  [EColorsRainbow.blue]: "#559afa",
  [EColorsRainbow.indigo]: "#9664f1",
  [EColorsRainbow.violet]: "#e54dd1",
};

const rainboxRgbTest: TRainbowColorsRgb = {
  [EColorsRainbow.red]: "rgb(234,84,85)",
  [EColorsRainbow.orange]: "rgb(240,123,63)",
  [EColorsRainbow.yellow]: "rgb(255,212,96)",
  [EColorsRainbow.green]: "rgb(97,220,51)",
  [EColorsRainbow.blue]: "rgb(85,154,250)",
  [EColorsRainbow.indigo]: "rgb(150,100,241)",
  [EColorsRainbow.violet]: "rgb(229,77,209)",
};*/

export enum EFrontendStyleConfigBaseTheme {
  DARK = "DARK",
  LIGHT = "LIGHT",
}

export interface IFrontendStyleConfigTheme {
  isDark: boolean;
  shadow: {
    base: TColorRgbArray;
    intensity: number;
  };
  Colors: {
    neutral: TAppColors;
    palette: TPaletteColors;
    surface: {
      [key in ESurfaceHeight]: TColorRgbArray;
    };
    fore: {
      [key in EForeContrast]: TColorRgbArray;
    };
    deepSurfaceStops: IGradientStop[];
    deepFore: {
      [key in EForeContrast]: TColorRgbArray;
    };
  };
}

export interface IFrontendStyleConfigThemeUnzipped extends IFrontendStyleConfigTheme {
  RgbColors: {
    neutral: TAppColorsUnzipped;
    palette: TPaletteColorsUnzipped;
    surface: {
      [key in ESurfaceHeight]: string;
    };
    fore: {
      [key in EForeContrast]: string;
    };
  };
}

type TTextFamilyWeights = { [key in ETextWeightType]: number };

export interface IFrontendStyleConfig {
  Themes: {
    [EFrontendStyleConfigBaseTheme.DARK]: IFrontendStyleConfigTheme;
    [EFrontendStyleConfigBaseTheme.LIGHT]: IFrontendStyleConfigTheme;
    [theme: string]: IFrontendStyleConfigTheme;
  };
  Button: {
    sizes: {
      [EButtonSize.mainPageAction]: number;
      [EButtonSize.regular]: number;
      [EButtonSize.smallOptional]: number;
      [EButtonSize.tiny]: number;
    };
  };
  Font: {
    defaultTypography: ETextTypographyType | string;
    family: {
      [ETextTypographyType.sansSerif]: string;
      [ETextTypographyType.serif]: string;
      [ETextTypographyType.mono]: string;
      [customFont: string]: string;
    };
    sizes: {
      [ETextSize.huge]: number;
      [ETextSize.bigHeader]: number;
      [ETextSize.smallHeader]: number;
      [ETextSize.content]: number;
      [ETextSize.small]: number;
      [ETextSize.tiny]: number;
    };
    defaultSpacing: {
      [ETextTypographyType.sansSerif]: number;
      [ETextTypographyType.serif]: number;
      [ETextTypographyType.mono]: number;
      [customFont: string]: number;
    };
    defaultLineHeight: {
      [ETextTypographyType.sansSerif]: number;
      [ETextTypographyType.serif]: number;
      [ETextTypographyType.mono]: number;
      [customFont: string]: number;
    };
    weights: {
      [ETextTypographyType.sansSerif]: TTextFamilyWeights;
      [ETextTypographyType.serif]: TTextFamilyWeights;
      [ETextTypographyType.mono]: TTextFamilyWeights;
      [customFont: string]: TTextFamilyWeights;
    };
    italicStyleType: {
      [ETextTypographyType.sansSerif]: EItalicStyleType;
      [ETextTypographyType.serif]: EItalicStyleType;
      [ETextTypographyType.mono]: EItalicStyleType;
      [customFont: string]: EItalicStyleType;
    };
  };
  Colors: {
    named: TPaletteColors;
    black: TColorRgbArray;
    white: TColorRgbArray;
    neutral: TAppColors;
    darkBack: {
      shadow: TColorRgbArray;
      dar: TColorRgbArray;
      mid: TColorRgbArray;
      lig: TColorRgbArray;
      lit: TColorRgbArray;
    };
    darkFore: {
      dar: TColorRgbArray;
      mid: TColorRgbArray;
      lig: TColorRgbArray;
    };
    lightBack: {
      shadow: TColorRgbArray;
      lit: TColorRgbArray;
      lig: TColorRgbArray;
      mid: TColorRgbArray;
      dar: TColorRgbArray;
    };
    lightFore: {
      lig: TColorRgbArray;
      mid: TColorRgbArray;
      dar: TColorRgbArray;
    };
  };
}

type TUnzippedThemes = {
  [EFrontendStyleConfigBaseTheme.DARK]: IFrontendStyleConfigThemeUnzipped;
  [EFrontendStyleConfigBaseTheme.LIGHT]: IFrontendStyleConfigThemeUnzipped;
  [theme: string]: IFrontendStyleConfigThemeUnzipped;
};

export interface IFrontendStyleConfigUnzipped extends IFrontendStyleConfig {
  Themes: TUnzippedThemes;
  RgbColors: {
    black: string;
    white: string;
    neutral: TAppColorsUnzipped;
    darkBack: {
      shadow: string;
      dar: string;
      mid: string;
      lig: string;
      lit: string;
    };
    darkFore: {
      dar: string;
      mid: string;
      lig: string;
    };
    lightBack: {
      shadow: string;
      lit: string;
      lig: string;
      mid: string;
      dar: string;
    };
    lightFore: {
      lig: string;
      mid: string;
      dar: string;
    };
  };
  RgbaColors: {
    shadowDarkest: string;
    shadowDark: string;
    shadowMid: string;
    shadowLight: string;
    shadowLightest: string;
  };
}

const namedDefaults: TPaletteColors = {
  [EPaletteColor.candy_pink]: ["234,84,85", 234, 84, 85],
  [EPaletteColor.watermelon_pink]: ["234,84,85", 234, 84, 85],
  [EPaletteColor.tomato_red]: ["240,123,63", 240, 123, 63],
  [EPaletteColor.steak_red]: ["255,212,96", 255, 212, 96],
  [EPaletteColor.wine_red]: ["97,220,51", 97, 220, 51],
  [EPaletteColor.patty_brown]: ["229,77,209", 229, 77, 209],
  [EPaletteColor.fire_orange]: ["85,154,250", 85, 154, 250],
  [EPaletteColor.orange]: ["150,100,241", 150, 100, 241],
  [EPaletteColor.bun_beige]: ["150,100,241", 150, 100, 241],
  [EPaletteColor.banana_yellow]: ["150,100,241", 150, 100, 241],
  [EPaletteColor.edam_yellow]: ["150,100,241", 150, 100, 241],
  [EPaletteColor.lettuce_green]: ["150,100,241", 150, 100, 241],
  [EPaletteColor.grass_green]: ["150,100,241", 150, 100, 241],
  [EPaletteColor.aquamarine]: ["150,100,241", 150, 100, 241],
  [EPaletteColor.bubblegum_blue]: ["150,100,241", 150, 100, 241],
  [EPaletteColor.blueberry_blue]: ["150,100,241", 150, 100, 241],
};

const neutralDefaults: TAppColors = {
  primary: [`244, 42, 66`, 244, 42, 66],
  accent: [`76, 175, 247`, 76, 175, 247],
  warning: [`235, 136, 0`, 235, 136, 0],
  error: [`255, 79, 15`, 255, 79, 15],
  go: [`36, 207, 94`, 36, 207, 94],
  loading: [`76, 175, 247`, 76, 175, 247],
};

const darkTheme: IFrontendStyleConfigTheme = {
  isDark: true,
  shadow: {
    base: ["3, 4, 8", 3, 4, 8],
    intensity: 0.25,
  },
  Colors: {
    palette: namedDefaults,
    neutral: neutralDefaults,
    fore: {
      [EForeContrast.strong]: ["242, 244, 247", 242, 244, 247],
      [EForeContrast.extra]: ["212, 214, 227", 212, 214, 227],
      [EForeContrast.regular]: ["153, 169, 194", 153, 169, 194],
      [EForeContrast.subtle]: ["122, 136, 157", 122, 136, 157],
    },
    surface: {
      [ESurfaceHeight.shadow]: ["12, 17, 23", 12, 17, 23],
      [ESurfaceHeight.deepest]: ["13, 24, 39", 13, 24, 39],
      [ESurfaceHeight.deep]: ["24, 37, 56", 24, 37, 56],
      [ESurfaceHeight.low]: ["29, 45, 62", 29, 45, 62],
      [ESurfaceHeight.mid]: ["38, 52, 71", 38, 52, 71],
      [ESurfaceHeight.upper]: ["42, 57, 79", 42, 57, 79],
      [ESurfaceHeight.high]: ["48, 64, 87", 48, 64, 87],
      [ESurfaceHeight.highest]: ["58, 74, 97", 58, 74, 97],
    },
    deepFore: {
      [EForeContrast.strong]: ["242, 244, 247", 242, 244, 247],
      [EForeContrast.extra]: ["212, 214, 227", 212, 214, 227],
      [EForeContrast.regular]: ["153, 169, 194", 153, 169, 194],
      [EForeContrast.subtle]: ["122, 136, 157", 122, 136, 157],
    },
    deepSurfaceStops: [
      {
        colorString: "rgb(20,19,57)",
        def: "0",
      },
      {
        colorString: "rgb(20,38,47)",
        darken: 120,
        def: "40em",
      },
      {
        colorString: "rgb(20,19,57)",
        def: "80em",
      },
    ],
    // deepSurface: {},
  },
};

const lightTheme: IFrontendStyleConfigTheme = {
  isDark: false,
  shadow: {
    base: ["12, 18, 25", 12, 18, 25],
    intensity: 0.1,
  },
  Colors: {
    palette: namedDefaults,
    neutral: neutralDefaults,
    deepFore: {
      [EForeContrast.strong]: ["242, 244, 247", 242, 244, 247],
      [EForeContrast.extra]: ["212, 214, 227", 212, 214, 227],
      [EForeContrast.regular]: ["153, 169, 194", 153, 169, 194],
      [EForeContrast.subtle]: ["122, 136, 157", 122, 136, 157],
    },
    deepSurfaceStops: [
      {
        colorString: "rgb(91,127,217)",
        def: "0",
      },
      {
        colorString: "rgb(60,175,146)",
        def: "40em",
      },
      {
        colorString: "rgb(60,175,146)",
        def: "50em",
      },
      {
        colorString: "rgb(91,127,217)",
        def: "80em",
      },
      {
        colorString: "rgb(78, 102, 180)",
        def: "120em",
      },
      {
        colorString: "rgb(78, 102, 180)",
        def: "125em",
      },
      {
        colorString: "rgb(91,127,217)",
        def: "160em",
      },
    ],
    fore: {
      [EForeContrast.strong]: ["24, 37, 56", 24, 37, 56],
      [EForeContrast.extra]: ["45, 66, 96", 45, 66, 96],
      [EForeContrast.regular]: ["83, 98, 120", 83, 98, 120],
      [EForeContrast.subtle]: ["120, 143, 152", 120, 143, 152],
    },
    surface: {
      [ESurfaceHeight.shadow]: ["42, 53, 86", 42, 53, 86],
      // [ESurfaceHeight.shadow]: ["53, 85, 190", 53, 85, 190],
      [ESurfaceHeight.deepest]: ["203, 204, 216", 203, 204, 216],
      [ESurfaceHeight.deep]: ["217, 221, 229", 217, 221, 229],
      [ESurfaceHeight.low]: ["224, 229, 236", 224, 229, 236],
      [ESurfaceHeight.mid]: ["234, 238, 242", 234, 238, 242],
      [ESurfaceHeight.upper]: ["241, 242, 248", 241, 242, 248],
      [ESurfaceHeight.high]: ["248, 248, 253", 248, 248, 253],
      [ESurfaceHeight.highest]: ["252, 252, 255", 252, 252, 255],
    },
  },
};

export const DefaultVibescoutThemes = {
  darkTheme,
  lightTheme,
};

export const VibescoutFrontendStyleConfig: IFrontendStyleConfig = {
  Button: {
    sizes: {
      [EButtonSize.mainPageAction]: 2.85,
      [EButtonSize.regular]: 2.2,
      [EButtonSize.smallOptional]: 1.85,
      [EButtonSize.tiny]: 1.45,
    },
  },
  Font: {
    family: {
      sansSerif: "'Roboto', sans-serif",
      serif: "serif",
      mono: "'Ubuntu Mono', monospace",
      inherit: "inherit",
    },
    defaultTypography: ETextTypographyType.sansSerif,
    sizes: {
      [ETextSize.huge]: 2,
      [ETextSize.bigHeader]: 1.5,
      [ETextSize.smallHeader]: 1.31,
      [ETextSize.content]: 1,
      [ETextSize.small]: 0.85,
      [ETextSize.tiny]: 0.7,
    },
    defaultSpacing: {
      [ETextTypographyType.sansSerif]: 0.035,
      [ETextTypographyType.serif]: 0.035,
      [ETextTypographyType.mono]: 0.01,
    },
    defaultLineHeight: {
      [ETextTypographyType.sansSerif]: 1.4,
      [ETextTypographyType.serif]: 1.4,
      [ETextTypographyType.mono]: 1.2,
    },
    weights: {
      [ETextTypographyType.sansSerif]: {
        [ETextWeightType.light]: 400,
        [ETextWeightType.regular]: 500,
        [ETextWeightType.bold]: 700,
        [ETextWeightType.black]: 900,
      },
      [ETextTypographyType.mono]: {
        [ETextWeightType.light]: 400,
        [ETextWeightType.regular]: 500,
        [ETextWeightType.bold]: 700,
        [ETextWeightType.black]: 700,
      },
      [ETextTypographyType.serif]: {
        [ETextWeightType.light]: 300,
        [ETextWeightType.regular]: 500,
        [ETextWeightType.bold]: 700,
        [ETextWeightType.black]: 700,
      },
    },
    italicStyleType: {
      [ETextTypographyType.sansSerif]: EItalicStyleType.italic,
      [ETextTypographyType.serif]: EItalicStyleType.italic,
      [ETextTypographyType.mono]: EItalicStyleType.italic,
    },
  },
  Themes: {
    [EFrontendStyleConfigBaseTheme.DARK]: darkTheme,
    [EFrontendStyleConfigBaseTheme.LIGHT]: lightTheme,
  },
  Colors: {
    black: [`0, 0, 0`, 0, 0, 0],
    white: [`255, 255, 255`, 255, 255, 255],
    named: {
      ...namedDefaults,
    },
    neutral: {
      primary: [`244, 42, 66`, 244, 42, 66],
      accent: [`76, 175, 247`, 76, 175, 247],
      warning: [`235, 136, 0`, 235, 136, 0],
      error: [`255, 79, 15`, 255, 79, 15],
      go: [`36, 207, 94`, 36, 207, 94],
      loading: [`76, 175, 247`, 76, 175, 247],
    },
    darkBack: {
      shadow: [`13, 24, 39`, 13, 24, 39],
      dar: [`24, 37, 56`, 24, 37, 56],
      mid: [`38, 52, 71`, 38, 52, 71],
      lig: [`48, 64, 87`, 48, 64, 87],
      lit: [`58, 74, 97`, 58, 74, 97],
    },
    darkFore: {
      dar: [`112, 126, 147`, 112, 126, 147],
      mid: [`153, 169, 194`, 153, 169, 194],
      lig: [`242, 244, 247`, 242, 244, 247],
    },
    lightBack: {
      shadow: [`203, 204, 216`, 203, 204, 216],
      lit: [`252, 252, 255`, 252, 252, 255],
      lig: [`248, 248, 253`, 248, 248, 253],
      mid: [`234, 238, 242`, 234, 238, 242],
      dar: [`217, 221, 229`, 217, 221, 229],
    },
    lightFore: {
      lig: [`150, 160, 173`, 150, 160, 173],
      mid: [`93, 108, 130`, 93, 108, 130],
      dar: [`24, 37, 56`, 24, 37, 56],
    },
  },
};

function unzipColorsDeep(colors: any): any {
  const newObject = {};

  for (const key of Object.keys(colors)) {
    if (Array.isArray(colors[key])) {
      newObject[key] = `rgb(${(colors[key] as TColorRgbArray)[0]})`;
    } else {
      newObject[key] = unzipColorsDeep(colors[key]);
    }
  }

  return newObject;
}

export const unzipFrontendStyleConfig = (style: IFrontendStyleConfig): IFrontendStyleConfigUnzipped => {
  return {
    Button: style.Button,
    Font: style.Font,
    Colors: style.Colors,
    RgbColors: unzipColorsDeep(style.Colors),
    Themes: _.mapValues(style.Themes, unzipFrontendStyleTheme) as TUnzippedThemes,
    RgbaColors: {
      shadowDarkest: `rgba(${style.Colors.darkBack.shadow[0]}, 0.9)`,
      shadowDark: `rgba(${style.Colors.darkBack.shadow[0]}, 0.7)`,
      shadowMid: `rgba(${style.Colors.darkBack.shadow[0]}, 0.5)`,
      shadowLight: `rgba(${style.Colors.darkBack.shadow[0]}, 0.3)`,
      shadowLightest: `rgba(${style.Colors.darkBack.shadow[0]}, 0.15)`,
    },
  };
};

export const unzipFrontendStyleTheme = (val: IFrontendStyleConfigTheme): IFrontendStyleConfigThemeUnzipped => ({
  isDark: val.isDark,
  Colors: val.Colors,
  RgbColors: unzipColorsDeep(val.Colors),
  shadow: val.shadow,
});
