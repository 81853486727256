import { GeoLevelStatic } from "../../../data/crossover/geoLevels/GeoLevelStatic";
import { RegexUtils } from "@gt/common-utils/build/general/RegexUtils";
import { TaskFunctionError } from "@gt/common-utils/build/taskFunction/TaskFunctionUtils";
import { TFRFailure } from "@gt/common-utils/build/taskFunction/TaskFunctionResponses";
import { ETaskFunctionEndId } from "@gt/common-utils/build/taskFunction/TaskFunctionTypes";
import { EGeoLevelShortLevel } from "../../../data/crossover/geoLevels/GeoLevelTypes";
import { EGeoLevelSortDirection, IOConvertLevelToUrl, IOCreateShortGeoLevelInput } from "../GeoLevelUtilTypes";

export const CompressedCode = {
  Continent: "!", // 0
  // Country: "",   // 1
  State: "(", // 2
  Municipality: ")", // 3
  City: "*", // 4
  Suburb: ".", // 5
  SubSuburb: "_", // 6
};

const regexExtractCity = /\*([^!()*._]+)/;
const regexExtractMunicipality = /\)([^!()*._]+)/;
const regexExtractState = /\(([^!()*._]+)/;
const regexExtractSuburb = /\.([^!()*._]+)/;
const regexExtractSubSuburb = /_([^!()*._]+)/;

interface IGeoLvlParts {
  // NEEDS TO BE ABLE TO GET CONTINENT FROM COUNTRY CODE
  cn?: string;
  cc?: string;
  st?: string;
  mu?: string;
  ci?: string;
  su?: string;
  ss?: string;
}

function getPartsForCompressedGeoLevel(geoLvl: string): IGeoLvlParts {
  const parts: IGeoLvlParts = {};

  if (geoLvl[0] === "!") {
    parts.cn = `${GeoLevelStatic.continentMaps.continentCodeToMeta.get(geoLvl.substr(1, 2))!.urlPart}`;
    return parts;
  }

  parts.cc = `${geoLvl.substr(0, 2)}`;

  if (geoLvl.includes(CompressedCode.City)) {
    parts.ci = RegexUtils.getFirstCaptureOrNull(regexExtractCity, geoLvl)!;
    // url = `${url}/city/${RegexUtils.getFirstCaptureOrNull(regexExtractCity, geoLvl)}`;

    if (geoLvl.includes(CompressedCode.Municipality)) {
      parts.mu = RegexUtils.getFirstCaptureOrNull(regexExtractMunicipality, geoLvl)!;
      // url = `${url}_${RegexUtils.getFirstCaptureOrNull(regexExtractMunicipality, geoLvl)}`;
    }

    if (geoLvl.includes(CompressedCode.State)) {
      parts.st = RegexUtils.getFirstCaptureOrNull(regexExtractState, geoLvl)!;
      // url = `${url}_${RegexUtils.getFirstCaptureOrNull(regexExtractState, geoLvl)}`;
    }

    if (geoLvl.includes(CompressedCode.Suburb)) {
      parts.su = RegexUtils.getFirstCaptureOrNull(regexExtractSuburb, geoLvl)!;
      // url = `${url}/suburb/${RegexUtils.getFirstCaptureOrNull(regexExtractSuburb, geoLvl)}`;
    }

    if (geoLvl.includes(CompressedCode.SubSuburb)) {
      parts.ss = RegexUtils.getFirstCaptureOrNull(regexExtractSubSuburb, geoLvl)!;
      // url = `${url}/area/${RegexUtils.getFirstCaptureOrNull(regexExtractSubSuburb, geoLvl)}`;
    }
  } else if (geoLvl.includes(CompressedCode.Municipality)) {
    parts.mu = RegexUtils.getFirstCaptureOrNull(regexExtractMunicipality, geoLvl)!;
    // url = `${url}/municipality/${RegexUtils.getFirstCaptureOrNull(regexExtractMunicipality, geoLvl)}`;

    if (geoLvl.includes(CompressedCode.State)) {
      parts.st = RegexUtils.getFirstCaptureOrNull(regexExtractState, geoLvl)!;
      // url = `${url}_${RegexUtils.getFirstCaptureOrNull(regexExtractState, geoLvl)}`;
    }
  } else if (geoLvl.includes(CompressedCode.State)) {
    parts.st = RegexUtils.getFirstCaptureOrNull(regexExtractState, geoLvl)!;
    // url = `${url}/state/${RegexUtils.getFirstCaptureOrNull(regexExtractState, geoLvl)}`;
  }

  return parts;
}

function convertCompressedToUrl({ geoLvl }: IOConvertLevelToUrl): string {
  if (geoLvl[0] === "!") {
    return `${GeoLevelStatic.continentMaps.continentCodeToMeta.get(geoLvl.substr(1, 2))!.urlPart}`;
  }

  let url = `${geoLvl.substr(0, 2)}`;

  if (geoLvl.includes(CompressedCode.City)) {
    url = `${url}/city/${RegexUtils.getFirstCaptureOrNull(regexExtractCity, geoLvl)}`;

    if (geoLvl.includes(CompressedCode.Municipality)) {
      url = `${url}_${RegexUtils.getFirstCaptureOrNull(regexExtractMunicipality, geoLvl)}`;
    }

    if (geoLvl.includes(CompressedCode.State)) {
      url = `${url}_${RegexUtils.getFirstCaptureOrNull(regexExtractState, geoLvl)}`;
    }

    if (geoLvl.includes(CompressedCode.Suburb)) {
      url = `${url}/suburb/${RegexUtils.getFirstCaptureOrNull(regexExtractSuburb, geoLvl)}`;
    }

    if (geoLvl.includes(CompressedCode.SubSuburb)) {
      url = `${url}/area/${RegexUtils.getFirstCaptureOrNull(regexExtractSubSuburb, geoLvl)}`;
    }
  } else if (geoLvl.includes(CompressedCode.Municipality)) {
    url = `${url}/municipality/${RegexUtils.getFirstCaptureOrNull(regexExtractMunicipality, geoLvl)}`;

    if (geoLvl.includes(CompressedCode.State)) {
      url = `${url}_${RegexUtils.getFirstCaptureOrNull(regexExtractState, geoLvl)}`;
    }
  } else if (geoLvl.includes(CompressedCode.State)) {
    url = `${url}/state/${RegexUtils.getFirstCaptureOrNull(regexExtractState, geoLvl)}`;
  }

  return url;
}

function convertUrlToCompressed({
  continentOrCountry,
  cityPart,
  suburbPart,
  subSuburbPart,
  municipalityPart,
  statePart,
}: {
  continentOrCountry: string;
  municipalityPart?: string;
  statePart?: string;
  cityPart?: string;
  suburbPart?: string;
  subSuburbPart?: string;
}): string {
  let compressed: string;

  if (continentOrCountry.length > 2) {
    return `${CompressedCode.Continent}${
      GeoLevelStatic.continentMaps.continentUrlToMeta.get(continentOrCountry)!.code
    }`;
  } else {
    compressed = `${continentOrCountry}`;
  }

  if (cityPart) {
    const parts = cityPart.split("_");

    let extra = "";

    if (suburbPart) {
      extra = `${CompressedCode.Suburb}${suburbPart}`;
    }

    if (subSuburbPart) {
      extra = `${extra}${CompressedCode.SubSuburb}${subSuburbPart}`;
    }

    if (parts.length === 1) {
      return `${compressed}${CompressedCode.City}${parts[0]}${extra}`;
    }

    if (parts.length === 2) {
      return `${compressed}${CompressedCode.State}${parts[1]}${CompressedCode.City}${parts[0]}${extra}`;
    }

    if (parts.length === 3) {
      return `${compressed}${CompressedCode.State}${parts[2]}${CompressedCode.Municipality}${parts[1]}${CompressedCode.City}${parts[0]}${extra}`;
    }

    throw new TaskFunctionError(TFRFailure(ETaskFunctionEndId.DATA_VALIDATION_FAILED, `Got incorrect city part`));
  }

  if (municipalityPart) {
    const parts = municipalityPart.split("_");
    return `${compressed}${CompressedCode.State}${parts[1]}${CompressedCode.Municipality}${parts[0]}`;
  }

  if (statePart) {
    return `${compressed}${CompressedCode.State}${statePart}`;
  }

  return compressed;
}

function createCompressedShortLevel({ cn, cc, st, mu, ci, su, ss, shortLvl }: IOCreateShortGeoLevelInput): string {
  if (!cc) {
    return `${CompressedCode.Continent}${cn}`;
  }

  let geoLvl = `${cc}`;

  if (!st && !ci && !mu) {
    return geoLvl;
  }

  if (!mu && !ci) {
    return `${geoLvl}${CompressedCode.State}${st}`;
  }

  if (!ci) {
    return `${geoLvl}${CompressedCode.State}${st}${CompressedCode.Municipality}${mu}`;
  }

  switch (shortLvl) {
    case EGeoLevelShortLevel.country_alone:
    case EGeoLevelShortLevel.country_canonical:
      geoLvl = `${geoLvl}${CompressedCode.City}${ci}`;
      break;
    case EGeoLevelShortLevel.state_alone:
    case EGeoLevelShortLevel.state_canonical:
      geoLvl = `${geoLvl}${CompressedCode.State}${st}${CompressedCode.City}${ci}`;
      break;
    case EGeoLevelShortLevel.municipal_alone:
    case EGeoLevelShortLevel.inapplicable:
    default:
      geoLvl = `${geoLvl}${CompressedCode.State}${st}${CompressedCode.Municipality}${mu}${CompressedCode.City}${ci}`;
      break;
  }

  if (!su) {
    return geoLvl;
  }

  if (!ss) {
    return `${geoLvl}${CompressedCode.Suburb}${su}`;
  }

  return `${geoLvl}${CompressedCode.Suburb}${su}${CompressedCode.SubSuburb}${ss}`;
}

function getEstimatedLvlValueForGeoLevelCompressed(geoLevel: string): number {
  if (geoLevel[0] === CompressedCode.Continent) {
    return 10;
  }

  if (geoLevel.length === 2) {
    return 20;
  }

  const codes = geoLevel.replace(/[^!()*._]+/g, "");

  switch (codes[codes.length - 1]) {
    case CompressedCode.SubSuburb:
      return 110;
    case CompressedCode.Suburb:
      return 100;
    case CompressedCode.City:
      return 80;
    case CompressedCode.Municipality:
      return 50;
    case CompressedCode.State:
      return 30;
  }

  throw new TaskFunctionError(
    TFRFailure(ETaskFunctionEndId.DATA_VALIDATION_FAILED, `GeoLevel: "${geoLevel}" can't be sorted properly`),
  );
}

function sortGeoLevelCompressedArray(
  geoLevels: string[],
  direction: EGeoLevelSortDirection = EGeoLevelSortDirection.asc,
): string[] {
  return geoLevels.sort((gl1, gl2) => {
    let difference = getEstimatedLvlValueForGeoLevelCompressed(gl1) - getEstimatedLvlValueForGeoLevelCompressed(gl2);

    if (difference === 0) {
      difference = gl1 > gl2 ? 1 : -1;
    }

    if (direction === EGeoLevelSortDirection.asc) {
      return difference;
    }

    return difference * -1;
  });
}

function compressLvl(lvl: string): string {
  let newLvl = lvl.replace("gl_cn~", CompressedCode.Continent);
  newLvl = newLvl.replace("gl_cc~", "");
  newLvl = newLvl.replace("_st~", CompressedCode.State);
  newLvl = newLvl.replace("_mu~", CompressedCode.Municipality);
  newLvl = newLvl.replace("_ci~", CompressedCode.City);
  newLvl = newLvl.replace("_su~", CompressedCode.Suburb);
  newLvl = newLvl.replace("_ss~", CompressedCode.SubSuburb);
  return newLvl;
}

function decompressLvl(lvl: string): string {
  let newLvl = lvl[0] === CompressedCode.Continent ? lvl.replace(CompressedCode.Continent, "gl_cn~") : `gl_cc~${lvl}`;
  // newLvl = newLvl.replace("1", "gl_cc~");
  newLvl = newLvl.replace(CompressedCode.State, "_st~");
  newLvl = newLvl.replace(CompressedCode.Municipality, "_mu~");
  newLvl = newLvl.replace(CompressedCode.City, "_ci~");
  newLvl = newLvl.replace(CompressedCode.Suburb, "_su~");
  newLvl = newLvl.replace(CompressedCode.SubSuburb, "_ss~");
  return newLvl;
}

export const GeoLevelCompressUtils = {
  getPartsForCompressedGeoLevel,
  convertUrlToCompressed,
  convertCompressedToUrl,
  createCompressedShortLevel,
  sortGeoLevelCompressedArray,
  compressLvl,
  decompressLvl,
};
