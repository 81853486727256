export interface IOGetPartsForOldPostIdOutput {
  cc: string;
  ci?: string;
  id: string;
}

function getPartsForOldPostId(postId): IOGetPartsForOldPostIdOutput {
  const [cc, ciOrId, ...rest] = postId.split("/");

  if (rest.length > 0) {
    return {
      cc,
      ci: ciOrId,
      id: rest[0],
    };
  }

  return {
    cc,
    id: ciOrId,
  };
}

function getNewUrlForOldPostId(postId): string {
  const { cc, ci, id } = getPartsForOldPostId(postId);

  if (ci) {
    return `${cc}/city/${ci}/post/${id}`;
  }

  return `${cc}/post/${id}`;
}

function makeOldPostIdFromParts({ id, ci, cc }: IOGetPartsForOldPostIdOutput): string {
  if (ci) {
    return `${cc}/${ci}/${id}`;
  }

  return `${cc}/${id}`;
}

export const OldPostUtils = {
  getPartsForOldPostId,
  getNewUrlForOldPostId,
  makeOldPostIdFromParts
};
