import { Context } from "koa";
import Joi, { Schema } from "joi";
import {
  EInternalTaskIds,
  ETaskFunctionEndId,
  IFractionFeedback,
  ITaskFunctionNegativeResponse,
  ITaskFunctionPositiveResponse,
  ITaskFunctionResponse,
  ITaskProgressUpdate,
  TFRPromise,
  TObjectOfTaskFunctions,
} from "./TaskFunctionTypes";
import { TFR, TFRFailure, TFRSuccess } from "./TaskFunctionResponses";
import { _getEndIdForHttpStatusCode, _getHttpStatusCodeForEndId } from "./TaskFunctionUtils/_getHttpStatusCodeForEndId";

export function hasEndTags<TP, TE, ET extends string>(
  response: ITaskFunctionResponse<TP, TE, ET>,
  ...tags: ET[]
): boolean {
  for (const tag of tags) {
    if (response.endTags.indexOf(tag) === -1) {
      return false;
    }
  }

  return true;
}

export function isPositive<TP, TE>(
  response: ITaskFunctionResponse<TP, TE>,
): response is ITaskFunctionPositiveResponse<TP> {
  return response.positive;
}

export function isNegative<TP, TE>(
  response: ITaskFunctionResponse<TP, TE>,
): response is ITaskFunctionNegativeResponse<TE> {
  return !response.positive;
}

export async function runTaskFunctionOnObject<T extends TObjectOfTaskFunctions>(
  obj: T,
  key: keyof T,
  ...args
): Promise<ITaskFunctionResponse> {
  const func: any = obj[key];

  if (func == null) {
    return TFR({
      positive: false,
      endId: ETaskFunctionEndId.NOT_FOUND,
      errorPayload: null,
      payload: null,
      endMessage: `Function not found for key: ${key} in ${obj.constructor.name}`,
      endTags: [],
      taskId: EInternalTaskIds.util_run_task_function_on_object,
    });
  }

  return await func(...args);
}

// TODO remove and place into @gt/server
export function apiResponseTFR(ctx: Context, taskFunctionResponse: ITaskFunctionResponse) {
  ctx.body = taskFunctionResponse;
  ctx.type = "application/json";
  ctx.status = taskFunctionResponse.positive ? 200 : 500;
}

export function fractionalFeedback(defaultUnit: string, defaultLimit: number) {
  return (current: number, limit: number = defaultLimit, unit: string = defaultUnit): IFractionFeedback => ({
    unit,
    current,
    limit,
  });
}

export function convertErrorToTFR(e: Error): ITaskFunctionNegativeResponse<any> {
  let response: ITaskFunctionNegativeResponse<any>;

  if (e instanceof TaskFunctionError) {
    response = e.taskFunctionResponse;
  } else {
    response = TFRFailure(ETaskFunctionEndId.THROWN_ERROR, e.message, e);
  }

  return response;
}

export class TaskFunctionError<EP = any, ET extends string = string> extends Error {
  taskFunctionResponse: ITaskFunctionNegativeResponse<EP, ET>;

  constructor(negativeResponse: Partial<ITaskFunctionNegativeResponse<EP, ET>>) {
    if (negativeResponse.endMessage) {
      super(negativeResponse.endMessage);
    } else {
      super("Something went wrong.");
    }

    this.taskFunctionResponse = {
      ...TFRFailure(ETaskFunctionEndId.ERROR),
      ...negativeResponse,
    } as ITaskFunctionNegativeResponse<EP, ET>;
  }

  errorString(): string {
    return TaskFunctionUtils.printTaskFunctionError(this.taskFunctionResponse);
  }
}

export class EmptyObserver {
  public __isEmptyObserver = true;
  private _hideWarnings: boolean;

  constructor(hideWarnings: boolean = false) {
    this._hideWarnings = hideWarnings;
  }

  hideWarnings(hideWarnings: boolean) {
    this._hideWarnings = hideWarnings;
  }

  complete() {
    if (!this._hideWarnings) {
      console.warn(`Calling empty observer.complete(). Should only be for internal processes.`);
    }
  }

  error(err: any) {
    if (!this._hideWarnings) {
      console.warn(`Calling empty observer.error(). Should only be for internal processes.`);
    }
  }

  next(value: ITaskProgressUpdate) {
    if (!this._hideWarnings) {
      console.warn(
        `Calling empty observer.next(). Should only be for internal processes.\n ProgressUpdate: ${value.status} : ${value.action} : ${value.message}`,
      );
    }
  }
}

export enum ETFRKoaEndpointSetStatus {
  FROM_END_ID = "FROM_END_ID",
  ALWAYS_200 = "ALWAYS_200",
  DONT_AFFECT = "DONT_AFFECT",
}

export interface IOTFRKoaEndpointOptions {
  status?: ETFRKoaEndpointSetStatus;
}

export function TFRKoaEndpoint<C extends Context>(
  func: (ctx: C) => TFRPromise,
  { status = ETFRKoaEndpointSetStatus.ALWAYS_200 }: IOTFRKoaEndpointOptions = {},
) {
  return async (ctx: C) => {
    const resp = await func(ctx);

    ctx.body = resp;
    ctx.type = "application/json";

    if (status !== ETFRKoaEndpointSetStatus.DONT_AFFECT) {
      ctx.status = status === ETFRKoaEndpointSetStatus.FROM_END_ID ? _getHttpStatusCodeForEndId(resp.endId) : 200;
    }
  };
}

export function getValidationResponse(schema: Schema, values: any): ITaskFunctionResponse {
  // const validationResult = Joi.validate(values, schema, { stripUnknown: true });
  const validationResult = schema.validate(values, { stripUnknown: true });
  // Joi.validate(values, schema, { stripUnknown: true });

  if (validationResult.error !== null) {
    return TFRFailure(ETaskFunctionEndId.ILLEGAL_ARGUMENT, `Parameter Validation Failed: ${validationResult.error} ]`);
  }

  return TFRSuccess({ arguments: validationResult.value });
}

export function getPositivePayloadOrThrow<T>(response: ITaskFunctionResponse<T>): T {
  if (response.positive) {
    return response.payload;
  }

  throw new Error(`Task Function: getPositiveResponseOrThrow(), did throw: ${response.endId} - ${response.endMessage}`);
}

function printTaskFunctionError(error: ITaskFunctionNegativeResponse<any>): string {
  return `message("${error.endMessage}") endId(${error.endId}) taskId(${error.taskId ?? "_"}) tags(${error.endTags.join(
    ", ",
  )})`;
}

export const TaskFunctionUtils = {
  getHttpStatusCodeForEndId: _getHttpStatusCodeForEndId,
  getEndIdForHttpStatusCode: _getEndIdForHttpStatusCode,
  getEmptyObserver: (hideWarnings: boolean = false): EmptyObserver => new EmptyObserver(hideWarnings),
  runTaskFunctionOnObject,
  apiResponseTFR,
  getValidationResponse,
  printTaskFunctionError,
};
