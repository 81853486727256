import React from "react";
import { ZIndexes } from "../../../styling/VibescoutZIndexes";
import { useDelayedValue } from "../../hooks/useDelayedValue";
import { useFelaCache } from "../../fela/FelaCache";
import { TFelaRule } from "../fela/types";

/*export enum ELoadingStripPosition {
  TOP = "t",
  BOTTOM = "b",
}*/

interface ICPLoadingStrip {
  bottom?: boolean;
  zIndex?: number;
  show: boolean;
  delay?: number;
  // color:
}

/*function useDelayedSetter<T>(target: T): T {
  const [delayedVal, setDelayedVal] = useState<T>(target);
  const timerRef = useRef();
  useEffect(() => {

  }, [target]);
}*/

const FelaKeyframesLoadingStrip: TFelaRule = () => {
  return { "0%": { transform: "translateX(-35em)" }, "100%": { transform: "translateX(100%)" } };
  // 100% { transform: translateX(100%) };
};

export const LoadingStrip: React.FC<ICPLoadingStrip> = ({
  bottom = false,
  delay = 300,
  zIndex = ZIndexes.higherBar,
  show,
}) => {
  const { theme, cachedKeyframe } = useFelaCache();
  const finalShow = useDelayedValue(show, delay);

  const loadingKeyFrame = cachedKeyframe(FelaKeyframesLoadingStrip, {}, "loading_strip");

  return (
    <div
      style={{
        position: "absolute",
        overflow: "hidden",
        width: "100%",
        height: "2em",
        pointerEvents: "none",
        left: 0,
        zIndex,
        ...(bottom ? { bottom: 0 } : { top: 0 }),
      }}
    >
      <div
        style={{
          animation: finalShow ? `${loadingKeyFrame} 2s infinite ease-in-out` : "none",
          content: "",
          position: "absolute",
          width: "100%",
          height: "100%",
          transition: "bottom 0.25s ease-out",
          bottom: show ? 0 : "100%",
          transform: "translateX(-35em)",
        }}
      >
        <div
          style={{
            background: `radial-gradient(circle at 50% ${bottom ? "2300%" : "-2300%"}, rgba(${
              theme.styles.Colors.neutral.loading[0]
            },1) 0%, rgba(${theme.styles.Colors.neutral.loading[0]},1) 88%, rgba(${
              theme.styles.Colors.neutral.loading[0]
            },0) 94%, rgba(0,212,255,0) 100%)`,
            content: "",
            position: "absolute",
            width: "35em",
            height: "100%",
            top: 0,
            left: 0,
          }}
        />
      </div>
    </div>
  );
};
