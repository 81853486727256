import regexParser from "regex-parser";

function matchOneStringRegex(value: string, regexes: string[]): boolean {
  for (const stringRegex of regexes) {
    if (matchStringRegex(value, stringRegex)) {
      return true;
    }
  }

  return false;
}

function matchStringRegex(value: string, stringRegex: string): boolean {
  const regex = regexParser(stringRegex);
  const match = value.match(regex);

  return match != null;
}

export interface IGetStringRegexMatchOrCapturesOptions {
  combineMatchedGroups?: boolean;
}

export interface IGetStringRegexMatchesResponse {
  singleMatch: null|(string|string[]);
  globalMatch: null|Array<string|string[]>;
}

function getStringRegexMatches(value: string, stringRegex: string): IGetStringRegexMatchesResponse {
  const regex = regexParser(stringRegex);

  const matches = getStringRegexMatchOrCaptures(value, "", regex);

  if (matches != null) {
    if (regex.flags.includes("g")) {
      return {
        singleMatch: null,
        globalMatch: matches.map(match => match.hasGroups ? match.values.slice(1) : match.values[0]),
      }
    } else {
      return {
        singleMatch: matches[0].hasGroups ? matches[0].values.slice(1) : matches[0].values[0],
        globalMatch: null,
      }
    }
  } else {
    return {
      singleMatch: null,
      globalMatch: null,
    }
  }
}

/*function getStringRegexMatchOrCaptures(value: string, stringRegex: string, { combineMatchedGroups = true }: IGetStringRegexMatchOrCapturesOptions = {}): string[][]|null {
  const matches = getStringRegexMatchOrCapturesInArray(value, stringRegex);

  if (matches != null) {
    return matches.reduce((result: string[][], regexResult: IRegexMatchResult) => {
      result.push(combineMatchedGroups ? [catchGroups.reduce((combined, m) => `${combined}${m}`, "")] : catchGroups);
      return result;
    }, []);
  }

  return null;
}*/

export interface IRegexMatchResult {
  hasGroups: boolean;
  values: string[]
}

function getStringRegexMatchOrCaptures(value: string, stringRegex: string, passedRegex: RegExp|null = null): IRegexMatchResult[]|null {
  const regex = passedRegex === null ? regexParser(stringRegex!!) : passedRegex;

  if (regex.flags.includes("g")) {
    let match = regex.exec(value);
    let count = 0;
    const final: IRegexMatchResult[] = [];

    while (match !== null && count < 1000) {
      if (match != null) {
        if (match.length > 1) {
          final.push({
            hasGroups: true,
            values: match,
          });
        } else {
          final.push({
            hasGroups: false,
            values: match,
          });
        }
      }

      match = regex.exec(value);

      count++;
    }

    if (final.length > 0) {
      return final;
    }
  } else {
    const match = value.match(regex);

    if (match != null) {
      if (match.length > 1) {
        return [{
          hasGroups: true,
          values: match,
        }];
      }

      return [{
        hasGroups: false,
        values: match,
      }];
    }
  }

  return null;
}

function stringRegexContainsGroups(stringRegex: string): boolean {
  return /\([^|]+\)/g.test(stringRegex);
}

function getFirstCaptureOrNull(regex: RegExp, input: string): string|null {
  const results = regex.exec(input);
  if (results != null && results.length > 1) {
    return results[1];
  }

  return null;
}

function getFirstCaptureOrSame(regex: RegExp, input: string): string {
  const results = regex.exec(input);
  if (results != null && results.length > 1) {
    return results[1];
  }

  return input;
}

export const RegexUtils = {
  stringRegexContainsGroups,
  matchStringRegex,
  matchOneStringRegex,
  getStringRegexMatches,
  getStringRegexMatchOrCaptures,
  getFirstCaptureOrNull,
  getFirstCaptureOrSame,
};
