import { QuickStat } from "../components/Covid19Components";
import { VirusIcon } from "@vs/core/build/frontend/graphics/svg-icons/VirusIcon";
import { colorsCovidPage } from "../Covid19Static";
import { GiSwordman } from "react-icons/gi";
import { DeathFlatLineIcon } from "@vs/core/build/frontend/graphics/svg-icons/DeathCrossIcon";
import React from "react";

interface ICPCovidQuickStats {
  latestCases?: number;
  activeCases?: number;
  latestRecovered?: number;
  latestDeaths?: number;
  isSmall: boolean;
}

export const CovidQuickStats = React.memo<ICPCovidQuickStats>(
  ({ latestDeaths, latestRecovered, latestCases, activeCases, isSmall }) => {
    return (
      <>
        {(latestCases || activeCases) && (
          <QuickStat
            mainIcon={<VirusIcon />}
            mainHeader={"Cases"}
            stats={[
              ...(latestCases
                ? [
                    {
                      color: colorsCovidPage.totalCases,
                      value: latestCases,
                      header: "Total",
                    },
                  ]
                : []),
              ...(activeCases
                ? [
                    {
                      color: colorsCovidPage.activeCases,
                      value: `${activeCases}`,
                      header: "Active",
                    },
                  ]
                : []),
            ]}
            isSmall={isSmall}
          />
        )}
        {latestRecovered && (
          <QuickStat
            stats={[
              {
                color: colorsCovidPage.totalRecoveries,
                value: latestRecovered,
              },
            ]}
            isSmall={isSmall}
            mainHeader={"Recovered"}
            mainIcon={<GiSwordman />}
          />
        )}
        {latestDeaths && (
          <QuickStat
            stats={[
              {
                color: colorsCovidPage.neutralGrey,
                value: latestDeaths,
              },
            ]}
            isSmall={isSmall}
            mainHeader={"Deaths"}
            mainIcon={<DeathFlatLineIcon />}
          />
        )}
      </>
    );
  },
);
