import React, { useMemo } from "react";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { MovieSectionAsyncActions } from "../../state/navigation/_navMoviePages";
import { useParams } from "react-router";
import { PageTitleImage } from "./components/PageTitleImage";
import { FelaGrid } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaGrid";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import Youtube from "react-youtube";
import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { FullAbsFlexGridLayer, FullAbsFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaFlexLayer, FelaGridLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import { ETimeUnit, TimeUtils } from "@gt/common-utils/build/general/TimeUtils";
import { notNullEmpty } from "@gt/common-utils/build/datatypes/StringUtils";
import { IGradient } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { DateFormatStrings } from "@gt/common-utils/build/general/DateUtils";
import { SimpleImage } from "@gt/gt-frontend/build/react/vibescout-ui/media/SimpleImage";
import { CinemaListItem } from "../../components/snippets/CinemaListItem";
import { maxWidthPageStyles } from "../../config/StaticStyleConfig_VibescoutSpecial";
import _ from "lodash";
import { ICinemaTruthBase } from "@vs/core/build/data/gcloud/datastore/models/CinemaTruth";
import { EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { HorizontalScrollContainer } from "@gt/gt-frontend/build/react/vibescout-ui/components/HorizontalScrollContainer";
import { IDSCinemaTruthListing } from "@vs/core/build/data/gcloud/datastore/models/ShowingCinemas";
import { ColorUtils, TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { getDistance } from "geolib";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { IoMdLocate } from "react-icons/io";
import { UseLocationComponent } from "../../components/location/UseLocationComponent";
import { EButtonSize, FelaButton } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaButton";
import { ExecutionEnvironmentUtils } from "@gt/common-utils/build/general/ExecutionEnvironmentUtils";
import { MovieActorsBlock } from "./components/MovieActorsBlock";

export interface IMoviePageParams {
  movieId: string;
}

function getYoutubeId(trailerUrl) {
  if (trailerUrl != null && trailerUrl.indexOf("youtube") !== -1) {
    console.log(trailerUrl);
    return trailerUrl.substring(trailerUrl.indexOf("?v=") + 3);
  }

  return false;
}

const contentGradient: IGradient[] = [
  {
    def: "20deg",
    stops: [
      {
        height: ESurfaceHeight.shadow,
        opacity: 0.4,
        def: "3em",
      },
      {
        height: ESurfaceHeight.shadow,
        opacity: 0.2,
        def: "13em",
      },
      {
        height: ESurfaceHeight.shadow,
        opacity: 0.1,
      },
    ],
  },
];

function colorForRating(rating: number): EAppColor {
  return rating >= 7 ? EAppColor.go : rating >= 5 ? EAppColor.warning : EAppColor.error;
}

const blockGradient: IGradient[] = [
  {
    def: "25deg",
    stops: [
      {
        height: ESurfaceHeight.shadow,
        opacity: 0.3,
      },
      {
        height: ESurfaceHeight.shadow,
        opacity: 0.1,
      },
    ],
  },
];

export const MoviePage = React.memo(() => {
  const params = useParams<IMoviePageParams>();
  const moviePageAction = MovieSectionAsyncActions.getMoviePage.use({ movieId: params.movieId });
  const { UIStore } = PSC_VibescoutSpecial.useStores();
  const [isSmall, udi] = UIStore.useState((s) => [s.isSmall, s.udi] as const);

  console.log("Rendering Movie Page");

  return moviePageAction.renderPayload(({ moviePage, ratings, credits }) => {
    console.log(moviePage);
    console.log(credits);

    const {
      genres,
      guidanceRating,
      title,
      runtime,
      voteRatings,
      releaseYear,
      openingDate,
      hasVoteRating,
      cinemas,
      images: { backdrop, poster },
    } = moviePage;

    const hasBackdrop = backdrop?.normal?.url != null;
    const hasPoster = poster?.normal?.url != null;
    const youtubeId = getYoutubeId(moviePage.trailerUrl);
    const color: TColorRgbArray | undefined =
      moviePage.images?.poster?.thumbnail?.meta?.color?.preferredColorUIRgbArray;

    const smallStats: { title: string; value: string | number }[] = [
      {
        title: "Runtime",
        value: TimeUtils.getPracticalTimeString(runtime, ETimeUnit.minutes),
      },
    ];

    if (notNullEmpty(guidanceRating)) {
      smallStats.push({
        title: "Guidance Rating",
        value: guidanceRating,
      });
    }

    // console.log(openingDate.toUTCString());

    if (openingDate != null) {
      smallStats.push({
        title: "Release Date (South Africa)",
        value: ExecutionEnvironmentUtils.isNode()
          ? format(utcToZonedTime(openingDate, "Africa/Johannesburg"), DateFormatStrings.fullDate)
          : format(openingDate, DateFormatStrings.fullDate),
      });
    }

    const voteRatingList: { icon: string; value: string; name: string; color: EAppColor }[] = [];

    if (hasVoteRating && voteRatings?.tmdb?.totalVotes != null) {
      const rating: number = voteRatings?.tmdb?.voteRating;
      voteRatingList.push({
        icon: "https://storage.googleapis.com/vibescout-movies/images/brands/tmdb/small-icon.png",
        value: rating.toFixed(1),
        name: "The Movie Database",
        color: colorForRating(rating),
      });
    }

    if (ratings.imdb != null) {
      voteRatingList.push({
        icon: "https://storage.googleapis.com/vibescout-movies/images/brands/imdb/small-icon.png",
        value: ratings.imdb.toFixed(1),
        name: "The Internet Movie Database",
        color: colorForRating(ratings.imdb),
      });
    }

    if (ratings.metacritic != null) {
      voteRatingList.push({
        icon: "https://storage.googleapis.com/vibescout-movies/images/brands/metacritic/icon-small.png",
        value: ratings.metacritic.toFixed(1),
        name: "Metacritic",
        color: colorForRating(ratings.metacritic),
      });
    }

    if (ratings.rottenTomatoes != null) {
      voteRatingList.push({
        icon: "https://storage.googleapis.com/vibescout-movies/images/brands/rotten-tomatoes/small-icon.png",
        name: "Rotten Tomatoes",
        value: `${Math.round(ratings.rottenTomatoes * 10)}%`,
        color: colorForRating(ratings.rottenTomatoes),
      });
    }

    let voteRatingBlock: undefined | React.ReactElement = undefined;

    if (voteRatingList.length > 0) {
      voteRatingBlock = (
        <FelaFlexbox direction={"column"} padding={"0 0 1em 0"} gap={0.5}>
          <FelaFlexbox gap={0.5} align={"flex-end"}>
            {voteRatingList.map((r) => {
              return (
                <FelaFlexLayer
                  key={r.name}
                  direction={"column"}
                  padding={isSmall ? "0.5em 0.3em" : "0.65em 0.3em"}
                  radius={2}
                  gradient={blockGradient}
                  align={"center"}
                  gap={isSmall ? 0.25 : 0.2}
                  style={{ width: isSmall ? "2.5em" : "3em" }}
                >
                  <FelaFlexbox padding={0.1} position={"relative"} style={{ width: "100%" }} grow={1}>
                    <SimpleImage objectPosition={"center"} imageFit={"contain"} src={r.icon} alt={r.name} aspect={1} />
                  </FelaFlexbox>
                  <FelaText
                    size={isSmall ? ETextSize.content : ETextSize.smallHeader}
                    weight={ETextWeightType.black}
                    forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                    contrast={EForeContrast.extra}
                    neutralColor={r.color}
                    style={{
                      textShadow: "0 2px 1px rgba(0,0,0,0.2)",
                    }}
                  >
                    {r.value}
                  </FelaText>
                </FelaFlexLayer>
              );
            })}
          </FelaFlexbox>
        </FelaFlexbox>
      );
    }

    return (
      <FelaFlexbox grow={1} direction={"column"} rootProps={{ itemScope: true, itemType: "http://schema.org/Movie" }}>
        <meta itemProp="name" content={title} />
        {hasBackdrop && (
          <PageTitleImage
            image={{
              colorRgbArray: backdrop.normal.meta?.color?.preferredColorRgbArray,
              srcUrl: backdrop.normal.url!,
            }}
            aspect={isSmall ? 2.2 : 2.7}
            alt={`Backdrop image for ${title}`}
          >
            <FullAbsFlexGridLayer
              gridTemplateColumns={"1fr 1fr"}
              gap={1}
              alignItems={"flex-end"}
              gradient={[
                {
                  def: "to bottom",
                  stops: [
                    {
                      height: ESurfaceHeight.shadow,
                      opacity: 0,
                    },
                    {
                      height: ESurfaceHeight.shadow,
                      opacity: 0.3,
                    },
                    {
                      height: ESurfaceHeight.shadow,
                      opacity: 1,
                      darken: 30,
                    },
                  ],
                },
              ]}
            >
              {hasVoteRating && (
                <>
                  <FelaFlexbox />
                  {voteRatingBlock}
                </>
              )}
            </FullAbsFlexGridLayer>
          </PageTitleImage>
        )}
        <FelaFlexbox direction={"column"} align={"center"} grow={1}>
          <FelaGridLayer
            grow={1}
            gradient={contentGradient}
            style={maxWidthPageStyles}
            gridTemplateColumns={"1fr"}
            padding={"0 0 1em 0"}
            gap={1}
            radius={isSmall ? "0 0 1em 1em" : undefined}
          >
            <FelaGrid grow={1} gridTemplateColumns={"1fr 1fr"} gap={1} alignItems={"end"}>
              <FelaFlexbox
                direction={"column"}
                justify={"center"}
                padding={"1em 0 0 1em"}
                alignSelf={hasPoster ? undefined : "start"}
                gap={1}
              >
                <FelaFlexbox align={"center"} direction={"column"}>
                  {hasPoster && (
                    <div
                      style={{
                        minHeight: isSmall ? "9.5em" : "14em",
                        maxHeight: hasBackdrop ? "10em" : undefined,
                        position: "relative",
                        width: "100%",
                        maxWidth: "25em",
                      }}
                    >
                      <FelaFlexLayer
                        position={hasBackdrop ? "absolute" : undefined}
                        radius={0.5}
                        overflow={"hidden"}
                        extraSoftShadow
                        margin={hasBackdrop ? (isSmall ? "-50% 0 0 0" : 0) : 0}
                        aspect={
                          poster?.normal?.meta?.basic != null
                            ? poster.normal.meta.basic!.width / poster.normal.meta.basic!.height
                            : 0.67
                        }
                        bottom={0}
                        style={{
                          width: "100%",
                          backgroundImage: `url(${poster.normal.url})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                    </div>
                  )}
                </FelaFlexbox>
              </FelaFlexbox>
              <FelaGrid
                gridTemplateColumns={"1fr"}
                padding={isSmall ? "1em 1em 0 0" : "1em 2em 0 0"}
                justifyItems={"start"}
                gap={0.75}
              >
                {!hasBackdrop && voteRatingBlock}
                <FelaFlexLayer
                  gap={0.2}
                  align={"flex-end"}
                  backgroundOpacity={0}
                  borderWidth={"0 0 2px 0"}
                  borderColorString={"rgba(225, 235, 255, 0.5)"}
                  padding={"0 0 0.25em 0"}
                  wrap={"wrap"}
                  // height={ESurfaceHeight.highest}
                >
                  <FelaText
                    lineHeight={1.1}
                    forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                    weight={ETextWeightType.black}
                    contrast={EForeContrast.strong}
                    size={isSmall ? ETextSize.smallHeader : ETextSize.huge}
                    transform={"uppercase"}
                  >
                    {title}
                  </FelaText>
                  <FelaText
                    forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                    contrast={EForeContrast.extra}
                    size={ETextSize.small}
                  >
                    ({releaseYear})
                  </FelaText>
                </FelaFlexLayer>
                {smallStats.map((stat) => {
                  return (
                    <FelaFlexbox key={stat.title} direction={"column"} gap={0.25}>
                      <FelaText
                        tag={"h2"}
                        forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                        weight={ETextWeightType.bold}
                        contrast={EForeContrast.strong}
                        size={ETextSize.tiny}
                        transform={"uppercase"}
                      >
                        {stat.title}
                      </FelaText>
                      <FelaText
                        lineHeight={1}
                        size={ETextSize.small}
                        contrast={EForeContrast.strong}
                        forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                        weight={ETextWeightType.light}
                      >
                        {stat.value}
                      </FelaText>
                    </FelaFlexbox>
                  );
                })}
              </FelaGrid>
            </FelaGrid>
            <FelaGrid gridTemplateColumns={isSmall ? "1fr" : "1fr 1fr"} gap={1} padding={1}>
              <FelaFlexbox direction={"column"} align={"center"}>
                <FelaFlexbox style={{ maxWidth: isSmall ? undefined : "25em", width: "100%" }} direction={"column"}>
                  {genres.length > 0 && (
                    <FelaFlexbox wrap={"wrap"} padding={"0 0 1em 0"} gap={0.5}>
                      {genres.map((genre) => {
                        return (
                          <FelaFlexLayer
                            key={genre}
                            padding={"0.1em 0.35em"}
                            radius={1}
                            backgroundOpacity={0.45}
                            height={ESurfaceHeight.highest}
                          >
                            <FelaText contrast={EForeContrast.extra} size={ETextSize.small}>
                              {genre}
                            </FelaText>
                          </FelaFlexLayer>
                        );
                      })}
                    </FelaFlexbox>
                  )}
                  <FelaText
                    tag={"h2"}
                    margin={"0 0 0.5em 0"}
                    forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                    weight={ETextWeightType.black}
                    contrast={EForeContrast.strong}
                    transform={"uppercase"}
                  >
                    Synopsis
                  </FelaText>
                  <FelaText
                    // align={isSmall ? "justify" : undefined}
                    size={ETextSize.small}
                    contrast={EForeContrast.strong}
                    forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                  >
                    {moviePage.overview}
                  </FelaText>
                </FelaFlexbox>
                {credits?.cast.length > 0 && (
                  <>
                    <FelaFlexbox
                      style={{ maxWidth: isSmall ? undefined : "25em", width: "100%" }}
                      direction={"column"}
                      margin={"2em 0 0 0"}
                    >
                      <FelaText
                        tag={"h2"}
                        margin={"0 0 0.5em 0"}
                        forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                        weight={ETextWeightType.black}
                        contrast={EForeContrast.strong}
                        transform={"uppercase"}
                      >
                        Featuring
                      </FelaText>
                    </FelaFlexbox>
                    <MovieActorsBlock isSmall={isSmall} cast={credits.cast.slice(0, 4)} />
                  </>
                )}
              </FelaFlexbox>
              {youtubeId && (
                <FelaFlexbox grow={1} padding={isSmall ? 0 : "0 1em 0 0"} direction={"column"} position={"relative"}>
                  <FelaFlexbox grow={1} />
                  <FelaText
                    tag={"h2"}
                    margin={"0 0 0.5em 0"}
                    forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                    weight={ETextWeightType.black}
                    contrast={EForeContrast.strong}
                    transform={"uppercase"}
                  >
                    Movie Trailer
                  </FelaText>
                  <FelaFlexbox
                    position={"relative"}
                    aspect={16 / 9}
                    overflow={"hidden"}
                    radius={0.5}
                    cssExtra={[
                      {
                        width: "100%",
                        "& .yt-vid": {
                          zIndex: 1,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          top: 0,
                          left: 0,
                        },
                      },
                    ]}
                  >
                    <Youtube
                      className={"yt-vid"}
                      onPlay={() => {
                        console.log("Playing video");
                      }}
                      videoId={youtubeId}
                      opts={{
                        playerVars: { showinfo: 0, modestbranding: 1 },
                      }}
                    />
                  </FelaFlexbox>
                </FelaFlexbox>
              )}
            </FelaGrid>
          </FelaGridLayer>
          <FelaGridLayer
            gridTemplateColumns={isSmall ? "1fr" : "1fr 86em 1fr"}
            backgroundOpacity={0.1}
            alignSelf={"stretch"}
            direction={"column"}
            padding={"0 0 2em 0"}
            margin={isSmall ? "1em 0" : undefined}
            extraSoftShadow
            radius={1}
          >
            <FelaFlexbox
              gridColumn={isSmall ? undefined : "2/-1"}
              padding={"1em 1em 0 1em"}
              align={"start"}
              style={maxWidthPageStyles}
              alignSelf={"center"}
              direction={"column"}
            >
              <FelaText
                lineHeight={1}
                size={ETextSize.huge}
                transform={"uppercase"}
                weight={ETextWeightType.regular}
                forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                contrast={EForeContrast.strong}
                margin={"0 0.45em 0 0"}
              >
                Cinemas showing
              </FelaText>
              <FelaText
                style={{ textShadow: "0 1px 1px rgba(0, 10, 30, 0.35)" }}
                color={color ? ColorUtils.lightenRgbArray(color, 65) : undefined}
                lineHeight={1}
                size={ETextSize.smallHeader}
                transform={"uppercase"}
                weight={ETextWeightType.bold}
                forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                contrast={EForeContrast.strong}
              >
                <i>{title}</i>
              </FelaText>
            </FelaFlexbox>
            <MovieCinemas isSmall={isSmall} udi={udi} cinemas={cinemas} />
          </FelaGridLayer>
        </FelaFlexbox>
      </FelaFlexbox>
    );
  });
});

interface ICPMovieCinemas {
  isSmall: boolean;
  udi: EUDI;
  cinemas: IDSCinemaTruthListing[];
}

const MovieCinemas: React.FC<ICPMovieCinemas> = ({ isSmall, cinemas, udi }) => {
  const { LocationStore } = PSC_VibescoutSpecial.useStores();
  const userLocation = LocationStore.useState((s) => s.accuratePos);

  const groupedCinemas = useMemo(() => {
    let grouped: Record<string, (IDSCinemaTruthListing & { distance?: number })[]> = _.groupBy(
      cinemas,
      "province" as keyof ICinemaTruthBase,
    );
    const ordered: Record<string, (IDSCinemaTruthListing & { distance?: number })[]> = {};

    if (userLocation) {
      for (const key of Object.keys(grouped)) {
        grouped[key] = grouped[key]
          .map((cinema) => {
            return {
              ...cinema,
              distance: getDistance(userLocation.latLon, cinema.coordinates),
            };
          })
          .sort((a, b) => {
            return a.distance - b.distance;
          });
      }
    }

    Object.keys(grouped)
      .sort((a, b) => {
        if (userLocation) {
          return grouped[a][0].distance! - grouped[b][0].distance!;
        }

        return grouped[b].length - grouped[a].length;
      })
      .forEach(function (key) {
        ordered[key] = grouped[key]
          .map((cinema) => {
            return {
              ...cinema,
              distance: userLocation ? getDistance(userLocation.latLon, cinema.coordinates) : 0,
            };
          })
          .sort((a, b) => {
            return a.distance - b.distance;
          });
      });

    return ordered;
  }, [userLocation?.latLon?.lon, userLocation?.latLon?.lat]);
  console.log(groupedCinemas);

  return (
    <FelaFlexbox gridColumn={isSmall ? undefined : "2/-1"} direction={"column"}>
      <UseLocationComponent>
        {({ tryGetLocation, position, errorCode, triedBefore, triedNow }) => {
          if (!position) {
            return (
              <FelaFlexbox alignSelf={"start"} margin={0.75}>
                <FelaButton onClick={() => tryGetLocation()} borderRadius={1} size={EButtonSize.regular}>
                  <IoMdLocate />
                  <span>Sort by Closest to me</span>
                </FelaButton>
              </FelaFlexbox>
            );
          }

          return (
            <FelaFlexLayer
              margin={"0.5em 0.75em 0 0.75em"}
              padding={"0.2em 0.3em"}
              radius={1}
              align={"center"}
              alignSelf={"start"}
              backgroundOpacity={0.3}
            >
              <FelaIconWrap size={ETextSize.content} noBackground noPadding>
                <IoMdLocate />
              </FelaIconWrap>
              <FelaText size={ETextSize.small} contrast={EForeContrast.extra} transform={"uppercase"} margin={0.25}>
                Sorted by Closest To You
              </FelaText>
            </FelaFlexLayer>
          );
        }}
      </UseLocationComponent>
      <FelaFlexbox direction={"column"} style={{ maxWidth: "100vw" }}>
        {Object.keys(groupedCinemas).map((province) => {
          return (
            <FelaFlexLayer
              // padding={isSmall ? "0 0 0 1em" : undefined}
              // style={{ maxWidth: "100vw" }}
              key={province}
              direction={"column"}
              radius={"1em 0 0 1em"}
              height={ESurfaceHeight.shadow}
              backgroundOpacity={0.15}
              margin={"1em 0 0.5em 1em"}
            >
              <FelaFlexbox padding={isSmall ? "1em 1em 0.5em 1em" : "1em 1em 0.5em 1em"}>
                <FelaText
                  size={ETextSize.smallHeader}
                  transform={"uppercase"}
                  // spacing={0.1}
                  weight={ETextWeightType.bold}
                  forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                  contrast={EForeContrast.strong}
                >
                  {province}
                </FelaText>
              </FelaFlexbox>
              <HorizontalScrollContainer showScrollHint={false} scrollKey={province} touch={udi === EUDI.TOUCH}>
                <FelaFlexbox
                  // style={{ minWidth: "100vw", width: "auto" }}
                  // height={ESurfaceHeight.shadow}
                  // backgroundOpacity={0}
                  gap={1}
                  padding={1}
                >
                  {groupedCinemas[province].map((cinema) => {
                    return (
                      <CinemaListItem
                        key={cinema.id}
                        childrenOverImage={
                          <FullAbsFlexLayer
                            direction={"column"}
                            gradient={[
                              {
                                def: "to bottom",
                                stops: [
                                  {
                                    height: ESurfaceHeight.shadow,
                                  },
                                  {
                                    height: ESurfaceHeight.shadow,
                                    opacity: 0,
                                    def: "5em",
                                  },
                                ],
                              },
                            ]}
                            padding={0.5}
                            gap={0.15}
                          >
                            <FelaText
                              weight={ETextWeightType.light}
                              size={ETextSize.small}
                              forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                            >
                              Showing in
                            </FelaText>
                            <FelaFlexbox align={"center"} gap={0.5}>
                              {cinema.screenAttributes.map((attribute) => {
                                return (
                                  <FelaFlexLayer
                                    key={attribute}
                                    height={ESurfaceHeight.shadow}
                                    backgroundOpacity={0.5}
                                    padding={"0.1em 0.3em"}
                                    radius={0.5}
                                    wrap={"wrap"}
                                  >
                                    <FelaText
                                      size={ETextSize.small}
                                      spacing={0.12}
                                      weight={ETextWeightType.bold}
                                      forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                                      contrast={EForeContrast.strong}
                                    >
                                      {attribute}
                                    </FelaText>
                                  </FelaFlexLayer>
                                );
                              })}
                            </FelaFlexbox>
                          </FullAbsFlexLayer>
                        }
                        name={cinema.name}
                        brandId={cinema.cinemaBrandID}
                        cinemaId={cinema.id}
                        isSmall={isSmall}
                        image={cinema.titleImage?.thumbnailUrl}
                        area={cinema.city}
                        brandLogoImage={`https://storage.googleapis.com/vibescout-movies/images/brands/${cinema.cinemaBrandID}/logo-small.jpg`}
                      />
                    );
                  })}
                </FelaFlexbox>
              </HorizontalScrollContainer>
            </FelaFlexLayer>
          );
        })}
      </FelaFlexbox>
    </FelaFlexbox>
  );
};
