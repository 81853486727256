// import { ResponsiveGeoMap } from "@nivo/geo";
import React, { useEffect } from "react";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { createAsyncActionDirect } from "pullstate";
import { LeafletMap } from "@gt/gt-frontend/build/react/vibescout-ui/mapping/LeafletMap";
import { QuickUseAsyncResultModal } from "@gt/gt-frontend/build/react/vibescout-ui/components/QuickAsyncResultModal";
import { PSC_VibescoutSpecial } from "../../../state/PSC_VibescoutSpecial";
import { Garage } from "@gt/common-utils/build/specialized/GarageUtil";
import type { Layer, Map, Popup, TileLayer } from "leaflet";
import type { Feature, Polygon } from "@turf/turf";
import { IDMCovid19DailyFlatState } from "@vs/core/build/data/models/covid19/Covid19DailyFlatModel";
import { useFela } from "react-fela";
import { LegendItem } from "../components/Covid19Components";
import { colorsCovidPage } from "../Covid19Static";
import { EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { TFelaRule } from "@gt/gt-frontend/build/react/vibescout-ui/fela/types";
import { browserHistory } from "../../../entry_VibescoutFrontendSpecial";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { TGeoLevelNodeCovid } from "@vs/core/build/data/models/covid19/actions/_getCovidPageData";
import { ETextTypographyType } from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";

type Leaflet = typeof import("leaflet");

const mapGarage = new Garage<{ map: Map; tileLayer: TileLayer; L: Leaflet }>();

const loadLeaflet = createAsyncActionDirect<{}, Leaflet>(async () => {
  // @ts-ignore
  await import("leaflet/dist/leaflet.css");
  const leaflet = await import("leaflet");
  await import("leaflet.pm");
  // @ts-ignore
  await import("leaflet.pm/dist/leaflet.pm.css");
  mapGarage.park("L", leaflet);
  return leaflet;
});

const darkTileTemplate = {
  maxZoom: 20,
  zoomOffset: 0,
  url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png",
  attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>`,
};

const lightTileTemplate = {
  maxZoom: 19,
  zoomOffset: 0,
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  attribution: `Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors`,
};

const baseGeoStyle = { weight: 2, fillOpacity: 0.5, fillColor: "#64a4ee" };

let currentPopup: undefined | Popup;

function popupProvince(properties: TGeoLevelNodeCovid & { daily: IDMCovid19DailyFlatState[] }) {
  mapGarage.runIfParked(["L", "map"], ({ L, map }) => {
    const {
      name,
      latLon: { lat, lon },
      // daily,
    } = properties;

    // const lastDay = daily[daily.length - 1];

    currentPopup = L.popup({ keepInView: false, closeButton: false, className: "popup-province" })
      .setLatLng([lat, lon])
      .setContent(`<div><span class="popup-name">${name}</span></div>`)
      .openOn(map);
  });
}

const createGeoJsonFeatureFunctions = (mobile: boolean) => (leafFeature: TCovidMapFeature, leafLayer) => {
  if (mobile) {
    leafLayer.on("click", function () {
      this.setStyle({ ...baseGeoStyle, fillOpacity: 0.7, fillColor: "#75bbff" });
      popupProvince(leafFeature.properties!);
    });
  } else {
    leafLayer.on("click", function () {
      const { st } = GeoLevelCompressUtils.getPartsForCompressedGeoLevel(leafFeature.properties!.geoLvlShort);
      browserHistory.push(`/reports/coronavirus/${st}`);
    });
  }

  leafLayer.on("mouseover", function () {
    this.setStyle({ ...baseGeoStyle, fillOpacity: 0.7, fillColor: "#75bbff" });
    popupProvince(leafFeature.properties!);
  });

  leafLayer.on("mouseout", function () {
    console.log("mouse out");
    this.setStyle(baseGeoStyle);
    if (currentPopup) {
      mapGarage.runIfParked(["map"], ({ map }) => {
        map.closePopup(currentPopup);
      });
    }
  });
};

export type TCovidMapFeature = Feature<Polygon, TGeoLevelNodeCovid & { daily: IDMCovid19DailyFlatState[] }>;

interface ICPCovidGeoMap {
  features: TCovidMapFeature[];
}

const covidMapCssStyles: TFelaRule = ({ theme }) => ({
  "& .popup-province": {
    marginBottom: "4em",
    pointerEvents: "none",
  },
  "& .leaflet-popup-content": {
    margin: "0.25em 0.45em",
  },
  "& .popup-name": {
    letterSpacing: "0.05em",
    fontWeight: "bold",
    fontSize: "1.15em",
    fontFamily: theme.styles.Font.family[ETextTypographyType.sansSerif],
  },
  "& .leaflet-province-cases": {
    pointerEvents: "none",
    // display: "flex",
    // transform: "translate(-50%, -50%)",
    "> .latest-stats": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      lineHeight: "1.75em",
      transform: "translate(25%, -50%)",
      "> .items": {
        background: theme.dark ? "rgba(7,26,71,0.84)" : "rgba(255,255,255,0.84)",
        padding: "0.2em",
        borderRadius: "0.5em",
        // border: "1px solid black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "> .cases.length-5": {
          fontSize: "0.95em",
        },
        "> .cases.length-4": {
          fontSize: "1.1em",
        },
        "> .cases": {
          display: "inline-block",
          color: colorsCovidPage.newCases,
          fontSize: "1.35em",
          fontWeight: "bold",
          fontFamily: theme.styles.Font.family[ETextTypographyType.mono],
        },
        "> .deaths": {
          display: "inline-block",
          color: colorsCovidPage.totalDeaths,
          fontSize: "1.9em",
          fontWeight: "bold",
          fontFamily: theme.styles.Font.family[ETextTypographyType.mono],
        },
        "> .deaths.length-2": {
          fontSize: "1.6em",
        },
      },
    },
  },
});

export const CovidGeoMap: React.FC<ICPCovidGeoMap> = ({ features }) => {
  const { UIStore } = PSC_VibescoutSpecial.useStores();
  const { css } = useFela();
  const leafletModule = loadLeaflet.use({}, { initiate: true, ssr: false });
  const [isDark, udi] = UIStore.useState((s) => [s.theme.dark, s.udi]);

  useEffect(
    UIStore.subscribe(
      (s) => s.theme.dark,
      (isDark) => {
        mapGarage.runIfParked(["tileLayer"], ({ tileLayer }) => {
          console.log(`Should change tile layer`);
          const tileTemplate = isDark ? darkTileTemplate : lightTileTemplate;
          tileLayer.setUrl(tileTemplate.url);
        });
      },
    ),
    [],
  );

  const onEachGeoJsonFeature = createGeoJsonFeatureFunctions(udi === EUDI.TOUCH);

  return (
    <>
      <FelaFlexbox align={"center"} justify={"space-between"} padding={0.5}>
        <LegendItem color={colorsCovidPage.newCases} name={"Cases"} />
        <LegendItem color={colorsCovidPage.totalDeaths} name={"Deaths"} />
      </FelaFlexbox>
      <FelaFlexbox
        position={"relative"}
        style={{ width: "100%", height: "40vh", minHeight: "25em", maxHeight: "45em" }}
      >
        <QuickUseAsyncResultModal title={"Province Map"} position={"absolute"} state={leafletModule} />
        {leafletModule.renderPayload((L) => {
          return (
            <div className={css(covidMapCssStyles)}>
              <LeafletMap
                id={"leaflet-provinces"}
                L={L}
                options={{
                  center: [-28.5, 24],
                  zoom: 5,
                  zoomSnap: 0,
                  zoomControl: false,
                  scrollWheelZoom: false,
                  doubleClickZoom: false,
                  boxZoom: false,
                  dragging: false,
                  touchZoom: false,
                }}
                onMapMounted={(map) => {
                  console.log("Mounted Map");
                  mapGarage.park("map", map);
                  const tileTemplate = isDark ? darkTileTemplate : lightTileTemplate;
                  const tiles = L!.tileLayer(tileTemplate.url, tileTemplate);
                  mapGarage.park("tileLayer", tiles);
                  tiles.addTo(map);

                  for (const feature of features) {
                    const {
                      latLon: { lat, lon },
                      daily,
                    } = feature.properties!;

                    const lastDay = daily[daily.length - 1];

                    const myTextLabel = L.marker([lat, lon], {
                      icon: L.divIcon({
                        className: "leaflet-province-cases", // Set class for CSS styling
                        html: `<div class="latest-stats"><div class="items"><span class="cases length-${
                          lastDay.casesTotal!.toString().length
                        }">${lastDay.casesTotal}</span><span class="deaths length-${
                          lastDay.deathsTotal!.toString().length
                        }">${lastDay.deathsTotal}</span></div></div>`,
                      }),
                      interactive: false,
                      zIndexOffset: 1000, // Make appear above other map features
                    });

                    L.geoJSON(feature, {
                      onEachFeature: onEachGeoJsonFeature as any,
                      pointToLayer(geoJsonPoint, latlng): Layer {
                        return L.divIcon({ html: "hello" });
                      },
                    })
                      .setStyle(baseGeoStyle)
                      .addTo(map);

                    myTextLabel.addTo(map);
                  }

                  map.fitBounds([
                    [-21.026707780012655, 16.208203124999993],
                    [-35.06597313798421, 33.06884765625001],
                  ]);

                  return () => {
                    mapGarage.remove(["map", "tileLayer"]);
                  };
                }}
              />
            </div>
          );
        })}
      </FelaFlexbox>
    </>
  );
};
