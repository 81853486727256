import React from "react";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { GraphSection, LegendItem } from "../components/Covid19Components";
import { colorsCovidPage } from "../Covid19Static";
import { MyResponsiveLine, NivoLayerDashedLine } from "../NivoChartTest";
import { CovidAsyncActions } from "../../../state/stores/CovidStore/CovidAsyncActions";
import { area } from "d3-shape";
// @ts-ignore
import { Defs } from "@nivo/core";
import { LineLayerType } from "@nivo/line";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import {
  EAppColor,
  ETextSize,
  ETextTypographyType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { HeaderText, UnderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import { PSC_VibescoutSpecial } from "../../../state/PSC_VibescoutSpecial";

const AreaLayer = ({ series, xScale, yScale, innerHeight }) => {
  const areaGeneratorGrowing = area()
    .x((d) => xScale(d.data.x))
    .y0((d) => Math.min(innerHeight, yScale(Math.max(d.data.l, 1))))
    .y1((d) => yScale(Math.max(d.data.h, 1)));
  // .curve(curveMonotoneX);

  const areaGeneratorReceding = area()
    .x((d) => xScale(d.data.x))
    .y0((d) => Math.min(innerHeight, yScale(Math.min(d.data.l, 1))))
    .y1((d) => yScale(Math.min(d.data.h, 1)));
  // .curve(curveMonotoneX);

  return (
    <>
      <Defs
        defs={[
          {
            id: "pattern",
            type: "patternLines",
            background: "rgba(122,159,255,0.34)",
            color: "#3daff7",
            lineWidth: 1,
            spacing: 5,
            rotation: -45,
          },
        ]}
      />
      <path
        d={areaGeneratorGrowing(series[0].data)}
        // {/*fill="url(#pattern)"*/}
        fill={"rgba(255,101,101,0.3)"}
        fillOpacity={0.5}
        stroke="#3daff7"
        strokeWidth={0}
      />
      <path
        d={areaGeneratorReceding(series[0].data)}
        // fill="url(#pattern)"
        fill={"rgba(87,232,122,0.35)"}
        fillOpacity={0.5}
        stroke="#3daff7"
        strokeWidth={0}
      />
    </>
  );
};

interface ICPGraphRt {
  geoLvl: string;
  isSmall: boolean;
  isDark: boolean;
  areaName: string;
  rowSpan?: number;
}

const prelimDays = 6;

export const GraphSectionRt: React.FC<ICPGraphRt> = React.memo<ICPGraphRt>(
  ({ geoLvl, isSmall, areaName, rowSpan = 4, isDark }) => {
    const { UIStore } = PSC_VibescoutSpecial.useStores();
    const styles = UIStore.useState((s) => s.theme.styles);
    const rtValuesResponse = CovidAsyncActions.getRtForGeoLvl.use({ geoLvl, limit: 60 });

    console.log(rtValuesResponse.payload);

    return rtValuesResponse.renderPayload(({ items }) => {
      if (items != null) {
        let isGrowing = items[0].rt > 1;

        const growingData = items.map((item, index) => {
          const nextItemIsGrowing = items[index + 1] != null && items[index + 1].rt > 1;
          const d = {
            x: item.date.slice(5),
            y: nextItemIsGrowing || isGrowing || item.rt > 1 ? item.rt : null,
            h: item.high_90,
            l: item.low_90,
          };
          isGrowing = item.rt > 1;
          return d;
        });

        let lowest = 100;
        let highest = -100;

        const recedingData = items.map((item, index) => {
          if (item.low_90 < lowest) {
            lowest = item.low_90;
          }

          if (item.high_90 > highest) {
            highest = item.high_90;
          }

          return { x: item.date.slice(5), y: item.rt <= 1 ? item.rt : null, h: item.high_90, l: item.low_90 };
        });

        const lineStyle = { strokeWidth: isSmall ? 1 : 2 };
        const lineStylePrelim = {
          strokeWidth: isSmall ? 1 : 2,
          strokeDasharray: "1, 6",
          strokeLinejoin: "round",
          strokeLinecap: "round",
        };

        let prelimMarker: any = undefined;

        if (recedingData.length > prelimDays) {
          prelimMarker = {
            axis: "x",
            value: recedingData[recedingData.length - prelimDays].x,
            legend: "Preliminary",
            textStyle: {
              transform: "translate(3px, 1em)",
              fontFamily: styles.Font.family[ETextTypographyType.mono],
              fontSize: isSmall ? "0.5em" : "0.65em",
              fill: "#8c94a7",
            },
            lineStyle: {
              stroke: "rgba(100,111,161,0.6)",
              strokeWidth: 1,
              strokeDasharray: "1, 4",
              strokeLinejoin: "round",
              strokeLinecap: "round",
            },
          };
        }

        const recedingPrelim = recedingData.map((item, index) => {
          if (index < recedingData.length - prelimDays) {
            return {
              ...item,
              y: null,
            };
          } else {
            const newItem = {
              ...item,
            };

            if (index > recedingData.length - prelimDays) {
              item.y = null;
            }

            return newItem;
          }
        });

        const growingPrelim = growingData.map((item, index) => {
          if (index < growingData.length - prelimDays) {
            return {
              ...item,
              y: null,
            };
          } else {
            const newItem = {
              ...item,
            };

            if (index > growingData.length - prelimDays) {
              item.y = null;
            }

            return newItem;
          }
        });

        const markers: any[] = [
          {
            axis: "y",
            value: 1,
            lineStyle: {
              stroke: "#2598ff",
              strokeWidth: 2,
              strokeDasharray: "1, 4",
              strokeLinejoin: "round",
              strokeLinecap: "round",
            },
          },
        ];

        if (prelimMarker) {
          markers.push(prelimMarker);
        }

        const tickSkip = Math.ceil(items.length / 14);
        const tickValues: string[] = [];

        for (let i = recedingData.length - 1; i >= 0; i -= tickSkip) {
          tickValues.push(recedingData[i].x);
        }

        return (
          <GraphSection
            rowSpan={rowSpan}
            title={
              <>
                Virus Transmission Rate (R<sub style={{ textTransform: "none" }}>t</sub>)
              </>
            }
            subtext={`The rate at which the virus is transmitted between people in ${areaName}`}
          >
            <FelaFlexbox justify={"space-between"} align={"center"} padding={0.5}>
              <LegendItem
                name={
                  <>
                    R<sub style={{ textTransform: "none" }}>t</sub> (Virus Growing)
                  </>
                }
                color={colorsCovidPage.negative}
              />
              <LegendItem
                name={
                  <>
                    R<sub style={{ textTransform: "none" }}>t</sub> (Virus Receding)
                  </>
                }
                color={colorsCovidPage.positive}
              />
            </FelaFlexbox>
            <FelaFlexbox style={{ height: "25vh", maxHeight: "19em" }}>
              <MyResponsiveLine
                markers={markers}
                colors={[colorsCovidPage.positive, colorsCovidPage.negative]}
                pointSize={isSmall ? 2 : 4}
                // lineWidth={isSmall ? 1 : 10}
                // enableArea={true}
                enableGridX={false}
                enableSlices={"x"}
                xFormat={(value) => `Date: ${value}`}
                yFormat={(value) => `Rate: ${value}`}
                layers={
                  [
                    "grid",
                    "markers",
                    "axes",
                    "areas",
                    "crosshair",
                    AreaLayer,
                    NivoLayerDashedLine,
                    "points",
                    "slices",
                    "mesh",
                    "legends",
                  ] as LineLayerType[]
                }
                /*
              //@ts-ignore */
                areaBaselineValue={1}
                areaOpacity={0.5}
                yScaleExtras={{ min: 0, max: Math.max(highest, 3), base: 1, stacked: false }}
                // xScaleExtras={{ type: "linear" }}
                axisBottomExtras={{ tickValues }}
                axisLeftExtras={{ tickValues: [0, 0.5, 1, 1.5, 2, 2.5, 3] }}
                gridYValues={[0, 0.5, 1, 1.5, 2, 2.5, 3]}
                data={[
                  {
                    id: "(Receding)",
                    lineStyle,
                    color: colorsCovidPage.totalCases,
                    data: recedingData,
                  },
                  {
                    id: "(Growing)",
                    lineStyle,
                    color: colorsCovidPage.totalCases,
                    data: growingData,
                  },
                  {
                    id: "(Preliminary Receding)",
                    lineStyle: lineStylePrelim,
                    color: colorsCovidPage.totalCases,
                    data: recedingPrelim,
                  },
                  {
                    id: "(Preliminary Growing)",
                    lineStyle: lineStylePrelim,
                    color: colorsCovidPage.totalCases,
                    data: growingPrelim,
                  },
                ]}
              />
            </FelaFlexbox>
            <FelaFlexbox direction={"column"} padding={0.5}>
              <HeaderText margin={"0.5em 0"}>What is this?</HeaderText>
              <UnderText weight={"normal"}>
                It’s the average number of people who become infected by an infectious person.
              </UnderText>
              <UnderText margin={"0.5em 0"} weight={"normal"}>
                If{" "}
                <b>
                  R<sub>t</sub>
                </b>{" "}
                is above 1.0, the virus will spread quicker. When{" "}
                <b>
                  R<sub>t</sub>
                </b>{" "}
                is below 1.0, the virus will begin to stop spreading.
              </UnderText>
              <FelaFlexbox align={"center"} justify={"space-between"}>
                <UnderText weight={"normal"} style={{ flexBasis: "75%" }}>
                  Also known as the <b>Effective Reproductive Number</b> and{" "}
                  <b>
                    R<sub>e</sub>
                  </b>
                </UnderText>
                <a href={"https://rt.live/#learn-more"} target={"_blank"}>
                  <FelaText
                    neutralColor={EAppColor.accent}
                    transform={"uppercase"}
                    size={ETextSize.small}
                    weight={"bold"}
                  >
                    Learn More
                  </FelaText>
                </a>
              </FelaFlexbox>
            </FelaFlexbox>
          </GraphSection>
        );
      }

      return (
        <GraphSection
          rowSpan={2}
          title={
            <>
              Virus Transmission Rate (R<sub style={{ textTransform: "none" }}>t</sub>)
            </>
          }
          subtext={`The rate at which the virus is transmitted between people in ${areaName}`}
        >
          <FelaFlexbox direction={"column"} padding={0.5}>
            <HeaderText>Data Unavailable</HeaderText>
            <UnderText weight={"normal"} margin={"0.5em 0"}>
              Some data seems to be missing in {areaName}, and this algorithm requires consistent information over a
              period of at least a couple weeks or the transmission rate cannot be calculated. (either that, or the
              numbers are so low the algorithm isn't applicable)
            </UnderText>
          </FelaFlexbox>
        </GraphSection>
      );
    });
  },
);
