import React from "react";
import redraft from "redraft";
import Youtube from "react-youtube";

import { ReactImage } from "./ReactImage";

import { OldLinkOrPlainText } from "./OldLinkOrPlainText";
import { convertToSlug } from "@gt/common-utils/build/datatypes/StringUtils";
import { OldPostIndex } from "./OldPostIndex";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";

let currentHeaderTwoIndex = 1;

const renderers = {
  /**
   * Those callbacks will be called recursively to render a nested structure
   */
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
    ITALIC: (children, { key }) => <em key={key}>{children}</em>,
    UNDERLINE: (children, { key }) => <u key={key}>{children}</u>,
    // LINK: (children, { key }) => <a key={key} href="http://www.google.com">{children}</a>,
  },
  /**
   * Blocks receive children and depth
   * Note that children are an array of blocks with same styling,
   */

  blocks: {
    unstyled: (children, { depth, keys }) =>
      children.map((child, index) => (
        <p className="post-p" key={keys[index] + depth}>
          {child}
        </p>
      )),
    atomic: (children, { keys }) => children.map((child, i) => <div key={keys[i]}>{child}</div>),
    blockquote: (children, { keys }) => children.map((child, i) => <blockquote key={keys[i]}>{child}</blockquote>),
    "header-one": (children, { keys }) =>
      children.map((child, index) => (
        <h1 key={keys[index]} id={`h1-${convertToSlug(child ?? "")}`}>
          {child}
        </h1>
      )),
    // key={keys.join("|")}
    "header-two": (children, { keys }) => {
      // let headerTwoIndex = 0;

      return children.map((child, index) => {
        const renderBodyAd = currentHeaderTwoIndex % 3 === 0;

        // console.log(`HEADER-TWO: index: ${currentHeaderTwoIndex} - Render Ad: ${renderBodyAd} - ${child}`);

        currentHeaderTwoIndex += 1;

        return (
          <div key={`header-two-${keys[index]}`} className={`pmp-post-header-two ${renderBodyAd ? "with-ad" : ""}`}>
            {/*{renderBodyAd && (
              <PmpSpacePanel side={"body"} boxType={"banner"} dynamic dynamicIndex={`${currentHeaderTwoIndex}`} />
            )}*/}
            <h2>
              <span id={`${keys[0]}-${convertToSlug(child ?? "")}`} className="hash-anchor hash-anchor-120" />
              {child}
            </h2>
          </div>
        );
      });
    },
    // key={`${keys.join("|")}`}
    "header-three": (children, { keys }) =>
      children.map((child, index) => (
        <h3 key={`header-three-${keys[index]}`}>
          <span id={`${keys[index]}-${convertToSlug(child ?? "")}`} className="hash-anchor hash-anchor-120" />
          {child}
        </h3>
      )),
    "header-four": (children, { keys }) =>
      children.map((child, index) => {
        const slug = convertToSlug(child ?? "");
        return (
          <h4 key={keys[index] + slug}>
            <span id={`${keys[index]}-${slug}`} className="hash-anchor hash-anchor-120" />
            {child}
          </h4>
        );
      }),
    "unordered-list-item": (children, { depth, keys }) => (
      <ul key={keys[keys.length - 1] + depth + ""} className={`ul-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>
            <span className="text">{child}</span>
          </li>
        ))}
      </ul>
    ),
    "ordered-list-item": (children, { depth, keys }) => (
      <ol key={keys.join("|")} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>
            <span className="text">{child}</span>
          </li>
        ))}
      </ol>
    ),
  },
  entities: {
    // key is the entity key value from raw
    LINK: (children, data, { key }) => {
      // console.log(data.url);
      // console.log(children);

      return (
        <OldLinkOrPlainText key={`${key}-${data.url}`} url={data.url} allowFollow={!!data.urlFollow}>
          {children}
        </OldLinkOrPlainText>
      );
    },
    YOUTUBE: (children, data, { key }) => {
      return (
        <div key={key + data.youTubeId} className="fill-width mar-10em-tb bord-rad-05em overflow-hidden">
          <Youtube
            containerClassName={"post-video"}
            videoId={data.youTubeId}
            opts={{ playerVars: { showinfo: 0, modestbranding: 1 } }}
          />
        </div>
      );
    },
    IMAGE: (children, entity, { key }) => {
      if (entity && entity.meta) {
        const [r, g, b] = JSON.parse(entity.meta.colorPalette)[entity.meta.preferredColorIndex || 0];

        // console.log(entity.meta.imageCreditText);
        // console.log(entity.meta.aspectRatio);

        return (
          <span className="post-image" key={key}>
            <ReactImage
              aspectRatio={Number(entity.meta.aspectRatio)}
              affectDominantColor
              dominantColorRgbString={`${r},${g},${b}`}
              dominantColorRgbArray={[r, g, b]}
              src={entity.url}
              alt={entity.meta.altText}
              lazyLoad
            />
            <span className="image-extra-info">
              {entity.meta.subtitleText && (
                <span className="image-subtitle">
                  <span className="subtitle-text">{entity.meta.subtitleText}</span>
                </span>
              )}
              {entity.meta.imageCreditText && (
                <OldLinkOrPlainText
                  text={entity.meta.imageCreditText}
                  url={entity.meta.imageCreditUrl}
                  className="image-credit"
                />
              )}
            </span>
          </span>
        );
      }

      console.error(`Something wrong with IMAGE`);
      console.log(entity);

      return (
        <span key={key} className="post-image" style={{ background: "red", color: "white" }}>
          Incorrect Image Information
        </span>
      );
    },
    RULE: (children, entity, { key }) => {
      return <span key={key} className="post-rule-line" />;
    },
    POST_INDEX: (children, entity, { key }) => {
      console.log(`Received post index`, entity);

      return <OldPostIndex key={`post-index-${key}`} />;
    },
  },
};

export const OldVibescoutPost: React.FC = React.memo(() => {
  /*state = {
    error: "",
  };

  componentDidCatch(error, info) {
    this.setState({
      error: `Something went wrong with the Post Viewer. Please refresh.`,
    });

    console.error(error, info);
  }*/
  // const { postRawState } = this.props;

  const { OldPostPageStore } = PSC_VibescoutSpecial.useStores();
  const postRawState = OldPostPageStore.useState((s) => s.body);

  currentHeaderTwoIndex = 1;

  /*if (this.state.error) {
    return <div>{this.state.error}</div>;
  }*/

  const rendered = redraft(postRawState, renderers);

  return (
    <article className="draft-post-display post" itemProp="articleBody">
      {rendered}
    </article>
  );

  /*render() {

  }*/
});
/*

OldVibescoutPost.propTypes = {
  postRawState: PropTypes.object,
  children: PropTypes.any,
};
*/
