import React from "react";

export const Cocktail = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M403.8 72.4c3.6-4.6 4.2-10.7 1.7-16s-7.8-8.4-13.6-8.4h-56.2c.1-10.5-5-20.5-13.7-26.5l4.5-11.3a7.5 7.5 0 00-14-5.4L308 16.2A31.6 31.6 0 00275 36l-4.7 12H71.1c-5.8 0-11 3.3-13.6 8.5s-1.9 11.3 1.7 15.9L224 282v117.4a48.6 48.6 0 01-48.5 48.5h-24a7.5 7.5 0 100 15h160a7.5 7.5 0 100-15h-24a48.6 48.6 0 01-48.5-48.5V282.1L403.8 72.4zm-135 70.6c7.8 0 11.3 1.5 15.7 3.4 5 2.2 10.8 4.6 21.6 4.6a47 47 0 0019.8-3.8l-94.4 120.2-94.4-120.2a47 47 0 0019.7 3.8 48 48 0 0021.6-4.6c4.4-1.9 8-3.4 15.7-3.4 7.8 0 11.3 1.5 15.7 3.4a48 48 0 0021.6 4.6c10.9 0 16.6-2.4 21.7-4.6 4.4-1.9 7.9-3.4 15.7-3.4zm20-101.5a16.5 16.5 0 0131.9 6.5h-34.4l2.6-6.5zM71.2 63h320.8s.1 0 0 .1l-51 65c-9.2.3-14.4 2.5-19 4.5-4.5 1.9-8 3.4-15.8 3.4-7.8 0-11.3-1.5-15.7-3.4-3-1.3-6.2-2.6-10.6-3.6l15.3-38.8a7.5 7.5 0 00-14-5.4l-17 43.4c-8 .6-12.7 2.6-17 4.4-4.4 1.9-7.9 3.4-15.7 3.4-7.8 0-11.3-1.5-15.7-3.4a48 48 0 00-21.6-4.6 48 48 0 00-21.6 4.6c-4.4 1.9-7.9 3.4-15.7 3.4-7.8 0-11.3-1.5-15.7-3.4-4.6-2-9.8-4.2-19-4.5L71 63V63zm175.5 385h-30.2a64 64 0 0015.1-18.6 63.9 63.9 0 0015 18.6z" />
    <path d="M249.7 164.8l-25.2 64a7.5 7.5 0 1014 5.4l25.1-64a7.5 7.5 0 10-14-5.4z" />
  </svg>
);
export const Balloons = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 466 466">
    <path d="M190 52c6 1 11 3 15 6a8 8 0 008-13l-18-8a8 8 0 00-9 5 8 8 0 004 10zm51 80c0 11-1 22-4 34a8 8 0 1015 3c3-12 4-25 4-37 0-26-6-48-19-65a8 8 0 00-12 9c11 14 16 33 16 56zM133 246c-19-12-35-37-45-65a8 8 0 00-14 5c11 31 29 58 51 73a7 7 0 0010-2 8 8 0 00-2-11z" />
    <path d="M395 122c-23-22-55-34-90-34l-22 2c-6-22-17-41-32-56-23-22-55-34-90-34S94 12 71 34c-25 24-38 58-38 98s14 83 37 116c20 28 44 46 70 52a23 23 0 00-3 11v16a8 8 0 008 8h8l-3 11c-2 6-5 12-5 24s3 19 5 24 3 10 3 19-1 13-3 19c-2 5-5 11-5 24a8 8 0 0015 0c0-10 2-14 4-19 2-6 4-12 4-24s-2-19-4-24-4-10-4-19 2-14 4-19l4-16h9a8 8 0 007-7v-16l-2-12a93 93 0 0011-3c20 48 54 82 91 91a23 23 0 00-3 12v16a8 8 0 008 7h8l-3 11c-2 6-5 12-5 24a8 8 0 0015 0c0-9 2-14 4-19l4-16h9a8 8 0 007-7v-16l-2-12c26-6 50-24 70-52 23-33 36-76 36-116s-13-74-37-98zM82 239c-21-30-34-70-34-107 0-36 12-66 33-87 20-19 48-30 80-30s59 11 79 30c22 21 33 51 33 87 0 37-12 77-33 107-23 32-50 49-79 49s-57-17-79-49zm87 73v8h-17v-8a9 9 0 0117 0zm144 88v8h-17v-8a9 9 0 0117 0zm71-73c-23 32-50 49-79 49-20 0-41-9-59-25-16-15-30-37-40-60l15-10c10 28 28 52 48 66a7 7 0 0010-2 8 8 0 00-2-11c-18-12-35-36-44-64 7-6 13-14 19-22a207 207 0 0034-144 133 133 0 0119-1c31 0 59 11 79 30 22 21 33 51 33 87 0 37-12 77-33 107z" />
    <path d="M381 155a8 8 0 00-12 9c11 14 16 33 16 56 0 11-1 22-4 34a8 8 0 1015 3c3-12 4-25 4-37 0-26-6-48-19-65zm-24-22l-18-8a8 8 0 00-5 15c6 1 11 3 15 6a8 8 0 0010-3 7 7 0 00-2-10z" />
  </svg>
);
export const Beer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M362 53c-2-3-6-5-11-5a32 32 0 00-44-29 39 39 0 00-42-8 55 55 0 00-67 0c-4-2-9-3-14-3-11 0-20 4-28 11a31 31 0 00-12-3c-18 0-32 14-32 32-5 0-9 2-11 5-3 3-5 6-5 11 1 95 15 145 28 193 13 46 27 94 28 183 0 13 11 23 23 23h113c12 0 23-10 23-23 1-89 15-137 28-183 13-48 27-98 28-193 0-5-2-8-5-11zM111 63h17c4 0 8 4 8 9a24 24 0 0047-1c0-4 4-8 8-8h16c5 0 9 4 9 9v50l-6 3c-5 2-8 3-16 3s-11-1-16-3c-5-3-10-5-21-5s-17 2-22 5c-4 2-8 3-16 3h-5c-2-19-3-40-3-64a1 1 0 010-1zm213 190c-13 47-26 96-28 187 0 4-4 8-8 8H175c-4 0-8-4-8-8-2-91-15-140-28-187-10-33-18-65-24-110h4c11 0 17-2 22-5 4-2 8-3 16-3s11 1 15 3c6 3 11 5 22 5s17-2 22-5v86a24 24 0 0047 0v-16a7 7 0 10-15 0v16a9 9 0 01-17 0V72c0-13-11-24-24-24h-16c-12 0-23 11-23 24a9 9 0 01-17-1c0-12-11-23-23-23h-1c0-10 7-17 17-17l9 3a7 7 0 0011-1 24 24 0 0132-7 7 7 0 008-1 40 40 0 0155 0 7 7 0 008 1 24 24 0 0132 7 8 8 0 0011 1l9-3c10 0 17 7 17 17-13 0-24 11-24 24v16a7 7 0 1015 0V72c0-5 4-9 9-9h16a1 1 0 010 1c0 24-1 45-3 64h-6c-7 0-11-1-15-3l-1-1v-4a7 7 0 10-15 0v15a9 9 0 01-17 1V72a24 24 0 00-47-1v104a8 8 0 008 8 8 8 0 007-8v-32a61 61 0 0018-1 24 24 0 0046-2 48 48 0 0021 3c-6 45-14 77-24 110zm-55-125h-6V71l2-6a8 8 0 017-2c4 0 8 4 8 9v54l-11 2z" />
    <path d="M272 416h-80a7 7 0 100 15h80a7 7 0 100-15z" />
  </svg>
);
export const Calendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M424 44h-17v-8a24 24 0 00-47 0v8H103v-8a24 24 0 00-47 0v8H40C18 44 0 62 0 84v328c0 21 18 39 40 39h384c21 0 39-18 39-39V84c0-22-18-40-39-40zm-49-8a9 9 0 0117 0v16c0 1 0 0 0 0v16a9 9 0 01-17 0V36zM80 27c4 0 8 4 8 9v32a9 9 0 01-17 0V52 36c0-5 4-9 9-9zm344 409H40c-14 0-25-11-25-24V123h56a7 7 0 100-15H15V84c0-14 11-25 25-25h16v9a24 24 0 0047 0v-9h257v9a24 24 0 0047 0v-9h17c13 0 24 11 24 25v24h-40a7 7 0 100 15h40v289c0 13-11 24-24 24z" />
    <path d="M375 108h-15a7 7 0 100 15h15a7 7 0 100-15zm-40 8c0-5-3-8-7-8H103a7 7 0 100 15h225c4 0 7-3 7-7zm-5 120h-56a6 6 0 01-5-4l-18-54c-3-8-10-14-19-14s-17 6-20 14l-18 54a6 6 0 01-5 4h-56c-9 0-17 5-20 14s0 18 8 23l45 33a6 6 0 012 7l-17 53c-3 9 0 18 7 23s17 6 25 0l45-33a6 6 0 017 0l45 33a21 21 0 0032-23l-17-53a6 6 0 012-7l45-33c8-5 11-14 8-23s-11-14-20-14zm4 25l-46 33a21 21 0 00-7 23l17 54c1 3-1 6-2 6-1 1-4 2-7 0l-45-33a21 21 0 00-25 0l-45 33c-3 2-6 1-7 0-1 0-3-3-2-6l17-54a21 21 0 00-7-23l-46-33c-3-2-2-5-2-6 1-1 2-4 6-4h56a21 21 0 0020-15l17-53c1-4 5-4 6-4s4 0 5 4l17 53a21 21 0 0020 15h56c4 0 5 3 6 4 0 1 1 4-2 6z" />
    <path d="M268 269c-12 9-19 23-20 38a53 53 0 002 19 8 8 0 007 5 8 8 0 007-10 38 38 0 01-1-13c0-11 6-20 14-27a7 7 0 001-10 7 7 0 00-10-2zm-29-26a8 8 0 00-10-5 8 8 0 00-5 10 53 53 0 0010 16 7 7 0 006 3 8 8 0 005-12 38 38 0 01-6-12z" />
  </svg>
);
export const DiscoBall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M257 98a31 31 0 006-18c0-15-10-28-24-31V8a8 8 0 00-15 0v41a32 32 0 00-18 49 184 184 0 1051 0zm133 238h-37c3-16 5-32 6-49h41a167 167 0 01-10 49zm-33 56h-25c7-13 13-26 17-41h35a169 169 0 01-27 41zm-251 0a169 169 0 01-27-41h35c4 15 10 28 17 41h-25zM73 223h37c-3 16-5 32-6 49H63a167 167 0 0110-49zm33-56h25c-7 13-13 26-17 41H79a169 169 0 0127-41zm226 0h25a169 169 0 0127 41h-35c-4-15-10-28-17-41zm-9-15v-1l-21-24a169 169 0 0140 25h-19zm-35 128c0 90-25 155-49 166v-39h9a8 8 0 000-15h-9v-41h25a8 8 0 000-15h-25v-49h25a8 8 0 000-15h-25v-49h24a8 8 0 000-15h-24v-41h8a8 8 0 000-15h-8v-39c11 5 22 21 31 45 12 32 18 75 18 122zm-93 127h29v39c-10-4-20-18-29-39zm29-294v39h-28c8-21 18-35 28-39zm-29 7c-6 9-11 19-16 32h-21c11-14 23-25 37-32zm29 47v41h-43c2-15 5-29 9-41h34zm0 56v49h-49c0-17 2-34 3-49h46zm0 64v49h-46c-2-16-3-32-3-49h49zm0 64v41h-34c-4-12-7-26-9-41h43zm-84-200v1h-19a169 169 0 0140-25l-21 24zm-36 136c1 17 3 33 6 49H73a167 167 0 01-10-49h41zm26 64c3-1 5-4 5-7a8 8 0 00-7-8h-2c-4-15-6-32-7-49a8 8 0 000-15c1-17 3-34 7-49h1a8 8 0 008-7c0-4-2-7-5-8 4-15 10-29 18-41h26l-9 41h-5a8 8 0 000 15h3c-2 16-3 32-3 49h-8a8 8 0 000 15h8c0 17 1 33 3 49h-3a8 8 0 000 15h5l9 41h-26c-8-12-14-26-18-41zm49 56c5 13 10 23 16 32-14-7-26-18-37-32h21zm89 32c6-9 11-19 16-32h3a8 8 0 008-7c0-4-3-7-6-8l9-41h22a8 8 0 000-15h-20c2-16 3-32 3-49h17a8 8 0 000-15h-17c0-17-1-33-3-49h11a8 8 0 000-15h-13l-9-41c3-1 6-4 6-8a8 8 0 00-8-7h-3c-5-13-10-23-16-32 44 23 76 86 76 160s-32 136-76 159zm91-167c-1-17-3-33-6-49h37a167 167 0 0110 49h-41zM215 80c0-7 4-12 9-15v15a8 8 0 0015 0V65a17 17 0 11-24 15zm-94 327h19v1l21 24a169 169 0 01-40-25zm202 1v-1h19a169 169 0 01-40 25l21-24z" />
  </svg>
);
export const EyeMask = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M463 8a8 8 0 00-10-8s-23 8-93 8c-52 0-93 17-122 51l-6 3c-2 0-5-1-7-3-29-34-70-51-121-51-70 0-94-8-94-8A7 7 0 000 8c0 34 3 85 24 127 11 20 26 37 44 50 20 14 45 24 74 30 49 9 70 27 78 36a15 15 0 0023 0c8-9 29-27 78-36 29-6 54-16 74-30a143 143 0 005-4v275a8 8 0 0015 0V168c9-10 17-21 24-33 10-20 24-55 24-127zM318 200c-50 10-75 28-86 41h-1c-11-13-36-31-86-41-52-10-87-33-107-72-10-18-22-49-23-111 13 2 40 6 89 6 46 0 83 15 110 46a23 23 0 0035 0c27-31 64-46 111-46 48 0 75-4 88-6-1 32-4 75-23 111-20 39-55 62-107 72z" />
    <path d="M391 81a8 8 0 00-7-9h-22c-33 0-57 9-74 29-9 11-16 26-18 41a8 8 0 007 9h29c21 0 39-6 54-18 14-12 24-29 31-52zm-85 55h-19c3-14 10-23 13-26 13-16 33-23 62-23h11c-12 33-34 49-67 49zm-136 15h16a8 8 0 007-9c-2-15-9-30-18-41-17-20-41-29-74-29H80a8 8 0 00-8 9c7 23 17 40 31 52 15 12 33 18 54 18h13zM90 87h11c29 0 49 7 62 23 3 3 10 12 13 26h-19c-33 0-55-16-67-49zm253-31h1a163 163 0 0116-1 8 8 0 000-15l-17 1a8 8 0 000 15zm-193 7l16 7a7 7 0 0010-3 8 8 0 00-3-10l-18-9a8 8 0 10-5 15zm82 46c15 0 31-7 41-19 11-13 25-22 41-28a8 8 0 10-5-14c-19 7-34 17-47 32a40 40 0 01-61 0 8 8 0 00-11 10c10 12 26 19 41 19z" />
    <path d="M50 53a769 769 0 0069 3c4 0 8-3 8-7a8 8 0 00-7-8 178 178 0 00-16-1l-62-2a7 7 0 00-1 0h-2a7 7 0 00-3 2 7 7 0 00-2 6c4 46 17 80 40 103a7 7 0 005 2 7 7 0 006-3 7 7 0 000-10c-20-19-31-46-35-85zm377-13a7 7 0 00-6-2l-30 1a8 8 0 00-7 8c0 4 4 8 8 7a645 645 0 0021-1c-4 34-13 60-28 78-16 19-40 32-73 38a8 8 0 003 14c36-6 63-20 81-43 19-22 30-53 33-94a7 7 0 00-2-6zM277 178c-17 5-33 13-45 21-21-14-49-24-81-30-14-3-26-6-36-11a8 8 0 00-7 13c12 6 25 10 40 12 33 7 59 17 79 32a7 7 0 009 0c12-10 28-17 46-23a8 8 0 00-5-14z" />
  </svg>
);
export const GamePad = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M163.8 115.39a7.5 7.5 0 10-8.58 12.3 49.1 49.1 0 0113.87 14.82 7.5 7.5 0 0010.3 2.56 7.5 7.5 0 002.55-10.3 64.23 64.23 0 00-18.14-19.38zm208 0a7.5 7.5 0 00-8.58 12.3 49.1 49.1 0 0113.87 14.82 7.5 7.5 0 0010.3 2.56 7.5 7.5 0 002.55-10.3 64.23 64.23 0 00-18.14-19.38z" />
    <path d="M461.5 312.91l-40-192c-8.04-38.57-45.99-63.44-84.59-55.41A71.63 71.63 0 00298.06 88H164.95a71.64 71.64 0 00-38.86-22.5c-38.6-8.03-76.54 16.84-84.58 55.41l-40 192c-8.04 38.6 16.82 76.54 55.42 84.59A72.23 72.23 0 0071.58 399c33.64 0 63.05-23.94 69.93-56.92l14.68-69.9a43.23 43.23 0 0023.32 6.8 43.43 43.43 0 0033.76-16.1c.4.07.8.1 1.21.1h34.03a7.43 7.43 0 001.24-.1 43.43 43.43 0 0033.76 16.1 43.22 43.22 0 0023.3-6.8l14.69 69.9c6.87 32.98 36.28 56.92 69.93 56.92 4.87 0 9.8-.5 14.65-1.51 38.6-8.05 63.45-46 55.41-84.59zM179.5 264c-15.7 0-28.5-12.79-28.5-28.5s12.8-28.5 28.5-28.5 28.5 12.78 28.5 28.5-12.78 28.5-28.5 28.5zm104 0c-15.7 0-28.5-12.79-28.5-28.5s12.8-28.5 28.5-28.5 28.5 12.78 28.5 28.5-12.78 28.5-28.5 28.5zm119.53 118.8a57.17 57.17 0 01-11.6 1.21c-26.58 0-49.82-18.92-55.25-45l-16.63-79.18A43.25 43.25 0 00327 235.5a43.63 43.63 0 00-.31-5.12A63.57 63.57 0 00399 167.5a7.5 7.5 0 10-15 0 48.55 48.55 0 01-62.8 46.35 43.67 43.67 0 00-26.7-20.44 48.23 48.23 0 01-7.5-25.92 48.55 48.55 0 0148.5-48.5 7.5 7.5 0 100-15 63.57 63.57 0 00-63.5 63.5 63.3 63.3 0 005.1 24.98A43.56 43.56 0 00241.85 248h-20.68a43.35 43.35 0 001.84-12.5 43.56 43.56 0 00-37.1-43.03 63.22 63.22 0 005.1-24.98 7.5 7.5 0 10-15 0c0 9.27-2.6 18.17-7.5 25.92a43.67 43.67 0 00-26.7 20.44 48.47 48.47 0 01-14.3 2.14c-26.74 0-48.5-21.75-48.5-48.5s21.76-48.5 48.5-48.5a7.5 7.5 0 100-15C92.49 104 64 132.48 64 167.5a63.57 63.57 0 0072.31 62.88 43.25 43.25 0 007.14 29.44l-16.64 79.2c-5.43 26.08-28.67 45-55.25 45a56.57 56.57 0 01-55.38-68.04l40-192a56.6 56.6 0 0166.84-43.8 56.58 56.58 0 0132.58 20 7.5 7.5 0 005.86 2.82h140.06a7.5 7.5 0 005.86-2.81 56.58 56.58 0 0132.57-20 56.6 56.6 0 0166.85 43.79l40 192a56.57 56.57 0 01-43.78 66.84z" />
    <path d="M127.5 128a7.5 7.5 0 00-7.5 7.5v16a7.5 7.5 0 1015 0v-16a7.5 7.5 0 00-7.5-7.5zm0 48a7.5 7.5 0 00-7.5 7.5v16a7.5 7.5 0 1015 0v-16a7.5 7.5 0 00-7.5-7.5zm-16-16h-16a7.5 7.5 0 100 15h16a7.5 7.5 0 100-15zm48 0h-16a7.5 7.5 0 100 15h16a7.5 7.5 0 100-15zm176-32a7.5 7.5 0 00-7.5 7.5v16a7.5 7.5 0 1015 0v-16a7.5 7.5 0 00-7.5-7.5zm0 48a7.5 7.5 0 00-7.5 7.5v16a7.5 7.5 0 1015 0v-16a7.5 7.5 0 00-7.5-7.5zm-16-16h-16a7.5 7.5 0 100 15h16a7.5 7.5 0 100-15zm48 0h-16a7.5 7.5 0 100 15h16a7.5 7.5 0 100-15zm-188 64c-6.33 0-11.5 5.16-11.5 11.5s5.17 11.5 11.5 11.5 11.5-5.16 11.5-11.5-5.15-11.5-11.5-11.5zm68-64h-32a7.5 7.5 0 100 15h32a7.5 7.5 0 100-15zm36 64c-6.33 0-11.5 5.16-11.5 11.5s5.17 11.5 11.5 11.5 11.5-5.16 11.5-11.5-5.15-11.5-11.5-11.5z" />
  </svg>
);
export const Glasses = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M450 101l-29-35a8 8 0 00-11 10l28 34a41 41 0 0110 26v127h-13a53 53 0 00-87-16c-2 2-6 2-9 0a53 53 0 00-86 17 32 32 0 00-21-8c-8 0-16 3-22 8a53 53 0 00-86-17c-2 2-7 2-9 0a53 53 0 00-86 16H15V136a41 41 0 019-26l29-34a8 8 0 00-11-10l-29 35a56 56 0 00-13 35v151c0 13 11 23 24 23h6c4 11 11 24 24 39 17 20 40 38 62 50a7 7 0 007 0c22-12 45-30 62-50 25-29 30-51 30-64v-1a17 17 0 0133 1c1 14 6 36 30 64 17 20 40 38 62 50a7 7 0 007 0c23-12 45-30 62-50 13-15 20-28 25-39h5c13 0 24-10 24-23V136a56 56 0 00-13-35zM24 295c-5 0-9-3-9-8v-9h10a54 54 0 00-1 7l1 10h-1zm96 88c-49-26-81-71-81-98a38 38 0 0165-27c9 8 22 8 31 0 7-8 16-12 27-12 21 0 38 18 38 39 0 27-32 72-80 98zm224 0c-48-26-81-71-81-98a38 38 0 0166-27c8 8 21 8 30 0 7-8 17-12 27-12 21 0 38 18 38 39 0 27-32 72-80 98zm95-88h-1l1-10a54 54 0 000-7h9v9c0 5-4 8-9 8z" />
    <path d="M171 300a8 8 0 00-10 2c-10 16-27 34-46 47a8 8 0 009 12c20-14 39-33 50-51a8 8 0 00-3-10zm-9-37a7 7 0 100 15 6 6 0 015 4 8 8 0 1014-6c-4-8-11-13-19-13zm233 37a8 8 0 00-10 2c-10 16-27 34-46 47a8 8 0 009 12c20-14 39-33 50-51a8 8 0 00-3-10zm-9-37a7 7 0 100 15 6 6 0 015 4 8 8 0 1014-6c-4-8-11-13-19-13z" />
  </svg>
);
export const IceCream = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M351 135.5a63.6 63.6 0 00-56.6-63.1 63.6 63.6 0 10-125.8 0 63.6 63.6 0 00-32.3 113v.4l88 272a7.5 7.5 0 0014.3 0l88-272 .1-.4a63.2 63.2 0 0024.3-49.9zm-15 0c0 16.4-8.2 31.5-21.8 40.5h-53.4a48.6 48.6 0 1175.2-40.5zM238.6 176h-14.2a63 63 0 007.1-10.5 63 63 0 007 10.5zm-7.1-161A48.6 48.6 0 01280 63.5a48.9 48.9 0 01-.9 9 63.6 63.6 0 00-47.6 33.1 63.6 63.6 0 00-47.6-33 48.9 48.9 0 01-.9-9.1A48.6 48.6 0 01231.5 15zM127 135.5a48.6 48.6 0 1175.2 40.5h-53.4a48.6 48.6 0 01-21.8-40.5zM272.6 191l-3.9 1.5a7.5 7.5 0 005.6 14l34.3-13.8-14.8 45.8-17 6.8a7.5 7.5 0 005.5 14l5.5-2.3-14.8 45.8-28.8 11.5a7.5 7.5 0 005.6 14l17.2-7-14.8 45.8-36.7 14.7-11.4-35.3 18.2-7.3a7.5 7.5 0 00-5.6-13.9l-17.2 6.9-11.4-35.3 22.2-8.8a7.5 7.5 0 00-5.6-14l-21.2 8.5-11.5-35.2 29-11.6a7.5 7.5 0 00-5.5-14l-28 11.3-13.7-42.1h118.8zm-41.1 240.1l-11.3-35 26-10.4-14.7 45.4z" />
    <path d="M175.5 119c9.1 0 16.5 7.4 16.5 16.5a7.5 7.5 0 1015 0 31.5 31.5 0 00-31.5-31.5 7.5 7.5 0 100 15zm56-72c9.1 0 16.5 7.4 16.5 16.5a7.5 7.5 0 1015 0A31.5 31.5 0 00231.5 32a7.5 7.5 0 100 15zm48.5 64.5c0 4.1 3.4 7.5 7.5 7.5 9.1 0 16.5 7.4 16.5 16.5a7.5 7.5 0 1015 0 31.5 31.5 0 00-31.5-31.5 7.5 7.5 0 00-7.5 7.5zm-54.1 113.8a7.5 7.5 0 002.8-.6l16.6-6.6a7.5 7.5 0 10-5.6-14l-16.6 6.7a7.5 7.5 0 002.8 14.5zm5.8 38a7.5 7.5 0 005.6 14l17-6.8a7.5 7.5 0 00-5.6-14l-17 6.8z" />
  </svg>
);
export const Karaoke = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M319 87.5a87.6 87.6 0 00-175 0 86.6 86.6 0 0029.3 65.2 23.4 23.4 0 00-5.3 14.8c0 7 3.1 13.4 8.1 17.7l14.9 216a23.6 23.6 0 0023.4 21.8h9.6v32.5a7.5 7.5 0 0015 0V423h9.6c12.3 0 22.6-9.6 23.4-21.9L287 185.2a23.4 23.4 0 002.8-32.5A86.6 86.6 0 00319 87.5zm-160 0a72.6 72.6 0 01145 0c0 22.2-9.9 42.6-27.2 56.5h-90.6A71.8 71.8 0 01159 87.5zm98 312.6c-.3 4.4-4 7.9-8.4 7.9h-34.2a8.5 8.5 0 01-8.4-8l-14.5-209h40a7.5 7.5 0 000-15h-40a8.5 8.5 0 010-17h80a8.5 8.5 0 010 17h-8a7.5 7.5 0 000 15h8L257 400z" />
    <path d="M231.5 208c-13 0-23.5 10.5-23.5 23.5v32a23.5 23.5 0 0047 0v-32c0-13-10.5-23.5-23.5-23.5zm0 15a8.5 8.5 0 110 17 8.5 8.5 0 010-17zm0 49a8.5 8.5 0 01-8.5-8.5v-10.1a23.4 23.4 0 0017 0v10.1c0 4.7-3.8 8.5-8.5 8.5zm0-225c4.6 0 9.2.8 13.5 2.3a7.5 7.5 0 002.5.4 7.5 7.5 0 002.5-14.5 55.4 55.4 0 00-18.5-3.2 7.5 7.5 0 000 15zm30.8 78a7.5 7.5 0 005 2 7.5 7.5 0 005.6-2.5 55.4 55.4 0 0014.1-37c0-11-3.2-21.6-9.3-30.7a7.5 7.5 0 10-12.4 8.3 40.3 40.3 0 016.7 22.4 40.4 40.4 0 01-10.3 27 7.5 7.5 0 00.6 10.6z" />
  </svg>
);
export const Pizza = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 462.9 462.9">
    <path d="M55 247.4c0-88.5 72-160.5 160.5-160.5a7.5 7.5 0 100-15A175.7 175.7 0 0077.7 356a7.5 7.5 0 005.9 2.9 7.5 7.5 0 005.8-12.1A158.9 158.9 0 0155 247.4zm328.5-7.5a7.5 7.5 0 00-7.5 7.5 160.7 160.7 0 01-264.5 122.3 7.5 7.5 0 00-10.5.8 7.5 7.5 0 00.8 10.6A175.6 175.6 0 00391 247.4a7.5 7.5 0 00-7.5-7.5z" />
    <path d="M408.9 170.4l-181 64.6 64.6-181a7.5 7.5 0 00-4.5-9.6A214.9 214.9 0 00215.5 32 214 214 0 0063.1 95C22.4 135.7 0 189.8 0 247.4s22.4 111.7 63.1 152.4 94.8 63.1 152.4 63.1 111.7-22.4 152.4-63.1A214 214 0 00431 247.4c0-24.8-4.2-49.2-12.5-72.5a7.5 7.5 0 00-9.6-4.5zM215.5 447.9c-110.6 0-200.5-90-200.5-200.5A200.8 200.8 0 01275.8 56.2l-8.4 23.5a175.1 175.1 0 00-18.5-4.6c-4-.8-8 1.9-8.8 6a7.5 7.5 0 006 8.7 160 160 0 0116.3 4l-54 151a7.5 7.5 0 009.6 9.7l151-54a159.9 159.9 0 014 16.3 7.5 7.5 0 008.9 6 7.5 7.5 0 006-8.8 175 175 0 00-4.7-18.5l23.5-8.4a200.7 200.7 0 01-191.2 260.8zM380.7 76.7a7.5 7.5 0 10-10.4 10.9 161.5 161.5 0 0112 12.6 7.5 7.5 0 005.7 2.7 7.5 7.5 0 005.7-12.3 177.4 177.4 0 00-13-13.9z" />
    <path d="M462.5 131A216.9 216.9 0 00332 .3a7.5 7.5 0 00-9.6 4.6l-70 195.9a7.5 7.5 0 009.6 9.6L420 154h.1l37.8-13.5a7.5 7.5 0 004.6-9.6zm-40.5 6.4a175.3 175.3 0 00-10.7-21.8 7.5 7.5 0 00-13 7.6 160.2 160.2 0 019.5 19.3l-136 48.5 48.6-136a160.3 160.3 0 0127 14.3 7.5 7.5 0 008.2-12.5A175.1 175.1 0 00325.5 41l8.5-23.8A202 202 0 01445.7 129l-23.7 8.5zM147.8 243.2a7.5 7.5 0 10-13-7.5 8.5 8.5 0 01-7.3 4.2 8.5 8.5 0 010-17 7.5 7.5 0 100-15 23.5 23.5 0 1020.3 35.3z" />
    <path d="M353.1 129a7.5 7.5 0 00-10.2 2.7 8.5 8.5 0 01-7.4 4.2 8.5 8.5 0 010-17 7.5 7.5 0 100-15 23.5 23.5 0 1020.3 35.3 7.5 7.5 0 00-2.7-10.3zm-71.5 222c8.4 0 16.2-4.5 20.4-11.7a7.5 7.5 0 10-13-7.6 8.5 8.5 0 01-7.4 4.3 8.5 8.5 0 010-17 7.5 7.5 0 100-15 23.5 23.5 0 000 47zM199 323.7a8.5 8.5 0 01-7.4 4.2 8.5 8.5 0 010-17 7.5 7.5 0 100-15 23.5 23.5 0 1020.3 35.3 7.5 7.5 0 00-2.7-10.3 7.5 7.5 0 00-10.2 2.8zm-15.4-180.8a7.5 7.5 0 100-15 23.5 23.5 0 1020.3 35.3 7.5 7.5 0 10-13-7.5 8.5 8.5 0 01-7.3 4.2 8.5 8.5 0 010-17zm-29.3 51.2a7.5 7.5 0 000 10.6l16 16a7.5 7.5 0 0010.6 0 7.5 7.5 0 000-10.6l-16-16a7.5 7.5 0 00-10.6 0zm-37.4-13.4a7.5 7.5 0 00-10.6-10.6l-8 8a7.5 7.5 0 0010.6 10.6l8-8zm-2.6 117.4l-8 8a7.5 7.5 0 0010.6 10.6l8-8a7.5 7.5 0 00-10.6-10.6zM239.5 287h16a7.5 7.5 0 100-15h-16a7.5 7.5 0 100 15zm96-24.1a7.5 7.5 0 005.3-2.2l8-8a7.5 7.5 0 00-10.6-10.6l-8 8a7.5 7.5 0 005.3 12.8zm-13.3 53.9a7.5 7.5 0 0010.6 0 7.5 7.5 0 000-10.6l-8-8a7.5 7.5 0 00-10.6 10.6l8 8zm-98.7 66.1a7.5 7.5 0 005.3-2.2l8-8a7.5 7.5 0 00-10.6-10.6l-8 8a7.5 7.5 0 005.3 12.8zm-48-111.9a7.5 7.5 0 100-15h-8a7.5 7.5 0 100 15h8zM338.2 92.7a7.5 7.5 0 0010.6 0 7.5 7.5 0 000-10.6l-8-8a7.5 7.5 0 00-10.6 10.6l8 8zm37.3 19.3a7.5 7.5 0 00-7.5 7.5v15.9a7.5 7.5 0 1015 0v-15.9a7.5 7.5 0 00-7.5-7.5zM154.2 364.8a7.5 7.5 0 0010.6 0 7.5 7.5 0 000-10.6l-8-8a7.5 7.5 0 00-10.6 10.6l8 8zM295.5 263a7.5 7.5 0 007.5-7.5v-8a7.5 7.5 0 10-15 0v8a7.5 7.5 0 007.5 7.5zm-194.7-4.8l-8-8a7.5 7.5 0 00-10.6 10.6l8 8a7.5 7.5 0 0010.6 0 7.5 7.5 0 000-10.6zM216 111.5v8a7.5 7.5 0 1015 0v-8a7.5 7.5 0 10-15 0z" />
  </svg>
);
export const SunGlasses = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 463 463">
    <path d="M450 109l-29-34a8 8 0 00-11 9l28 35a41 41 0 0110 26v127h-9v-9c0-8-7-15-16-15H263c-8 0-15 7-15 15v5a32 32 0 00-16-4 32 32 0 00-17 5v-6c0-8-7-15-16-15H39c-8 0-15 7-15 15v9h-9V145a41 41 0 019-26l29-35a8 8 0 00-11-9l-29 34a56 56 0 00-13 36v150c0 13 11 24 24 24v6c0 9 3 17 9 23l36 36a23 23 0 0017 7h67a23 23 0 0016-7l37-36c6-6 9-14 9-23v-32c1-8 8-14 17-14a17 17 0 0116 12v34c0 9 3 17 9 23l36 36a23 23 0 0017 7h67a23 23 0 0016-7l37-36c6-6 9-14 9-23v-6c13 0 24-11 24-24V145a56 56 0 00-13-36zM24 304c-5 0-9-4-9-9v-8h9v17zm135 69a8 8 0 01-6 3H86a8 8 0 01-6-3l-15-14h38a7 7 0 100-15H50l-6-7a16 16 0 01-5-10h64a7 7 0 100-15H39v-1-16h64a7 7 0 100-15H39v-1-16h160a1 1 0 011 0v17h-17a7 7 0 100 15h17v17h-17a7 7 0 100 15h17a16 16 0 01-5 10l-36 36zm224 0a8 8 0 01-6 3h-67a8 8 0 01-6-3l-15-14h38a7 7 0 100-15h-53l-6-7a16 16 0 01-5-10h64a7 7 0 100-15h-64v-17h64a7 7 0 100-15h-64v-17h160a1 1 0 011 0v17h-17a7 7 0 100 15h17v17h-17a7 7 0 100 15h17a16 16 0 01-5 10l-36 36zm56-69v-17h9v8c0 5-4 9-9 9z" />
    <path d="M135 295h16a7 7 0 100-15h-16a8 8 0 000 15zm0 32h16a7 7 0 100-15h-16a8 8 0 000 15zm16 17h-16a7 7 0 100 15h16a7 7 0 100-15zm208-49h16a7 7 0 100-15h-16a8 8 0 000 15zm0 32h16a7 7 0 100-15h-16a8 8 0 000 15zm16 17h-16a7 7 0 100 15h16a7 7 0 100-15z" />
  </svg>
);

export const PartyIcons = {
  Cocktail,
  Balloons,
  Beer,
  Calendar,
  DiscoBall,
  EyeMask,
  GamePad,
  Glasses,
  Karaoke,
  SunGlasses,
  Pizza,
  IceCream,
};
