import { useRef } from "react";
import isEqual from "fast-deep-equal";

export function useSingleRun(deps: any = ""): (func?: () => any) => any {
  const prevDeps = useRef<any>("__null__");
  const prevValue = useRef<any>();

  return (func) => {
    if (!isEqual(prevDeps.current, deps)) {
      prevDeps.current = deps;
      prevValue.current = func?.();
    }
    return prevValue.current!;
  };
}
