import { FelaTextInput, TFelaTextInputProps } from "../fela/FelaTextInput";
import { FelaIconWrap } from "../fela/FelaIconWrap";
import { EFelaLayerHeight } from "../fela/FelaLayers";
import React, { FunctionComponent, ReactElement } from "react";
import { FiX } from "react-icons/fi";
import { ETextSize } from "../config/VibescoutFrontendStyleConfig";

export const TextInputWithButton: FunctionComponent<
  TFelaTextInputProps & {
    disabled?: boolean;
    showButton?: boolean;
    onClickButton?: () => void;
    icon?: ReactElement;
    outerDivStyle?: any;
    buttonProps?: any;
  }
> = ({
  showButton = true,
  disabled = false,
  icon = <FiX />,
  onClickButton,
  outerDivStyle,
  buttonProps = {},
  children,
  trackPadding,
  ...props
}) => {
  // props.cssExtra = [...(props.cssExtra || [])];

  buttonProps.style = {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    ...buttonProps.style,
  };

  return (
    <div style={{ position: "relative", ...outerDivStyle }}>
      <FelaTextInput
        trackPadding={trackPadding ?? 60}
        padding={"0.5em 2em 0.5em 0.5em"}
        disabled={disabled}
        {...props}
      />
      {showButton && (
        <FelaIconWrap
          onClick={() => {
            if (!disabled && onClickButton) {
              onClickButton();
            }
          }}
          noBackground
          // style={{ position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)" }}
          margin={"0 0.5em"}
          isButton
          size={props.size ?? ETextSize.small}
          sizeNum={props.fontSize || 1}
          hoverable
          height={EFelaLayerHeight.HIGH}
          {...buttonProps}
        >
          {icon}
        </FelaIconWrap>
      )}
      {children}
    </div>
  );
};
