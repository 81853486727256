import { Store } from "pullstate";
import { IDMOldPostSnippet } from "@vs/core/build/data/models/oldVibescout/oldPost/OldPostSnippet";
import { TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { ILockdownGeoInfoWithDates } from "@vs/core/build/data/models/covid19/lockdownLevels/lockdownLevelsGeo/LockdownLevelsGeoUtils";
import {
  EGSLockdownLevelValue,
  TLockdownLevelValueActualLevels,
} from "@vs/core/build/data/models/covid19/lockdownLevels/lockdownLevelsGeo/LockdownLevelsGeoSheetModels";
import { IDMVibescoutIcon } from "@vs/core/build/data/models/graphics/icons/VibescoutIconModel";
import stringify from "json-stringify-pretty-compact";

interface IOldPostExtension {
  colorString: string;
  colorRgbArray: TColorRgbArray;
  colorPaletteHydrated: [number, number, number][];
}

export interface IOldPostSnippetWithColorInformation extends IOldPostExtension, IDMOldPostSnippet {}

export interface ILocalityStore {
  icons: {
    [iconKey: string]: IDMVibescoutIcon;
  };
  covid: {
    lockdownLevels: {
      selectedCategory: string;
      selectedLvl: TLockdownLevelValueActualLevels;
      previous?: ILockdownGeoInfoWithDates;
      current?: ILockdownGeoInfoWithDates;
      next?: ILockdownGeoInfoWithDates;
    };
  };
  oldLocalityPosts: {
    press: IOldPostSnippetWithColorInformation[];
    guides: IOldPostSnippetWithColorInformation[];
    food: IOldPostSnippetWithColorInformation[];
  };
  closedBlurb: boolean;
}

export const LocalityStore = new Store<ILocalityStore>({
  icons: {},
  oldLocalityPosts: {
    guides: [],
    press: [],
    food: [],
  },
  covid: {
    lockdownLevels: {
      selectedCategory: "PgU-",
      selectedLvl: EGSLockdownLevelValue.LVL_1,
    },
  },
  closedBlurb: false,
});

/*LocalityStore.listenToPatches((patches) => {
  console.log(patches.map((p) => `${p.op}:${p.path.join(" > ")}:${stringify(p.value)}`).join("\n"));
});*/
