import { EGradientType, IFelaTheme, IGradient, IGradientStop } from "./FelaCommon";
import { EFrontendStyleConfigBaseTheme } from "../config/VibescoutFrontendStyleConfig";
import { ColorUtils, TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";

/*function getForeRgbColorForThemeAndContrast(theme: IFelaTheme, contrast: EGraphicContrast, hover: boolean = false) {
  if (theme.dark) {
    if (!hover) {
      switch (contrast) {
        case EGraphicContrast.HIGH:
          return theme.styles.RgbColors.darkFore.lig;
        case EGraphicContrast.MID:
          return theme.styles.RgbColors.darkFore.mid;
        case EGraphicContrast.LOW:
          return theme.styles.RgbColors.darkFore.dar;
      }
    }

    switch (contrast) {
      case EGraphicContrast.HIGH:
        return theme.styles.RgbColors.white;
      case EGraphicContrast.MID:
        return theme.styles.RgbColors.darkFore.lig;
      case EGraphicContrast.LOW:
        return theme.styles.RgbColors.darkFore.mid;
    }
  } else {
    if (!hover) {
      switch (contrast) {
        case EGraphicContrast.HIGH:
          return theme.styles.RgbColors.lightFore.dar;
        case EGraphicContrast.MID:
          return theme.styles.RgbColors.lightFore.mid;
        case EGraphicContrast.LOW:
          return theme.styles.RgbColors.lightFore.lig;
      }
    }

    switch (contrast) {
      case EGraphicContrast.HIGH:
        return theme.styles.RgbColors.darkBack.shadow;
      case EGraphicContrast.MID:
        return theme.styles.RgbColors.lightFore.dar;
      case EGraphicContrast.LOW:
        return theme.styles.RgbColors.lightFore.mid;
    }
  }

  console.error(
    `FelaUtility: Get Foreground Color For Theme and Contrast failed to get any color for theme dark? [${theme.dark}] and contrast [${contrast}] and hover? [${hover}]`,
  );
}

function getBackRgbColorForThemeAndHeight(theme: IFelaTheme, height: EFelaLayerHeight, hover: boolean = false) {
  if (theme.dark) {
    if (!hover) {
      switch (height) {
        case EFelaLayerHeight.HIGH:
          return theme.styles.RgbColors.darkBack.lig;
        case EFelaLayerHeight.MID:
          return theme.styles.RgbColors.darkBack.mid;
        case EFelaLayerHeight.LOW:
          return theme.styles.RgbColors.darkBack.dar;
      }
    }

    switch (height) {
      case EFelaLayerHeight.HIGH:
        return theme.styles.RgbColors.darkBack.lit;
      case EFelaLayerHeight.MID:
        return theme.styles.RgbColors.darkBack.lig;
      case EFelaLayerHeight.LOW:
        return theme.styles.RgbColors.darkBack.mid;
    }
  } else {
    if (!hover) {
      switch (height) {
        case EFelaLayerHeight.HIGH:
          return theme.styles.RgbColors.lightBack.lig;
        case EFelaLayerHeight.MID:
          return theme.styles.RgbColors.lightBack.mid;
        case EFelaLayerHeight.LOW:
          return theme.styles.RgbColors.lightBack.dar;
      }
    }

    switch (height) {
      case EFelaLayerHeight.HIGH:
        return theme.styles.RgbColors.lightBack.lit;
      case EFelaLayerHeight.MID:
        return theme.styles.RgbColors.lightBack.lig;
      case EFelaLayerHeight.LOW:
        return theme.styles.RgbColors.lightBack.mid;
    }
  }
}

function getBackColorForThemeAndHeight(
  theme: IFelaTheme,
  height: EFelaLayerHeight,
  hover: boolean = false,
): TColorRgbArray {
  if (theme.dark) {
    if (!hover) {
      switch (height) {
        case EFelaLayerHeight.HIGH:
          return theme.styles.Colors.darkBack.lig;
        case EFelaLayerHeight.MID:
          return theme.styles.Colors.darkBack.mid;
        case EFelaLayerHeight.LOW:
          return theme.styles.Colors.darkBack.dar;
      }
    }

    switch (height) {
      case EFelaLayerHeight.HIGH:
        return theme.styles.Colors.darkBack.lit;
      case EFelaLayerHeight.MID:
        return theme.styles.Colors.darkBack.lig;
      case EFelaLayerHeight.LOW:
        return theme.styles.Colors.darkBack.mid;
    }
  } else {
    if (!hover) {
      switch (height) {
        case EFelaLayerHeight.HIGH:
          return theme.styles.Colors.lightBack.lig;
        case EFelaLayerHeight.MID:
          return theme.styles.Colors.lightBack.mid;
        case EFelaLayerHeight.LOW:
          return theme.styles.Colors.lightBack.dar;
      }
    }

    switch (height) {
      case EFelaLayerHeight.HIGH:
        return theme.styles.Colors.lightBack.lit;
      case EFelaLayerHeight.MID:
        return theme.styles.Colors.lightBack.lig;
      case EFelaLayerHeight.LOW:
        return theme.styles.Colors.lightBack.mid;
    }
  }
}*/

function getColorStringForStop(stop: IGradientStop, theme: IFelaTheme, currentId: string): string {
  if (stop.colorString) {
    return `${stop.colorString} ${stop.def ?? ""}`;
  }

  let color: TColorRgbArray | undefined;

  if (stop.height) {
    color = theme.styles.Themes[currentId].Colors.surface[stop.height];
    /*return `rgba(${theme.styles.Themes[currentId].Colors.surface[stop.height][0]}, ${stop.opacity ?? 1}) ${
      stop.def ?? ""
    }`;*/
  }

  if (stop.palette) {
    color = theme.styles.Themes[currentId].Colors.palette[stop.palette];
    // return `rgba(${theme.styles.Themes[currentId].Colors.named[stop.named][0]}, ${stop.opacity ?? 1}) ${
    //   stop.def ?? ""
    // }`;
  }

  if (stop.neutral) {
    color = theme.styles.Themes[currentId].Colors.neutral[stop.neutral];
    // return `rgba(${theme.styles.Themes[currentId].Colors.neutral[stop.neutral][0]}, ${stop.opacity ?? 1}) ${
    //   stop.def ?? ""
    // }`;
  }

  if (stop.color) {
    color = stop.color;
    // return `rgba(${stop.color[0]}, ${stop.opacity ?? 1}) ${stop.def ?? ""}`;
  }

  if (color) {
    if (stop.darken) {
      color = ColorUtils.darkenRgbArray(color, stop.darken);
    }

    if (stop.lighten) {
      color = ColorUtils.lightenRgbArray(color, stop.lighten);
    }

    return `rgba(${color[0]}, ${stop.opacity ?? 1}) ${stop.def ?? ""} ${stop.def ?? ""}`;
  }

  return `transparent ${stop.def ?? ""}`;
}

/*{
              if (stop.colorString) {
                return stop.colorString;
              }

              if (stop.height) {
                return `rgba(${theme.styles.Themes[currentId].Colors.surface[stop.height][0]}, ${stop.opacity ?? 1}) ${
                  stop.def ?? ""
                }`;
              }

              console.log(stop);

              if (stop.named) {
                console.log(theme.styles.Themes[currentId].Colors.named[stop.named]);
                return `rgba(${theme.styles.Themes[currentId].Colors.named[stop.named][0]}, ${stop.opacity ?? 1}) ${
                  stop.def ?? ""
                }`;
              }

              if (stop.neutral) {
                return `rgba(${theme.styles.Themes[currentId].Colors.neutral[stop.neutral][0]}, ${stop.opacity ?? 1}) ${
                  stop.def ?? ""
                }`;
              }

              if (stop.color) {
                return `rgba(${stop.color[0]}, ${stop.opacity ?? 1}) ${stop.def ?? ""}`;
              }

              return `transparent ${stop.def ?? ""}`;
            }*/

function createGradients(
  theme: IFelaTheme,
  currentId: EFrontendStyleConfigBaseTheme | string,
  gradients: IGradient[],
): string {
  return gradients
    .map((gradient) =>
      gradient.type === EGradientType.none
        ? getColorStringForStop(gradient.stops[0] ?? {}, theme, currentId)
        : gradient.type === EGradientType.flat
        ? `linear-gradient(${getColorStringForStop(gradient.stops[0] ?? {}, theme, currentId)}, ${getColorStringForStop(
            gradient.stops[0] ?? {},
            theme,
            currentId,
          )})`
        : `${gradient.type ?? "linear"}-gradient(${
            gradient.def ? `${gradient.def}, ` : ""
          }${gradient.stops.map((stop) => getColorStringForStop(stop, theme, currentId)).join(", ")})`,
    )
    .join(", ");
}

export const FelaUtility = {
  createGradients,
  // getForeRgbColorForThemeAndContrast,
  // getBackRgbColorForThemeAndHeight,
  // getBackColorForThemeAndHeight,
};
