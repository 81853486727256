import { PSC_VibescoutSpecial } from "../PSC_VibescoutSpecial";
import { WildcardUtilsClient } from "@gt/gt-frontend/build/utils/WildcardUtilsClient";
import { wildcard } from "../../wildcard/VibescoutSpecial_WildcardClient";

// const _getGeoLevelBasics = PSC_VibescoutSpecial.createAsyncAction(
//   WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getGeoLevelBasics),
// );

const _getGeoLevelForUserLocation = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getGeoLevelForUserLocation),
);

const _oldLocalityIdToGeoLevel = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.oldLocalityIdToGeoLevel),
);

const _searchGeoLevelBasics = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.searchGeoLevelsWithText),
);

export const GeoLevelAsyncActions = {
  // getGeoLevelBasics: _getGeoLevelBasics,
  oldLocalityIdToGeoLevel: _oldLocalityIdToGeoLevel,
  searchGeoLevelBasics: _searchGeoLevelBasics,
  getGeoLevelForUserLocation: _getGeoLevelForUserLocation,
};
