import React, { FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useFela } from "react-fela";
import { IFelaComponentBaseProps } from "../fela/FelaCommon";
import { EModalFeedbackState, SimpleModalComponent, TModalOnCloseFunction } from "./SimpleModalComponent";
import { FelaFlexbox } from "../fela/FelaFlexbox";
import { ForegroundFadeInBox } from "./ForegroundFadeInBox";
import { ZIndexes } from "../../../styling/VibescoutZIndexes";

export interface ISectionCoverFeedback {
  // show: boolean;
  getStateAndMessage?: () => [EModalFeedbackState, string] | [EModalFeedbackState, string, string | undefined] | void;
  onClose?: TModalOnCloseFunction;
  highZIndex?: number;
  lowZIndex?: number;
  fadeTimeout?: number;
  loadingDelay?: number;
  position?: "fixed" | "absolute";
}

export const SectionCoverModalNoChildren: FunctionComponent<ISectionCoverFeedback & IFelaComponentBaseProps> = ({
  // style,
  // cssExtra = [],
  // className = "",
  getStateAndMessage = () => [EModalFeedbackState.LOADING, `Loading`],
  onClose,
  highZIndex = ZIndexes.modalAboveAll,
  lowZIndex = -1,
  fadeTimeout = 500,
  loadingDelay = 0,
  position,
}) => {
  // console.log(`Rendering section model no children`);
  const [fullyClosed, setFullyClosed] = useState(true);
  const [internalShow, setInternalShow] = useState(false);

  const { css, theme } = useFela({ show: internalShow });

  const stateAndMessage = getStateAndMessage();

  const [currentState, currentMessage, ...rest] = stateAndMessage ? stateAndMessage : [EModalFeedbackState.NONE, ""];
  let forceTitle: undefined | string = undefined;

  if (rest.length > 0) {
    forceTitle = rest[0];
  }

  const show = currentState !== EModalFeedbackState.NONE;

  const delayedState = useRef<{
    forceTitle?: string;
    message: string;
    feedbackState: EModalFeedbackState | null;
    shouldUpdate: boolean;
    currentShowProp: boolean;
    onCloseFunction?: TModalOnCloseFunction;
  }>({
    forceTitle: undefined,
    message: "",
    feedbackState: null,
    shouldUpdate: true,
    currentShowProp: show,
    onCloseFunction: onClose,
  });

  if (currentState !== EModalFeedbackState.NONE && delayedState.current.feedbackState !== currentState) {
    delayedState.current.message = "";
    delayedState.current.feedbackState = null;
  }

  delayedState.current.currentShowProp = show;
  delayedState.current.onCloseFunction = onClose;

  const timeoutHolder = useRef<any>(null);
  const loadingDelayTimeout = useRef<any>(null);

  let modal: ReactElement;

  const runCloseSequence = useCallback(
    (
      feedbackState: EModalFeedbackState,
      message: string,
      onCloseModal?: TModalOnCloseFunction,
      positive: boolean = true,
      forceTitle?: string,
    ) => {
      delayedState.current.forceTitle = forceTitle;
      delayedState.current.message = message;
      delayedState.current.feedbackState = feedbackState;

      if (delayedState.current.onCloseFunction) {
        delayedState.current.onCloseFunction(feedbackState, positive);
      }

      if (timeoutHolder.current != null) {
        clearTimeout(timeoutHolder.current);
      }

      setTimeout(() => {
        delayedState.current.message = "";
        delayedState.current.feedbackState = null;
      }, fadeTimeout);

      timeoutHolder.current = setTimeout(() => {
        if (delayedState.current.shouldUpdate) {
          setFullyClosed(true);
        }
      }, fadeTimeout);
      setInternalShow(false);
    },
    [fadeTimeout],
  );

  switch (delayedState.current.feedbackState !== null ? delayedState.current.feedbackState : currentState) {
    case EModalFeedbackState.LOADING:
      modal = (
        <SimpleModalComponent
          customHeader={delayedState.current.forceTitle || forceTitle}
          message={delayedState.current.message.length > 0 ? delayedState.current.message : currentMessage}
          feedbackState={EModalFeedbackState.LOADING}
          isFloating
        />
      );
      break;
    case EModalFeedbackState.ERROR:
      modal = (
        <SimpleModalComponent
          customHeader={delayedState.current.forceTitle || forceTitle}
          message={delayedState.current.message.length > 0 ? delayedState.current.message : currentMessage}
          feedbackState={EModalFeedbackState.ERROR}
          isFloating
          onClose={(fromState, positiveConfirmation) =>
            runCloseSequence(fromState, currentMessage, onClose, positiveConfirmation, forceTitle)
          }
        />
      );
      break;
    case EModalFeedbackState.CONFIRMATION:
      modal = (
        <SimpleModalComponent
          customHeader={delayedState.current.forceTitle || forceTitle}
          message={delayedState.current.message.length > 0 ? delayedState.current.message : currentMessage}
          feedbackState={EModalFeedbackState.CONFIRMATION}
          isFloating
          onClose={(fromState, positiveConfirmation) =>
            runCloseSequence(fromState, currentMessage, onClose, positiveConfirmation, forceTitle)
          }
        />
      );
      break;
    case EModalFeedbackState.ALERT:
      modal = (
        <SimpleModalComponent
          customHeader={delayedState.current.forceTitle || forceTitle}
          message={delayedState.current.message.length > 0 ? delayedState.current.message : currentMessage}
          onClose={(fromState, positiveConfirmation) =>
            runCloseSequence(fromState, currentMessage, onClose, positiveConfirmation, forceTitle)
          }
          feedbackState={EModalFeedbackState.ALERT}
          isFloating
        />
      );
      break;
    default:
      modal = <div />;
  }

  useEffect(() => {
    delayedState.current.shouldUpdate = true;
    clearTimeout(loadingDelayTimeout.current);
    clearTimeout(timeoutHolder.current);

    if (show) {
      if (currentState === EModalFeedbackState.LOADING && loadingDelay > 0) {
        loadingDelayTimeout.current = setTimeout(() => {
          if (delayedState.current.shouldUpdate && delayedState.current.currentShowProp) {
            setInternalShow(true);
            setFullyClosed(false);
          }
        }, loadingDelay);
      } else {
        setInternalShow(true);
        setFullyClosed(false);
      }
    } else {
      setInternalShow(false);
      timeoutHolder.current = setTimeout(() => {
        if (delayedState.current.shouldUpdate) {
          setFullyClosed(true);
        }
      }, fadeTimeout);
    }

    return () => {
      delayedState.current.shouldUpdate = false;
    };
  }, [show, currentState]);

  const zIndex = fullyClosed ? lowZIndex : highZIndex;

  return (
    <ForegroundFadeInBox position={position} zIndex={zIndex} fadeTimeout={fadeTimeout} show={internalShow}>
      {modal}
    </ForegroundFadeInBox>
    /*<FelaFlexbox
      align={"center"}
      justify={"center"}
      style={{
        zIndex,
        position: "absolute",
        transition: `background ${fadeTimeout}ms ease-in-out, backdrop-filter 0.3s ease-in-out, opacity 0.3s ease-in-out`,
        background: `rgba(${
          theme.dark ? theme.styles.Colors.darkBack.shadow[0] : theme.styles.Colors.lightBack.shadow[0]
        }, ${internalShow ? `0.55` : `0`})`,
        width: "100%",
        height: "100%",
        backdropFilter: "blur(2px)",
      }}>
      <FelaFlexbox
        align={"center"}
        justify={"center"}
        style={{
          transition: `opacity ${fadeTimeout}ms ease-in-out, transform ${fadeTimeout + 100}ms ease-in-out`,
          opacity: internalShow ? 1 : 0,
          transform: `translateY(${internalShow ? 0 : 35}%)`,
          position: "sticky",
          top: "40%",
          left: 0,
        }}>
        {modal}
      </FelaFlexbox>
      {/!*<FelaFlexbox
            // direction={"column"}
            // className={className}
            // cssExtra={cssExtra}
            style={{
              transition: "filter 0.3s ease-in-out",
              width: "100%",
              height: "100%",
              ...(internalShow && {
                backdropFilter: "blur(2px)",
              }),
              ...style,
            }}>

          </FelaFlexbox>*!/}
    </FelaFlexbox>*/
  );
};
