import {
  ENodeType,
  IGeoLevelLocatedNode,
  INodeImg,
  INodeWithDates,
  INodeWithDescription,
  INodeWithId,
  INodeWithImages_Old,
  INodeWithLatLon,
  INodeWithName,
  INodeWithNodeType,
  INodeWithOneLine,
  INodeWithSubType,
} from "../nodes/BaseNodeModel";
import { IADBDocumentExtras } from "@gt/services/build/arangodb/ArangodbTypes";
import { EPriceCurrency } from "../nodes/hostItemNode/TempHostItemModel";
import { ETempNodeSourceIds } from "./TempNodeSourceItem";

export enum ETempNodePlaceType {
  CINEMA = "cinema",
}

export enum ETempNodeHostType {
  CINEMA_BRAND = "cinema-brand",
  STREAMING_SERVICE = "streaming",
}

export enum ETempNodeInfoType {
  MOVIE = "in_mov",
}

export enum ETempListedNodeType {
  MOVIE_SCREENING = "li_mov_scr",
  MOVIE_STREAMING = "li_mov_str",
  EVENT = "li_eve",
  ACTIVITY = "li_act",
}

export interface ILogoImage {
  url: string;
  w: number;
  h: number;
}

export interface ILogoImages {
  m: ILogoImage;
  s: ILogoImage;
}

export enum ETimeRelevance {
  BEFORE_ONLY = "bef",
  CUSTOM = "cus",
  ALL = "all",
  UNKNOWN = "unk",
}

interface ITempListedTimeData {
  timeStart: Date;
  timeEnd: Date;
  timeCutoff: Date;
  timeRunMins: number;
  timeRelevance: ETimeRelevance;
  tz: string;
}

interface IDMTempNodeBase
  extends IADBDocumentExtras,
    INodeWithDates,
    INodeWithNodeType,
    INodeWithName,
    INodeWithId,
    INodeWithOneLine,
    INodeWithDescription,
    Omit<INodeWithImages_Old, "tagMedId" | "tagMed"> {
  // sourceLinks: TTempSourceLinkType[];
  urlId?: string;
}

export enum ETempListedConsumeType {
  DIGITAL = "di",
  DELIVERY = "de",
  AT_PLACE = "pl",
}

/*interface ITempListedGeo {
  granularity: ENodeSubTypeGeoLevel | "_";
  /!* The absolute lowest geo levels, at the same granularity level *!/
  glGran: string[];
  glDi: string[];
  glAr: string[];
  glRe: string[];
}*/

interface ITempListedPrice {
  fr: number;
  to: number;
  cur: EPriceCurrency;
}

export type TTempListedDistIdType = ETempNodeInfoType | ETempNodeHostType | ETempNodePlaceType;

export interface IDMTempNodeListed
  extends IADBDocumentExtras,
    IGeoLevelLocatedNode,
    INodeWithDates,
    INodeWithId,
    INodeWithImages_Old,
    INodeWithSubType<ETempListedNodeType>,
    INodeWithLatLon,
    ITempListedTimeData {
  /* Distributed ID */
  distId: string;
  distType: TTempListedDistIdType;
  hostId: string;
  atId: string;
  subPlace: {
    id: string;
    name: string;
  };
  consumeType: ETempListedConsumeType;
  // sourceLinks: TTempSourceLinkType[];
  pri: ITempListedPrice;
}

export enum ETempNodeVibescoutSource {
  vibescoutUser = "vibescout",
}

export interface ITempNodeRatingLink {
  sourceId: string | ETempNodeVibescoutSource;
  sourceScore: number;
  votes: number;
  /* out of 10 */
  score: number;
}

export interface ITempNodePopularityLink {
  sourceId: ETempNodeSourceIds | ETempNodeVibescoutSource;
  sourceScore: number;
}

export interface IDMTempNodeInfo<T extends ETempNodeInfoType, A extends any>
  extends IDMTempNodeBase,
    INodeWithSubType<T> {
  nType: ENodeType.infoNode;
  popularity: number;
  sourcePopularity: ITempNodePopularityLink[];
  sourceRatings: ITempNodeRatingLink[];
  aggRating?: {
    votes: number;
    score: number;
  };
  nodeAttr: A;
}

export interface IDMTempNodePlace<T extends ETempNodePlaceType, A extends any>
  extends IDMTempNodeBase,
    IGeoLevelLocatedNode,
    INodeWithLatLon,
    INodeWithSubType<T> {
  nType: ENodeType.place;
  tz: string;
  /* Chain Host Node ID */
  chainId?: string;
  aggRating?: {
    votes: number;
    score: number;
  };
  nodeAttr: A;
}

export interface IDMTempNodeHost<T extends ETempNodeHostType, A extends any>
  extends IDMTempNodeBase,
    INodeWithSubType<T> {
  nType: ENodeType.host;
  logo?: ILogoImages;
  placeId?: string;
  nodeAttr: A;
}
