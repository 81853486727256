import {
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  EPaletteColor,
  ESurfaceHeight,
  ETextSize,
  ETextTypographyType,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { HeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import { FelaFlexLayer, TFelaFlexLayerProps } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import React from "react";
import { useFela } from "react-fela";
import { EGradientType, IFelaTheme, IGradient } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import { FelaFlexbox, IFelaFlexboxProps } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";

interface ICPPageSectionHeader {
  text: string;
  icon?: any;
  isSmall: boolean;
  fillIcon?: boolean;
  topHeight?: ESurfaceHeight;
  bottomHeight?: ESurfaceHeight;
  innerHeight?: ESurfaceHeight;
  contentHeight?: EForeContrast;
  seed?: number;
  // seed: number;
}

const seededColors: EPaletteColor[] = [
  EPaletteColor.bubblegum_blue,
  EPaletteColor.banana_yellow,
  // EColorsRainbow.violet,
  // EColorsRainbow.indigo,
  EPaletteColor.fire_orange,
  // EColorsRainbow.green,
  EPaletteColor.tomato_red,
];

const colorOpacity = 0.7;

export const PageSectionHeader: React.FC<ICPPageSectionHeader & IFelaFlexboxProps> = ({
  icon,
  text,
  fillIcon,
  children,
  isSmall,
  ...props
}) => {
  return (
    <FelaFlexbox direction={"column"} {...props}>
      <FelaFlexbox align={"center"}>
        {icon && (
          <FelaIconWrap
            forceTheme={EFrontendStyleConfigBaseTheme.DARK}
            fillAlso={fillIcon}
            contrast={EForeContrast.strong}
            size={ETextSize.smallHeader}
            margin={"0 0.5em 0 0"}
            noBackground
          >
            {icon}
          </FelaIconWrap>
        )}
        <FelaText
          lineHeight={1}
          size={isSmall ? ETextSize.smallHeader : ETextSize.bigHeader}
          transform={"uppercase"}
          weight={ETextWeightType.regular}
          forceTheme={EFrontendStyleConfigBaseTheme.DARK}
          contrast={EForeContrast.strong}
          margin={"0 0.45em 0 0"}
        >
          {text}
        </FelaText>
      </FelaFlexbox>
    </FelaFlexbox>
  );
};

export const PageSectionHeaderOld = React.memo<ICPPageSectionHeader & TFelaFlexLayerProps>(
  ({
    text,
    icon,
    bottomHeight,
    topHeight,
    innerHeight,
    contentHeight = EForeContrast.extra,
    fillIcon = false,
    seed = 0,
    children,
    ...flexLayerProps
  }) => {
    const { theme } = useFela<IFelaTheme>();
    const { UIStore } = PSC_VibescoutSpecial.useStores();
    const gradientSlide = UIStore.useState((s) => s.keyFrames.gradientSlide);

    const mainGradient: IGradient = {
      type: EGradientType.repeatingLinear,
      def: "145deg",
      stops: [
        {
          palette: seededColors[seed % seededColors.length],
          opacity: colorOpacity,
          def: "0",
          lighten: theme.dark ? undefined : 20,
        },
        {
          palette: seededColors[(seed + 1) % seededColors.length],
          opacity: colorOpacity,
          def: "33%",
          lighten: theme.dark ? undefined : 20,
        },
        {
          palette: seededColors[(seed + 2) % seededColors.length],
          opacity: colorOpacity,
          def: "66%",
          lighten: theme.dark ? undefined : 20,
        },
        {
          palette: seededColors[(seed + 3) % seededColors.length],
          opacity: colorOpacity,
          def: "100%",
          lighten: theme.dark ? undefined : 20,
        },
        {
          palette: seededColors[seed % seededColors.length],
          opacity: colorOpacity,
          def: "133%",
          lighten: theme.dark ? undefined : 20,
        },
      ],
    };

    const gradients: IGradient[] = [
      {
        type: EGradientType.flat,
        stops: [{ color: ["255, 255, 255", 255, 255, 255], opacity: theme.dark ? 0.15 : 0.25 }],
      },
      mainGradient,
      {
        def: "to right",
        stops: [{ height: ESurfaceHeight.deep }, { height: ESurfaceHeight.low, opacity: 0.3 }],
      },
    ];

    const outerGradients: IGradient[] = [
      {
        type: EGradientType.flat,
        stops: [{ color: ["0, 5, 12", 0, 5, 12], opacity: 0.1 }],
      },
      mainGradient,
    ];

    return (
      <FelaFlexLayer
        forceTheme={EFrontendStyleConfigBaseTheme.LIGHT}
        // height={ESurfaceHeight.deepest}
        gradient={outerGradients}
        style={{ animation: `${gradientSlide} 20s ease-in-out infinite`, backgroundSize: "400% 400%" }}
        // height={ESurfaceHeight.deepest}
        padding={"0 0 0.25em 0"}
        {...flexLayerProps}
      >
        <FelaFlexLayer
          // forceTheme={EFrontendStyleConfigBaseTheme.DARK}
          // shadow
          gradient={gradients}
          style={{ animation: `${gradientSlide} 20s ease-in-out infinite`, backgroundSize: "400% 400%" }}
          grow={1}
          // shadow
          height={innerHeight ?? ESurfaceHeight.deepest}
          padding={"0.85em 1em"}
          radius={0.1}
          align={"center"}
        >
          {icon && (
            <FelaIconWrap
              // forceTheme={EFrontendStyleConfigBaseTheme.DARK}
              fillAlso={fillIcon}
              contrast={EForeContrast.strong}
              size={ETextSize.smallHeader}
              margin={"0 0.5em 0 0"}
              noBackground
            >
              {icon}
            </FelaIconWrap>
          )}
          <HeaderText
            // forceTheme={EFrontendStyleConfigBaseTheme.DARK}
            contrast={EForeContrast.strong}
            typography={ETextTypographyType.sansSerif}
            size={ETextSize.content}
            weight={ETextWeightType.bold}
          >
            {text}
          </HeaderText>
          {children}
        </FelaFlexLayer>
      </FelaFlexLayer>
    );
  },
);
