import { contrastToFore, EGraphicContrast, IFelaComponentBaseProps } from "./FelaCommon";
import { default as React, FunctionComponent } from "react";
import { EFelaLayerHeight, FelaRuleLayer, IFelaLayerProps, ruleKeysFlexLayer } from "./FelaLayers";
import { ColorUtils, TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { TFelaRule } from "./types";
import { FelaText } from "./FelaText";
import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ETextSize,
} from "../config/VibescoutFrontendStyleConfig";
import { FelaTippy } from "./FelaTippy";
import { combineRulesWithName, useFelaCache } from "../../fela/FelaCache";

export interface IFelaRuleIconWrapProps {
  isButton?: boolean;
  contrast?: EGraphicContrast | EForeContrast;
  size?: ETextSize;
  sizeNum?: number;
  noBackground?: boolean;
  noPadding?: boolean;
  neutralColor?: EAppColor;
  color?: TColorRgbArray;
  colorString?: string;
  disabled?: boolean;
  fillAlso?: boolean;
  iconFilter?: string;
  fullWidth?: boolean;
  forceTheme?: EFrontendStyleConfigBaseTheme | string;
}

const FelaRuleIconWrap: TFelaRule<IFelaRuleIconWrapProps> = ({
  theme,
  sizeNum,
  size,
  isButton = false,
  contrast = EGraphicContrast.MID,
  noBackground = false,
  noPadding = false,
  neutralColor,
  color,
  colorString,
  iconFilter,
  disabled = false,
  fillAlso = false,
  fullWidth = false,
  forceTheme,
}) => {
  let sizeNumVal: string | number = theme.styles.Font.sizes[ETextSize.content] * 1.25;
  const foreContrast = contrastToFore[contrast];

  if (fullWidth) {
    sizeNumVal = "100%";
  } else if (size) {
    sizeNum = theme.styles.Font.sizes[size] * 1.25;
    sizeNumVal = theme.styles.Font.sizes[size] * 1.25;
  } else if (sizeNum) {
    sizeNumVal = sizeNum;
  }

  if (!colorString) {
    if (neutralColor) {
      colorString = theme.styles.RgbColors.neutral[neutralColor];
    }
  }

  //FelaUtility.getForeRgbColorForThemeAndContrast(theme, contrast)

  const colorMade = colorString
    ? colorString
    : color !== undefined
    ? `rgb(${color[0]})`
    : theme.styles.Themes[forceTheme ?? theme.currentId].RgbColors.fore[foreContrast];

  const colorMadeHover = `rgb(${
    ColorUtils.lightenRgbArray(
      color !== undefined ? color : theme.styles.Themes[forceTheme ?? theme.currentId].Colors.fore[foreContrast],
      20,
    )[0]
  })`;
  // : FelaUtility.getForeRgbColorForThemeAndContrast(theme, contrast, true);

  return {
    ...(!noPadding && {
      padding: (sizeNum ?? 2) / 4,
    }),
    zIndex: 1,
    boxSizing: "content-box",
    width: sizeNumVal,
    height: sizeNumVal,
    borderRadius: "100%",
    display: "inline-block",
    cursor: isButton ? "pointer" : "auto",
    "& svg": {
      width: sizeNumVal,
      height: sizeNumVal,
      color: colorMade,
      fill: fillAlso ? colorMade : undefined,
      transition: "color 0.15s ease-in-out",
      ...(iconFilter != null && {
        filter: iconFilter,
      }),
    },
    ...(isButton
      ? {
          ":hover": {
            "& svg": {
              color: colorMadeHover,
              fill: fillAlso ? colorMadeHover : undefined,
            },
          },
        }
      : {
          pointerEvents: "none",
        }),
    ...(noBackground && {
      background: "none",
    }),
  };
};

export const ButtonRules = {
  iconButton: FelaRuleIconWrap,
};

export const ruleKeysFelaIconWrap = [
  "theme",
  "sizeNum",
  "size",
  "isButton",
  "contrast",
  "noBackground",
  "noPadding",
  "neutralColor",
  "color",
  "colorString",
  "iconFilter",
  "disabled",
  "fillAlso",
  "fullWidth",
  "forceTheme",
];

export type TCPFelaIconWrap = IFelaRuleIconWrapProps & IFelaLayerProps & IFelaComponentBaseProps;

const FelaIconWrapSuperRule: any = combineRulesWithName([FelaRuleLayer, FelaRuleIconWrap], "FelaIconWrapSuperRule");
const ruleKeysFelaIconWrapSuper = [...new Set([...ruleKeysFelaIconWrap, ...ruleKeysFlexLayer])];

export const FelaIconWrap: FunctionComponent<TCPFelaIconWrap> = ({
  children,
  className = "",
  style,
  cssExtra = [],
  onClick,
  onMouseEnter,
  onMouseLeave,
  innerHtml,
  ref,
  cssKey,
  // backgroundImage,
  ...props
}) => {
  if (props.clickable || onClick != null) {
    props.isButton = true;
  }

  const { cachedClass, inlineCss } = useFelaCache();

  const divProps: any = {
    onMouseEnter,
    onMouseLeave,
    onClick,
  };

  if (innerHtml) {
    divProps.dangerouslySetInnerHTML = { __html: innerHtml };
  }

  return (
    <div
      // dangerouslySetInnerHTML={innerHtml ? { __html: innerHtml } : undefined}
      ref={ref}
      style={style}
      // className={`${className} ${css(FelaRuleLayer, FelaRuleIconWrap, ...cssExtra)}`}
      className={`${className} ${cachedClass(
        FelaIconWrapSuperRule,
        props,
        cssKey ?? ruleKeysFelaIconWrapSuper,
      )} ${inlineCss(cssExtra)}`}
      {...divProps}
    >
      {/*{backgroundImage && <img src={backgroundImage.url} alt={backgroundImage.alt}/>}*/}
      {children}
    </div>
  );
};

interface ICPFelaTippyIconWrap {
  tippyTextContent?: any;
  tippyFullContent?: any;
  onClickIcon?: () => void;
  iconProps?: TCPFelaIconWrap;
}

export const FelaTippyIconWrap: React.FC<ICPFelaTippyIconWrap> = ({
  tippyFullContent,
  tippyTextContent,
  onClickIcon,
  iconProps = {},
  children,
}) => {
  return (
    <FelaTippy
      content={
        tippyFullContent ? tippyFullContent : <FelaText contrast={EGraphicContrast.HIGH}>{tippyTextContent}</FelaText>
      }
    >
      <div>
        <FelaIconWrap
          onClick={onClickIcon}
          height={EFelaLayerHeight.HIGH}
          contrast={EGraphicContrast.HIGH}
          shadow
          margin={0.25}
          size={ETextSize.content}
          isButton
          clickable
          hoverable
          {...iconProps}
        >
          {children}
        </FelaIconWrap>
      </div>
    </FelaTippy>
  );
};
