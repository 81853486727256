import { ILockdownGeoInfo } from "../../../../../pageDataRequests/Vibescout/LocalityScout/LocalityScoutPageRequest";
import _ from "lodash";
import { EGSLockdownLevelValue, IGSLockdownLevelGeoSheet_Input } from "./LockdownLevelsGeoSheetModels";
import { TGeoLevelBasics } from "../../../geolevels/GeoLevelModelTypes";

export interface ILockdownGeoInfoWithDates extends ILockdownGeoInfo {
  lockdown: Pick<IGSLockdownLevelGeoSheet_Input, "id" | "date" | "lvl"> & { dateTime: number };
}

function sortAndFilterForCurrentGeo(
  inputLockdownLevels: ILockdownGeoInfo[],
  geo: TGeoLevelBasics,
): ILockdownGeoInfoWithDates[] {
  const filteredAndSorted = _.sortBy(
    inputLockdownLevels.filter((l) => geo.allIds.includes(l.geo.id)),
    (l) => {
      return new Date(l.lockdown.date).getTime() + l.geo.lvlValue;
    },
  );

  const finalLevels: ILockdownGeoInfoWithDates[] = [];

  function findLastUnignored(ind: number): ILockdownGeoInfo | null {
    const cur = filteredAndSorted[ind];

    let foundIndex = -1;
    let lvlValue = 0;

    for (let i = 0; i < ind; i += 1) {
      if (filteredAndSorted[i].geo.lvlValue < cur.geo.lvlValue && filteredAndSorted[i].geo.lvlValue > lvlValue) {
        foundIndex = i;
      }
    }

    if (foundIndex >= 0) {
      if (filteredAndSorted[foundIndex].lockdown.lvl === EGSLockdownLevelValue.IGNORE) {
        return findLastUnignored(foundIndex);
      }
      return filteredAndSorted[foundIndex];
    }

    return null;
  }

  let currentLvlValue = 0;
  // let currentLockdownLvl: EGSLockdownLevelValue = EGSLockdownLevelValue.IGNORE;

  let index = -1;
  for (const levelInfo of filteredAndSorted) {
    index += 1;

    if (currentLvlValue <= levelInfo.geo.lvlValue) {
      if (levelInfo.lockdown.lvl === EGSLockdownLevelValue.IGNORE) {
        const lvl = findLastUnignored(index);

        // console.log(lvl);

        if (lvl && currentLvlValue > lvl.geo.lvlValue) {
          finalLevels.push({
            lockdown: {
              ...lvl.lockdown,
              date: levelInfo.lockdown.date,
              dateTime: new Date(`${levelInfo.lockdown.date}T00:00:00`).getTime(),
            },
            geo: lvl.geo,
          });
          currentLvlValue = lvl.geo.lvlValue;
          // currentLockdownLvl = lvl.lockdown.lvl;
        }
      } else {
        finalLevels.push({
          lockdown: {
            ...levelInfo.lockdown,
            dateTime: new Date(`${levelInfo.lockdown.date}T00:00:00`).getTime(),
          },
          geo: levelInfo.geo,
        });
        currentLvlValue = levelInfo.geo.lvlValue;
        // currentLockdownLvl = levelInfo.lockdown.lvl;
      }
    }
  }

  let prevLevel: EGSLockdownLevelValue = EGSLockdownLevelValue.IGNORE;

  return finalLevels.filter((l) => {
    if (l.lockdown.lvl !== prevLevel) {
      prevLevel = l.lockdown.lvl;
      return true;
    }

    return false;
  });
}

export const LockdownLevelsGeoUtils = {
  sortAndFilterForCurrentGeo,
};
