import { PSC_VibescoutSpecial } from "../PSC_VibescoutSpecial";
import { WildcardUtilsClient } from "@gt/gt-frontend/build/utils/WildcardUtilsClient";
import { wildcard } from "../../wildcard/VibescoutSpecial_WildcardClient";
import { TVibescoutSpecialResolver } from "../ASN_VibescoutSpecial";
import { ICinemaPageParams } from "../../sections/movies/CinemaPage";
import { TaskFunctionError } from "@gt/common-utils/build/taskFunction/TaskFunctionUtils";
import { PullstateAsyncUtils } from "@gt/gt-frontend/build/utils/PullstateAsyncUtils";
import { MovieUtils } from "../../sections/movies/MovieUtils";
import { ENavigationMetaPageType } from "@gt/gt-frontend/build/utils/NavigationUtils/AsyncNavigator";
import { OldIImage } from "@vs/core/build/types/media/VibescoutImageTypes";
import { notNullEmpty } from "@gt/common-utils/build/datatypes/StringUtils";

const getMoviePage = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.pageData_MoviePage),
);
const getCinemaPage = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getCinemaPage),
);
const getShowingCinemas = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getShowingCinemas),
);
const getMovieList = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getMovieList),
);

export const _navCinemaPage: TVibescoutSpecialResolver = {
  paths: ["/za/cinemas/:brandId/:cinemaId", "/za/cinemas/:brandId/:cinemaId/:movieId"],
  meta: {
    showTitleBar: true,
    searchTerm: "movie",
  },
  resolve: async ({ state: { instance }, info, updateMeta }) => {
    const pageResponse = await instance.runAsyncAction(
      MovieSectionAsyncActions.getCinemaPage,
      info.params as ICinemaPageParams,
      { respectCache: true },
    );

    if (!pageResponse.error) {
      const { page } = pageResponse.payload;
      const { cinemaBrandName, name } = page;

      const year = new Date().getFullYear();

      const betterName = MovieUtils.betterName(name);

      const attributeSet = new Set<string>();
      page.movies?.nowShowing?.forEach((movieShowing) =>
        movieShowing.screenAttributes.forEach((att) => {
          attributeSet.add(att);
        }),
      );

      const excludeCinemaBrand =
        cinemaBrandName.toLowerCase().indexOf(betterName.toLowerCase()) !== -1 ||
        betterName.toLowerCase().indexOf(cinemaBrandName.toLowerCase()) !== -1;

      updateMeta((s) => {
        s.title = excludeCinemaBrand
          ? `${betterName} Movies & Showtimes`
          : `${cinemaBrandName} ${betterName} Movies & Showtimes`;
        s.metaTitle = excludeCinemaBrand
          ? `${betterName} Movies | Latest Show Times & Prices | ${year}`
          : `${betterName} Movies | ${cinemaBrandName} | Latest Show Times & Prices | ${year}`;

        s.metaDescription = `Showtimes for the Latest Movies this week at ${betterName} (${cinemaBrandName}). See Trailers, Reviews, Ratings, Release Dates & More${
          attributeSet.size > 0 ? ` - (${[...attributeSet].join(", ")})` : "."
        }`;
      });
    } else {
      updateMeta((s) => {
        s.searchTerm = "movie";
      });
      throw new TaskFunctionError(PullstateAsyncUtils.asyncResponseToTaskFunctionErrorResponse(pageResponse));
    }
  },
  parallel: false,
};

export const _navMoviePage: TVibescoutSpecialResolver = {
  paths: ["/za/movies/:movieId"],
  meta: {
    showTitleBar: true,
    searchTerm: "movie",
  },
  resolve: async ({ state: { instance }, info, ctx, updateMeta }) => {
    console.log(`Trying to get movie: ${info.params.movieId}`);
    const moviePageResponse = await instance.runAsyncAction(
      MovieSectionAsyncActions.getMoviePage,
      {
        movieId: info.params.movieId,
      },
      {
        respectCache: true,
      },
    );

    if (!moviePageResponse.error) {
      const { moviePage } = moviePageResponse.payload;
      updateMeta((meta) => {
        let image: OldIImage | undefined;

        if (moviePage.images.backdrop?.normal?.url != null) {
          image = moviePage.images.backdrop.normal;
        } else if (moviePage.images.poster?.normal?.url) {
          image = moviePage.images.poster.normal;
        }

        if (image != null) {
          meta.ogImage = {
            url: image.url!,
            height: `${image.meta?.basic?.width ?? 1200}`,
            width: `${image.meta?.basic?.height ?? 630}`,
          };
        }

        meta.title = `${moviePage.title} (${moviePage.releaseYear})`;
        meta.metaTitle = `${moviePage.title} (${moviePage.releaseYear}) | Release Date South Africa | Movie Showtimes`;
        meta.metaDescription = `Showtimes for ${moviePage.title}. Trailer, Reviews, Ratings &amp; More. Find out What is Showing at Ster Kinekor, Nu Metro and Independent Cinemas around South Africa.`;
      });
    } else {
      if (notNullEmpty(moviePageResponse.errorPayload.redirectNewId)) {
        ctx.redirect = { path: `/za/movies/${moviePageResponse.errorPayload.redirectNewId}` };
      } else {
        updateMeta((s) => {
          s.searchTerm = "movie";
        });
        throw new TaskFunctionError(PullstateAsyncUtils.asyncResponseToTaskFunctionErrorResponse(moviePageResponse));
      }
    }
  },
  parallel: false,
};

export const MovieSectionAsyncActions = {
  getMoviePage,
  getCinemaPage,
  getShowingCinemas,
  getMovieList,
};
