import React, { useEffect } from "react";
import { EFelaLayerHeight, FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { EPageSizeRange, EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { GraphGrid, GraphSection } from "./components/Covid19Components";
import { colorsCovidSeries } from "./Covid19Static";
import { CovidAsyncActions } from "../../state/stores/CovidStore/CovidAsyncActions";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { CovidReportFooter } from "./pageSection/CovidReportFooter";
import { GraphNewCases } from "./graphSection/GraphNewCases";
import { CovidReportHeader } from "./pageSection/CovidReportHeader";
import { QuickUseAsyncResultModal } from "@gt/gt-frontend/build/react/vibescout-ui/components/QuickAsyncResultModal";
import { GraphDeathDemographics } from "./graphSection/GraphDeathDemographics";
import { GraphTotalTests } from "./graphSection/GraphTotalTests";
import { GraphNewTests } from "./graphSection/GraphNewTests";
import { GraphStateCasesComparison } from "./graphSection/GraphStateCasesComparison";
import { EGraphicContrast } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { CovidGeoMap, TCovidMapFeature } from "./graphsAndCharts/CovidGeoMap";
import type { Polygon } from "@turf/turf";
import { NewsStorySection } from "./miscSections/NewsStorySection";
import { FeedbackFloatingButton, FeedbackSection } from "./miscSections/FeedbackSection";
import { GraphTotalDeaths } from "./graphSection/GraphTotalDeaths";
import { GraphTotalCasesAndActiveAndRecoveries } from "./graphSection/GraphTotalCasesAndActiveAndRecoveries";
import { GraphRateOfCasesToTests } from "./graphSection/GraphRateOfCasesToTests";
import { GraphSectionRt } from "./graphSection/GraphSectionRt";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { useInView } from "react-intersection-observer";
import { GraphNewDeaths } from "./graphSection/GraphNewDeaths";
import { CovidReportBottomBar } from "./pageSection/CovidReportBottomBar";
import { ECovidPageContextButton } from "../../state/stores/CovidStore/CovidStore";
import { ASN_VibescoutSpecial } from "../../state/ASN_VibescoutSpecial";
import { HeaderText, UnderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import {
  ESurfaceHeight,
  ETextSize,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { CovidQuickStats } from "./graphsAndCharts/CovidQuickStats";
import { Store, useLocalStore } from "pullstate";

interface ILocalPageStateCovid19 {
  provincesInView: boolean;
}

export const PageSpecial_Covid19: React.FC = () => {
  const { UIStore } = PSC_VibescoutSpecial.useStores();
  const [theme, pageSize] = UIStore.useState((s) => [s.theme, s.pageSize] as const);

  const isSmall = pageSize <= EPageSizeRange.mobile;

  const pageDataResult = CovidAsyncActions.getCovidPageData.use();

  const covidPageStore = useLocalStore<ILocalPageStateCovid19>({ provincesInView: false });

  return (
    <FelaFlexLayer
      style={{ minHeight: "100vh" }}
      direction={"column"}
      position={"relative"}
      cssExtra={[
        {
          mobileOnly: {
            fontSize: "14px",
          },
          smallMobileOnly: {
            fontSize: "12px",
          },
        },
      ]}
    >
      <QuickUseAsyncResultModal title={"Country Covid-19 Data"} state={pageDataResult} />
      {pageDataResult.renderPayload(
        ({
          latestDeaths,
          latestRecovered,
          latestCases,
          dailyCases: caseItems,
          dailyDeaths,
          dailyRecoveries,
          dailyTests: testItems,
          individualDeaths,
          latestTests,
          meta: covidMeta,
          provinceGeoLevels,
          statesDaily,
        }) => {
          // console.log(features);

          return (
            <>
              <CovidReportHeader
                pageTitle={"Covid-19 South Africa"}
                pageSubtitle={"Cases, Tests, Deaths & Recovery Stats"}
                covidMeta={covidMeta}
                rightFloater={
                  !isSmall ? <RightProvincesFloater isSmall={isSmall} pageStore={covidPageStore} /> : undefined
                }
              />
              {!isSmall && <FeedbackFloatingButton />}
              <FelaFlexLayer
                direction={"row"}
                justify={"center"}
                align={"stretch"}
                padding={isSmall ? "0.5em 0.25em" : 1}
                gradient={[
                  { def: "to bottom", stops: [{ height: ESurfaceHeight.mid }, { height: ESurfaceHeight.deepest }] },
                ]}
              >
                <CovidQuickStats
                  isSmall={isSmall}
                  latestCases={latestCases}
                  activeCases={latestCases! - (latestDeaths! + latestRecovered!)}
                  latestDeaths={latestDeaths}
                  latestRecovered={latestRecovered}
                />
              </FelaFlexLayer>
              <GraphGrid>
                <GraphSection
                  rowSpan={9}
                  title={"The latest daily stats"}
                  subtext={<>How has the virus progressed in South Africa?</>}
                >
                  <GraphTotalCasesAndActiveAndRecoveries caseItems={caseItems} />
                  <GraphNewCases caseItems={caseItems} />
                  {/*<GraphTotalDeaths caseItems={caseItems} latestDeaths={latestDeaths} />*/}
                  <GraphNewDeaths caseItems={caseItems} />
                </GraphSection>
                <GraphSectionRt isDark={theme.dark} isSmall={isSmall} geoLvl={"za"} areaName={"South Africa"} />
                {/*<GraphSection
                  rowSpan={3}
                  title={"Deaths"}
                  extraValue={`TOTAL: ${latestDeaths}`}
                  subtext={"Gender and age distribution"}
                >
                  <GraphDeathDemographics individualDeaths={individualDeaths} latestDeaths={latestDeaths} />
                </GraphSection>*/}
                <GraphSection
                  rowSpan={6}
                  title={"Tests"}
                  subtext={<>What is the current rate of testing for the virus?</>}
                >
                  {/*<GraphTotalTests testItems={testItems} />*/}
                  <GraphNewTests testItems={testItems} />
                  <GraphRateOfCasesToTests testItems={testItems} />
                </GraphSection>
                <ProvincesSection
                  pageStore={covidPageStore}
                  provinceGeoLevels={provinceGeoLevels}
                  statesDaily={statesDaily}
                />
                <GraphSection rowSpan={4} title={"More Info"}>
                  <NewsStorySection />
                </GraphSection>
                <GraphSection id={"feedback"} rowSpan={4} title={"Feedback"}>
                  <FeedbackSection />
                </GraphSection>
              </GraphGrid>
              <CovidReportBottomBar
                buttons={[ECovidPageContextButton.GO_TO_PROVINCE, ECovidPageContextButton.GIVE_FEEDBACK]}
                disableButtons={[]}
              />
              <CovidReportFooter />
            </>
          );
        },
      )}
    </FelaFlexLayer>
  );
};

const RightProvincesFloater = ({
  isSmall,
  pageStore,
}: {
  pageStore: Store<ILocalPageStateCovid19>;
  isSmall: boolean;
}) => {
  const provincesInView = pageStore.useState((s) => s.provincesInView);

  return (
    <FelaFlexLayer
      height={EFelaLayerHeight.HIGH}
      shadow
      extraSoftShadow
      padding={isSmall ? 0.3 : 0.5}
      radius={"0.5em 0 0 0.5em"}
      align={"center"}
      direction={"column"}
      alignSelf={"flex-start"}
      style={{
        pointerEvents: provincesInView ? "none" : "all",
        opacity: provincesInView ? 0 : 1,
        transition: "opacity 0.15s ease-in-out",
      }}
    >
      <a href={"#provinces"}>
        <FelaIconWrap
          size={ETextSize.bigHeader}
          // iconFilter={"drop-shadow(0 0.25em 0.25em rgba(0, 0, 0, .7))"}
          // style={{ position: "relative", overflow: "hidden" }}
          shadow
          extraSoftShadow
          isButton
          // colorString={"#FFFFFF"}
        >
          <FaMapMarkedAlt />
        </FelaIconWrap>
      </a>
      <FelaFlexbox direction={"column"} align={"center"}>
        <FelaText weight={500} margin={"0.5em 0 0 0"} size={ETextSize.tiny} transform={"uppercase"}>
          Go To
        </FelaText>
        <FelaText weight={500} size={ETextSize.tiny} transform={"uppercase"}>
          Province
        </FelaText>
      </FelaFlexbox>
    </FelaFlexLayer>
  );
};

const ProvincesSection = ({
  provinceGeoLevels,
  statesDaily,
  pageStore,
}: {
  pageStore: Store<ILocalPageStateCovid19>;
  provinceGeoLevels: any;
  statesDaily: any;
}) => {
  const { UIStore } = PSC_VibescoutSpecial.useStores();
  const udi = UIStore.useState((s) => s.udi);

  const [provincesRef, provincesInView] = useInView();

  useEffect(() => {
    pageStore.update((s) => {
      s.provincesInView = provincesInView;
    });
  }, [provincesInView]);

  const newProvinceGeoLevels = provinceGeoLevels.sort((a, b) => {
    const lastDailyA = statesDaily[a.geoLvlShort][statesDaily[a.geoLvlShort].length - 1];
    const lastDailyB = statesDaily[b.geoLvlShort][statesDaily[b.geoLvlShort].length - 1];
    // return (lastDailyB.casesTotal ?? 0) - (lastDailyA.casesTotal ?? 0);
    return (lastDailyA.casesTotal ?? 0) - (lastDailyB.casesTotal ?? 0);
  });

  const features: TCovidMapFeature[] = provinceGeoLevels.map(({ lowPolyGeoShape, ...rest }) => ({
    type: "Feature",
    properties: { ...rest, daily: statesDaily[rest.geoLvlShort] },
    geometry: lowPolyGeoShape! as Polygon,
    id: rest.geoLvlShort,
  }));

  return (
    <GraphSection
      rowSpan={9}
      colSpan={1}
      title={"Provincial"}
      subtext={<>How has the virus spread through different regions in South Africa?</>}
    >
      <CovidGeoMap features={features} />
      <FelaFlexbox position={"relative"} align={"center"} padding={0.5}>
        <FullAbsFlexbox>
          <div style={{ transform: "translateY(-5em)" }} id={"provinces"} />
        </FullAbsFlexbox>
        <UnderText>{udi === EUDI.MOUSE ? "Click" : "Tap"} on a province name for more detailed info</UnderText>
      </FelaFlexbox>
      <div ref={provincesRef}>
        <FelaFlexbox wrap={"wrap"}>
          {newProvinceGeoLevels
            .map((geo, index) => {
              const { st } = GeoLevelCompressUtils.getPartsForCompressedGeoLevel(geo.geoLvlShort);

              return (
                <ASN_VibescoutSpecial.Link key={st} to={`/reports/coronavirus/${st}`}>
                  <FelaFlexLayer
                    pop={true}
                    padding={0.5}
                    margin={0.25}
                    radius={0.25}
                    height={EFelaLayerHeight.MID}
                    hoverable
                    clickable
                  >
                    <HeaderText colorHex={colorsCovidSeries[index + 1]} contrast={EGraphicContrast.MID}>
                      {geo.name}
                    </HeaderText>
                  </FelaFlexLayer>
                </ASN_VibescoutSpecial.Link>
              );
            })
            .reverse()}
        </FelaFlexbox>
      </div>
      <FelaFlexbox align={"center"} padding={0.5}>
        <UnderText>
          <b>Total Cases</b> comparison between the provinces ({udi === EUDI.MOUSE ? "Hover on" : "Tap"} graph for
          direct daily stats)
        </UnderText>
      </FelaFlexbox>
      <GraphStateCasesComparison provinceGeoLevels={newProvinceGeoLevels} statesDaily={statesDaily} />
    </GraphSection>
  );
};
