import React from "react";
import { IDSMovieTruthListing } from "@vs/core/build/data/gcloud/datastore/models/ShowingMovies";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FillerImages } from "../../../graphics/FillerImages";
import { MovieTitleImages } from "./MovieTitleImages";
import { useFelaCache } from "@gt/gt-frontend/build/react/fela/FelaCache";
import { ETextTag, FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { AsyncNavLink } from "../../../state/ASN_VibescoutSpecial";
import { HeaderText, SubHeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import {
  EForeContrast,
  ESurfaceHeight,
  ETextSize,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { TriangleIcon } from "../../../graphics/icons/VibescoutIcons";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { FullAbsFlexbox, FullAbsFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { PSC_VibescoutSpecial } from "../../../state/PSC_VibescoutSpecial";
import { EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { EButtonSize, FelaButton } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaButton";
import { SvgVibescoutIcons } from "@vs/core/build/frontend/graphics/svg-icons/SvgVibescoutIcons";

export enum EMovieItemLinkType {
  NONE = "NONE",
  CINEMA_PAGE = "CINEMA_PAGE",
  MOVIE_PAGE = "MOVIE_PAGE",
}

interface ICPMovieListItem {
  movie: IDSMovieTruthListing;
  showReleaseDate: boolean;
  disableLink?: boolean;
  movieInfoButton?: boolean;
  linkToCinemaMovie?: {
    brandId: string;
    cinemaId: string;
  };
  linkType: EMovieItemLinkType;
  children: any;
}

export const MovieListItem = React.memo<ICPMovieListItem>(
  ({ movie, showReleaseDate, movieInfoButton = false, disableLink = false, linkType, linkToCinemaMovie, children }) => {
    const { theme } = useFelaCache();
    const { UIStore } = PSC_VibescoutSpecial.useStores();
    const udi = UIStore.useState((s) => s.udi);
    let posterSrc: string = movie?.images?.poster?.thumbnail?.url ?? FillerImages.fillerImageCover;
    let backdropSrc: string = movie?.images?.backdrop?.thumbnail?.url ?? posterSrc;

    const openingDate = `${new Date(movie.minStartDate)}`.substr(4, 11);

    const runtimeHours = Math.floor(movie.runtime / 60);
    const runtimeMins = movie.runtime % 60;

    return (
      <FelaFlexbox direction={"column"} rootProps={{ itemScope: true, itemType: "http://schema.org/Movie" }}>
        {movie.imdbID && movie.imdbID.length > 0 && (
          <meta itemProp="sameAs" content={`http://www.imdb.com/title/${movie.imdbID}`} />
        )}
        <meta itemProp="url" content={`https://www.vibescout.com/za/movies/${movie.id}`} />
        <span itemScope itemProp="releasedEvent" itemType="http://schema.org/PublicationEvent">
          <meta itemProp="startDate" content={new Date(movie.openingDate!).toUTCString()} />
          <span itemScope itemProp="location" itemType="http://schema.org/Country">
            <meta itemProp="name" content="ZA" />
          </span>
        </span>
        <MovieLink linkType={linkType} movieId={movie.id} linkToCinemaMovie={linkToCinemaMovie}>
          <FelaFlexbox direction={theme.isSmall ? "column" : "row"} position={"relative"}>
            <FelaFlexbox shrink={0} basis={theme.isSmall ? undefined : "45%"}>
              <MovieTitleImages
                movieBackdrop={backdropSrc}
                moviePoster={posterSrc}
                style={{
                  // maxHeight: "15em",
                  overflow: "hidden",
                }}
              />
            </FelaFlexbox>
            <FelaFlexLayer
              grow={1}
              height={ESurfaceHeight.high}
              padding={0.75}
              gridTemplateColumns={"1fr"}
              alignItems={"start"}
              direction={"column"}
            >
              <FelaFlexbox align={"center"} justify={"space-between"}>
                <FelaText
                  tag={ETextTag.h3}
                  weight={ETextWeightType.bold}
                  size={ETextSize.smallHeader}
                  transform={"uppercase"}
                  // margin={"0.25em 0.5em"}
                  contrast={EForeContrast.extra}
                >
                  {movie.title}
                </FelaText>
                {(movie.guidanceRating ?? "").length > 0 && (
                  <FelaFlexbox padding={0.25} align={"flex-end"}>
                    <FelaIconWrap opacity={0.5} size={ETextSize.small} noBackground>
                      <SvgVibescoutIcons.TriangleIcon />
                    </FelaIconWrap>
                    <FelaText opacity={0.6}>{movie.guidanceRating}</FelaText>
                  </FelaFlexbox>
                )}
                <FelaFlexbox padding={0.25} direction={"column"}>
                  {movie.runtime != null && movie.runtime > 1 && (
                    <SubHeaderText>
                      {runtimeHours}h {runtimeMins}m
                    </SubHeaderText>
                  )}
                </FelaFlexbox>
              </FelaFlexbox>
              <FelaFlexbox wrap={"wrap"} gap={0.25} padding={"0.25em 0"}>
                {movie.genres.map((genre) => {
                  return <SimpleMovieTag key={genre}>{transformGenres[genre.toUpperCase()] ?? genre}</SimpleMovieTag>;
                  /*return (
                    <FelaFlexLayer
                      height={ESurfaceHeight.low}
                      radius={1}
                      padding={"0.15em 0.35em"}
                      key={genre}
                      // size={EButtonSize.tiny}
                      // id={genre}
                      // active={false}
                      // name={transformGenres[genre.toUpperCase()] ?? genre}
                    >
                      <FelaText size={ETextSize.small}>{transformGenres[genre.toUpperCase()] ?? genre}</FelaText>
                    </FelaFlexLayer>
                  );*/
                })}
              </FelaFlexbox>
              {showReleaseDate && (
                <FelaFlexbox>
                  <FelaText size={ETextSize.small} className={`text text-400 text-mini op-80`}>
                    Showing From:{" "}
                  </FelaText>
                  <FelaText weight={ETextWeightType.black} className={`text-700 text-small text`}>
                    {openingDate}
                  </FelaText>
                </FelaFlexbox>
              )}
              <FelaFlexbox
                direction={"column"}
                // margin={"0.5em 0"}
                grow={1}
                position={"relative"}
                overflow={"hidden"}
                style={{ height: "6em" }}
              >
                <FullAbsFlexbox
                  cssExtra={[{ maskImage: "linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0))" }]}
                  // padding={0.5}
                >
                  <FullAbsFlexLayer
                    direction={"column"}
                    align={"center"}
                    justify={"flex-end"}
                    gradient={[
                      {
                        def: "to top",
                        stops: [
                          { height: ESurfaceHeight.high },
                          {
                            height: ESurfaceHeight.high,
                            opacity: 0,
                            def: "75%",
                          },
                        ],
                      },
                    ]}
                  >
                    <FelaFlexLayer
                      borderWidth={"1px"}
                      extraSoftShadow
                      height={ESurfaceHeight.highest}
                      backgroundOpacity={0.75}
                      borderColorString={"rgba(0, 20, 50, 0.15)"}
                      // backgroundString={"rgba(255, 255, 255, 0.7)"}
                      radius={0.5}
                      size={EButtonSize.tiny}
                      padding={"0.3em 0.5em"}
                      margin={"0 0 0.5em 0"}
                    >
                      <FelaText
                        weight={ETextWeightType.bold}
                        contrast={EForeContrast.extra}
                        size={ETextSize.small}
                        transform={"uppercase"}
                        // forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                      >
                        {udi === EUDI.TOUCH ? "tap" : "click"} for more movie info
                      </FelaText>
                    </FelaFlexLayer>
                  </FullAbsFlexLayer>
                  <FelaText size={ETextSize.small}>{movie.overview}</FelaText>
                </FullAbsFlexbox>
              </FelaFlexbox>
              <FelaFlexbox align={"center"}>
                {movie.cinemaCount && (
                  <span className="showing-cinemas flex-row flex-centered col-light-blue-shadow-op-7 flex-shrink-1">
                    <span className="text text-tiny text-uppercase">
                      Showing in {movie.cinemaCount} cinema
                      {movie.cinemaCount > 1 && "s"}
                    </span>
                  </span>
                )}
                {movieInfoButton && (
                  <AsyncNavLink
                    to={
                      linkToCinemaMovie
                        ? `/za/cinemas/${linkToCinemaMovie.brandId}/${linkToCinemaMovie.cinemaId}/${movie.id}`
                        : `/za/movies/${movie.id}`
                    }
                  >
                    <FelaButton size={EButtonSize.smallOptional}>
                      <span>{udi === EUDI.MOUSE ? "click" : "tap"} here for more movie info</span>
                    </FelaButton>
                  </AsyncNavLink>
                )}
                <FelaFlexbox grow={1} />
              </FelaFlexbox>
            </FelaFlexLayer>
          </FelaFlexbox>
        </MovieLink>
        {children}
      </FelaFlexbox>
    );
  },
);

const MovieLink: React.FC<{
  linkType: EMovieItemLinkType;
  linkToCinemaMovie?: {
    brandId: string;
    cinemaId: string;
  };
  movieId: string;
  children: React.ReactElement;
}> = ({ linkType, children, linkToCinemaMovie, movieId }) => {
  if (linkType === EMovieItemLinkType.CINEMA_PAGE && linkToCinemaMovie) {
    return (
      <AsyncNavLink to={`/za/cinemas/${linkToCinemaMovie.brandId}/${linkToCinemaMovie.cinemaId}/${movieId}`}>
        {children}
      </AsyncNavLink>
    );
  }

  if (linkType === EMovieItemLinkType.MOVIE_PAGE) {
    return <AsyncNavLink to={`/za/movies/${movieId}`}>{children}</AsyncNavLink>;
  }

  return children;
};

export const transformGenres = {
  "SCIENCE-FICTION": "sci-fi",
};

export const SimpleMovieTag: React.FC = ({ children }) => {
  return (
    <FelaFlexLayer
      height={ESurfaceHeight.low}
      radius={1}
      padding={"0.15em 0.35em"}
      // size={EButtonSize.tiny}
      // id={genre}
      // active={false}
      // name={transformGenres[genre.toUpperCase()] ?? genre}
    >
      <FelaText size={ETextSize.small}>{children}</FelaText>
    </FelaFlexLayer>
  );
};
