import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { EFelaLayerHeight, FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { EPageSizeRange, EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { GraphGrid, GraphSection } from "./components/Covid19Components";
import { colorsCovidSeries } from "./Covid19Static";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { CovidAsyncActions } from "../../state/stores/CovidStore/CovidAsyncActions";
import { CovidReportHeader } from "./pageSection/CovidReportHeader";
import { QuickUseAsyncResultModal } from "@gt/gt-frontend/build/react/vibescout-ui/components/QuickAsyncResultModal";
import { GraphTotalCases } from "./graphSection/GraphTotalCases";
import { GraphNewCases } from "./graphSection/GraphNewCases";
import { GraphDeathDemographics } from "./graphSection/GraphDeathDemographics";
import { GraphTotalDeaths } from "./graphSection/GraphTotalDeaths";
import { EGraphicContrast } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { GraphStateCasesComparison } from "./graphSection/GraphStateCasesComparison";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { browserHistory } from "../../entry_VibescoutFrontendSpecial";
import { NewsStorySection } from "./miscSections/NewsStorySection";
import { FeedbackFloatingButton, FeedbackSection } from "./miscSections/FeedbackSection";
import { GraphSectionRt } from "./graphSection/GraphSectionRt";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { TiArrowBack } from "react-icons/ti";
import { GraphNewDeaths } from "./graphSection/GraphNewDeaths";
import { CovidReportFooter } from "./pageSection/CovidReportFooter";
import { ECovidPageContextButton } from "../../state/stores/CovidStore/CovidStore";
import { CovidReportBottomBar } from "./pageSection/CovidReportBottomBar";
import { ASN_VibescoutSpecial } from "../../state/ASN_VibescoutSpecial";
import { HeaderText, UnderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import {
  ESurfaceHeight,
  ETextSize,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { CovidQuickStats } from "./graphsAndCharts/CovidQuickStats";

export const PageSpecial_Covid19State: React.FC = () => {
  const { stateGeoLevelPart } = useParams<any>();

  // console.log(stateGeoLevelPart);

  const { UIStore, CovidStore } = PSC_VibescoutSpecial.useStores();
  const [theme, pageSize, udi] = UIStore.useState((s) => [s.theme, s.pageSize, s.udi] as const);

  const [nextProvinceGeoLvl, setNextProvince] = useState<undefined | string>(undefined);

  const loadNextProvinceResult = CovidAsyncActions.getCovidPageData.use(
    { geoLvl: nextProvinceGeoLvl },
    { initiate: false },
  );

  const loadNextProvinceRt = CovidAsyncActions.getRtForGeoLvl.use(
    { geoLvl: nextProvinceGeoLvl!, limit: 60 },
    { initiate: false },
  );

  const isSmall = pageSize <= EPageSizeRange.mobile;

  const stateGeoLevel = GeoLevelCompressUtils.convertUrlToCompressed({
    continentOrCountry: "za",
    statePart: stateGeoLevelPart,
  });

  useEffect(() => {
    if (nextProvinceGeoLvl) {
      loadNextProvinceResult.execute({ respectCache: true }).then((response) => {
        if (!response.error) {
          const provincePart = GeoLevelCompressUtils.getPartsForCompressedGeoLevel(nextProvinceGeoLvl).st;
          browserHistory.push(`/reports/coronavirus/${provincePart}`);
        }
      });
      loadNextProvinceRt.execute({ respectCache: true });
    }
  }, [nextProvinceGeoLvl]);

  const pageDataResult = CovidAsyncActions.getCovidPageData.use({ geoLvl: stateGeoLevel });
  // const provinceRtResult = CovidAsyncActions.getRtForGeoLvl.use({ geoLvl: stateGeoLevel });

  return (
    <FelaFlexLayer
      style={{ minHeight: "100vh" }}
      direction={"column"}
      position={"relative"}
      cssExtra={[
        {
          mobileOnly: {
            fontSize: "14px",
          },
          smallMobileOnly: {
            fontSize: "12px",
          },
        },
      ]}
    >
      <QuickUseAsyncResultModal title={"Provincial Covid-19 Data"} state={pageDataResult} />
      <QuickUseAsyncResultModal title={"Provincial Covid-19 Data"} state={loadNextProvinceResult} />
      {pageDataResult.renderPayload(
        ({
          meta: covidMeta,
          latestTests,
          latestCases,
          latestRecovered,
          latestDeaths,
          dailyCases: caseItems,
          dailyTests,
          dailyRecoveries,
          dailyDeaths,
          individualDeaths,
          provinceGeoLevels,
          statesDaily,
        }) => {
          const provinceGeoLevelIndex = provinceGeoLevels.findIndex((geo) => geo.geoLvlShort === stateGeoLevel);
          const provinceGeoLevel = provinceGeoLevels[provinceGeoLevelIndex];
          /*const newProvinceGeoLevels = [
            provinceGeoLevel,
            ...provinceGeoLevels.filter(geo => geo.geoLvlShort !== stateGeoLevel),
          ];*/
          // console.log(statesDaily);

          const newProvinceGeoLevels = provinceGeoLevels.sort((a, b) => {
            const lastDailyA = statesDaily[a.geoLvlShort][statesDaily[a.geoLvlShort].length - 1];
            const lastDailyB = statesDaily[b.geoLvlShort][statesDaily[b.geoLvlShort].length - 1];
            // return (lastDailyB.casesTotal ?? 0) - (lastDailyA.casesTotal ?? 0);
            return (lastDailyA.casesTotal ?? 0) - (lastDailyB.casesTotal ?? 0);
          });

          covidMeta.metaTitle = `Covid19 Cases in ${provinceGeoLevel.name} Today | Live Updates, Cases and Deaths`;
          covidMeta.metaDescription = `Live Covid19 updates for ${provinceGeoLevel.fullName} - Statistics and graphs: New cases per day, demographics on deaths | Updated Daily`;
          covidMeta.fullReportUrl = `${covidMeta.fullReportUrl}/${stateGeoLevelPart}`;

          return (
            <>
              <CovidReportHeader
                leftFloater={
                  !isSmall ? (
                    <FelaFlexLayer
                      height={EFelaLayerHeight.HIGH}
                      shadow
                      extraSoftShadow
                      padding={0.5}
                      radius={"0 0.5em 0.5em 0"}
                      align={"center"}
                      direction={"column"}
                      alignSelf={"flex-start"}
                      style={{ pointerEvents: "all" }}
                    >
                      <ASN_VibescoutSpecial.Link to={"/reports/coronavirus"}>
                        <FelaIconWrap
                          size={ETextSize.bigHeader}
                          iconFilter={"drop-shadow(0 0.25em 0.25em rgba(0, 0, 0, .7))"}
                          style={{ position: "relative", overflow: "hidden" }}
                          shadow
                          extraSoftShadow
                          isButton
                          colorString={"#FFFFFF"}
                          fillAlso
                        >
                          <FullAbsFlexbox>
                            <img
                              src={"/_static/vs-special/images/sa_flag_square.png"}
                              style={{ objectFit: "cover", width: "100%", objectPosition: "top", opacity: 0.6 }}
                            />
                          </FullAbsFlexbox>
                          <FullAbsFlexbox align={"center"} justify={"center"} zIndex={1}>
                            <TiArrowBack />
                          </FullAbsFlexbox>
                        </FelaIconWrap>
                      </ASN_VibescoutSpecial.Link>
                      <FelaFlexbox direction={"column"} align={"center"}>
                        <FelaText weight={500} margin={"0.5em 0 0 0"} size={ETextSize.tiny} transform={"uppercase"}>
                          Country
                        </FelaText>
                        <FelaText weight={500} size={ETextSize.tiny} transform={"uppercase"}>
                          Stats
                        </FelaText>
                      </FelaFlexbox>
                    </FelaFlexLayer>
                  ) : undefined
                }
                pageTitle={`Covid-19 in ${provinceGeoLevel.name}`}
                pageSubtitle={"Cases and Deaths"}
                covidMeta={covidMeta}
              />
              {!isSmall && <FeedbackFloatingButton />}
              <FelaFlexLayer
                direction={"row"}
                justify={"center"}
                align={"stretch"}
                padding={isSmall ? "0.5em 0.25em" : 1}
              >
                <CovidQuickStats
                  isSmall={isSmall}
                  latestCases={latestCases}
                  latestDeaths={latestDeaths}
                  latestRecovered={latestRecovered}
                  activeCases={
                    latestCases && latestDeaths && latestRecovered
                      ? latestCases - (latestDeaths + latestRecovered)
                      : undefined
                  }
                />
              </FelaFlexLayer>
              <GraphGrid>
                <GraphSection
                  rowSpan={5}
                  title={"The latest daily stats"}
                  subtext={
                    <>
                      How has the virus progressed in {provinceGeoLevel.fullName}?
                    </>
                  }
                >
                  <GraphTotalCases caseItems={caseItems} />
                  <GraphNewCases caseItems={caseItems} />
                </GraphSection>
                <GraphSectionRt
                  isDark={theme.dark}
                  geoLvl={stateGeoLevel}
                  isSmall={isSmall}
                  areaName={provinceGeoLevel.fullName}
                />
                <GraphSection
                  rowSpan={5}
                  title={"Deaths"}
                  extraValue={`TOTAL: ${latestDeaths}`}
                  subtext={"Gender and age distribution"}
                >
                  {/*<GraphDeathDemographics individualDeaths={individualDeaths} latestDeaths={latestDeaths} />*/}
                  <GraphTotalDeaths caseItems={caseItems} latestDeaths={latestDeaths} />
                  <GraphNewDeaths caseItems={caseItems} />
                </GraphSection>
                <GraphSection
                  rowSpan={5}
                  title={"Provincial"}
                  subtext={<>How has the virus spread through different regions in South Africa?</>}
                >
                  <FelaFlexbox position={"relative"} align={"center"} padding={0.5}>
                    <FullAbsFlexbox>
                      <div style={{ transform: "translateY(-5em)" }} id={"provinces"} />
                    </FullAbsFlexbox>
                    <UnderText>{udi === EUDI.MOUSE ? "Click" : "Tap"} on a province for more detailed info</UnderText>
                  </FelaFlexbox>
                  <FelaFlexbox wrap={"wrap"}>
                    {newProvinceGeoLevels
                      .map((geo, index) => {
                        const { st } = GeoLevelCompressUtils.getPartsForCompressedGeoLevel(geo.geoLvlShort);
                        const isCurrentLvl = geo.geoLvlShort === provinceGeoLevel.geoLvlShort;

                        if (isCurrentLvl) {
                          return (
                            <FelaFlexLayer
                              key={st}
                              padding={0.5}
                              margin={0.25}
                              radius={0.25}
                              height={EFelaLayerHeight.HIGH}
                            >
                              <HeaderText
                                weight={700}
                                colorHex={colorsCovidSeries[index + 1]}
                                contrast={EGraphicContrast.MID}
                              >
                                {geo.name}
                              </HeaderText>
                            </FelaFlexLayer>
                          );
                        }
                        return (
                          <ASN_VibescoutSpecial.Link key={st} to={`/reports/coronavirus/${st}`}>
                            <FelaFlexLayer
                              pop={true}
                              onClick={(e) => {
                                console.log("Hit inner link");
                                e.preventDefault();
                                ASN_VibescoutSpecial.go(`/reports/coronavirus/${st}`);
                                setNextProvince(undefined);
                              }}
                              padding={0.5}
                              margin={0.25}
                              radius={0.25}
                              height={ESurfaceHeight.highest}
                              hoverable
                              clickable
                            >
                              <HeaderText colorHex={colorsCovidSeries[index + 1]} contrast={EGraphicContrast.MID}>
                                {geo.name}
                              </HeaderText>
                            </FelaFlexLayer>
                          </ASN_VibescoutSpecial.Link>
                        );
                      })
                      .reverse()}
                  </FelaFlexbox>
                  <FelaFlexbox align={"center"} padding={0.5}>
                    <UnderText>
                      <b>Total Cases</b> comparison between the provinces ({udi === EUDI.MOUSE ? "Hover on" : "Tap"}{" "}
                      graph for direct stats)
                    </UnderText>
                  </FelaFlexbox>
                  <GraphStateCasesComparison
                    highlightLvl={provinceGeoLevel.geoLvlShort}
                    provinceGeoLevels={newProvinceGeoLevels}
                    statesDaily={statesDaily}
                  />
                </GraphSection>
                <GraphSection rowSpan={4} title={"More Info"}>
                  <NewsStorySection />
                </GraphSection>
                <GraphSection id={"feedback"} rowSpan={4} title={"Feedback"}>
                  <FeedbackSection />
                </GraphSection>
              </GraphGrid>
              <CovidReportBottomBar
                buttons={[
                  ECovidPageContextButton.COUNTRY_STATS,
                  ECovidPageContextButton.GO_TO_PROVINCE,
                  ECovidPageContextButton.GIVE_FEEDBACK,
                ]}
                disableButtons={[]}
              />
              <CovidReportFooter />
            </>
          );
        },
      )}
    </FelaFlexLayer>
  );
};
