import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import { Article, WithContext } from "schema-dts";
import { EFelaLayerHeight, FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { ZIndexes } from "@gt/gt-frontend/build/styling/VibescoutZIndexes";
import { SvgPathVibescoutVLetter } from "@vs/core/build/frontend/graphics/svg-components/VibescoutBrandingSvg";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { EGraphicContrast } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { EShareProvider, ShareButton } from "@gt/gt-frontend/build/react/vibescout-ui/components/ShareButton";
import { EPageSizeRange, EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { FiMoon, FiSun } from "react-icons/fi";
import React from "react";
import {
  ECovidMetaDateUpdatedShow,
  IDMCovidDataMeta,
} from "@vs/core/build/data/models/covid19/CovidDataMeta/CovidDataMeta";
import { PSC_VibescoutSpecial } from "../../../state/PSC_VibescoutSpecial";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import {
  EFrontendStyleConfigBaseTheme,
  ETextSize,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { AsyncNavLink } from "../../../state/ASN_VibescoutSpecial";

interface ICPCovidReportHeader {
  pageTitle: any;
  pageSubtitle: any;
  covidMeta: IDMCovidDataMeta & { dateFormatted: string };
  goBack?: { name: any; path: string };
  leftFloater?: React.ReactElement;
  rightFloater?: React.ReactElement;
}

export const CovidReportHeader: React.FC<ICPCovidReportHeader> = React.memo(
  ({ covidMeta, pageTitle, pageSubtitle, goBack, leftFloater, rightFloater }) => {
    const { UIStore } = PSC_VibescoutSpecial.useStores();
    const [theme, pageSize, udi] = UIStore.useState((s) => [s.theme, s.pageSize, s.udi] as const);
    const isSmall = pageSize <= EPageSizeRange.mobile;

    const metaDescriptionWithDate = `${covidMeta.metaDescription} | Last updated: ${covidMeta.dateFormatted}`;

    const articleJsonLd: WithContext<Article> = {
      "@context": "https://schema.org",
      "@type": "Article",
      author: {
        "@type": "Organization",
        name: "Vibescout",
        logo: {
          "@type": "ImageObject",
          url: covidMeta.schemaOrgLogoUrl,
          width: `${covidMeta.schemaOrgLogoWidth}`,
          height: `${covidMeta.schemaOrgLogoHeight}`,
        },
      },
      publisher: {
        "@type": "Organization",
        name: "Vibescout",
        logo: {
          "@type": "ImageObject",
          url: covidMeta.schemaOrgLogoUrl,
          width: `${covidMeta.schemaOrgLogoWidth}`,
          height: `${covidMeta.schemaOrgLogoHeight}`,
        },
      },
      description: covidMeta.dateUpdatedShow.includes(ECovidMetaDateUpdatedShow.SCHEMA_DESCRIPTION)
        ? metaDescriptionWithDate
        : covidMeta.metaDescription,
      datePublished: covidMeta.dateCreated.toISOString(),
      dateModified: covidMeta.dateUpdated.toISOString(),
      headline: covidMeta.metaTitle,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": covidMeta.fullReportUrl,
      },
      image: [covidMeta.titleImage1x1, covidMeta.titleImage4x3, covidMeta.titleImage16x9],
    };

    return (
      <>
        <Helmet script={[helmetJsonLdProp<Article>(articleJsonLd)]}>
          <title>{covidMeta.metaTitle}</title>
          <meta
            name="description"
            content={
              covidMeta.dateUpdatedShow.includes(ECovidMetaDateUpdatedShow.DESCRIPTION_META)
                ? metaDescriptionWithDate
                : covidMeta.metaDescription
            }
          />
          <meta property="og:title" content={covidMeta.metaTitle} />
          <meta property="og:site_name" content={"Vibescout Reports"} />
          <meta property="og:url" content={covidMeta.fullReportUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={covidMeta.metaDescription} />
          <meta property="og:image" content={covidMeta.titleImageUrl} />
          <meta property="og:image:width" content={`${covidMeta.titleImageWidth}`} />
          <meta property="og:image:height" content={`${covidMeta.titleImageHeight}`} />
        </Helmet>
        {/*<JsonLd<Article> item={articleJsonLd} />*/}
        <FelaFlexLayer
          extraSoftShadow
          shadow
          zIndex={ZIndexes.topBar}
          height={EFelaLayerHeight.HIGH}
          position={"sticky"}
          top={0}
          padding={"0 0.5em 0 0"}
        >
          <FullAbsFlexbox
            direction={"row"}
            justify={"space-between"}
            top={"0"}
            style={{ transform: "translateY(calc(100% + 0.5em))", pointerEvents: "none" }}
          >
            {leftFloater != null ? leftFloater : <FelaFlexbox />}
            {rightFloater != null ? rightFloater : <FelaFlexbox />}
          </FullAbsFlexbox>
          <FelaFlexbox align={"stretch"}>
            <AsyncNavLink to={"/"} style={{ lineHeight: 0 }}>
              <FelaFlexbox style={{ width: "3.5em", minHeight: "4em" }} overflow={"hidden"} position={"relative"}>
                <svg
                  viewBox={"0 0 400 400"}
                  style={{ position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)" }}
                  width="6.5em"
                  height="8em"
                  xmlns="http://www.w3.org/2000/svg"
                  baseProfile="full"
                >
                  <circle
                    cx={0}
                    cy={100}
                    r={"400"}
                    fill={theme.dark ? theme.styles.RgbColors.darkBack.mid : theme.styles.RgbColors.lightBack.mid}
                  />
                  <g transform={"translate(200,129) scale(1.2)"}>
                    <SvgPathVibescoutVLetter fill={theme.styles.RgbColors.neutral.primary} />
                  </g>
                </svg>
              </FelaFlexbox>
            </AsyncNavLink>
          </FelaFlexbox>
          {/*<AsyncNavLink to={"/"} style={{ lineHeight: 0 }}>
            <svg viewBox={"0 0 150 200"} width="4em" height="5em" xmlns="http://www.w3.org/2000/svg" baseProfile="full">
              <circle
                cx={-55}
                cy={30}
                r={"200"}
                fill={theme.dark ? theme.styles.RgbColors.darkBack.mid : theme.styles.RgbColors.lightBack.mid}
              />
              <g transform={"translate(0,25) scale(0.8)"}>
                <SvgVibescoutVLetterPath fill={theme.styles.RgbColors.neutral.primary} />
              </g>
            </svg>
          </AsyncNavLink>*/}
          <FelaFlexbox align={"center"} padding={"0.5em"}>
            <h1 style={{ display: "flex", flexDirection: "column" }}>
              <FelaText tag={"span"} weight={"500"} size={ETextSize.smallHeader} contrast={EGraphicContrast.HIGH}>
                {pageTitle}&nbsp;
              </FelaText>
              <FelaText tag={"span"} weight={"500"} size={ETextSize.small} margin={"0.25em 0 0 0"}>
                {pageSubtitle}
              </FelaText>
            </h1>
          </FelaFlexbox>
          <FelaFlexbox grow={1} />
          <FelaFlexbox align={"center"} padding={0.5}>
            <ShareButton
              providers={udi === EUDI.MOUSE ? [EShareProvider.FACEBOOK, EShareProvider.TWITTER] : undefined}
              tippyProps={{
                delay: isSmall ? 100 : 0,
              }}
              iconProps={{
                size: ETextSize.bigHeader,
                isButton: true,
                height: EFelaLayerHeight.HIGH,
                shadow: true,
                extraSoftShadow: true,
              }}
              title={"Coronavirus Live Stats for South Africa"}
              url={"https://www.vibescout.com/reports/coronavirus"}
            />
            <FelaIconWrap
              size={ETextSize.bigHeader}
              onClick={() => {
                UIStore.update((s, o) => {
                  s.theme.dark = !o.theme.dark;
                  s.theme.currentId = o.theme.dark
                    ? EFrontendStyleConfigBaseTheme.LIGHT
                    : EFrontendStyleConfigBaseTheme.DARK;
                });
              }}
              margin={"0 0 0 0.5em"}
              isButton
              height={EFelaLayerHeight.HIGH}
              shadow
              extraSoftShadow
            >
              {theme.dark ? <FiSun /> : <FiMoon />}
            </FelaIconWrap>
          </FelaFlexbox>
        </FelaFlexLayer>
      </>
    );
  },
);
