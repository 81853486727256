import React from "react";
import {
  FelaFlexLayer,
  IFelaLayerProps,
  TFelaFlexLayerProps,
} from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { SimpleImage } from "@gt/gt-frontend/build/react/vibescout-ui/media/SimpleImage";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { FiAlertTriangle } from "react-icons/fi";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { MdLocationOn } from "react-icons/md";
import { AsyncNavLink } from "../../state/ASN_VibescoutSpecial";
import { ArrayUtils } from "@gt/common-utils/build/datatypes/ArrayUtils";

export interface ICPCinemaListItem {
  brandLogoImage?: string;
  name: string;
  area?: string;
  image?: string;
  brandId: string;
  cinemaId: string;
  isSmall: boolean;
  childrenOverImage?: React.ReactElement;
  outerProps?: TFelaFlexLayerProps;
  childrenUnder?: React.ReactElement;
}

export const CinemaListItem: React.FC<ICPCinemaListItem> = ({
  cinemaId,
  brandId,
  isSmall,
  brandLogoImage,
  image,
  childrenOverImage,
  name,
  area,
  outerProps,
}) => {
  const { cssExtra, ...restOuterProps } = outerProps ?? {};

  /*ArrayUtils.pushOrCreateOnObject(outerProps, "cssExtra", {
    transition: "transform 0.2s ease-in-out",
    ":hover": {
      transform: "scale(1.1) translateY(-0.25em)",
    },
  });*/

  return (
    <AsyncNavLink to={`/za/cinemas/${brandId}/${cinemaId}`}>
      <FelaFlexLayer
        cssExtra={[
          ...(cssExtra ?? []),
          {
            transition: "transform 0.2s ease-in-out",
            ":hover": {
              transform: "scale(1.05) translateY(-0.25em)",
            },
          },
        ]}
        clickable
        hoverable
        overflow={"hidden"}
        radius={0.25}
        // pop
        shadow
        extraSoftShadow
        style={{ width: "10em", minWidth: isSmall ? "8.5em" : "10em" }}
        direction={"column"}
        {...restOuterProps}
      >
        <FelaFlexbox position={"relative"} overflow={"hidden"}>
          <SimpleImage aspect={1} src={image ?? ""} />
          {childrenOverImage}
          <FullAbsFlexbox pointerEvents={false} align={"flex-end"} justify={"flex-end"}>
            <FelaFlexLayer
              opacity={0.9}
              radius={"0.25em 0 0 0"}
              forceTheme={EFrontendStyleConfigBaseTheme.LIGHT}
              height={ESurfaceHeight.highest}
              padding={0.25}
              align={"center"}
            >
              <SimpleImage style={{ maxWidth: "5em" }} src={brandLogoImage ?? ""} />
            </FelaFlexLayer>
          </FullAbsFlexbox>
        </FelaFlexbox>
        <FelaFlexbox direction={"column"} align={"flex-start"} padding={0.5} grow={1}>
          <FelaText
            size={ETextSize.small}
            transform={"uppercase"}
            contrast={EForeContrast.extra}
            weight={ETextWeightType.bold}
            // forceTheme={EFrontendStyleConfigBaseTheme.DARK}
          >
            {name}
          </FelaText>
          {area && (
            <FelaFlexbox align={"center"}>
              <FelaIconWrap noPadding size={ETextSize.small}>
                <MdLocationOn />
              </FelaIconWrap>
              <FelaText
                margin={"0 0 0 0.3em"}
                size={ETextSize.tiny}
                transform={"uppercase"}
                // forceTheme={EFrontendStyleConfigBaseTheme.DARK}
              >
                {area}
              </FelaText>
            </FelaFlexbox>
          )}
        </FelaFlexbox>
      </FelaFlexLayer>
    </AsyncNavLink>
  );
};
