import { createMetaObjectFromArray, ISelectItem } from "../../../common/StaticTypes";
import { EGSLockdownLevelValue } from "./LockdownLevelsGeoSheetModels";
import { TObjectKeyEnumSet } from "@gt/common-utils/build/typescript/SpecialTypes";

const funnelLevelValueToBasicLevelValue: TObjectKeyEnumSet<
  EGSLockdownLevelValue,
  | EGSLockdownLevelValue.LVL_0
  | EGSLockdownLevelValue.LVL_1
  | EGSLockdownLevelValue.LVL_2
  | EGSLockdownLevelValue.LVL_3_ADV
  | EGSLockdownLevelValue.LVL_3
  | EGSLockdownLevelValue.LVL_4
  | EGSLockdownLevelValue.LVL_5
> = {
  [EGSLockdownLevelValue.IGNORE]: EGSLockdownLevelValue.LVL_0,
  [EGSLockdownLevelValue.LVL_0]: EGSLockdownLevelValue.LVL_0,
  [EGSLockdownLevelValue.LVL_1]: EGSLockdownLevelValue.LVL_1,
  [EGSLockdownLevelValue.LVL_2]: EGSLockdownLevelValue.LVL_2,
  [EGSLockdownLevelValue.LVL_3]: EGSLockdownLevelValue.LVL_3,
  [EGSLockdownLevelValue.LVL_4]: EGSLockdownLevelValue.LVL_4,
  [EGSLockdownLevelValue.LVL_5]: EGSLockdownLevelValue.LVL_5,
  [EGSLockdownLevelValue.LVL_1_HOT]: EGSLockdownLevelValue.LVL_1,
  [EGSLockdownLevelValue.LVL_2_HOT]: EGSLockdownLevelValue.LVL_2,
  [EGSLockdownLevelValue.LVL_3_HOT]: EGSLockdownLevelValue.LVL_3,
  [EGSLockdownLevelValue.LVL_4_HOT]: EGSLockdownLevelValue.LVL_4,
  [EGSLockdownLevelValue.LVL_5_HOT]: EGSLockdownLevelValue.LVL_5,
  [EGSLockdownLevelValue.LVL_3_ADV]: EGSLockdownLevelValue.LVL_3_ADV,
};

export interface ISelectItemLockdownLevel extends ISelectItem<EGSLockdownLevelValue> {
  num: number;
  short: string;
}

const levelTypeMetaArray: ISelectItemLockdownLevel[] = [
  {
    num: 0,
    short: "0",
    id: EGSLockdownLevelValue.LVL_0,
    name: "No Lockdown",
    description: "No Lockdown",
  },
  {
    num: 1,
    short: "1",
    id: EGSLockdownLevelValue.LVL_1,
    name: "Level 1",
    description: "Lockdown Level 1",
  },
  {
    num: 2,
    short: "2",
    id: EGSLockdownLevelValue.LVL_2,
    name: "Level 2",
    description: "Lockdown Level 2",
  },
  {
    num: 2.5,
    short: "3A",
    id: EGSLockdownLevelValue.LVL_3_ADV,
    name: "Level 3 (Advanced)",
    description: "Lockdown Level 3 (Advanced)",
  },
  {
    num: 3,
    short: "3",
    id: EGSLockdownLevelValue.LVL_3,
    name: "Level 3",
    description: "Lockdown Level 3",
  },
  {
    num: 4,
    short: "4",
    id: EGSLockdownLevelValue.LVL_4,
    name: "Level 4",
    description: "Lockdown Level 4",
  },
  {
    num: 5,
    short: "5",
    id: EGSLockdownLevelValue.LVL_5,
    name: "Level 5",
    description: "Lockdown Level 5",
  },
  {
    num: 1,
    short: "1",
    id: EGSLockdownLevelValue.LVL_1_HOT,
    name: "Level 1",
    description: "Lockdown Level 1",
  },
  {
    num: 2,
    short: "2",
    id: EGSLockdownLevelValue.LVL_2_HOT,
    name: "Level 2",
    description: "Lockdown Level 2",
  },
  {
    num: 3,
    short: "3",
    id: EGSLockdownLevelValue.LVL_3_HOT,
    name: "Level 3",
    description: "Lockdown Level 3",
  },
  {
    num: 4,
    short: "4",
    id: EGSLockdownLevelValue.LVL_4_HOT,
    name: "Level 4",
    description: "Lockdown Level 4",
  },
  {
    num: 5,
    short: "5",
    id: EGSLockdownLevelValue.LVL_5_HOT,
    name: "Level 5",
    description: "Lockdown Level 5",
  },
  {
    id: EGSLockdownLevelValue.IGNORE,
    num: 0,
    short: "0",
    name: "Unset",
    description: "Unset Level",
  },
];

const levelTypeMetaObject = createMetaObjectFromArray(levelTypeMetaArray);

const lvlsOnly: EGSLockdownLevelValue[] = [
  EGSLockdownLevelValue.LVL_1,
  EGSLockdownLevelValue.LVL_2,
  EGSLockdownLevelValue.LVL_3_ADV,
  EGSLockdownLevelValue.LVL_3,
  EGSLockdownLevelValue.LVL_4,
  EGSLockdownLevelValue.LVL_5,
];

export const LockdownLevelsGeoStatic = {
  levelTypeMetaArray,
  levelTypeMetaObject,
  levelTypeMetaArrayLevelsOnly: levelTypeMetaArray.filter((l) => lvlsOnly.includes(l.id)),
  levelTypeMetaArrayLevelsOnlyShort: levelTypeMetaArray
    .filter((l) => lvlsOnly.includes(l.id))
    .map((lvl) => ({
      ...lvl,
      name: lvl.short,
    })),
  funnelLevelValueToBasicLevelValue,
};
