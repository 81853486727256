export const ZIndexes = {
  higherBar: 20,
  menuUnderHigherBar: 15,
  menuAboveHigherBar: 25,
  foregroundFadeBox: 24,
  modalAboveAll: 30,
  topBar: 10,
  menuUnderTopBar: 5,
  menuAboveTopBar: 15,
  mainContent: 1,
};
