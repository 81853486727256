const base = "_psNav_";

function getRoutePath(route: string): string {
  return `/${base}/${route}`;
}

const routePaths = {
  userLocationToLocality: getRoutePath("userLocationToLocality"),
};

export const PseudoNavStatic = {
  base,
  routePaths,
};
