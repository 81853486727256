import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight, FiChevronsRight } from "react-icons/fi";
import { useFelaCache } from "../../fela/FelaCache";
import { FelaFlexbox } from "../fela/FelaFlexbox";
import { FelaFlexLayer, IFelaLayerProps } from "../fela/FelaLayers";
import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
} from "../config/VibescoutFrontendStyleConfig";
import { FelaText } from "../fela/FelaText";
import { FelaIconWrap } from "../fela/FelaIconWrap";
import { useMeasure } from "react-use";
import { TFelaRule } from "../fela/types";
import { FullAbsFlexbox } from "../common/FullAbsDiv";
import { EGradientType, IGradientStop } from "../fela/FelaCommon";

interface ICPHorizontalScrollContainer {
  touch: boolean;
  children: React.ReactElement;
  scrollKey: string;
  layerProps?: IFelaLayerProps;
  showScrollHint?: boolean;
}

const edgeGradientStops: IGradientStop[] = [
  {
    height: ESurfaceHeight.shadow,
    opacity: 1,
  },
  /*{
    height: ESurfaceHeight.shadow,
    opacity: 0.5,
    def: "50%",
  },
  {
    height: ESurfaceHeight.shadow,
    opacity: 0,
    def: "75%",
  },
  {
    height: ESurfaceHeight.shadow,
    opacity: 0,
  },*/
  {
    height: ESurfaceHeight.shadow,
    opacity: 0.75,
    def: "3.2em",
  },
  {
    height: ESurfaceHeight.shadow,
    def: "4.8em",
    opacity: 0.15,
  },
  {
    height: ESurfaceHeight.shadow,
    def: "6em",
    opacity: 0,
  },
];

export const HorizontalScrollContainer: React.FC<ICPHorizontalScrollContainer> = ({
  touch,
  children,
  scrollKey,
  layerProps,
  showScrollHint = true,
}) => {
  // const { UIStore } = PSC_VibescoutFrontend.useStores();
  // const [finished, result] = AAGetTopMovies.useBeckon({}, { ssr: false });
  const [{ leftShadow, rightShadow, triedScrolling }, setShadows] = useState({
    leftShadow: false,
    rightShadow: false,
    triedScrolling: !showScrollHint,
  });

  const [containerBlockRef, { width: containerBlockWidth }] = useMeasure();

  // const { theme } = useFelaCache();

  // const { udi, smallDevice } = useStoreState(UIStore, s => ({ udi: s.udi, smallDevice: s.smallDeviceWidth }));
  const scrollContainer = useRef<HTMLDivElement>(null);

  const onScrollFunc = useCallback(
    (setTried: boolean = true) => {
      // const element: HTMLDivElement = e.target as HTMLDivElement;
      if (scrollContainer.current) {
        const element = scrollContainer.current;

        const atMaxScroll = element.scrollWidth - element.scrollLeft - 10 <= element.clientWidth;
        const atLowestScroll = element.scrollLeft < 10;

        if (
          (leftShadow && atLowestScroll) ||
          (!leftShadow && !atLowestScroll) ||
          (rightShadow && atMaxScroll) ||
          (!rightShadow && !atMaxScroll)
        ) {
          setShadows(() => ({
            triedScrolling: setTried ? true : triedScrolling,
            leftShadow: !atLowestScroll,
            rightShadow: !atMaxScroll,
          }));
        } else if (!triedScrolling) {
          setShadows({
            triedScrolling: setTried ? true : triedScrolling,
            leftShadow,
            rightShadow,
          });
        }
      }
    },
    [leftShadow, rightShadow, triedScrolling],
  );

  useEffect(() => {
    onScrollFunc(false);
  }, [containerBlockWidth, scrollKey]);

  const hasAnyShadow = leftShadow || rightShadow;

  /*const boxShadow = `inset 2.5em 0 1.5em -1.6em rgba(30,30,30,${
    leftShadow ? "0.5" : "0"
  }), inset -2.5em 0 1.5em -1.6em rgba(30,30,30,${rightShadow ? "0.5" : "0"})`;*/

  return (
    <FelaFlexbox rootProps={{ ref: containerBlockRef as any }} position={"relative"} grow={1}>
      {!touch && (
        <>
          <ScrollButton
            left={true}
            onClick={() => {
              let scrollAmount = -1 * (scrollContainer.current!.clientWidth / 1.5);

              if (scrollContainer.current!.scrollLeft + scrollAmount < 150) {
                scrollAmount -= 150;
              }

              scrollContainer.current!.scrollBy({ behavior: "smooth", left: scrollAmount });
            }}
            show={leftShadow}
          />
          <ScrollButton
            left={false}
            onClick={() => {
              const { clientWidth, scrollLeft, scrollWidth } = scrollContainer.current!;
              let scrollAmount = clientWidth / 1.5;

              if (scrollLeft + scrollAmount + clientWidth > scrollWidth - 150) {
                scrollAmount += 150;
              }

              scrollContainer.current!.scrollBy({ behavior: "smooth", left: scrollAmount });
            }}
            show={rightShadow}
          />
        </>
      )}
      <FullAbsFlexbox pointerEvents={false} zIndex={2} align={"stretch"} justify={"space-between"} overflow={"hidden"}>
        <FelaFlexLayer
          margin={"0 0 0 -3.5em"}
          gradient={[{ stops: edgeGradientStops, type: EGradientType.radial, def: "ellipse at left" }]}
          style={{
            width: "6em",
            height: "180%",
            transition: "opacity 0.3s ease-in-out",
            transform: "translateY(-20%)",
          }}
          opacity={leftShadow ? 1 : 0}
        />
        <FelaFlexLayer
          gradient={[{ stops: edgeGradientStops, type: EGradientType.radial, def: "ellipse at right" }]}
          margin={"0 -3.5em 0 0"}
          style={{
            width: "6em",
            height: "180%",
            transition: "opacity 0.3s ease-in-out",
            transform: "translateY(-20%)",
          }}
          opacity={rightShadow ? 1 : 0}
        />
      </FullAbsFlexbox>
      {touch && hasAnyShadow && (
        <FelaFlexLayer
          backgroundNeutralColor={EAppColor.go}
          // className={"bg-green col-white"}
          style={{
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            padding: "0.2em 0.35em",
            borderRadius: "0.5em 0 0 0",
            animation: "text-fading-in-out-white-simple 0.8s linear infinite alternate",
            bottom: 0,
            right: 0,
            opacity: triedScrolling ? 0 : 1,
            transition: "opacity 1.5s ease-in-out",
            zIndex: 5,
          }}
        >
          <FelaText
            colorHex={"#FFFFFF"}
            transform={"uppercase"}
            size={ETextSize.small}
            className={"text text-uppercase text-mini text-700"}
          >
            Scroll
          </FelaText>
          <FelaIconWrap
            noBackground
            noPadding
            size={ETextSize.small}
            forceTheme={EFrontendStyleConfigBaseTheme.DARK}
            contrast={EForeContrast.strong}
          >
            <FiChevronsRight />
          </FelaIconWrap>
        </FelaFlexLayer>
      )}
      <FelaFlexLayer
        cssExtra={[
          {
            overflowStyle: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          },
        ]}
        grow={1}
        backgroundOpacity={0}
        rootProps={{ ref: scrollContainer, onScroll: () => onScrollFunc() }}
        // padding={theme.isSmall ? 0 : 0.5}
        // ref={scrollContainer}
        // onScroll={onScrollFunc}
        // className={"flex-row pad-05em-b"}
        style={{
          overflowX: hasAnyShadow ? (touch ? "scroll" : "hidden") : "auto" /*paddingLeft: theme.isSmall ? "1em" : 0*/,
        }}
        {...layerProps}
      >
        <div>
          {children}
          {/*<FelaFlexbox padding={0.5}/>*/}
        </div>
      </FelaFlexLayer>
    </FelaFlexbox>
  );
};

interface ICPScrollButton {
  left: boolean;
  onClick: () => void;
  show: boolean;
}

const FelaRuleScrollButton: TFelaRule<{ show: boolean; svgKeyframe: string }> = ({ show, svgKeyframe }) => ({
  "&:hover": {
    boxShadow:
      "0 0 1em rgba(30, 30, 30, 0.6), 0 0 2em rgba(30, 30, 30, 0.3), inset 0 -0.1em 0.1em rgba(10, 20, 50, 0.5)",
    transform: "translateY(-52%)",
  },
  "&:active": {
    transform: "translateY(-49%)",
  },
  boxShadow: "0 0 1em rgba(30, 30, 30, 0.3), 0 0 2em rgba(30, 30, 30, 0), inset 0 -0.1em 0.1em rgba(10, 20, 50, 0.5)",
  pointerEvents: show ? "all" : "none",
  opacity: show ? 1 : 0,
  transform: "translateY(-50%)",
  zIndex: 6,
  "& svg": {
    animation: `${svgKeyframe} 1.5s linear infinite`,
  },
});

const FelaKeyframesScrollButtonSvg: TFelaRule<{ left: boolean }> = ({ left }) => {
  return {
    "0%": {
      transform: left ? "translateX(0.25em)" : "translateX(-0.25em)",
      opacity: 0,
    },

    "20%": {
      transform: left ? "translateX(0.15em)" : "translateX(-0.15em)",
      opacity: 1,
    },

    "80%": {
      opacity: 1,
    },

    "100%": {
      transform: left ? "translateX(-0.6em)" : "translateX(0.6em)",
      opacity: 0,
    },
  };
};

const ScrollButton: React.FC<ICPScrollButton> = ({ left, onClick, show }) => {
  const { cachedClass, cachedKeyframe } = useFelaCache();

  const svgKeyframe = cachedKeyframe(FelaKeyframesScrollButtonSvg, { left }, `${left}`);

  return (
    <FelaFlexLayer
      className={cachedClass(FelaRuleScrollButton, { show, svgKeyframe }, `${left}${show}${svgKeyframe}`)}
      clickable
      position={"absolute"}
      top={"50%"}
      // bottom={1}
      left={left ? 0 : undefined}
      right={!left ? 0 : undefined}
      style={{
        // transform: "translateY(50%)",
        height: "100%",
        transition: "opacity 0.3s ease-in-out, transform 0.15s ease-in-out, box-shadow 0.3s ease-in-out",
      }}
      align={"center"}
      // shadow
      // extraSoftShadow
      gradient={[
        {
          def: left ? "to left" : "to right",
          stops: [
            { height: ESurfaceHeight.shadow, opacity: 0.6 },
            { height: ESurfaceHeight.shadow, lighten: 10, opacity: 0.9 },
            // { neutral: EColorsNeutralName.go, darken: 40, opacity: 0.75 },
            // { neutral: EColorsNeutralName.go, lighten: 30, opacity: 0.9 },
          ],
        },
      ]}
      radius={left ? "0 0.5em 0.5em 0" : "0.5em 0 0 0.5em"}
      onClick={() => onClick()}
      padding={0.75}
    >
      <FelaIconWrap
        shadow
        noPadding
        size={ETextSize.huge}
        forceTheme={EFrontendStyleConfigBaseTheme.DARK}
        contrast={EForeContrast.strong}
        noBackground
      >
        {left ? <FiChevronLeft /> : <FiChevronRight />}
      </FelaIconWrap>
    </FelaFlexLayer>
  );
};
