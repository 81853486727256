import { useEffect, useRef, useState } from "react";

export function useDelayedValue<T>(target: T, delay: number): T {
  const [delayedVal, setDelayedVal] = useState<T>(target);
  const timerRef = useRef<any>();
  const unmountedRef = useRef<boolean>(false);

  useEffect(() => {
    unmountedRef.current = false;
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      if (!unmountedRef.current) {
        setDelayedVal(target);
      }
    }, delay);

    return () => {
      unmountedRef.current = true;
    };
  }, [target]);

  return delayedVal;
}
