import { pick } from "lodash";

export class Garage<T = any> {
  public parked: T = {} as T;

  constructor() {}

  park<S extends keyof T>(what: S, obj: T[S]): () => void {
    if (this.parked[what] == null) {
      this.parked[what] = obj;
    } else {
      console.error(
        `Garage: Can't park in the same spot twice [ id: ${what} ]. Run remove("${what}") before parking again.`,
      );
    }

    return () => this.remove([what]);
  }

  parkOverwrite<S extends keyof T>(what: S, obj: T[S]): () => void {
    this.parked[what] = obj;
    return () => this.remove([what]);
  }

  remove<S extends keyof T>(what: S[]) {
    for (const w of what) {
      delete this.parked[w];
    }
  }

  getParked<S extends keyof T>(what: S): T[S] {
    if (this.parked[what] != null) {
      return this.parked[what];
    }

    throw new Error(
      `Garage: Can't get a value [ id: ${what} ] which hasn't been parked yet. Run park("${what}") before trying to get it.`,
    );
  }

  isParked<S extends keyof T>(what: S[]): boolean {
    return !what.find((s) => this.parked[s] == null);
  }

  runIfParked<S extends keyof T, R = any>(
    what: S[],
    run: (picked: Pick<T, S>, stillParked: () => boolean) => R,
  ): R | null {
    if (this.isParked(what)) {
      return run((pick(this.parked, what) as any) as Pick<T, S>, () => this.isParked(what));
    }

    return null;
  }

  getParkedOrNewlyCreated<S extends keyof T>(what: S, create: () => T[S]) {
    if (this.parked[what] != null) {
      return this.parked[what];
    }

    const created = create();

    this.park(what, created);
    return created;
  }

  createIsParkedCheck<S extends keyof T>(what: S[]): () => boolean {
    return () => this.isParked(what);
  }

  createRunIfParked<S extends keyof T, R = any>(
    what: S[],
  ): (run: (picked: Pick<T, S>, stillParked: () => boolean) => R) => R | null {
    return (run) => this.runIfParked(what, run);
  }
}
