import React, { useState } from "react";
import classnames from "classnames";
import { FiChevronDown } from "react-icons/fi";
import { convertToSlug } from "@gt/common-utils/build/datatypes/StringUtils";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";

export const CAPostAllowedBlocks = ["header-two", "header-three", "header-four"];

export function createPostIndexArrayFromState(rawState) {
  const indexArray: any[] = [];

  for (let i = 0; i < rawState.blocks.length; i += 1) {
    if (CAPostAllowedBlocks.indexOf(rawState.blocks[i].type) !== -1) {
      indexArray.push({
        type: rawState.blocks[i].type,
        text: rawState.blocks[i].text,
        key: rawState.blocks[i].key,
        hashId: `${rawState.blocks[i].key}-${convertToSlug(rawState.blocks[i].text)}`,
        inlineWarning: rawState.blocks[i].inlineStyleRanges.length > 0,
      });
    }
  }

  let currentHeaderIndex = 0;

  for (let j = 0; j < indexArray.length; j += 1) {
    indexArray[j].hasSubheaders =
      indexArray[j].type === "header-two" && indexArray[Math.min(j + 1, indexArray.length - 1)].type !== "header-two";
    indexArray[j].isMainHeader = indexArray[j].type === "header-two";

    if (indexArray[j].hasSubheaders) {
      currentHeaderIndex = j;
    }

    indexArray[j].currentHeaderIndex = currentHeaderIndex;
  }

  return indexArray;
}

export const OldPostIndex: React.FC = () => {
  /*constructor(props) {
    super(props);

    this.state = {
      openHeaderIndexes: [],
    };
  }

  toggleHeader(index) {
    const openIndexes = this.state.openHeaderIndexes;
    const pos = openIndexes.indexOf(index);
    console.log(`Toggling header with index ${index}`);

    if (pos !== -1) {
      openIndexes.splice(pos, 1);
    } else {
      openIndexes.push(index);
    }

    this.setState({
      openHeaderIndexes: openIndexes,
    });
  }
*/
  // render() {
  // let { postIndexArray } = this.props;
  const { OldPostPageStore } = PSC_VibescoutSpecial.useStores();
  const postIndexArray = OldPostPageStore.useState(s => s.postIndexArray);
  const [openHeaderIndexes, setOpenHeaderIndexes] = useState<number[]>([]);
  // let { postIndexArray } = this.props.PostStore;
  // const { dominantColorRgbString } = this.props.UIStore;
  // const { contextUIColorArray: color } = this.props.ContentContextStore;

  /*if (this.props.overrideIndex) {
    postIndexArray = this.props.overrideIndex;
  }*/

  // console.dir(postIndexArray);

  // console.dir(toJS(postIndexArray));

  if (postIndexArray.length > 0) {
    const postIndexElements: any[] = [];

    for (let i = 0; i < postIndexArray.length; i += 1) {
      const value = postIndexArray[i];

      const currentIndex = i;
      const headerOpen = value.hasSubheaders && openHeaderIndexes.indexOf(i) !== -1;

      const headerClassNames = classnames("post-index-header", value.type, {
        "header-open": headerOpen,
        // "subheader-open": (!value.hasSubheaders && this.state.openHeaderIndexes.indexOf(value.currentHeaderIndex) !== -1),
        // "subheader": !value.isMainHeader,
        "inline-warning": value.inlineWarning,
      });

      const headerTextStyles: any = {};

      if (value.isMainHeader) {
        // headerTextStyles.color = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
        headerTextStyles.color = `rgb(10, 30, 50)`;
      }

      postIndexElements.push(
        <a href={`#${value.hashId}`} key={`${value.hashId}-${i}`} className={headerClassNames}>
          <span className="left-section">
            <span className="text" style={headerTextStyles}>
              {value.text}
            </span>
          </span>
          {value.hasSubheaders && (
            <span
              className="show-subheaders"
              onClick={(e) => {
                e.preventDefault();
                const openIndexes = [...openHeaderIndexes];
                const pos = openIndexes.indexOf(currentIndex);
                console.log(`Toggling header with index ${currentIndex}`);

                if (pos !== -1) {
                  openIndexes.splice(pos, 1);
                } else {
                  openIndexes.push(currentIndex);
                }

                setOpenHeaderIndexes(openIndexes);
              }}
            >
              <FiChevronDown />
            </span>
          )}
        </a>,
      );

      if (postIndexArray[i].hasSubheaders) {
        // START NEW BLOCK FOR SUBHEADINGS
        const currentHeaderIndex = postIndexArray[i].currentHeaderIndex;
        const subheaderElements: any = [];

        while (
          i + 1 < postIndexArray.length &&
          !postIndexArray[i + 1].isMainHeader &&
          postIndexArray[i + 1].currentHeaderIndex === currentHeaderIndex
        ) {
          i += 1;
          const subheaderValue = postIndexArray[i];
          const subheaderBlockStyles: any = {};
          subheaderBlockStyles.background = `rgba(20, 30, 50, 0.85)`;

          // THIS IS A SUBHEADER
          const subheaderClassNames = classnames("post-index-header", "subheader", subheaderValue.type, {
            "inline-warning": subheaderValue.inlineWarning,
          });

          subheaderElements.push(
            <a
              href={`#${subheaderValue.hashId}`}
              key={`${subheaderValue.hashId}-${i}`}
              className={subheaderClassNames}
              style={subheaderBlockStyles}
            >
              <span className="left-section">
                <span className="text">{subheaderValue.text}</span>
              </span>
            </a>,
          );
        }

        postIndexElements.push(
          <span
            key={`subheaders-${currentHeaderIndex}`}
            className={`subheader-block ${headerOpen ? "subheaders-open" : ""}`}
            style={{
              maxHeight: headerOpen ? `${4.55 * subheaderElements.length}em` : "0",
            }}
          >
            {subheaderElements}
          </span>,
        );
      }
    }

    return (
      <span className="post-index gpu">
        <span id="post-quick-jump" className="hash-anchor hash-anchor-160" />
        <span className="post-index-title">Quick Jump</span>
        {postIndexElements}
      </span>
    );
  }

  return (
    <div className="editor-special-block">
      <div className="index-block">INDEX SHOULD SHOW HERE</div>
    </div>
  );
  // }
};


/*
(OldPostIndex as any).propTypes = {
  key: PropTypes.string,
  overrideIndex: PropTypes.any,
};

(OldPostIndex as any).defaultProps = {
  overrideIndex: null,
};
*/
