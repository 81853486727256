import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useFela } from "react-fela";
import { TFelaRule, TFelaRuleInputs } from "../vibescout-ui/fela/types";
import { ObjectUtils } from "@gt/common-utils/build/datatypes/ObjectUtils";
import { IFelaTheme } from "../vibescout-ui/fela/FelaCommon";
import { combineRules, TRule } from "fela";

interface IFelaCache {
  themeHash: string;
  theme: any;
  cache: { [themeHash: string]: { [key: string]: string } };
  cachedClass: TCachedClassFunction;
  cachedKeyframe: TCachedClassFunction;
  inlineCss: (css: any[]) => string;
}

const felaCacheContext = createContext<Partial<IFelaCache>>({
  cache: {},
  themeHash: "__unset__",
});

const Provider = felaCacheContext.Provider;
const isDev = process.env.NODE_ENV !== "production";

export function combineRulesWithName(rules: TRule[], name: string): TRule {
  const combined: any = combineRules(...rules);
  Object.defineProperty(combined, "name", { writable: true });
  combined.name = name;
  return combined;
}

export const FelaCacheProvider: React.FC<{ enabled?: boolean; themeHash: string }> = ({
  children,
  themeHash,
  enabled = true,
}) => {
  const { renderer, css, theme } = useFela();
  const cache = useRef<IFelaCache>();

  function makeKey(rule, props, key?: string | string[]): string {
    if (isDev) {
      if (rule.name === "anonymous" || rule.name === "") {
        console.error(`Fela Cache: incoming rule function has no name - can't create cache correctly.`);
      }
    }

    if (!key) {
      key = "_p_" + cache.current!.themeHash + ":" + rule.name + ":" + ObjectUtils.keyFromObject(props);
    } else if (typeof key === "string") {
      key = "_k_" + cache.current!.themeHash + ":" + rule.name + ":" + key;
    } else {
      key =
        "_a_" +
        cache.current!.themeHash +
        ":" +
        rule.name +
        ":" +
        key
          .filter((k) => props[k] != null)
          .map((k) => `${k}${ObjectUtils.keyFromObject(props[k])}`)
          .join("-");
    }

    return key;
  }

  function cachedClass(rule, props = {} as any, key) {
    if (enabled) {
      key = "_cc_" + makeKey(rule, props, key);
      // console.log(`cachedClass: made key ${key}`);

      if (cache.current!.cache![cache.current!.themeHash][key]) {
        return cache.current!.cache![cache.current!.themeHash][key];
      }

      const classes = renderer.renderRule(rule, { theme: cache.current!.theme, ...props });
      cache.current!.cache![cache.current!.themeHash][key] = classes;

      return classes;
    }

    return renderer.renderRule(rule, { theme: cache.current!.theme, ...props });
  }

  function cachedKeyframe(rule, props = {} as any, key) {
    if (enabled) {
      key = "_kf_" + makeKey(rule, props, key);

      if (cache.current!.cache![cache.current!.themeHash][key]) {
        return cache.current!.cache![cache.current!.themeHash][key];
      }

      const keyframeKey = renderer.renderKeyframe(rule, { theme: cache.current!.theme, ...props });
      cache.current!.cache![cache.current!.themeHash][key] = keyframeKey;

      return keyframeKey;
    }

    return renderer.renderKeyframe(rule, { theme: cache.current!.theme, ...props });
  }

  function inlineCss(cssRules: any[]) {
    if (cssRules.length > 0) {
      return css(...cssRules);
    }
    return "";
  }

  if (cache.current === undefined || cache.current.themeHash !== themeHash) {
    if (cache.current != null) {
      cache.current.themeHash = themeHash;
      cache.current.theme = theme;

      if (cache.current.cache[themeHash] == null) {
        cache.current.cache[themeHash] = {};
      }
    } else {
      cache.current = {
        cache: { [themeHash]: {} },
        cachedClass,
        cachedKeyframe,
        inlineCss,
        themeHash,
        theme,
      };
    }
  }

  return <Provider value={cache.current}>{children}</Provider>;
};

type TCachedClassFunction = <T>(rule: TFelaRule<T>, props?: T, key?: string | string[]) => string;

interface IOUseFelaCacheOutput {
  cachedClass: TCachedClassFunction;
  cachedKeyframe: TCachedClassFunction;
  inlineCss: (css: any[]) => string;
  theme: IFelaTheme;
}

export function useFelaCache(): IOUseFelaCacheOutput {
  const { theme } = useFela<IFelaTheme>();
  const cacheContext: IFelaCache = useContext(felaCacheContext) as IFelaCache;

  return {
    cachedClass: cacheContext.cachedClass,
    cachedKeyframe: cacheContext.cachedKeyframe,
    inlineCss: cacheContext.inlineCss,
    theme,
  };
}
