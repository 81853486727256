import { EGeoLevelShortLevel } from "../../data/crossover/geoLevels/GeoLevelTypes";

export enum EGeoLevelSortDirection {
  desc = "desc",
  asc = "asc",
}

export interface IOConvertLevelToUrl {
  geoLvl: string;
}

export interface IOCreateShortGeoLevelInput {
  shortLvl: EGeoLevelShortLevel;
  cn?: string;
  cc?: string;
  st?: string;
  mu?: string;
  ci?: string;
  su?: string;
  ss?: string;
}
