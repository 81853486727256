import { TUseResponse } from "pullstate";
import React, { FC } from "react";
import { SectionCoverModalNoChildren } from "./SectionCoverModalNoChildren";
import { EModalFeedbackState, TModalOnCloseFunction } from "./SimpleModalComponent";
import { ITaskFunctionResponse } from "@gt/common-utils/build/taskFunction/TaskFunctionTypes";

interface ICPQuickAsyncResultBase {
  title?: string;
  show?: boolean;
  showFinishedAlert?: boolean;
  ignoreLoading?: boolean;
  ignoreFeedback?: boolean;
  ignoreUpdating?: boolean;
  tfr?: boolean;
  loadingDelay?: number;
  onClose?: TModalOnCloseFunction;
  position?: "fixed" | "absolute";
}

interface ICPQuickUseAsyncResult extends ICPQuickAsyncResultBase {
  state: Omit<TUseResponse<any, any>, "execute">;
}

export const QuickUseAsyncResultModal: FC<ICPQuickUseAsyncResult> = ({
  state,
  show = true,
  showFinishedAlert = false,
  ignoreLoading = false,
  ignoreFeedback = false,
  ignoreUpdating = false,
  loadingDelay = 0,
  title = "",
  onClose,
  tfr = false,
  position,
}) => {
  if (show) {
    return (
      <SectionCoverModalNoChildren
        position={position}
        onClose={onClose}
        loadingDelay={loadingDelay}
        getStateAndMessage={() => {
          if (state.isUpdating) {
            if (!ignoreUpdating) {
              return [EModalFeedbackState.LOADING, title];
            } else {
              return [EModalFeedbackState.NONE, title];
            }
          }

          if (state.isLoading) {
            if (!ignoreLoading) {
              return [EModalFeedbackState.LOADING, title];
            } else {
              return [EModalFeedbackState.NONE, title];
            }
          }

          if (ignoreFeedback) {
            return [EModalFeedbackState.NONE, title];
          }

          if (state.isFinished && state.error) {
            return [EModalFeedbackState.ERROR, state.message, title.length > 0 ? title : undefined];
          }

          if (state.isFinished && !state.error && tfr && !(state.payload as ITaskFunctionResponse).positive) {
            return [
              EModalFeedbackState.ERROR,
              (state.payload as ITaskFunctionResponse).endMessage ??
                `${(state.payload as ITaskFunctionResponse).endId}: An unknown error occurred`,
              title.length > 0 ? title : undefined,
            ];
          }

          if (showFinishedAlert && state.isFinished) {
            return [
              EModalFeedbackState.ALERT,
              `${(state.message?.length ?? 0) > 0 ? state.message : "Completed"}`,
              title.length > 0 ? title : undefined,
            ];
          }
        }}
      />
    );
  }

  return <></>;
};
/*

function isObj<
  F extends IBaseObjResponse<any, any>,
  S extends TPullstateAsyncWatchResponse | TPullstateAsyncBeckonResponse
>(state: F | S): state is F {
  return state.hasOwnProperty("isStarted");
}

interface ICPQuickAsyncWatchResultModal extends ICPQuickAsyncResultBase {
  state: TPullstateAsyncWatchResponse | TUseWatchObjResponse<any, any>;
}

type TPickedWatchResponse = Pick<
  TUseWatchObjResponse<any, any>,
  "isStarted" | "isFinished" | "isLoading" | "error" | "message"
>;

export const QuickAsyncWatchResultModal: FC<ICPQuickAsyncWatchResultModal> = ({
  state,
  show = true,
  showFinishedAlert = false,
  ignoreLoading = false,
  title = "",
  onClose,
}) => {
  if (show) {
    const st: TPickedWatchResponse = isObj<TUseWatchObjResponse<any, any>, TPullstateAsyncWatchResponse>(state)
      ? state
      : {
          isStarted: state[0],
          isFinished: state[1],
          isLoading: (state[0] && !state[1]) || state[3],
          error: state[2].error,
          message: state[2].message,
        };

    return (
      <SectionCoverModalNoChildren
        onClose={onClose}
        getStateAndMessage={() => {
          if (st.isLoading) {
            if (!ignoreLoading) {
              return [EModalFeedbackState.LOADING, title];
            } else {
              return [EModalFeedbackState.NONE, title];
            }
          }

          if (st.isFinished && st.error) {
            return [EModalFeedbackState.ERROR, `${title} - ${st.message}`];
          }

          if (showFinishedAlert && st.isFinished) {
            return [EModalFeedbackState.ALERT, `${title} - Completed`];
          }
        }}
      />
    );
  }

  return <></>;
};

interface ICPQuickAsyncBeckonResultModal extends ICPQuickAsyncResultBase {
  state: TPullstateAsyncBeckonResponse | TUseBeckonObjResponse<any, any>;
}

type TPickedBeckonResponse = Pick<
  TUseBeckonObjResponse<any, any>,
  "isStarted" | "isFinished" | "isLoading" | "error" | "message"
>;

export const QuickAsyncBeckonResultModal: FC<ICPQuickAsyncBeckonResultModal> = ({
  state,
  show = true,
  ignoreLoading = false,
  title = "",
  onClose,
}) => {
  if (show) {
    const st: TPickedBeckonResponse = isObj<TUseBeckonObjResponse<any, any>, TPullstateAsyncBeckonResponse>(state)
      ? state
      : {
          isStarted: true,
          isFinished: state[0],
          isLoading: !state[0] || state[2],
          error: state[1].error,
          message: state[1].message,
        };
    /!*(state as TUseBeckonObjResponse<any, any>).isStarted != null
        ? (state as TUseBeckonObjResponse<any, any>)
        : {
            error: (state as TPullstateAsyncWatchResponse)[2].error,
            message: (state as TPullstateAsyncWatchResponse)[2].message,
            isFinished: (state as TPullstateAsyncWatchResponse)[1],
            isStarted: (state as TPullstateAsyncWatchResponse)[0],
          };
    const [finished, result] = state;
*!/
    return (
      <SectionCoverModalNoChildren
        onClose={onClose}
        getStateAndMessage={() => {
          if (st.isLoading) {
            if (!ignoreLoading) {
              return [EModalFeedbackState.LOADING, title];
            } else {
              return [EModalFeedbackState.NONE, title];
            }
          }

          if (st.isFinished && st.error) {
            return [EModalFeedbackState.ERROR, `${title} - ${st.message}`];
          }
        }}
      />
    );
  }

  return <></>;
};
*/
