import { ZIndexes } from "@gt/gt-frontend/build/styling/VibescoutZIndexes";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { SvgPathVibescoutVLetter } from "@vs/core/build/frontend/graphics/svg-components/VibescoutBrandingSvg";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { EGraphicContrast, IFelaTheme } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { EShareProvider, ShareButton } from "@gt/gt-frontend/build/react/vibescout-ui/components/ShareButton";
import { EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { FiMoon, FiSun } from "react-icons/fi";
import React, { PropsWithChildren } from "react";
import { useFela } from "react-fela";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import { AsyncNavLink } from "../../state/ASN_VibescoutSpecial";
import {
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { GeoLevelSearchBar } from "../../sections/landing/components/GeoLevelSearchBar";
import { HeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import Helmet from "react-helmet";

interface ICPVibescoutHeader {
  forceTitle?: string;
  noSharing?: boolean;
  show?: boolean;
  forceMeta?: {
    ogImage?: { url: string; width: string; height: string };
  };
  children?: any;
}

export const VibescoutHeader = React.memo<ICPVibescoutHeader>(
  ({ forceTitle, forceMeta, show = true, noSharing, children }) => {
    const { theme } = useFela<IFelaTheme>();
    const { UIStore } = PSC_VibescoutSpecial.useStores();

    const [udi, isSmall, { metaTitle, title, shareUrl, metaDescription, ogImage }] = UIStore.useState(
      (s) => [s.udi, s.isSmall, s.pageMeta] as const,
    );

    return (
      <FelaFlexbox
        style={!show ? { display: "none" } : undefined}
        zIndex={ZIndexes.topBar}
        direction={"column"}
        position={"sticky"}
        top={0}
      >
        <Helmet>
          <title>{forceTitle ?? metaTitle}</title>
          <meta name={"description"} content={metaDescription} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:site_name" content={"Vibescout"} />
          <meta property="og:url" content={shareUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={metaDescription} />
          <meta
            property="og:image"
            content={
              forceMeta?.ogImage?.url ??
              ogImage?.url ??
              "https://www.vibescout.com/_static/vs-special/images/new_title_landing_page_compressed.jpg"
            }
          />
          <meta property="og:image:width" content={forceMeta?.ogImage?.width ?? ogImage?.width ?? "1200"} />
          <meta property="og:image:height" content={forceMeta?.ogImage?.height ?? ogImage?.height ?? "630"} />
        </Helmet>
        {children}
        {show && (
          <FelaFlexLayer
            zIndex={ZIndexes.topBar - 1}
            direction={"row"}
            align={"stretch"}
            // extraSoftShadow
            shadow
            height={ESurfaceHeight.highest}
            position={"relative"}
          >
            <FelaFlexbox align={"stretch"}>
              <AsyncNavLink to={"/"} style={{ lineHeight: 0 }}>
                <FelaFlexbox style={{ width: "3.5em", minHeight: "4em" }} overflow={"hidden"} position={"relative"}>
                  <svg
                    viewBox={"0 0 400 400"}
                    style={{ position: "absolute", right: 0, top: "50%", transform: "translateY(-55%)" }}
                    width="6.5em"
                    height="8em"
                    xmlns="http://www.w3.org/2000/svg"
                    baseProfile="full"
                  >
                    <circle
                      cx={0}
                      cy={100}
                      r={"400"}
                      fill={theme.styles.Themes[theme.currentId].RgbColors.surface.upper}
                    />
                    <g transform={"translate(200,129) scale(1.2)"}>
                      <SvgPathVibescoutVLetter fill={theme.styles.RgbColors.neutral.primary} />
                    </g>
                  </svg>
                </FelaFlexbox>
              </AsyncNavLink>
            </FelaFlexbox>
            <FelaFlexbox direction={isSmall ? "column" : "row"} align={"center"} justify={"center"} grow={1}>
              <FelaFlexbox padding={0.25} margin={0.25} align={"center"}>
                <FelaFlexbox direction={"column"}>
                  <FelaText margin={"0 0 0 1em"} size={ETextSize.small}>
                    Search Places
                  </FelaText>
                  <GeoLevelSearchBar size={ETextSize.content} />
                </FelaFlexbox>
              </FelaFlexbox>
              {!isSmall && (
                <FelaFlexbox padding={"0.25em 0.5em"} grow={1} justify={"center"}>
                  <h1 style={{ display: "flex", flexDirection: "column" }}>
                    <FelaText
                      tag={"span"}
                      weight={"500"}
                      size={isSmall ? ETextSize.small : ETextSize.smallHeader}
                      contrast={EGraphicContrast.HIGH}
                    >
                      {forceTitle ?? title}&nbsp;
                    </FelaText>
                  </h1>
                </FelaFlexbox>
              )}
            </FelaFlexbox>
            {/*<FelaFlexbox grow={1} />*/}
            <FelaFlexbox direction={isSmall ? "column" : "row"} align={"center"} padding={0.5} justify={"flex-end"}>
              {!noSharing && (
                <ShareButton
                  providers={udi === EUDI.MOUSE ? [EShareProvider.FACEBOOK, EShareProvider.TWITTER] : undefined}
                  tippyProps={{
                    delay: isSmall ? 100 : 0,
                  }}
                  iconProps={{
                    size: ETextSize.smallHeader,
                    isButton: true,
                    height: ESurfaceHeight.highest,
                    shadow: true,
                    extraSoftShadow: true,
                  }}
                  title={title}
                  url={shareUrl}
                />
              )}
              <FelaIconWrap
                size={ETextSize.smallHeader}
                onClick={() => {
                  UIStore.update((s, o) => {
                    s.theme.dark = !o.theme.dark;
                    s.theme.currentId = o.theme.dark
                      ? EFrontendStyleConfigBaseTheme.LIGHT
                      : EFrontendStyleConfigBaseTheme.DARK;
                  });
                }}
                margin={isSmall ? "0.5em 0 0 0" : "0 0 0 0.5em"}
                isButton
                height={ESurfaceHeight.highest}
                shadow
                extraSoftShadow
              >
                {theme.dark ? <FiSun /> : <FiMoon />}
              </FelaIconWrap>
            </FelaFlexbox>
          </FelaFlexLayer>
        )}
        {isSmall && (
          <FelaFlexLayer
            shadow
            extraSoftShadow
            height={ESurfaceHeight.mid}
            zIndex={ZIndexes.topBar - 2}
            padding={"0.35em 0.5em"}
            grow={1}
          >
            <h1 style={{ display: "flex", flexDirection: "column" }}>
              <HeaderText
                tag={"span"}
                weight={"500"}
                size={isSmall ? ETextSize.small : ETextSize.smallHeader}
                contrast={EForeContrast.extra}
              >
                {forceTitle ?? title}&nbsp;
              </HeaderText>
            </h1>
          </FelaFlexLayer>
        )}
      </FelaFlexbox>
    );
  },
);
