import React from "react";
import { AsyncActionImageLoad } from "../../../pullstate/client-async-actions/AsyncActionImageLoad";
import { useSingleRun } from "../../hooks/useSingleRun";
import { FelaFlexbox } from "../fela/FelaFlexbox";
import { TFelaFlexLayerProps } from "../fela/FelaLayers";

interface ICPSimpleImage {
  src: string;
  alt?: string;
  /* width / height */
  fadeSpeed?: string;
  aspect?: number;
  maxOpacity?: number;
  onImageLoad?: () => void;
  outerProps?: TFelaFlexLayerProps;
  imageFit?: "cover" | "contain";
  objectPosition?: string;
  imgStyle?: any;
  absolute?: boolean;
}

export const SimpleImage: React.FC<
  ICPSimpleImage & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = React.memo(
  ({
    alt,
    children,
    src,
    aspect,
    style,
    maxOpacity = 1,
    fadeSpeed = "0.2s",
    onImageLoad,
    imageFit,
    objectPosition,
    outerProps,
    imgStyle,
    absolute = false,
    ...imgProps
  }) => {
    const imageLoad = AsyncActionImageLoad.use({ src }, { ssr: false });
    const runOnceImageLoaded = useSingleRun();

    imageLoad.renderPayload(() => {
      runOnceImageLoaded(onImageLoad);
    });

    if (absolute) {
      return (
        <>
          <img
            style={{
              position: "absolute",
              objectFit: imageFit ?? "cover",
              height: "100%",
              width: "100%",
              objectPosition: objectPosition ?? "top",
              opacity: !imageLoad.error ? maxOpacity : 0,
              transition: `opacity ${fadeSpeed} ease-in-out`,
              ...style,
              ...imgStyle,
            }}
            src={src}
            alt={alt}
            {...imgProps}
          />
          {children}
        </>
      );
    }

    if (aspect) {
      const { style, ...rest } = outerProps ?? {};

      return (
        <FelaFlexbox
          align={"flex-start"}
          position={"relative"}
          style={{ paddingBottom: `${100 / aspect}%`, width: "100%", overflow: "hidden", ...style }}
          {...rest}
        >
          <img
            style={{
              position: "absolute",
              objectFit: imageFit ?? "cover",
              height: "100%",
              width: "100%",
              objectPosition: objectPosition ?? "top",
              opacity: !imageLoad.error ? maxOpacity : 0,
              transition: `opacity ${fadeSpeed} ease-in-out`,
              ...style,
              ...imgStyle,
            }}
            src={src}
            alt={alt}
            {...imgProps}
          />
          {children}
        </FelaFlexbox>
      );
    }

    return (
      <FelaFlexbox align={"flex-start"} {...outerProps}>
        <img
          style={{
            objectFit: imageFit,
            height: "100%",
            width: "100%",
            objectPosition: objectPosition ?? "top",
            opacity: !imageLoad.error ? maxOpacity : 0,
            transition: `opacity ${fadeSpeed} ease-in-out`,
            ...style,
            ...imgStyle,
          }}
          src={src}
          alt={alt}
          {...imgProps}
        />
        {children}
      </FelaFlexbox>
    );
  },
);
