import {
  EGeoLevelLevelType,
  EGeoLevelShortLevel,
  ENodeSubTypeGeoLevel,
} from "../../../data/crossover/geoLevels/GeoLevelTypes";
import { GeoLevelStatic } from "../../../data/crossover/geoLevels/GeoLevelStatic";
import { TGeoLevelBasics } from "../../../data/models/geolevels/GeoLevelModelTypes";
import { GeoLevelCompressUtils } from "./GeoLevelCompressUtils";

interface IBasicGeoLevelInformation {
  lvlValue: number;
  name: string;
  type: EGeoLevelLevelType;
  id: string;
  short: string;
}

function getBasicGeoLevelInformationFromIds(geoLevel: TGeoLevelBasics): { [id: string]: IBasicGeoLevelInformation } {
  const resp: { [id: string]: IBasicGeoLevelInformation } = {};

  if (geoLevel.ccId) {
    resp[geoLevel.ccId] = {
      short: geoLevel.cc!,
      id: geoLevel.ccId,
      lvlValue: GeoLevelStatic.valuesForGeoLvlType.country.lvlValue,
      name: geoLevel.ccName!,
      type: EGeoLevelLevelType.country,
    };
  }

  if (geoLevel.stId) {
    resp[geoLevel.stId] = {
      short: geoLevel.st!,
      id: geoLevel.stId,
      type: EGeoLevelLevelType.province,
      name: geoLevel.stName!,
      lvlValue: GeoLevelStatic.valuesForGeoLvlType.province.lvlValue,
    };
  }

  let muIndex = -1;
  for (const mu of geoLevel.muId) {
    muIndex += 1;

    resp[mu] = {
      short: geoLevel.mu!,
      id: mu,
      type: geoLevel.muTypes[muIndex],
      name: geoLevel.muNames[muIndex],
      lvlValue: GeoLevelStatic.valuesForGeoLvlType[geoLevel.muTypes[muIndex]].lvlValue,
    };
  }

  if (geoLevel.ciId) {
    resp[geoLevel.ciId] = {
      short: geoLevel.ci!,
      id: geoLevel.ciId,
      type: geoLevel.ciType!,
      name: geoLevel.ciName!,
      lvlValue: GeoLevelStatic.valuesForGeoLvlType[geoLevel.ciType!].lvlValue,
    };
  }

  if (geoLevel.suId) {
    resp[geoLevel.suId] = {
      short: geoLevel.su!,
      id: geoLevel.suId,
      type: geoLevel.suType!,
      name: geoLevel.suName!,
      lvlValue: GeoLevelStatic.valuesForGeoLvlType[geoLevel.suType!].lvlValue,
    };
  }

  if (geoLevel.ssId) {
    resp[geoLevel.ssId] = {
      short: geoLevel.ss!,
      id: geoLevel.ssId,
      type: geoLevel.ssType!,
      name: geoLevel.ssName!,
      lvlValue: GeoLevelStatic.valuesForGeoLvlType[geoLevel.ssType!].lvlValue,
    };
  }

  return resp;
}

interface IGeoLevelBreadcrumbInfo {
  lvlValue: number;
  lvlType: EGeoLevelLevelType;
  nSubType: ENodeSubTypeGeoLevel;
  shortLvl: string;
  allLvlParts: string[];
  id: string;
  allIds: string[];
  name: string;
}

function createBreadcrumbInfo(geoLevel: TGeoLevelBasics): IGeoLevelBreadcrumbInfo[] {
  const breadcrumbs: IGeoLevelBreadcrumbInfo[] = [];

  if (geoLevel.cc) {
    breadcrumbs.push({
      allIds: [geoLevel.ccId!],
      allLvlParts: [geoLevel.cc!],
      lvlType: EGeoLevelLevelType.country,
      id: geoLevel.ccId!,
      lvlValue: GeoLevelStatic.valuesForGeoLvlType["country"].lvlValue,
      name: geoLevel.ccName!,
      nSubType: ENodeSubTypeGeoLevel.gl_cc,
      shortLvl: geoLevel.cc!,
    });
  }

  if (geoLevel.st) {
    breadcrumbs.push({
      allIds: [geoLevel.ccId!, geoLevel.stId!],
      allLvlParts: [geoLevel.cc!, geoLevel.st!],
      lvlType: EGeoLevelLevelType.province,
      id: geoLevel.stId!,
      lvlValue: GeoLevelStatic.valuesForGeoLvlType["province"].lvlValue,
      name: geoLevel.stName!,
      nSubType: ENodeSubTypeGeoLevel.gl_st,
      shortLvl: GeoLevelCompressUtils.createCompressedShortLevel({
        cc: geoLevel.cc!,
        st: geoLevel.st!,
        shortLvl: EGeoLevelShortLevel.inapplicable,
      }),
    });
  }

  if (geoLevel.muId.length > 0) {
    const type = geoLevel.muTypes[geoLevel.muTypes.length - 1];

    breadcrumbs.push({
      allIds: [geoLevel.ccId!, geoLevel.stId!, ...geoLevel.muId],
      allLvlParts: [geoLevel.cc!, geoLevel.st!, geoLevel.mu!],
      lvlType: type,
      id: geoLevel.muId[geoLevel.muId.length - 1]!,
      lvlValue: GeoLevelStatic.valuesForGeoLvlType[type].lvlValue,
      name: geoLevel.muNames[geoLevel.muNames.length - 1]!,
      nSubType: ENodeSubTypeGeoLevel.gl_mu,
      shortLvl: GeoLevelCompressUtils.createCompressedShortLevel({
        cc: geoLevel.cc!,
        st: geoLevel.st!,
        mu: geoLevel.mu!,
        shortLvl: EGeoLevelShortLevel.inapplicable,
      }),
    });
  }

  if (geoLevel.ci) {
    breadcrumbs.push({
      allIds: [geoLevel.ccId!, geoLevel.stId!, ...geoLevel.muId, geoLevel.ciId!],
      allLvlParts: [geoLevel.cc!, geoLevel.st!, geoLevel.mu!, geoLevel.ci!],
      lvlType: geoLevel.ciType!,
      id: geoLevel.ciId!,
      lvlValue: GeoLevelStatic.valuesForGeoLvlType[geoLevel.ciType!].lvlValue,
      name: geoLevel.ciName!,
      nSubType: ENodeSubTypeGeoLevel.gl_ci,
      shortLvl: geoLevel.ciShort!,
    });
  }

  return breadcrumbs;
}

interface IOGetGeoLevelPartsInput {
  geoLevel: TGeoLevelBasics;
  includeContinent?: boolean;
}

function getGeoLevelParts({ geoLevel, includeContinent = false }: IOGetGeoLevelPartsInput): string[] {
  const inputParts: (string | undefined)[] = [
    includeContinent ? geoLevel.cn : undefined,
    geoLevel.cc,
    geoLevel.st,
    geoLevel.mu,
    geoLevel.ci,
    geoLevel.su,
    geoLevel.ss,
  ];
  return inputParts.filter((part) => part != null) as string[];
}

export const GeoLevelCommonUtils = {
  getBasicGeoLevelInformationFromIds,
  createBreadcrumbInfo,
  getGeoLevelParts,
};
