import React, { useEffect } from "react";
import { ASN_VibescoutSpecial } from "../../state/ASN_VibescoutSpecial";
import { ZIndexes } from "@gt/gt-frontend/build/styling/VibescoutZIndexes";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { VibescoutLoadingModal } from "@vs/core/build/frontend/components/ui/VibescoutLoadingModal";
import { useDelayedValue } from "@gt/gt-frontend/build/react/hooks/useDelayedValue";
import { SectionCoverModalNoChildren } from "@gt/gt-frontend/build/react/vibescout-ui/components/SectionCoverModalNoChildren";
import { EModalFeedbackState } from "@gt/gt-frontend/build/react/vibescout-ui/components/SimpleModalComponent";

export const AsyncNavigationFeedback = React.memo(function AsyncNavigationFeedback() {
  /*const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ITaskFunctionNegativeResponse<any>[]>([]);

  useEffect(() => {
    const effectState: any = {
      ord: -1,
      shouldUpdate: true,
      timeout: null,
    };
    // let timeout: any;
    // let ord = -1;
    const unregister = ASN_VibescoutSpecial.listen((nav) => {
      if (nav.pos === EAsyncNavigatorTimePosition.START) {
        effectState.ord = nav.ord;
        clearTimeout(effectState.timeout);
        effectState.timeout = setTimeout(() => {
          if (effectState.shouldUpdate) {
            setIsLoading(true);
          }
        }, 100);
      } else if (nav.pos === EAsyncNavigatorTimePosition.END && nav.ord === effectState.ord) {
        clearTimeout(effectState.timeout);
        if (effectState.shouldUpdate) {
          setIsLoading(false);

          if (nav.ctx.errors.length > 0) {
            setErrors(nav.ctx.errors.map((e) => e.error.taskFunctionResponse));
          }
        }
      }
    });

    return () => {
      effectState.shouldUpdate = false;
      clearTimeout(effectState.timeout);
      unregister();
    };
  }, []);*/
  const { errors, isLoading, clearErrors } = ASN_VibescoutSpecial.useAsyncNavigatorState();

  useEffect(() => {
    console.log(`Async Navigation Feedback MOUNTED`);

    return () => {
      console.log(`Async Navigation Feedback unmounting...`);
    };
  }, []);

  const isCompletelyFinished = useDelayedValue(!isLoading, 250);

  return (
    <React.Fragment key={"nav-feedback"}>
      {/*<FullAbsFlexLayer
        style={{ opacity: isLoading ? 1 : 0, transition: "opacity 0.25s ease-in-out" }}
        key={"backdrop"}
        height={ESurfaceHeight.deepest}
        zIndex={ZIndexes.mainContent - 1}
        position={"fixed"}
      />*/}
      <FullAbsFlexbox
        key={"modal"}
        position={"fixed"}
        style={{
          backdropFilter: "blur(3px) brightness(50%)",
          transition: "opacity 0.25s ease-in-out",
          opacity: isLoading ? 1 : 0,
        }}
        zIndex={ZIndexes.modalAboveAll}
        align={"center"}
        justify={"center"}
        pointerEvents={false}
      >
        {(isLoading || !isCompletelyFinished) && <VibescoutLoadingModal />}
      </FullAbsFlexbox>
      {errors.length > 0 && (
        <SectionCoverModalNoChildren
          key={"error"}
          position={"fixed"}
          lowZIndex={ZIndexes.modalAboveAll - 1}
          highZIndex={ZIndexes.modalAboveAll}
          onClose={() => {
            clearErrors();
          }}
          getStateAndMessage={() => {
            return [EModalFeedbackState.ERROR, `${errors.map((e) => e.endMessage).join(", ")}`];
          }}
        />
      )}
    </React.Fragment>
  );
});
