import React from "react";
import { FelaText, TFelaTextProps } from "../fela/FelaText";
import { EGraphicContrast } from "../fela/FelaCommon";
import { EForeContrast, ETextSize, ETextTypographyType, ETextWeightType } from "../config/VibescoutFrontendStyleConfig";

export const HeaderText: React.FC<TFelaTextProps> = ({ children, ...props }) => {
  return (
    <FelaText
      tag={"h2"}
      weight={"bold"}
      size={ETextSize.content}
      spacing={"0.05em"}
      contrast={EForeContrast.strong}
      transform={"uppercase"}
      typography={ETextTypographyType.mono}
      {...props}
    >
      {children}
    </FelaText>
  );
};

export const SubHeaderText: React.FC<TFelaTextProps> = ({ children, ...props }) => {
  return (
    <FelaText
      tag={"h3"}
      weight={ETextWeightType.regular}
      size={ETextSize.small}
      spacing={"0.015em"}
      contrast={EGraphicContrast.MID}
      transform={"uppercase"}
      typography={ETextTypographyType.mono}
      {...props}
    >
      {children}
    </FelaText>
  );
};

export const UnderText: React.FC<TFelaTextProps> = ({ children, ...props }) => {
  return (
    <FelaText
      lineHeight={1.35}
      tag={"p"}
      weight={500}
      size={ETextSize.small}
      contrast={EGraphicContrast.MID}
      typography={ETextTypographyType.sansSerif}
      {...props}
    >
      {children}
    </FelaText>
  );
};
