import { Store } from "pullstate";

export interface IOldPostPageStore {
  showQuickJump: boolean;
  body: any;
  postIndexArray: any;
}

export const OldPostPageStore = new Store<IOldPostPageStore>({
  showQuickJump: true,
  body: null,
  postIndexArray: null,
});
