import { default as React, FunctionComponent } from "react";
import { Property } from "csstype";
import { contrastToFore, EGraphicContrast, IFelaComponentBaseProps } from "./FelaCommon";
import { TFelaRule } from "./types";
import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ETextSize,
  ETextTypographyType,
  ETextWeightType,
} from "../config/VibescoutFrontendStyleConfig";
import { TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { useFelaCache } from "../../fela/FelaCache";

export enum ETextTag {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  p = "p",
  label = "label",
  div = "div",
  span = "span",
}

export interface IFelaRuleTextBasics {
  typography?: ETextTypographyType;
  transform?: Property.TextTransform;
  weight?: Property.FontWeight | number | ETextWeightType;
  spacing?: Property.LetterSpacing<any>;
  fontSize?: string | number;
  size?: ETextSize;
}

export const weightToTextWeightType: { [key in ETextWeightType]: ETextWeightType } & {
  [weight: number]: ETextWeightType;
} & { bold: ETextWeightType; normal: ETextWeightType } = {
  black: ETextWeightType.black,
  bold: ETextWeightType.bold,
  light: ETextWeightType.light,
  regular: ETextWeightType.regular,
  normal: ETextWeightType.regular,
  500: ETextWeightType.regular,
  300: ETextWeightType.light,
  700: ETextWeightType.bold,
  900: ETextWeightType.black,
};

export const heavierWeightFrom: { [key in ETextWeightType]: ETextWeightType } = {
  [ETextWeightType.light]: ETextWeightType.regular,
  [ETextWeightType.regular]: ETextWeightType.bold,
  [ETextWeightType.bold]: ETextWeightType.black,
  [ETextWeightType.black]: ETextWeightType.black,
};

export interface IFelaRuleTextProps extends IFelaRuleTextBasics {
  tag?: ETextTag | string;
  wordBreak?: Property.WordBreak;
  opacity?: number;
  block?: boolean;
  color?: TColorRgbArray;
  neutralColor?: EAppColor;
  colorHex?: string;
  // style?: FontFaceFontStyleProperty;
  align?: Property.TextAlign;
  contrast?: EGraphicContrast | EForeContrast;
  lineHeight?: number;
  margin?: Property.Margin<any>;
  whiteSpace?: Property.WhiteSpace;
  decoration?: Property.TextDecoration;
  forceTheme?: EFrontendStyleConfigBaseTheme | string;
  overflowWrap?: Property.OverflowWrap;
}

export const FelaRuleText: TFelaRule<IFelaRuleTextProps> = ({
  neutralColor,
  color,
  colorHex,
  opacity,
  block,
  theme,
  typography = ETextTypographyType.sansSerif,
  transform,
  size,
  wordBreak,
  // style,
  weight,
  spacing,
  lineHeight,
  align,
  contrast = EGraphicContrast.MID,
  fontSize,
  margin = 0,
  whiteSpace = "pre-line",
  decoration,
  forceTheme,
  overflowWrap,
}): any => {
  const fontWeight: ETextWeightType = weightToTextWeightType[weight ?? ETextWeightType.regular];

  let colorProp: string;
  const foreContrast = contrastToFore[contrast];

  if (spacing === undefined) {
    spacing = typography === ETextTypographyType.inherit ? undefined : theme.styles.Font.defaultSpacing[typography];
  }

  if (lineHeight === undefined) {
    lineHeight =
      typography === ETextTypographyType.inherit ? undefined : theme.styles.Font.defaultLineHeight[typography];
  }

  if (neutralColor) {
    colorProp =
      opacity == null
        ? theme.styles.Themes[forceTheme ?? theme.currentId].RgbColors.neutral[neutralColor]
        : `rgba(${theme.styles.Themes[forceTheme ?? theme.currentId].Colors.neutral[neutralColor][0]}, ${opacity}})`;
  } else if (color !== undefined) {
    colorProp = opacity == null ? `rgb(${color[0]})` : `rgba(${color[0]}, ${opacity})`;
  } else {
    if (colorHex !== undefined) {
      colorProp = colorHex;
    } else {
      // default high-contrast
      colorProp =
        opacity == null
          ? theme.styles.Themes[forceTheme ?? theme.currentId].RgbColors.fore[foreContrast]
          : `rgba(${theme.styles.Themes[forceTheme ?? theme.currentId].Colors.fore[foreContrast][0]}, ${opacity})`;
      /*colorProp = theme.dark ? theme.styles.RgbColors.darkFore.lig : theme.styles.RgbColors.lightFore.dar;

      if (contrast === EGraphicContrast.MID) {
        colorProp = theme.dark ? theme.styles.RgbColors.darkFore.mid : theme.styles.RgbColors.lightFore.mid;
      } else if (contrast === EGraphicContrast.LOW) {
        colorProp = theme.dark ? theme.styles.RgbColors.darkFore.dar : theme.styles.RgbColors.lightFore.lig;
      }*/
    }
  }

  return {
    "& b": {
      fontWeight: theme.styles.Font.weights[typography][heavierWeightFrom[fontWeight]],
    },
    "& i": {
      fontStyle: theme.styles.Font.italicStyleType[typography],
    },
    "& code": {
      fontFamily: theme.styles.Font.family[ETextTypographyType.mono],
    },
    "& sub": {
      verticalAlign: "sub",
      fontSize: "smaller",
    },
    fontFamily: typography === ETextTypographyType.inherit ? "inherit" : theme.styles.Font.family[typography],
    fontSize: fontSize ? fontSize : size ? theme.styles.Font.sizes[size] : theme.styles.Font.sizes[ETextSize.content],
    color: colorProp,
    display: block ? "block" : "inline-block",
    textTransform: transform,
    // fontWeight: weight as FontFaceFontWeightProperty,
    fontWeight:
      typography === ETextTypographyType.inherit ? "inherit" : theme.styles.Font.weights[typography][fontWeight],
    // fontStyle: style,
    letterSpacing: spacing,
    textAlign: align,
    textDecoration: decoration,
    overflowWrap,
    lineHeight,
    margin,
    whiteSpace,
    wordBreak,
  };
};

export const ruleKeysFelaText: (keyof IFelaRuleTextProps)[] = [
  "neutralColor",
  "opacity",
  "color",
  "colorHex",
  "block",
  "typography",
  "transform",
  "size",
  "weight",
  "spacing",
  "lineHeight",
  "align",
  "contrast",
  "fontSize",
  "margin",
  "whiteSpace",
  "decoration",
  "forceTheme",
  "overflowWrap",
];

export type TFelaTextProps = IFelaRuleTextProps & IFelaComponentBaseProps;

export const FelaText: FunctionComponent<TFelaTextProps> = ({
  children,
  cssExtra = [],
  style,
  rootProps,
  id,
  onClick,
  ...props
}) => {
  // const { css } = useFela(props);
  const { inlineCss, cachedClass } = useFelaCache();
  const { tag = ETextTag.p } = props;

  return React.createElement(
    tag,
    {
      style,
      id,
      onClick,
      className: /*`text ${css(FelaRuleText, ...cssExtra)}`*/ `text ${cachedClass(
        FelaRuleText,
        props,
        ruleKeysFelaText,
      )} ${inlineCss(cssExtra)}`,
      ...rootProps,
    },
    children,
  );
};
