export enum ECovidDataMetaIds {
  covid_page_updated = "covid_page_updated",
}

export enum ECovidMetaDateUpdatedShow {
  DESCRIPTION_META = "DESCRIPTION_META",
  SCHEMA_DESCRIPTION = "SCHEMA_DESCRIPTION",
  ON_PAGE = "ON_PAGE",
}

export interface IDMCovidDataMeta {
  id: string;
  fullReportUrl: string;
  dateUpdatedFormat: string;
  dateUpdatedShow: ECovidMetaDateUpdatedShow[];
  dateUpdated: Date;
  dateCreated: Date;
  metaDescription: string;
  metaTitle: string;
  schemaOrgLogoUrl: string;
  schemaOrgLogoWidth: number;
  schemaOrgLogoHeight: number;
  titleImage1x1: string;
  titleImage4x3: string;
  titleImage16x9: string;
  titleImageUrl: string;
  titleImageWidth: number;
  titleImageHeight: number;
}
