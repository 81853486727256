import React from "react";

export const SvgPathVibescoutVLetter = (props) => {
  return (
    <path
      {...props}
      d="M91.4 38.2c-.6 5.8-1.9 12.2-3.8 19.5-1.9 7.2-3.9 14.5-6.2 21.8-2.2 7.3-4.3 14.2-6.2 20.7-2 6.6-3.4 12-4.2 16.2-.2 1.2-.5 3-.8 5.2-.3 2.3-.3 4.4.1 6.3.4 2 1.2 3.3 2.5 4.1 1.3.8 3.4.3 6.3-1.4 2.4-1.5 5.1-4.7 8.1-9.6 2.9-5 5.9-10.9 8.9-17.9 3-7 5.9-14.6 8.7-23 2.8-8.3 5.3-16.7 7.4-25.2 2.1-8.4 3.9-16.6 5.2-24.3 1.3-7.8 2-14.5 2-20.3 0-1.8.8-3.1 2.3-3.9 1.5-.8 3.4-1.1 5.6-1 2.2.1 4.5.7 7 1.7 2.4 1 4.7 2.3 6.7 3.9 2 1.7 3.6 3.6 4.7 5.9 1.1 2.3 1.4 4.7.9 7.3-2.2 7.8-4.5 15.3-7 22.3s-5 13.9-7.8 20.5-5.6 13.1-8.6 19.5-6.2 12.8-9.6 19.3c-6.5 12.6-13.5 22.1-20.9 28.6-7.5 6.4-14.2 10-20.2 10.7-2.7.1-5.3-.4-7.7-1.5-2.5-1.1-4.6-2.9-6.3-5.5-1.8-2.6-3.1-5.9-3.9-10-.9-4.1-1.1-9.2-.7-15.3.2-5.4 1.1-11.4 2.5-18.1 1.4-6.7 2.9-13.4 4.6-20.3 1.7-6.9 3.2-13.5 4.5-19.9 1.3-6.4 2.1-12.1 2.3-16.9.2-4.8-.4-8.6-1.7-11.2-1.3-2.6-3.9-3.6-7.7-3-2.3.4-4.8 1.5-7.5 3.3-2.7 1.8-5.4 4.1-8 6.8-2.6 2.7-5.1 5.7-7.5 9.1-2.4 3.4-4.5 6.7-6.2 10.1-1.8 3.4-3.1 6.7-4 9.9-.9 3.2-1.3 6.1-1 8.5.6 5-1.4 7.2-6.1 6.4-1.7-.2-3.5-.9-5.3-1.8-1.8-1-3.5-2.3-4.9-3.9-1.4-1.7-2.5-3.7-3.2-6.1-.7-2.4-.7-5.2 0-8.5 1.3-6 4.2-11.8 8.5-17.3s9.5-10.4 15.4-14.6C34.5 21.1 41 17.7 48 15.1c7-2.6 13.7-3.9 20.2-4 1.2 0 3.2.2 6 .5s5.5 1.3 8.3 3c2.8 1.7 5.1 4.4 7 8.1 1.5 3.6 2.2 8.8 1.9 15.5z"
    />
  );
};

export const SvgVibescoutFullLogo = () => (
  <svg viewBox="0 0 441.3 108.8">
    <path d="M66.9 25.1C64.7 45.8 55 64.9 51.3 85.3c-.5 2.9-2 8.7.7 11.1 3.1 2.7 7.4-1.8 9.2-4.2 6.2-8 9.9-18.4 13.4-27.8 3.8-10.2 6.9-20.6 9.4-31.1 1.3-5.6 2.5-11.3 3.3-17 .4-2.4.6-4.8.8-7.2.1-1.8-.4-5.3.6-6.9 2.2-3.4 7.9-1.9 10.9-.7 4.1 1.6 8.4 4.9 9.4 9.5s-2 10.8-3.5 15.1c-1.7 5.2-3.6 10.4-5.6 15.6-3.9 10.5-8.7 20.8-13.9 31-4.3 8.4-8.9 16.9-15.7 23.6-5.9 5.9-16.2 14-24.8 8.8-7.8-4.7-7.8-16.3-7.3-24.3.8-11.1 3.9-22 6.4-32.8 1.2-5.1 2.4-10.1 3.2-15.3.8-4.8 1.9-11.2-.1-15.9-4.1-9.5-16.6 2.6-20.1 6.6-3.5 4-6.5 8.5-8.9 13.2-2.5 5-3.1 9.2-3.5 14.5-.3 5.1-5.2 4.4-8.8 2.4C1.9 51.2-.6 46.2.1 41c1.4-10.7 10.2-19.8 18.6-25.8C27.5 9 38.8 4 49.8 4.3c12.2.4 17.7 9.3 17.1 20.8zm43.9 65.7c0 1.5-.2 4.7 1.4 5.6 1.9 1.1 4.4-1.3 5.6-2.5 4.4-4 7.7-9.5 10.7-14.5 3.1-5.3 5.9-10.8 8.4-16.4.6-1.4 1.7-6.5 3-7.4 1.3-.9 4.9.5 4.5 2.3-5.4 10.8-9 22.3-15.4 32.7-5.1 8.2-16 21.9-26.9 14.1-9.9-7-3.7-26.6-1-35.9 1.6-5.5 3.1-12 6.3-16.7 3-4.5 10.2-3.1 13.7.1 5.4 4.9-1.1 12.4-3.5 17.4-3.1 6.7-5.9 13.8-6.8 21.2zM126 42.5c-2.8 2.5-7.7.4-9.6-2.2-2.4-3.2-.6-7.3.4-10.8.9-2.9 1.8-7.4 4.6-9.1 3.1-1.9 7.5-.3 10.3 1.5 2.5 1.6 5.2 4.5 3.8 7.7-.5 1.2-1.8 2.4-2.6 3.5-2.3 3.1-4.6 6.3-6.9 9.4z" />
    <path d="M139.6 94.6c1.2 6.9 8.8-.4 10.3-2.6 3.3-4.9 5.3-10.7 6.7-16.5-3.3-3.3-5.1-8.3-5.1-12.9.1-4.3 2.2-10.5 7-11.4 11.3-2.2 13.8 14 12.4 21.5 9.6.6 16.4-8.1 18.7-16.6 1.9-3.4 5.6-.2 5 2.8-.8 3.7-2.9 7.3-5.2 10.2-4.5 5.7-12.6 11.9-20.3 11.2-2.7 7.7-6.6 15.6-12.6 21.3-4.6 4.3-15 8.8-19.2 1.3-2.8 6.7-12.5 3.9-12.2-3.1.3-7.2 2.7-14.8 4.4-21.7 4-16.1 8.8-32 14.1-47.8 2.3-6.9 4.5-14 7.9-20.5 1.3-2.6 2.6-4 5.7-3.9 3.1 0 7 1.5 9.3 3.7 4.1 4-.9 10.5-2.7 14.7-3.1 7.2-6 14.5-8.9 21.8-3.4 8.9-6.7 17.8-9.7 26.8-1.3 4-2.6 8-3.8 12.1-.9 3-2.2 6.5-1.8 9.6z" />
    <path d="M233.8 56.1c.4-1 2-.9 2.8-.5 1.7.8 1.5 1.6 1.1 3.1-1 3.3-3.4 6.6-5 9.6-3.9 7.1-8.2 14-13.1 20.4-7.7 10.1-23.5 26-36.3 13.9-10.8-10.3-6.6-29.8 0-41.2 3.6-6.1 9.1-12 16.3-13.3 6.7-1.2 13.5 1.6 16.4 8.1 3.2 7.2.6 16.7-4.2 22.6-5.3 6.6-14 9.8-22.3 9.8-.9 5.5 3.5 11 9.2 10.3 5.4-.6 10.2-5.4 13.8-9.2 9.1-9.7 16-21.6 21.3-33.6zm-31.9 4.8c-6.4 3.6-10.2 15.1-11.5 21.8 5.4-.6 9.9-5.9 12.2-10.5.9-1.8 4.9-13.2-.7-11.3z" />
    <path d="M228.9 72.8c-3.2-10.9.4-23.4 12.9-25.6 5.4-1 11.7.2 15.1 4.8 3.2 4.3 4 11.8.6 16.4-2.1 2.8-10.4 3.4-12.2-.4-1.2-2.4 2.6-4.8.5-6.9-1.8-1.8-4.2.1-4.8 2-1.8 5.1 2.5 12.8 4.5 17.4 2 4.5 5 9.6 4.6 14.7 10.1-8.2 17.4-19.8 22.6-31.6.9-2 1.8-7.2 3.9-7.9.8-.3 2.6.4 3.1 1.1.9 1.3-.3 2.8-.9 4.1-2.8 6.3-5.4 12.6-8.9 18.6-7 11.9-16.2 22.7-29.4 27.5-5.1 1.9-12.4 3.5-17.4.4-4.7-2.9-3.8-12.9 2.9-11.6 3.1.6 2 4.7 4.7 5.1 3.4.5 3.9-4 3.8-6.2-.3-7.9-3.9-14.7-5.6-21.9z" />
    <path d="M291.3 73.6c-6.3-.8-3.7-4.3-2.8-8.9 1.3-6.3-2.5-5-5.6-1.2-3.7 4.5-5.8 10.7-7.3 16.2-1.3 4.8-2.8 11-1.1 15.9 3.9 11.7 19.4-4.6 22.8-8.8 7.5-9.2 12.6-19.6 19.1-29.4 1.1-1.7 2.6-2.8 4.5-1 1.8 1.7.1 3.2-.9 5-2.9 5.2-5.9 10.4-9.1 15.5-6.1 9.7-12.9 20.2-22.7 26.5-9.3 6-20.9 3.3-25.1-7.4-4-10.2-1.1-23.1 3.6-32.6 4.9-9.8 16.5-21.3 28.2-14 8.5 5.5 10.1 25.9-3.6 24.2z" />
    <path d="M344 75.3c5.7.7 11.2-5.8 14-10 1.7-2.5 2.4-6.4 4.3-8.7 1.5-1.9 4.5-1.8 5.2.8.6 2.5-2.1 6.8-3.2 8.8-1.7 3-3.7 5.9-6 8.5-4 4.5-9.8 9.5-16.2 9.1-2.5 9.3-7.8 21-18 23.5-11.8 2.9-18.8-7-19.6-17.6-.9-11.2 2.8-23.6 9.4-32.7 3-4.2 7.1-8.4 12.4-9.4 5.6-1.1 10.6 1.6 12.5 7.1 7.1 3.3 6.2 14.4 5.2 20.6zM319.1 101c7.2 0 10.5-15.5 10.9-20.6-3.7-2.5-5.3-10.1-3.5-14-7.4 2.6-17.6 34.6-7.4 34.6z" />
    <path d="M372.9 56.9c-3.4 6.5-6.1 13.4-8.4 20.3-.8 2.5-6.9 17.6-2.8 19.1 5.3 2 16.9-34.6 18.4-39 .9-2.6 1.5-6.2 3.5-8.2 2.5-2.5 7.4-1.6 10.4-.4 7.7 3.2 1 11.5-1.2 16.6-3.1 7.2-6.1 14.7-7.2 22.4-.2 1.7-1.3 6.6.4 7.8 2.3 1.6 6.1-3 7.4-4.5 5.2-6 9-13.3 12.4-20.4 1.9-4 3.6-8.1 5.3-12.2.7-1.7.8-3.2 2.9-3.2 1.1 0 2.9 1.1 2.7 2.4-6.3 12.5-10.3 25.8-18.1 37.6-3.4 5.1-7.9 11.9-14.8 11.6-6.8-.4-9.8-6.7-9.9-12.8-3.8 7.2-12 17.1-20.9 10.4-7-5.3-5.4-16.6-4.1-24 1.7-9.4 4.4-19.2 8.7-27.8 2.1-4.2 5.2-5.6 10-4.4 4.2 1 7.8 4.2 5.3 8.7z" />
    <path d="M401.9 59c-1.8-1.2-.7-4.8.8-5.8 1.6-1 4.5-.2 6.4-.2 2.7-10 6-19.8 9.6-29.5 1.7-4.4 3.4-8.8 5.5-13 2-4.1 4.2-5.5 8.9-4.3 3.1.8 8.2 3.1 8.3 6.9.1 3.1-3.5 7.8-4.7 10.6-4.2 9.7-8 19.5-11.4 29.4 2.1 0 4.4-.7 5.1 1.6 1.7 5.3-4.5 5.5-7.7 5.8-1.7 5-13.5 35.9-4.3 37.1 3 .4 5.8-2.3 7.6-4.4 1-1.1 1.8-2.4 2.5-3.7.7-1.3 1.4-4.4 2.4-5.3 1.2-1 3.8-1 4.5 1 .6 1.8-1.8 5.5-2.6 7-2.7 5.5-7 11.8-13.2 13.7-5.6 1.7-11.9.2-15-5.1-3.2-5.5-2.6-12.9-1.8-18.9.9-7.5 2.6-14.9 4.3-22.2-1.5.1-3.8.3-5.2-.7z" />
  </svg>
);

export const SvgVibescoutVLetter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="150.7" height="150.7" viewBox="0 0 150.7 150.7">
    <path d="M91.4 38.2c-.6 5.8-1.9 12.2-3.8 19.5-1.9 7.2-3.9 14.5-6.2 21.8-2.2 7.3-4.3 14.2-6.2 20.7-2 6.6-3.4 12-4.2 16.2-.2 1.2-.5 3-.8 5.2-.3 2.3-.3 4.4.1 6.3.4 2 1.2 3.3 2.5 4.1 1.3.8 3.4.3 6.3-1.4 2.4-1.5 5.1-4.7 8.1-9.6 2.9-5 5.9-10.9 8.9-17.9 3-7 5.9-14.6 8.7-23 2.8-8.3 5.3-16.7 7.4-25.2 2.1-8.4 3.9-16.6 5.2-24.3 1.3-7.8 2-14.5 2-20.3 0-1.8.8-3.1 2.3-3.9 1.5-.8 3.4-1.1 5.6-1 2.2.1 4.5.7 7 1.7 2.4 1 4.7 2.3 6.7 3.9 2 1.7 3.6 3.6 4.7 5.9 1.1 2.3 1.4 4.7.9 7.3-2.2 7.8-4.5 15.3-7 22.3s-5 13.9-7.8 20.5-5.6 13.1-8.6 19.5-6.2 12.8-9.6 19.3c-6.5 12.6-13.5 22.1-20.9 28.6-7.5 6.4-14.2 10-20.2 10.7-2.7.1-5.3-.4-7.7-1.5-2.5-1.1-4.6-2.9-6.3-5.5-1.8-2.6-3.1-5.9-3.9-10-.9-4.1-1.1-9.2-.7-15.3.2-5.4 1.1-11.4 2.5-18.1 1.4-6.7 2.9-13.4 4.6-20.3 1.7-6.9 3.2-13.5 4.5-19.9 1.3-6.4 2.1-12.1 2.3-16.9.2-4.8-.4-8.6-1.7-11.2-1.3-2.6-3.9-3.6-7.7-3-2.3.4-4.8 1.5-7.5 3.3-2.7 1.8-5.4 4.1-8 6.8-2.6 2.7-5.1 5.7-7.5 9.1-2.4 3.4-4.5 6.7-6.2 10.1-1.8 3.4-3.1 6.7-4 9.9-.9 3.2-1.3 6.1-1 8.5.6 5-1.4 7.2-6.1 6.4-1.7-.2-3.5-.9-5.3-1.8-1.8-1-3.5-2.3-4.9-3.9-1.4-1.7-2.5-3.7-3.2-6.1-.7-2.4-.7-5.2 0-8.5 1.3-6 4.2-11.8 8.5-17.3s9.5-10.4 15.4-14.6C34.5 21.1 41 17.7 48 15.1c7-2.6 13.7-3.9 20.2-4 1.2 0 3.2.2 6 .5s5.5 1.3 8.3 3c2.8 1.7 5.1 4.4 7 8.1 1.5 3.6 2.2 8.8 1.9 15.5z" />
  </svg>
);
