import React, { useState } from "react";
/*
import isMobile from "ismobilejs";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import LocalityInput, { LocalityInputRightButtonType } from "../uiComponents/search/LocalityInput";
import Spinner from "../uiComponents/animations/Spinner";

import { LogoSvg } from "../svg/Logo";
import { loadingIcons } from "../../../crossover/constants/LoadingBlurbs";
import { CoronavirusPanel } from "../panels/CoronavirusPanel";*/
import { ASN_VibescoutSpecial, AsyncNavLink } from "../../state/ASN_VibescoutSpecial";
import { ETextTag, FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { EGradientType, EGraphicContrast, IFelaTheme } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { useFela } from "react-fela";
import { SimpleImage } from "@gt/gt-frontend/build/react/vibescout-ui/media/SimpleImage";
import { FullAbsFlexbox, FullAbsFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaGrid } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaGrid";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
  ETextTypographyType,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { GeoLevelSearchBar } from "./components/GeoLevelSearchBar";
import { HeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import { FelaButton } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaButton";
import { IoMdLocate } from "react-icons/io";
import { successResult } from "pullstate";
import { LocationStatic } from "../../state/stores/LocationStore/LocationStatic";
import { PseudoNavStatic } from "../../state/pseudoNavigation/PseudoNavStatic";
import { hasNavigator } from "@gt/gt-frontend/build/react/hooks/hasNavigator";
import { useIsNodeJs } from "@gt/gt-frontend/build/react/hooks/useIsNodeJs";
import { SvgVibescoutFullLogo } from "@vs/core/build/frontend/graphics/svg-components/VibescoutBrandingSvg";

interface IPopularPlace {
  path: string;
  name: string;
}

const popularPlaces: IPopularPlace[] = [
  {
    name: "Cape Town",
    path: "/za/city/cape-town",
  },
  {
    name: "Joburg",
    path: "/za/city/johannesburg",
  },
  {
    name: "Durban",
    path: "/za/city/durban",
  },
  {
    name: "Pretoria",
    path: "/za/city/pretoria",
  },
  /*{
    name: "Stellenbosch",
    path: "/za/city/stellenbosch",
  },*/
];

/*const actionCheckNavigator = PSC_VibescoutSpecial.createAsyncAction<{}>(async () => {
  if (navigator?.geolocation == null) {
    throw new Error("Can't load navigator");
  }

  return successResult({});
});*/

export const LandingPage: React.FC = () => {
  const { theme } = useFela<IFelaTheme>();
  const { UIStore, LocationStore } = PSC_VibescoutSpecial.useStores();
  const isSmall = UIStore.useState((s) => s.isSmall);

  // const checkNavigator = actionCheckNavigator.use({}, { initiate: true, ssr: false });
  // const hasNavigator = hasNavigator();
  const isNodeJs = useIsNodeJs();
  const [clickedButton, setClickedButton] = useState(false);

  const [locationErrorCode, hasNavigator] = LocationStore.useState((s) => [s.errorCode, s.hasNavigator] as const);

  console.log(hasNavigator);

  const locationButton = (
    <FelaButton
      key={`${!hasNavigator}`}
      onClick={async () => {
        setClickedButton(true);
        ASN_VibescoutSpecial.go(PseudoNavStatic.routePaths.userLocationToLocality);
      }}
      disabled={!hasNavigator}
      iconLeft
      margin={"0 0 1em 0"}
      borderRadius={2}
    >
      <IoMdLocate />
      <span>{!hasNavigator ? "Current Position Unavailable" : "Use My Current Location"}</span>
    </FelaButton>
  );

  return (
    <FelaFlexLayer
      forceTheme={EFrontendStyleConfigBaseTheme.DARK}
      align={"center"}
      justify={"center"}
      direction={"column"}
      style={{ minHeight: "100vh" }}
    >
      <FelaFlexbox zIndex={1} align={"center"} justify={"center"} direction={"column"}>
        <FelaFlexbox direction={"column"} align={"center"} position={"relative"} margin={"0 0 7em 0"}>
          <FelaFlexbox
            cssExtra={[{ "> svg": { fill: theme.styles.RgbColors.neutral.primary } }]}
            direction={"column"}
            align={"stretch"}
            style={{ width: "30em", maxWidth: "100vw" }}
            padding={2}
            alignSelf={"stretch"}
            position={"relative"}
          >
            <FullAbsFlexbox align={"flex-end"} justify={"flex-end"}>
              <FelaFlexLayer
                backgroundOpacity={0.75}
                height={ESurfaceHeight.upper}
                borderNeutralColor={EAppColor.accent}
                forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                borderWidth={"2px"}
                radius={0.25}
                align={"center"}
                direction={"column"}
                // backgroundString={"rgba(10, 20, 30, 0.5)"}
                padding={"0.15em 0.25em"}
                style={{ transform: "rotate(-8deg) translate(-1em, -0.2em)" }}
              >
                <FelaText
                  forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                  size={ETextSize.small}
                  neutralColor={EAppColor.accent}
                  transform={"uppercase"}
                  contrast={EGraphicContrast.HIGH}
                  weight={"bold"}
                >
                  Lockdown
                </FelaText>
                <FelaText
                  forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                  neutralColor={EAppColor.accent}
                  transform={"uppercase"}
                  contrast={EGraphicContrast.HIGH}
                  weight={"bold"}
                >
                  food edition!
                </FelaText>
              </FelaFlexLayer>
            </FullAbsFlexbox>
            <SvgVibescoutFullLogo />
          </FelaFlexbox>
          <FelaFlexbox padding={1}>
            <FelaText
              forceTheme={EFrontendStyleConfigBaseTheme.DARK}
              size={ETextSize.smallHeader}
              contrast={EForeContrast.strong}
            >
              I'm looking for things to do in
            </FelaText>
          </FelaFlexbox>
          <FelaFlexbox alignSelf={"stretch"} zIndex={2} justify={"center"}>
            <div style={{ position: "relative" }}>
              <GeoLevelSearchBar size={ETextSize.smallHeader} />
            </div>
          </FelaFlexbox>
          <FelaFlexbox direction={"column"} align={"center"}>
            <FelaText
              forceTheme={EFrontendStyleConfigBaseTheme.DARK}
              weight={ETextWeightType.black}
              typography={ETextTypographyType.mono}
              margin={"0.5em 0"}
            >
              OR
            </FelaText>
            {locationErrorCode != null && clickedButton ? (
              <>
                <FelaFlexLayer
                  height={ESurfaceHeight.shadow}
                  radius={0.5}
                  backgroundOpacity={0.5}
                  forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                  padding={0.5}
                  style={{ maxWidth: "23em" }}
                  align={"center"}
                  direction={"column"}
                >
                  {locationButton}
                  <FelaText
                    align={"center"}
                    neutralColor={EAppColor.primary}
                    forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                    contrast={EForeContrast.subtle}
                    size={ETextSize.small}
                  >
                    {LocationStatic.messageForErrorCode[locationErrorCode]}
                  </FelaText>
                </FelaFlexLayer>
              </>
            ) : (
              locationButton
            )}
            <FelaFlexLayer
              // forceTheme={EFrontendStyleConfigBaseTheme.DARK}
              backgroundString={"rgba(0, 10, 20, 0.2)"}
              radius={0.35}
              padding={"0.25em 0.45em"}
              margin={"1em 1.5em 1em 1em"}
            >
              <FelaText
                forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                tag={ETextTag.h2}
                cssExtra={[{ textShadow: "0 0.1em 0.2em rgba(10, 20, 30, 0.5)" }]}
                transform={"uppercase"}
                weight={700}
                contrast={EForeContrast.subtle}
                // contrast={EGraphicContrast.LOW}
                size={ETextSize.small}
                spacing={0.1}
                typography={ETextTypographyType.mono}
              >
                Popular Places
              </FelaText>
            </FelaFlexLayer>
            {popularPlaces.map((place) => (
              <AsyncNavLink key={place.path} to={place.path}>
                <FelaFlexbox>
                  <FelaFlexLayer
                    tag={"button"}
                    forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                    radius={0.25}
                    shadow
                    height={ESurfaceHeight.upper}
                    // height={EFelaLayerHeight.LOW}
                    pop
                    padding={"0.2em 0.4em"}
                    hoverable
                    clickable
                    margin={0.2}
                  >
                    <HeaderText
                      size={ETextSize.smallHeader}
                      forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                      tag={ETextTag.h3}
                    >
                      {place.name}
                    </HeaderText>
                  </FelaFlexLayer>
                  {place.name.length % 2 > 0 && (
                    <HeaderText
                      forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                      size={ETextSize.smallHeader}
                      tag={ETextTag.h3}
                    >
                      &nbsp;
                    </HeaderText>
                  )}
                </FelaFlexbox>
              </AsyncNavLink>
            ))}
            <AsyncNavLink to="/locality-index/za">
              <HeaderText
                forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                margin={1}
                contrast={EForeContrast.subtle}
                className="text"
              >
                Full South Africa Index
              </HeaderText>
            </AsyncNavLink>
          </FelaFlexbox>
        </FelaFlexbox>
        <FelaFlexbox grow={1} />
      </FelaFlexbox>
      <FullAbsFlexbox align={"stretch"} direction={"column"} justify={"flex-end"} zIndex={0} overflow={"hidden"}>
        <FullAbsFlexLayer
          forceTheme={EFrontendStyleConfigBaseTheme.DARK}
          gradient={[
            {
              type: EGradientType.radial,
              stops: [
                {
                  neutral: EAppColor.primary,
                  opacity: 0.2,
                },
                {
                  height: ESurfaceHeight.deepest,
                  opacity: 0.2,
                },
                {
                  height: ESurfaceHeight.deepest,
                  opacity: 0.1,
                },
                {
                  height: ESurfaceHeight.deepest,
                  opacity: 1,
                  def: "150%",
                },
              ],
            },
          ]}
          zIndex={3}
        />
        <FelaGrid key={`${isSmall}`} gridTemplateColumns={isSmall ? "1fr" : "1fr 1fr 1fr"}>
          {!isSmall && (
            <SimpleImage
              outerProps={{ backgroundString: "none" }}
              key={"back1"}
              style={{ width: "100%", zIndex: 2, transform: "translateY(35%) rotate(-4deg)" }}
              maxOpacity={0.4}
              src={"/_static/vs-special/images/munchies.png"}
            />
          )}
          <SimpleImage
            key={"back2"}
            outerProps={{ backgroundString: "none" }}
            style={{
              width: "100%",
              zIndex: 2,
              transform: isSmall ? "translateY(-5%)" : "translateY(15%)",
            }}
            maxOpacity={0.4}
            src={"/_static/vs-special/images/munchies.png"}
          />
          {!isSmall && (
            <SimpleImage
              key={"back3"}
              outerProps={{ backgroundString: "none" }}
              style={{ width: "100%", zIndex: 2, transform: "translateY(35%) rotate(4deg)" }}
              maxOpacity={0.4}
              src={"/_static/vs-special/images/munchies.png"}
            />
          )}
        </FelaGrid>
        <FullAbsFlexbox
          cssExtra={[
            {
              background: "radial-gradient(transparent, rgba(0, 0, 0, 0.6))",
            },
          ]}
          zIndex={1}
        />
      </FullAbsFlexbox>
    </FelaFlexLayer>
  );
};
