import React, { useEffect } from "react";
import { FelaFlexbox } from "../fela/FelaFlexbox";
import { Map, MapOptions } from "leaflet";

export interface ICPLeafletMap {
  L: typeof import("leaflet");
  id: string;
  // options: {
  //   center: [number, number];
  //   zoom: number;
  //   maxZoom?: number;
  //   attributionControl?: boolean;
  // },
  options: MapOptions;
  onMapMounted?: (map: Map) => void | (() => void);
}

export const LeafletMap = ({ L, id, options, onMapMounted }: ICPLeafletMap) => {
  useEffect(() => {
    const map = L.map(id, options) as any;
    let onUnMountMap;

    if (onMapMounted) {
      onUnMountMap = onMapMounted(map);
    }
    return () => {
      if (onUnMountMap) {
        onUnMountMap();
      }
      map.remove();
    };
  }, []);

  return (
    <FelaFlexbox
      key={id}
      id={id}
      style={{
        zIndex: 0,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    />
  );
};
