import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FaRegCommentDots, FaRegStar, FaStar } from "react-icons/fa";
import { ETextInputType, FelaTextInput } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaTextInput";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { FelaButton } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaButton";
import { PSC_VibescoutSpecial } from "../../../state/PSC_VibescoutSpecial";
import { WildcardUtilsClient } from "@gt/gt-frontend/build/utils/WildcardUtilsClient";
import { wildcard } from "../../../wildcard/VibescoutSpecial_WildcardClient";
import { QuickUseAsyncResultModal } from "@gt/gt-frontend/build/react/vibescout-ui/components/QuickAsyncResultModal";
import { FelaSimpleCheck } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaSimpleCheck";
import { EGraphicContrast } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import Joi from "joi";
import {
  EAppColor,
  ETextSize,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { EFelaLayerHeight, FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { Store } from "pullstate";
import { useInView } from "react-intersection-observer";
import { HeaderText, UnderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";

const submitFeedbackAsyncAction = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.submitCovidFeedback, "Submit Covid Feedback"),
);

export enum EFeedbackEndIds {
  TOO_SOON = "TOO_SOON",
  BAD_COOKIES = "BAD_COOKIES",
}

export interface ICovidFeedbackForm {
  ratingOutOfFive: number;
  comment: string;
  wantsResponse: boolean;
  email: string;
}

const emailValidation = Joi.string().required().email({ tlds: false });

export const JoiObjectCovidFeedback = Joi.object<ICovidFeedbackForm>().keys({
  email: Joi.when("wantsResponse", { is: false, then: Joi.string().valid(""), otherwise: emailValidation }).label(
    "Email",
  ),
  wantsResponse: Joi.bool(),
  ratingOutOfFive: Joi.number().min(0).max(5).label("Rating"),
  comment: Joi.string().min(4).label("Comment"),
});

const defaultFeedback: ICovidFeedbackForm = {
  ratingOutOfFive: -1,
  comment: "",
  wantsResponse: false,
  email: "",
};

const FeedbackState = new Store({
  inView: false,
});

const feedbackColor = "#31beff";

export const FeedbackFloatingButton = () => {
  const inView = FeedbackState.useState((s) => s.inView);

  return (
    <a href={"#feedback"}>
      <FelaFlexLayer
        borderWidth={"2px"}
        borderNeutralColorString={feedbackColor}
        clickable
        style={{
          borderBottom: "none",
          borderRight: "none",
          ...(inView
            ? {
                opacity: 0,
                pointerEvents: "none",
                transition: "all 0.3s ease-in-out",
              }
            : {
                opacity: 1,
                pointerEvents: "all",
                transition: "all 0.3s ease-in-out",
              }),
        }}
        zIndex={10}
        position={"fixed"}
        bottom={0}
        right={0}
        height={EFelaLayerHeight.HIGH}
        shadow
        extraSoftShadow
        padding={0.3}
        radius={"0.5em 0 0 0"}
        align={"center"}
        direction={"column"}
      >
        <FelaIconWrap
          padding={"0 0.5em 0.5em 0.5em"}
          noPadding
          style={{ position: "relative", overflow: "hidden" }}
          noBackground
          colorString={feedbackColor}
        >
          <FaRegCommentDots />
        </FelaIconWrap>
        <FelaFlexbox direction={"column"} align={"center"}>
          <FelaText transform={"uppercase"} size={ETextSize.tiny} weight={"bold"} colorHex={feedbackColor}>
            Give
          </FelaText>
          <FelaText transform={"uppercase"} size={ETextSize.tiny} weight={"bold"} colorHex={feedbackColor}>
            Feedback
          </FelaText>
        </FelaFlexbox>
      </FelaFlexLayer>
    </a>
  );
};

export const FeedbackSection: React.FC = () => {
  const [feedback, setFeedback] = useState<ICovidFeedbackForm>(defaultFeedback);

  const submitFeedback = submitFeedbackAsyncAction.use({ feedback }, { initiate: false, ssr: false });

  const validationErrors = JoiObjectCovidFeedback.validate(feedback).error;

  const [ref, inView] = useInView();

  useEffect(() => {
    FeedbackState.update((s) => {
      s.inView = inView;
    });
  }, [inView]);

  return (
    <div ref={ref}>
      <FelaFlexbox position={"relative"} direction={"column"} padding={0.5}>
        <QuickUseAsyncResultModal
          showFinishedAlert={true}
          state={submitFeedback}
          title={"Submit Feedback"}
          onClose={() => {
            if (!submitFeedback.error) {
              setFeedback(defaultFeedback);
            }

            submitFeedbackAsyncAction.clearAllCache();
          }}
        />
        <UnderText weight={"normal"}>
          We welcome any feedback or constructive criticism of this dashboard. Please let us know what we can do to
          improve!
        </UnderText>
        <HeaderText size={ETextSize.small} margin={"0.5em 0"}>
          How would you rate this dashboard?
        </HeaderText>
        <FelaFlexbox
          padding={"0.25em 0"}
          cssExtra={[
            ({ theme }) => ({
              "& .full-star": {
                "> svg": {
                  fill: "#ffbe00",
                },
              },
              "& svg": {
                width: "2.5em",
                height: "2.5em",
                marginLeft: "0.2em",
                fill: theme.dark ? "rgba(255,255,255,0.50)" : "rgba(38,61,92,0.5)",
              },
            }),
          ]}
        >
          <Rating
            initialRating={feedback.ratingOutOfFive >= 0 ? feedback.ratingOutOfFive : 0}
            onChange={(val) => {
              console.log(val);
              setFeedback({
                ...feedback,
                ratingOutOfFive: val,
              });
            }}
            emptySymbol={<FaRegStar />}
            fullSymbol={
              <span className={"full-star"}>
                <FaStar />
              </span>
            }
          />
        </FelaFlexbox>
        <HeaderText margin={"0.5em 0"} size={ETextSize.small}>
          Please let us know what we can do to improve
        </HeaderText>
        <FelaTextInput
          style={{ minHeight: "8em" }}
          fullWidth
          type={ETextInputType.TEXT_AREA}
          value={feedback.comment}
          onChangeText={(text) => setFeedback({ ...feedback, comment: text })}
        />
        <FelaFlexbox justify={"flex-start"}>
          <FelaSimpleCheck
            textAfter
            size={1.35}
            checked={feedback.wantsResponse}
            onChange={() => setFeedback({ ...feedback, wantsResponse: !feedback.wantsResponse })}
            textComponent={
              <HeaderText margin={"1em 0.5em"} size={ETextSize.small}>
                I Would like a response
              </HeaderText>
            }
          />
        </FelaFlexbox>
        {feedback.wantsResponse && (
          <>
            <HeaderText size={ETextSize.small} margin={"0.5em 0"} contrast={EGraphicContrast.MID}>
              Your Email
            </HeaderText>
            <FelaTextInput onChangeText={(text) => setFeedback({ ...feedback, email: text })} />
            <UnderText margin={"0.35em 0"} neutralColor={EAppColor.warning}>
              {emailValidation.label("Email").validate(feedback.email).error?.message}
            </UnderText>
          </>
        )}
        <FelaFlexbox align={"center"} justify={"flex-end"} padding={"0.5em 0"}>
          <FelaButton onClick={() => submitFeedback.execute()} disabled={validationErrors != null}>
            <span>Submit Feedback</span>
          </FelaButton>
        </FelaFlexbox>
      </FelaFlexbox>
    </div>
  );
};
