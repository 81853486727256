import StackdriverErrorReporter from "stackdriver-errors-js";

interface IOSetupInput {
  serviceId: string;
  serviceVersion?: string;
  apiKey: string;
  projectId: string;
  consoleOnly?: boolean;
}

export interface IStackDriverErrorHandler {
  report: (error: Error | string) => void;
  setUser: (id: string) => void;
}

function setup({
  serviceId,
  serviceVersion,
  apiKey,
  projectId,
  consoleOnly = false,
}: IOSetupInput): IStackDriverErrorHandler {
  const isBrowser = typeof document !== "undefined";

  if (isBrowser && !consoleOnly) {
    const errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
      key: apiKey,
      projectId,
      service: serviceId,
      version: serviceVersion,

      // The following optional arguments can also be provided:

      // service: myServiceName,
      // Name of the service reporting the error, defaults to 'web'.

      // version: myServiceVersion,
      // Version identifier of the service reporting the error.

      // reportUncaughtExceptions: false
      // Set to false to prevent reporting unhandled exceptions, default: `true`.

      // reportUnhandledPromiseRejections: false
      // Set to false to prevent reporting unhandled promise rejections, default: `true`.

      // disabled: true
      // Set to true to not send error reports, this can be used when developing locally, default: `false`.

      // context: {user: 'user1'}
      // You can set the user later using setUser()
    });

    return errorHandler;
  }

  return {
    report: (error) => {
      console.error(error);
      console.warn(
        !isBrowser
          ? `Can't use JS StackdriverErrorHandler from Server Side`
          : "(Error reporting set to console only mode)",
      );
    },
    setUser: () => {
      console.warn(
        !isBrowser
          ? `Can't use JS StackdriverErrorHandler from Server Side`
          : "(Error reporting set to console only mode - no user set)",
      );
    },
  };
}

export const StackdriverErrorReporting = {
  setup,
};
