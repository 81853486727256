import React from "react";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { SimpleImage } from "@gt/gt-frontend/build/react/vibescout-ui/media/SimpleImage";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";

interface ICPMovieTitleImages {
  style?: any;
  moviePoster: string;
  movieBackdrop: string;
  // colorArray: TColorRgbArray;
}

export const MovieTitleImages = React.memo<ICPMovieTitleImages>(({ style, moviePoster, movieBackdrop }) => {
  return (
    <FelaFlexbox style={style} direction={"row"} grow={1} position={"relative"}>
      <FullAbsFlexbox zIndex={2} left={0} top={0} direction={"row"} align={"stretch"} justify={"flex-start"}>
        <SimpleImage
          imgStyle={{ filter: "drop-shadow(0.2em 0 0.45em rgba(10,20,30,0.55))" }}
          objectPosition={"left"}
          imageFit={"contain"}
          src={moviePoster}
        />
      </FullAbsFlexbox>
      <SimpleImage imgStyle={{ filter: "saturate(65%) contrast(55%)" }} src={movieBackdrop} aspect={177 / 100} />
    </FelaFlexbox>
  );
});
