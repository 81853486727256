import { Store } from "pullstate";
import {
  ECovidMetaDateUpdatedShow,
  IDMCovidDataMeta,
} from "@vs/core/build/data/models/covid19/CovidDataMeta/CovidDataMeta";
import format from "date-fns/format";

export const defaultDateFormat = "do MMM yyyy, HH:mm";

export enum ECovidPageContextButton {
  COUNTRY_STATS = "COUNTRY_STATS",
  GO_TO_PROVINCE = "GO_TO_PROVINCE",
  GIVE_FEEDBACK = "GIVE_FEEDBACK",
}

interface IPageContextButton {
  disabled: boolean;
  type: ECovidPageContextButton;
  url?: string;
  label?: string;
}

export interface ICovidStore {
  meta: IDMCovidDataMeta & { dateFormatted: string };
  bottomBar: IPageContextButton[];
}

const date = new Date();

export const CovidStore = new Store<ICovidStore>({
  meta: {
    fullReportUrl: "https://www.vibescout.com/reports/coronavirus",
    dateUpdatedFormat: defaultDateFormat,
    dateUpdatedShow: [
      ECovidMetaDateUpdatedShow.SCHEMA_DESCRIPTION,
      ECovidMetaDateUpdatedShow.DESCRIPTION_META,
      ECovidMetaDateUpdatedShow.ON_PAGE,
    ],
    dateFormatted: format(date, defaultDateFormat),
    dateUpdated: date,
    dateCreated: date,
    id: "covid_page_updated",
    metaDescription:
      "Live Coronavirus updates for South Africa - Statistics and graphs: New cases per day, recoveries, tests administered, and demographics on deaths",
    metaTitle: "Covid-19 South Africa | Cases, Tests, Deaths & Recovery Stats | REAL-TIME UPDATES",
    titleImageWidth: 1200,
    titleImageHeight: 630,
    titleImageUrl: "https://www.vibescout.com/_static/images/covid19_stats_title.jpg",
    titleImage4x3: "https://www.vibescout.com/_static/images/covid_19_stats_4x3.jpg",
    titleImage1x1: "https://www.vibescout.com/_static/images/covid_19_stats_1x1.jpg",
    titleImage16x9: "https://www.vibescout.com/_static/images/covid_19_stats_16x9.jpg",
    schemaOrgLogoUrl: "https://www.vibescout.com/_static/images/vibescout_schema_org_logo.png",
    schemaOrgLogoHeight: 60,
    schemaOrgLogoWidth: 200,
  },
  bottomBar: [{
    disabled: false,
    type: ECovidPageContextButton.GO_TO_PROVINCE,
    url: "#provinces",
  }, {
    disabled: false,
    type: ECovidPageContextButton.GIVE_FEEDBACK,
    url: "#feedback"
  }],
});
