import { LinkSource } from "../components/Covid19Components";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import React from "react";
import { HeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";

export const CovidReportFooter = () => {
  return (
    <FelaFlexLayer padding={0.5} direction={"row"} wrap={"wrap"} align={"center"}>
      <HeaderText>Sources&nbsp;</HeaderText>
      <LinkSource margin={"0 0.45em"} link={"https://sacoronavirus.co.za/"}>
        sacoronavirus.co.za
      </LinkSource>
      <LinkSource margin={"0 0.45em"} link={"http://www.nicd.ac.za/media/alerts/"}>
        NICD
      </LinkSource>
      <LinkSource margin={"0 0.45em"} link={"https://github.com/dsfsi/covid19za"}>
        DSFSI
      </LinkSource>
    </FelaFlexLayer>
  )
}
