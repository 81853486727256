import { IGeoLevelActionOptionGroup } from "../../elasticsearch/actions/geoLevels/_getGeoLevelDataActionTerms";
import { TElasticPropertyGeoShape } from "@gt/services/build/elasticsearch/ElasticSearchTypes";
import { IESGeoLevelNode } from "../../models/geolevels/GeoLevelModelTypes";

export enum EGeoLevelDataAction {
  NULL = "NULL",
  SET_GEO_TAG = "SET_GEO_TAG",
  REMOVE_GEO_TAG = "REMOVE_GEO_TAG",
  CHANGE_LVL_CLASSIFICATION = "CHANGE_LVL_CLASSIFICATION",
  EDIT_SHAPE = "EDIT_SHAPE",
  RENAME = "RENAME",
  EDIT_DATA = "EDIT_DATA",
  LOWER_LEVEL_TYPE = "LOWER_LEVEL_TYPE",
  RAISE_LEVEL_TYPE = "RAISE_LEVEL_TYPE",
  REMOVE = "REMOVE",
  DISABLE = "DISABLE",
  REDIRECT_TO_PARENT = "REDIRECT_TO_PARENT",
  REDIRECT_TO_CUSTOM = "REDIRECT_TO_CUSTOM",
  ABSORB_CHILDREN = "ABSORB_CHILDREN",
}

export interface IAffectedGeoLevelWithOptions {
  geoLevel: IESGeoLevelNode;
  options: IGeoLevelActionOptionGroup[];
}

export interface IGeoLevelDataActionChoiceItem_AssimilateChildren {
  action: EGeoLevelDataAction.ABSORB_CHILDREN;
  value: {
    childrenCuid: string[];
  };
}

export interface IGeoLevelDataActionChoiceItem_ChangeLevelClassification {
  action: EGeoLevelDataAction.CHANGE_LVL_CLASSIFICATION;
  value: {
    newClassification: EGeoLevelLevelType;
    changes?: Partial<IESGeoLevelNode>;
  };
}

export interface IGeoLevelDataActionChoiceItem_EditData {
  action: EGeoLevelDataAction.EDIT_DATA;
  value: {
    data: Partial<IESGeoLevelNode>;
  };
}

export interface IGeoLevelDataActionChoiceItem_Rename {
  action: EGeoLevelDataAction.RENAME;
  value: {
    newName: string;
    changes?: Partial<IESGeoLevelNode>;
  };
}

export interface IGeoLevelDataActionChoiceItem_LowerLevelType {
  action: EGeoLevelDataAction.LOWER_LEVEL_TYPE;
  value: {
    parentGeoLevels: IESGeoLevelNode[];
  };
}

export interface IGeoLevelDataActionChoiceItem_EditShape {
  action: EGeoLevelDataAction.EDIT_SHAPE;
  value: TElasticPropertyGeoShape;
}

export interface IGeoLevelDataActionChoiceItem_SetGeoTag {
  action: EGeoLevelDataAction.SET_GEO_TAG;
  value: string;
}

// export type TAffectedGeoLevel = IAffectedGeoLevelWithOptions | IAffectedGeoLevelWithSubOutput;
export type TAffectedGeoLevel = IAffectedGeoLevelWithOptions;

export type TGeoLevelDataActionChoiceItem =
  | {
      action:
        | EGeoLevelDataAction.REMOVE_GEO_TAG
        | EGeoLevelDataAction.NULL
        | EGeoLevelDataAction.REMOVE
        | EGeoLevelDataAction.DISABLE
        | EGeoLevelDataAction.REDIRECT_TO_CUSTOM
        | EGeoLevelDataAction.REDIRECT_TO_PARENT;
      value: string;
    }
  | IGeoLevelDataActionChoiceItem_ChangeLevelClassification
  | IGeoLevelDataActionChoiceItem_EditData
  | IGeoLevelDataActionChoiceItem_Rename
  | IGeoLevelDataActionChoiceItem_EditShape
  | IGeoLevelDataActionChoiceItem_SetGeoTag
  | IGeoLevelDataActionChoiceItem_LowerLevelType;
/*| {
      action: EGeoLevelDataAction.EDIT_PARENT_GEO_LEVELS;
      value: {
        subType: EESNodeSubTypeGeoLevelFull;
        lvlType: EGeoLevelLevelType;
      };
    };*/

export enum EGeoLevelLevelType {
  sub_suburb = "sub_suburb",
  suburb = "suburb",
  town = "town",
  city = "city",
  // city_or_town = "city_or_town",
  metropolitan_municipality = "metropolitan_municipality",
  local_municipality = "local_municipality",
  district_municipality = "district_municipality",
  province = "province",
  country = "country",
  continent = "continent",
}

export enum EGeoLevelShortLevel {
  country_alone = "country_alone",
  country_canonical = "country_canonical",
  state_alone = "state_alone",
  state_canonical = "state_canonical",
  municipal_alone = "municipal_alone",
  inapplicable = "inapplicable",
}

export enum ENodeSubTypeGeoLevel {
  gl_ss = "gl_ss",
  gl_su = "gl_su",
  gl_ci = "gl_ci",
  gl_mu = "gl_mu",
  gl_st = "gl_st",
  gl_cc = "gl_cc",
  gl_cn = "gl_cn",
}

export enum EGeoLevelRedirect {
  gl_ci = "gl_ci",
  gl_su = "gl_su",
}
