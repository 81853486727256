import { TVibescoutSpecialResolver } from "../ASN_VibescoutSpecial";
import { LocationActions } from "../stores/LocationStore/LocationActions";
import { throwOrSuccessPayload } from "@gt/gt-frontend/build/utils/PullstateAsyncUtils";
import { GeoLevelAsyncActions } from "../asyncActions/GeoLevelAsyncActions";
import { GeoLevelStatic } from "@vs/core/build/data/crossover/geoLevels/GeoLevelStatic";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { GeoLevelCommonUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCommonUtils";
import { NumberUtils } from "@gt/common-utils/build/datatypes/NumberUtils";

export const _psNav_userLocationToLocality: TVibescoutSpecialResolver = {
  paths: ["/_psNav_/userLocationToLocality"],
  resolve: async ({ state: { instance }, ctx }) => {
    const userLocation = throwOrSuccessPayload(await instance.runAsyncAction(LocationActions.initiateLocationWatcher));
    console.log(userLocation);

    const { geoLevel } = throwOrSuccessPayload(
      await instance.runAsyncAction(
        GeoLevelAsyncActions.getGeoLevelForUserLocation,
        {
          latLon: {
            lat: NumberUtils.roundToDecimal(userLocation.lat, 4),
            lon: NumberUtils.roundToDecimal(userLocation.lon, 4),
          },
        },
        { respectCache: true },
      ),
    );

    if (geoLevel.lvlValue >= GeoLevelStatic.valuesForGeoLvlType.city.lvlValue) {
      ctx.redirect = {
        path: `/${GeoLevelCompressUtils.convertCompressedToUrl({
          geoLvl: geoLevel.geoLvlShort,
        })}`,
      };
    } else {
      ctx.redirect = {
        path: `/locality-index/${GeoLevelCommonUtils.getGeoLevelParts({
          geoLevel,
        }).join("/")}`,
      };
    }
  },
};

/*{
        ssr: false,
        dormant: userLocation == null,
        onSuccess: () => {
          const geoLevel = geoLevelForUserLocation.payload!.geoLevel;

          if (geoLevel.lvlValue >= GeoLevelStatic.valuesForGeoLvlType.city.lvlValue) {
            ASN_VibescoutSpecial.go(
              `/${GeoLevelCompressUtils.convertCompressedToUrl({
                geoLvl: geoLevel.geoLvlShort,
              })}`,
              {
                nav: true,
              },
            );
          } else {
            ASN_VibescoutSpecial.go(
              `/locality-index/${GeoLevelCommonUtils.getGeoLevelParts({
                geoLevel,
              })}`,
              {
                nav: true,
              },
            );
          }
        },
      },*/
