import { createBrowserHistory, History } from "history";
import { EReactRouterProvider, EReactStyleProvider, ReactSetup } from "@gt/gt-frontend/build/react/ReactSetup";
// import { ETextTypographyType } from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
// import { TypographyMap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { getFelaRenderer } from "@gt/gt-frontend/build/react/fela/FelaRenderer";
import { SerializationUtils } from "@gt/common-utils/build/general/SerializationUtils";
import ReactDOM from "react-dom";
import { PullstateProvider } from "pullstate";
import React from "react";
import { PSC_VibescoutSpecial } from "./state/PSC_VibescoutSpecial";
import { initializeClientSideUIListeners } from "./state/stores/UIStore/UIStore";
import { VibescoutStaticStyles } from "@gt/gt-frontend/build/styling/VibescoutStaticStyles";
import { VibescoutSpecialApp } from "./VibescoutSpecialApp";
import { ASN_VibescoutSpecial } from "./state/ASN_VibescoutSpecial";
import { FelaConfig_VibescoutSpecial } from "./config/FelaConfig_VibescoutSpecial";
import { enableES5 } from "immer";
import { CssReset_Basic } from "@gt/gt-frontend/build/styling/resets/CssReset_Basic";

declare global {
  interface Window {
    __PULLSTATE__: string;
  }
}

const reactTreeFunction = ReactSetup.createReactTreeFunction({
  server: false,
  useRouter: EReactRouterProvider.REACT_ROUTER,
  useStyleProvider: EReactStyleProvider.FELA,
  useApollo: false,
});

// TypographyMap[ETextTypographyType.mono] = "'Jetbrains Mono', 'Inconsolata', monospace";

/*${VibescoutStaticStyles.scrollbar}*/

export const getVibescoutSpecialRenderer = () =>
  getFelaRenderer({
    extraStyles: `${CssReset_Basic}
    ${VibescoutStaticStyles.robotoFontFamily}
    ${VibescoutStaticStyles.scrollbar}
    ${VibescoutStaticStyles.jetbrainsMonoFamily}
    ${VibescoutStaticStyles.loadingStripeAnimation}`,
  });

console.log(`Mounting app`);

export let browserHistory: History;

if (typeof document !== "undefined") {
  enableES5();

  console.log(`Document is not undefined - On Client side`);
  browserHistory = createBrowserHistory();
  browserHistory.listen((location, action) => {
    console.log(`Listening to browser history: ${location.pathname}, ${action}`);

    if (action === "POP") {
      ASN_VibescoutSpecial.go(window.location.pathname, { nav: false });
    }
  });

  let hydrateSnapshot = JSON.parse(window.__PULLSTATE__ || "null", SerializationUtils.JsonRevivers.reviveDateObjects);

  // console.log(hydrateSnapshot);
  if (process.env.NODE_ENV === "development") {
    navigator?.serviceWorker?.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
    console.warn(`PULLSTATE. DEVELOPMENT MODE: Not hydrating state some`);
    // hydrateSnapshot = { asyncResults: {}, asyncActionOrd: {}, allState: {} };
  }

  const instance = PSC_VibescoutSpecial.instantiate({ ssr: false, hydrateSnapshot });

  /*const instance =
    process.env.NODE_ENV === "development"
      ? PSC_VibescoutSpecial.instantiate({
          ssr: false,
          hydrateSnapshot: {
            asyncResults: hydrateSnapshot.asyncResults,
            asyncActionOrd: hydrateSnapshot.asyncActionOrd,
            allState: hydrateSnapshot.allState,
          },
        })
      : */

  ASN_VibescoutSpecial.setDefaultState({ instance });

  const renderer = getVibescoutSpecialRenderer();
  instance.stores.UIStore.update((s) => {
    s.keyFrames = FelaConfig_VibescoutSpecial.registerKeyframes(renderer);
  });

  ReactDOM.hydrate(
    reactTreeFunction(
      function AppComponent() {
        return (
          <React.StrictMode>
            <PullstateProvider instance={instance}>
              <VibescoutSpecialApp />
            </PullstateProvider>
          </React.StrictMode>
        );
      },
      {
        styleProviderProps: { renderer },
        routerProps: { history: browserHistory, forceRefresh: true },
      },
    ),
    document.getElementById("react-mount"),
  );

  if (process.env.NODE_ENV !== "production") {
    console.warn("Doing full REACT re-render to prevent checksum issues during development.");
    ReactDOM.render(
      reactTreeFunction(
        () => (
          <React.StrictMode>
            <PullstateProvider instance={instance}>
              <VibescoutSpecialApp />
            </PullstateProvider>
          </React.StrictMode>
        ),
        {
          styleProviderProps: { renderer },
          routerProps: { history: browserHistory, forceRefresh: true },
        },
      ),
      document.getElementById("react-mount"),
    );
  }

  initializeClientSideUIListeners();

  /*LocalStorageCore_VibescoutSpecial.initiateLocalStorageValues().finally(() => {

  });*/
}
