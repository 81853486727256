import { Observer } from "rxjs";
import Joi from "joi";

export enum ETaskType {
  PARENT = "PARENT",
  CHILD = "CHILD",
}

export enum ETaskFunctionType {
  NORMAL = "NORMAL",
  OBSERVABLE = "OBSERVABLE",
}

export interface IOGetTasks {
  tags?: string[] | null;
  taskType?: ETaskType | null;
  excludeTags?: string[] | null;
}

export interface ITaskTiming {
  endDelayMillis: number;
}

export interface IParentTaskDefinition {
  subTaskIds?: string[];
  subTaskTimings?: { [taskId: string]: ITaskTiming };
  subTaskResponseToOptionLinking?: {
    [taskId: string]: {
      [option: string]: string[]; // PATH in _.get(payload, PATH)
    };
  };
}

export interface ITaskFunctionCreationOptions extends ITaskFunctionOptions {
  addToRegister?: boolean;
}

export interface ITaskFunctionOptions {
  skipInputValidation?: boolean;
  skipOutputValidation?: boolean;
  hideObserverMissingWarnings?: boolean;
}

// export interface ITaskFunctionOnRunOptions {
//   hideObserverMissingWarnings?: boolean;
// }

export interface ITaskResponseMapping {
  [prop: string]: string[];
}

export interface ITaskFunctionDescriptor {
  id?: string | null;
  title: string;
  description?: string;
  inputSchema?: Joi.Schema | null;
  outputSchema?: Joi.Schema | null;
  inputDescription?: any;
  outputDescription?: any;
  tags?: string[];
  authorizedUserGroups?: string[];
  taskResponseMapping?: ITaskResponseMapping | null;
  taskOptions?: ITaskFunctionCreationOptions;
}

export interface IRegisteredTask extends ITaskFunctionDescriptor, IParentTaskDefinition {
  id: string;
  tags: string[];
  authorizedUserGroups: string[];
  taskType: ETaskType;
  functionType: ETaskFunctionType;
  func: (...args: any[]) => Promise<any>;
}

export interface ITaskRunRequest {
  taskId: string;
  runningTaskUid: string;
  taskOptions: { [taskId: string]: any };
}

export interface IRunningTask {
  id: string;
  taskId: string;
  subTaskNames: string[];
}

export enum ERunningTaskStatus {
  RUNNING = "RUNNING",
  FINISHED = "FINISHED",
  TIMED_OUT = "TIMED_OUT",
  CRASHED = "CRASHED",
}

export interface IHistoricalRunningTask extends IRunningTask {
  taskProgressCollection: ITaskProgress[];
  taskResponseCollection: {
    [taskId: string]: any;
  };
  status: ERunningTaskStatus;
}

export enum ETaskFeedbackType {
  PERCENT = "PERCENT",
  UNQUANTIFIABLE = "UNQUANTIFIABLE",
  FRACTION = "FRACTION",
}

export enum ETaskStatus {
  OKAY = "OKAY",
  WARNING = "WARNING",
  ERROR = "ERROR",
  CRASH = "CRASH",
}

export enum ETaskProgressAction {
  DELAY = "DELAY",
  START = "START",
  BUSY = "BUSY",
  END = "END",
}

export interface IFractionFeedback {
  unit: string;
  limit: number;
  current: number;
}

export interface ITaskProgressUpdate {
  feedbackType?: ETaskFeedbackType;
  feedbackValue?: number;
  feedbackValueLimit?: number;
  feedbackUnit?: string;
  message?: string;
  status?: ETaskStatus;
  action?: ETaskProgressAction;
  tfe?: ITaskFunctionNegativeResponse<any>;
}

export interface ITaskProgressUpdateMeta {
  feedbackPercent: number;
  feedbackValueString: string;
}

export interface ITaskProgress extends ITaskProgressUpdate, ITaskProgressUpdateMeta {
  id: string;
  taskId: string;
  runningTaskUid: string;
  progressIndex: number;
  totalSubTasks: number;
  currentSubTaskIndex: number;
  timestamp?: number;
}

export enum ETaskFunctionEndId {
  SUCCESS = "SUCCESS",

  ERROR = "ERROR",
  THROWN_ERROR = "THROWN_ERROR",
  EXTERNAL_API_ERROR = "EXTERNAL_API_ERROR",
  WARNING = "WARNING",
  NOT_FOUND = "NOT_FOUND",
  CONFLICT_FOUND = "CONFLICT_FOUND",
  NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
  TIMED_OUT = "TIMED_OUT",
  ILLEGAL_ARGUMENT = "ILLEGAL_ARGUMENT",
  LIMIT_REACHED = "LIMIT_REACTED",
  UNAUTHORIZED = "UNAUTHORIZED",
  FORBIDDEN = "FORBIDDEN",
  DATA_VALIDATION_FAILED = "DATA_VALIDATION_FAILED",
  REDIRECT = "REDIRECT",
}

export enum EInternalTaskIds {
  util_run_task_function_on_object = "util_run_task_function_on_object",
}

export type TTaskFunctionFull<I extends any = any, OP = any, OE = any> = (
  options: I,
  observer: Observer<ITaskProgressUpdate>,
) => Promise<ITaskFunctionResponse<OP, OE>>;

export type TTaskFunctionOptionsOnly<I extends any = any, OP = any, OE = any> = (
  options: I,
) => Promise<ITaskFunctionResponse<OP, OE>>;

export type TTaskFunctionNoOptions<OP = any, OE = any> = () => Promise<ITaskFunctionResponse<OP, OE>>;

export type TTaskFunction<I extends any = any, OP = any, OE = any> =
  | TTaskFunctionNoOptions<OP, OE>
  | TTaskFunctionOptionsOnly<I, OP, OE>
  | TTaskFunctionFull<I, OP, OE>;

export type TTaskFunctionSyncFull<I extends any = any, OP = any, OE = any> = (
  options: I,
  observer: Observer<ITaskProgressUpdate>,
) => ITaskFunctionResponse<OP, OE>;

export type TTaskFunctionSyncOptionsOnly<I extends any = any, OP = any, OE = any> = (
  options: I,
) => ITaskFunctionResponse<OP, OE>;

export type TTaskFunctionSyncNoOptions<I extends any = any, OP = any, OE = any> = () => ITaskFunctionResponse<OP, OE>;

export type TTaskFunctionSync<I extends any = any, OP = any, OE = any> =
  | TTaskFunctionSyncNoOptions<I, OP, OE>
  | TTaskFunctionSyncOptionsOnly<I, OP, OE>
  | TTaskFunctionSyncFull<I, OP, OE>;

// export type TTaskFunction<I extends any = any, OP = any, OE = any> = (
//   options: I,
//   observer: Observer<ITaskProgressUpdate>
// ) => Promise<ITaskFunctionResponse<OP, OE>>;

export type TCreatedTaskFunction<I = {}, O = any, E = any> = (
  options?: I,
  observer?: Observer<ITaskProgressUpdate> | null,
  taskOptions?: ITaskFunctionOptions,
) => Promise<ITaskFunctionResponse<O, E>>;

// export type TTaskFunctionSync<I = {}, OP = any, OE = any> = (
//   ...args: [I, Observer<ITaskProgressUpdate>]
// ) => // options?: I,
// observer?: Observer<ITaskProgressUpdate>
// ITaskFunctionResponse<OP, OE>;

export enum EEndLabelSeverity {
  error = 0,
  warning = 1,
  info = 2,
}

export interface ITaskFunctionEndLabel<TAG extends string = string> {
  /** The overall type of this label */
  tag: TAG;
  /** Severity */
  sev: EEndLabelSeverity;
  /** If applicable, specific identifiers */
  keys?: string[];
  /** Internal text message describing this specific reason for the tag */
  text?: string;
}

export interface ITaskFunctionResponseBase<ET> {
  endId: ETaskFunctionEndId;
  endMessage?: string;
  endTags: ET[];
  labels?: ITaskFunctionEndLabel[];
  taskId?: string | null;
  uid?: string;
}

export interface ITaskFunctionNegativeResponse<TE, ET extends string = string> extends ITaskFunctionResponseBase<ET> {
  positive: false;
  errorPayload: TE;
  payload: null;
  endMessage: string;
}

export interface ITaskFunctionPositiveResponse<TP, ET extends string = string> extends ITaskFunctionResponseBase<ET> {
  positive: true;
  errorPayload: null;
  payload: TP;
}

export type ITaskFunctionResponse<TP = any, TE = any, ET extends string = string> =
  | ITaskFunctionNegativeResponse<TE, ET>
  | ITaskFunctionPositiveResponse<TP, ET>;

export type TFRPromise<TP = any, TE = any, ET extends string = string> = Promise<ITaskFunctionResponse<TP, TE, ET>>;

export type TObjectOfTaskFunctions<T extends string = any> = {
  [prop in T]?: (...args: any[]) => Promise<ITaskFunctionResponse>;
};
