import React from "react";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { HeaderText, SubHeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import {
  EAppColor,
  ESurfaceHeight,
  ETextSize,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { PostAsyncActions } from "../../state/asyncActions/PostAsyncActions";
import { FelaGrid } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaGrid";
import { AsyncNavLink } from "../../state/ASN_VibescoutSpecial";
import { EButtonSize, FelaButton } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaButton";
import { GoGraph } from "react-icons/go";
import { TempPostSearchSnippet } from "../../components/post/TempPostSearchSnippet";

export const PageErrorSection = React.memo(() => {
  console.log("Rendering Error Section");
  const postSearch = PostAsyncActions.searchPost.use({ text: "Things to do"! });

  return (
    <FelaFlexbox direction={"column"} grow={1}>
      <FelaFlexLayer grow={1} align={"center"} justify={"center"} direction={"column"}>
        <FelaFlexLayer
          shadow
          extraSoftShadow
          padding={0.5}
          radius={0.5}
          height={ESurfaceHeight.high}
          direction={"column"}
          align={"center"}
          margin={"1em 1em 3em 1em"}
        >
          <SubHeaderText size={ETextSize.content}>Something seems to have gone wrong.</SubHeaderText>
          <SubHeaderText margin={"1em 0 0 0"} size={ETextSize.small}>
            Please double check the URL you're trying to access. We apologize for the inconvenience, hopefully the issue
            will be fixed soon enough!
          </SubHeaderText>
        </FelaFlexLayer>
        <SubHeaderText>In the meantime...</SubHeaderText>
        <AsyncNavLink to={"/reports/coronavirus"}>
          <FelaButton size={EButtonSize.regular} neutralColor={EAppColor.accent}>
            <GoGraph />
            <span>Detailed Covid 19 stats and graphs</span>
          </FelaButton>
        </AsyncNavLink>
        {postSearch.renderPayload(({ posts }) => {
          return (
            <FelaFlexbox direction={"column"} padding={0.5}>
              <HeaderText margin={1}>Some Vibescout Content</HeaderText>
              <FelaGrid gap={0.5} gridTemplateColumns={"repeat(auto-fill, minmax(25em, 1fr))"}>
                {posts
                  .filter((p) => p.publishedUrl != null)
                  .sort((a, b) => b.dateLastPublished!.getTime() - a.dateLastPublished!.getTime())
                  .map((post) => (
                    <TempPostSearchSnippet
                      key={post.publishedUrl}
                      // isSmall={theme.isSmall}
                      post={post}
                    />
                  ))}
              </FelaGrid>
            </FelaFlexbox>
          );
        })}
      </FelaFlexLayer>
    </FelaFlexbox>
  );
});
