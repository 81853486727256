import { PSC_VibescoutSpecial } from "../../PSC_VibescoutSpecial";
import { WildcardUtilsClient } from "@gt/gt-frontend/build/utils/WildcardUtilsClient";
import { wildcard } from "../../../wildcard/VibescoutSpecial_WildcardClient";
import { TimeUtils } from "@gt/common-utils/build/general/TimeUtils";

const getCovidPageData = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getCovidPageData, "Get Covid Page Data"),
  {
    cacheBreakHook: ({ timeCached }) => timeCached < Date.now() - TimeUtils.CalculateMillis.hoursInMillis(1),
  },
);

const getRtForGeoLvl = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getRtForGeoLvl, "Get Rt For Geo Lvl"),
  {
    cacheBreakHook: ({ timeCached }) => timeCached < Date.now() - TimeUtils.CalculateMillis.hoursInMillis(1),
  },
);

const getBasicStatsForGeoLevel = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getBasicCovidStatsForGeoLevel, "Get Basic Covid Stats"),
);

export const searchGeoLevels = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.searchGeoLevels, "Search Geo Levels"),
);

export const CovidAsyncActions = {
  getCovidPageData,
  getRtForGeoLvl,
  getBasicStatsForGeoLevel,
};

/*
export const getGeoLevel = PSC_VibescoutSpecial.createAsyncAction<IOSearchGeoLevelsInput>(async (input) => {
  const levels = await wildcard.searchGeoLevels(input);

  if (levels.positive) {

  }
})*/
/*

export const getCovidMeta = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getCovidMeta, "Get Covid Meta"),
  {
    postActionHook: ({ stores: { CovidStore }, result }) => {
      if (!result.error) {
        let dateFormatted;

        try {
          dateFormatted = format(result.payload.dateUpdated, result.payload.dateUpdatedFormat);
        } catch (e) {
          dateFormatted = format(result.payload.dateUpdated, defaultDateFormat);
        }

        CovidStore.update(s => {
          s.meta = { ...result.payload, dateFormatted };
        });
      }
    },
  },
);

const queryLastTwelveDaysZA = ({ stateGeoLevel }: IOGetLastTwelveDaysInput = {}) => {
  const boolQuery = esb.boolQuery();

  if (stateGeoLevel) {
    boolQuery.filter(esb.termQuery("stateGeoLvl", stateGeoLevel));
  } else {
    boolQuery.filter(esb.termQuery("ccIso2", "ZA"));
  }

  return esb
    .requestBodySearch()
    .size(12)
    .query(boolQuery)
    .sort(esb.sort("date", "desc"))
    .toJSON();
};

const hasTotalTests = i => i.hasData.includes(ECovid19HasDataTypesFlat.testsTotal);
const hasNewTests = i => i.hasData.includes(ECovid19HasDataTypesFlat.testsNew);
const hasTotalCases = i => i.hasData.includes(ECovid19HasDataTypesFlat.casesTotal);

export interface IOGetLastTwelveDaysInput {
  stateGeoLevel?: string;
}

interface IOGetLastTwelveDaysOutput {
  caseItems: IDMCovid19DailyFlat[];
  testItems: IDMCovid19DailyFlat[];
  latestDeaths?: number;
  latestCases?: number;
  latestRecovered?: number;
}

export const getLastTwelveDays = createAsyncAction<IOGetLastTwelveDaysInput, IOGetLastTwelveDaysOutput>(
  async ({ stateGeoLevel }) => {
    console.log(`Querying with stateGeoLvl: ${stateGeoLevel}`);

    const response =
      stateGeoLevel != null
        ? await wildcard.anyQueryCovidDailyFlatState({
            query: queryLastTwelveDaysZA({ stateGeoLevel }),
            source: ECovid19DataSource.sa_covid_github,
          })
        : await wildcard.anyQueryCovidDailyFlat({
            query: queryLastTwelveDaysZA({ stateGeoLevel }),
            source: ECovid19DataSource.sa_covid_github,
          });

    if (response.positive) {
      console.log(response.payload.items);

      let orderedItems: IDMCovid19DailyFlat[] | undefined;
      // let latestDataItem: IDMCovid19DailyFlat | undefined;
      let latestDeaths: number | undefined;
      let latestRecovered: number | undefined;
      let latestCases: number | undefined;

      orderedItems = [...response.payload.items].reverse();
      // latestDataItem = orderedItems[orderedItems.length - 1];
      for (let i = orderedItems.length - 1; i >= 0; i -= 1) {
        if (latestDeaths == null) {
          latestDeaths = orderedItems[i].deathsTotal;
        }

        if (latestRecovered == null) {
          latestRecovered = orderedItems[i].recoveriesTotal;
        }

        if (latestCases == null) {
          latestCases = orderedItems[i].casesTotal;
        }

        if (latestDeaths != null && latestCases != null && latestRecovered != null) {
          break;
        }
      }

      return successResult({
        latestCases,
        latestDeaths,
        latestRecovered,
        caseItems: orderedItems.filter(hasTotalCases),
        testItems: orderedItems.filter(hasTotalTests),
      });
    }

    return errorResult([response.endId, ...response.endTags], response.endMessage);
  },
);

const queryDeathsZa = ({ stateGeoLevel }: IOAnyQueryCovidCaseInput = {}) => {
  const boolQuery = esb.boolQuery().filter(esb.termQuery("caseType", ECovid19CaseType.death));

  if (stateGeoLevel) {
    boolQuery.filter(esb.termQuery("glDi", stateGeoLevel));
  }

  return esb
    .requestBodySearch()
    .query(boolQuery)
    .size(1000)
    .from(0)
    .sort(esb.sort("date", "desc"))
    .toJSON();
};

export interface IOAnyQueryCovidCaseInput {
  stateGeoLevel?: string;
}

export const anyQueryCovidCases = createAsyncAction<IOAnyQueryCovidCaseInput>(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(async ({ stateGeoLevel }) => {
    const response = wildcard.anyQueryCovidCases({
      query: queryDeathsZa({ stateGeoLevel }),
      source: ECovid19DataSource.sa_covid_github,
    });

    console.log(response);

    return response;
  }, "Any Query Cases"),
);
*/
