import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { GraphSection, LegendItem } from "../components/Covid19Components";
import { colorsCovidPage } from "../Covid19Static";
import { MyResponsiveLine } from "../NivoChartTest";
import React from "react";

export const GraphTotalCases = ({ caseItems }) => {
  const data = caseItems.map((item) => ({ x: item.date.slice(5), y: item.casesTotal }));

  return (
    <>
      <FelaFlexbox align={"center"} padding={0.5}>
        <LegendItem name={"Total Cases"} color={colorsCovidPage.totalCases} />
      </FelaFlexbox>
      <FelaFlexbox style={{ height: "30vh", maxHeight: "22em" }}>
        <MyResponsiveLine
          yScaleExtras={{ min: 0 }}
          data={[
            {
              id: "Cases:",
              color: colorsCovidPage.totalCases,
              data,
            },
          ]}
        />
      </FelaFlexbox>
    </>
  );
};
