import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { GraphSection, LegendItem } from "../components/Covid19Components";
import { colorsCovidPage } from "../Covid19Static";
import { MyResponsiveLine } from "../NivoChartTest";
import React from "react";
import { MyResponsiveBar } from "../NivoBar";

export const GraphNewTests = ({ testItems }) => {
  return (
    <>
      <FelaFlexbox align={"center"} padding={0.5}>
        <LegendItem name={"Tests Administered Per Day"} color={colorsCovidPage.newTests} />
      </FelaFlexbox>
      <FelaFlexbox style={{ width: "100%", height: "15vh", minHeight: "13em", maxHeight: "18em" }}>
        <MyResponsiveLine
          // keys={["Tests"]}
          data={[
            {
              id: "Tests:",
              color: colorsCovidPage.newTests,
              data: testItems.map((item) => ({
                x: item.date.slice(5),
                y: item.testsNew,
                // Tests: item.testsNew,
                // date: item.date.slice(5),
                // color: colorsCovidPage.newTests
              })),
            },
          ]}
        />
      </FelaFlexbox>
    </>
  );
};
