import React from "react";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { HeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import { HorizontalScrollContainer } from "@gt/gt-frontend/build/react/vibescout-ui/components/HorizontalScrollContainer";
import { EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { MovieUtils } from "../../movies/MovieUtils";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { TPlaceNodeOutput } from "@vs/core/build/data/models/tempNodes/actions/_queryForAreaPlaceNodes";
import { TGeoLevelBasics } from "@vs/core/build/data/models/geolevels/GeoLevelModelTypes";
import { GiFilmProjector } from "react-icons/gi";
import { FiAlertTriangle } from "react-icons/fi";
import { CinemaListItem } from "../../../components/snippets/CinemaListItem";
import { PageSectionHeader } from "../../../components/content/PageSectionHeader";

interface ICPLocalityCinemaScroller {
  items: TPlaceNodeOutput[];
  geoLevel: TGeoLevelBasics;
  isSmall: boolean;
  udi: EUDI;
}

export const LocalityCinemaScroller: React.FC<ICPLocalityCinemaScroller> = ({ items, geoLevel, isSmall, udi }) => {
  console.log(`UID: ${udi}`);

  return (
    <FelaFlexbox direction={"column"} padding={0}>
      <PageSectionHeader
        isSmall={isSmall}
        padding={"1em 0.5em 0.5em 0.5em"}
        text={`Cinemas around ${geoLevel.name}`}
        icon={<GiFilmProjector />}
      />
      {/*<FelaFlexbox padding={isSmall ? 1 : 0.5} align={"center"}>
        <FelaIconWrap contrast={EForeContrast.strong} forceTheme={EFrontendStyleConfigBaseTheme.DARK} noBackground>
          <GiFilmProjector />
        </FelaIconWrap>
        <HeaderText forceTheme={EFrontendStyleConfigBaseTheme.DARK}>Cinemas around {geoLevel.name}</HeaderText>
      </FelaFlexbox>*/}
      <HorizontalScrollContainer scrollKey={geoLevel.id} touch={udi === EUDI.TOUCH}>
        <FelaFlexbox padding={1}>
          {items.map((item) => {
            const betterName = MovieUtils.betterName(item.name);
            const placeName = item.geo.ci?.[0]?.name;

            return (
              <CinemaListItem
                key={item.urlId}
                outerProps={{
                  margin: "0 1em 0 0",
                }}
                name={betterName}
                brandId={item.nodeAttr.temp.oldBrandId}
                cinemaId={item.nodeAttr.temp.oldCinemaId}
                isSmall={isSmall}
                area={placeName}
                brandLogoImage={item.brand.logo?.s?.url}
                image={item.nodeAttr?.temp?.oldEntityImage?.thumbnailUrl}
                childrenOverImage={
                  geoLevel.lvlValue < 100 && item.sortPos < 1 ? (
                    <FullAbsFlexbox
                      direction={"column"}
                      align={"flex-end"}
                      justify={"flex-start"}
                      pointerEvents={false}
                    >
                      <FelaFlexLayer
                        direction={"column"}
                        backgroundOpacity={0.8}
                        height={ESurfaceHeight.shadow}
                        radius={0.5}
                        align={"center"}
                        margin={0.5}
                        padding={"0.3em 0.5em"}
                      >
                        <FelaFlexbox align={"center"}>
                          <FelaIconWrap neutralColor={EAppColor.warning} noBackground size={ETextSize.small}>
                            <FiAlertTriangle />
                          </FelaIconWrap>
                          <FelaText
                            size={ETextSize.small}
                            weight={ETextWeightType.black}
                            transform={"uppercase"}
                            neutralColor={EAppColor.warning}
                          >
                            Far
                          </FelaText>
                        </FelaFlexbox>
                        <FelaText
                          contrast={EForeContrast.extra}
                          forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                          size={ETextSize.small}
                          weight={ETextWeightType.black}
                        >
                          {Math.round((item as any).distanceTo / 1000)}km
                        </FelaText>
                      </FelaFlexLayer>
                    </FullAbsFlexbox>
                  ) : undefined
                }
              />
            );
          })}
        </FelaFlexbox>
      </HorizontalScrollContainer>
    </FelaFlexbox>
  );
};
