const ANALYTICS_EVENT_TYPES = {
  PAGEVIEW: "PAGEVIEW",
  PAGEVIEW_WITH_EXTRA: "PAGEVIEW_WITH_EXTRA",
  REGULAR_EVENT: "REGULAR_EVENT",
  EVENT_WITH_PAGEVIEW: "EVENT_WITH_PAGEVIEW",
  EMAIL_SIGN_UP: "EMAIL_SIGN_UP",
};

function trackPageView() {
  window.ga('send', 'pageview');
}

function trackPageViewWithExtra(extra) {
  window.ga('send', 'pageview', `${location.pathname}/${extra}`);
}

function trackEvent(category, action, label = null, value = null) {
  if (value) {
    window.ga('send', 'event', category, action, label, value);
  } else if (label) {
    window.ga('send', 'event', category, action, label);
  } else {
    window.ga('send', 'event', category, action);
  }
}

function runAnalyticEvent(eventType, payload) {
  console.log(`Running Analytic Event: ${eventType} -> ${payload.category} : ${payload.action} : ${payload.label}`);
  // console.dir(payload);

  if (typeof window !== 'undefined' && window.ga) {
    switch (eventType) {
      case ANALYTICS_EVENT_TYPES.PAGEVIEW:
        trackPageView();
        break;
      case ANALYTICS_EVENT_TYPES.PAGEVIEW_WITH_EXTRA:
        trackPageViewWithExtra(payload.extra);
        break;
      case ANALYTICS_EVENT_TYPES.REGULAR_EVENT:
        trackEvent(payload.category, payload.action, payload.label, payload.value);
        break;
      case ANALYTICS_EVENT_TYPES.EVENT_WITH_PAGEVIEW:
        trackEvent(payload.category, payload.action, payload.label, payload.value);
        trackPageView();
        break;
      case ANALYTICS_EVENT_TYPES.EMAIL_SIGN_UP:
        trackEvent("Mailing List", "signup", payload.label);
        break;
      default:
        break;
    }
  }
}

export const GoogleAnalyticsEventUtils = {
  ANALYTICS_EVENT_TYPES,
  runAnalyticEvent,
};
