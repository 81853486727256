import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
  ETextTypographyType,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { AsyncNavLink } from "../../state/ASN_VibescoutSpecial";
import { SimpleImage } from "@gt/gt-frontend/build/react/vibescout-ui/media/SimpleImage";
import { FullAbsFlexbox, FullAbsFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { EGradientType } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import React from "react";
import { OldPostUtils } from "@vs/core/build/data/models/oldVibescout/oldPost/OldPostUtils";
import { ColorUtils } from "@gt/common-utils/build/general/ColorUtils";
import { IOldPostSnippetWithColorInformation } from "../../state/stores/LocalityStore/LocalityStore";
import { ZIndexes } from "@gt/gt-frontend/build/styling/VibescoutZIndexes";

interface ICPPostSnippet {
  post: IOldPostSnippetWithColorInformation;
  isSmall: boolean;
}

export const PostSnippetStatic = {
  borderWidth: 0.2,
};

export const PostSnippet = React.memo<ICPPostSnippet>(({ post, isSmall }) => {
  const postUrl = `/${OldPostUtils.getNewUrlForOldPostId(post.postUrl)}`;
  const newRgbArray = ColorUtils.createRgbArrayUISaturatedColor(post.colorRgbArray);

  return (
    <FelaFlexLayer
      radius={0.5}
      grow={1}
      direction={"column"}
      hoverable
      clickable
      overflow={"hidden"}
      position={"relative"}
      extraSoftShadow
      backgroundColor={newRgbArray}
    >
      {/*<FullAbsFlexLayer
        pointerEvents={false}
        backgroundString={"none"}
        zIndex={ZIndexes.mainContent + 1}
        backgroundNeutralColor={EColorsNeutralName.warning}
        radius={isSmall ? 0 : 0.75}
        borderWidth={isSmall ? undefined : `${PostSnippetStatic.borderWidth}em`}
        borderHeight={ESurfaceHeight.high}
        borderOpacity={0.5}
      />*/}
      <AsyncNavLink key={post.postUrl} to={postUrl}>
        <SimpleImage
          // outerProps={{ overflow: "hidden", radius: isSmall ? undefined : "0.35em 0.35em 0 0" }}
          src={post.thumbnailUrl}
          aspect={2.67}
        >
          <FullAbsFlexLayer
            forceTheme={EFrontendStyleConfigBaseTheme.DARK}
            gradient={[
              {
                stops: [
                  { height: ESurfaceHeight.highest, opacity: 0.25 },
                  { height: ESurfaceHeight.highest, opacity: 0.05, def: "50%" },
                  { color: newRgbArray, opacity: 0.35, def: "98%" },
                  { color: newRgbArray, opacity: 0.95 },
                ],
              },
            ]}
          />
        </SimpleImage>
        <FelaFlexbox padding={"0.75em"} style={{ minHeight: "4em" }} grow={1}>
          <FelaText
            weight={"bold"}
            colorHex={"#FFFFFF"}
            // colorHex={`rgb(${newRgbArray[0]})`}
            typography={ETextTypographyType.sansSerif}
            transform={"uppercase"}
            size={ETextSize.content}
          >
            {post.title}
          </FelaText>
        </FelaFlexbox>
        <FelaFlexLayer
          // radius={isSmall ? undefined : "0 0 0.65em 0.65em"}
          /*margin={
            isSmall
              ? undefined
              : `0 ${PostSnippetStatic.borderWidth}em ${PostSnippetStatic.borderWidth}em ${PostSnippetStatic.borderWidth}em`
          }*/
          gradient={[
            {
              stops: [
                { color: newRgbArray, opacity: 0.25, def: "1em" },
                { color: newRgbArray, opacity: 0.1 },
              ],
            },
            {
              stops: [
                { height: ESurfaceHeight.low },
                // { height: ESurfaceHeight.low, def: "0.15em" },
                { height: ESurfaceHeight.mid, def: "2em" },
                // { height: ESurfaceHeight.upper, def: "1em" },
              ],
            },
          ]}
          grow={1}
          justify={"flex-end"}
          direction={"column"}
          // padding={`0.5em ${0.75 - PostSnippetStatic.borderWidth}em`}
          padding={`0.5em 0.75em`}
        >
          <FelaFlexbox style={{ maxHeight: "2.5em" }} overflow={"hidden"} position={"relative"}>
            <FullAbsFlexbox justify={"flex-end"} align={"flex-end"}>
              <FelaFlexLayer
                padding={"0 0 0 3.5em"}
                gradient={[
                  /*{
                    type: EGradientType.radial,
                    def: "3em 2em at right bottom",
                    stops: [
                      { height: ESurfaceHeight.mid },
                      // { neutral: EColorsNeutralName.warning },
                      { height: ESurfaceHeight.mid, def: "50%" },
                      {},
                    ],
                  },*/
                  /*{
                    stops: [{ color: newRgbArray, opacity: 0.1 }],
                  },*/
                  {
                    type: EGradientType.radial,
                    def: "3em 2em at right bottom",
                    stops: [
                      { color: newRgbArray, opacity: 0.15 },
                      { color: newRgbArray, opacity: 0.15, def: "60%" },
                      {},
                    ],
                  },
                  {
                    type: EGradientType.radial,
                    def: "3em 2em at right bottom",
                    stops: [
                      { height: ESurfaceHeight.mid },
                      { height: ESurfaceHeight.mid, def: "50%" },
                      // { height: ESurfaceHeight.low, def: "0.15em" },
                      // { height: ESurfaceHeight.mid, def: "2em" },
                      {},
                      // { height: ESurfaceHeight.upper, def: "1em" },
                    ],
                  },
                ]}
              >
                <FelaText weight={"bold"} colorHex={`rgb(${newRgbArray[0]})`} size={ETextSize.content}>
                  ...
                </FelaText>
              </FelaFlexLayer>
            </FullAbsFlexbox>
            <FelaText
              lineHeight={1.5}
              weight={ETextWeightType.regular}
              style={{ textOverflow: "ellipsis" }}
              contrast={EForeContrast.extra}
              // weight={"bold"}
              // colorHex={`rgb(${newRgbArray[0]})`}
              typography={ETextTypographyType.sansSerif}
              transform={"none"}
              size={ETextSize.small}
            >
              {post.subtitle}
            </FelaText>
          </FelaFlexbox>
        </FelaFlexLayer>
      </AsyncNavLink>
    </FelaFlexLayer>
  );
});
