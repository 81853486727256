import React from "react";
import { EGradientType } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { ESurfaceHeight } from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { maxWidthPageStyles } from "../../../config/StaticStyleConfig_VibescoutSpecial";

interface IImage {
  colorRgbArray?: TColorRgbArray;
  srcUrl: string;
}

export interface ICPPageTitleImage {
  aspect: number;
  image: IImage;
  alt?: string;
}

export const PageTitleImage: React.FC<ICPPageTitleImage> = ({ image, aspect, children }) => {
  return (
    <FelaFlexLayer
      // height={ESurfaceHeight.shadow}
      gradient={[
        {
          type: EGradientType.radial,
          def: "circle at 50% -25%",
          stops: [
            { color: image.colorRgbArray, darken: 50, opacity: 1 },
            { color: image.colorRgbArray, darken: 50, def: "53em", opacity: 0.5 },
            { color: image.colorRgbArray, opacity: 0.4 /*def: "75em"*/ },
          ],
        },
      ]}
      direction={"column"}
      align={"center"}
      position={"relative"}
    >
      <FullAbsFlexbox
        cssExtra={[
          {
            opacity: 0.35,
            filter: "blur(3px) saturate(10%)",
            backgroundSize: "cover",
            backgroundImage: `url(${image.srcUrl})`,
          },
        ]}
      />
      <div>
        <FelaFlexLayer
          shadow
          extraSoftShadow
          height={ESurfaceHeight.shadow}
          style={{
            ...maxWidthPageStyles,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url(${image.srcUrl})`,
            backgroundPosition: "50% 50%",
          }}
          position={"relative"}
          direction={"column"}
          aspect={aspect}
        >
          {/*<SimpleImage objectPosition={"center"} alt={alt ?? "Title image"} aspect={aspect} src={image.srcUrl} />*/}
          {children}
        </FelaFlexLayer>
      </div>
    </FelaFlexLayer>
  );
};
