import { browserHistory } from "../entry_VibescoutFrontendSpecial";
import { IPullstateInstanceConsumable, useInstance } from "pullstate";
import {
  AsyncNavigator,
  EAsyncNavigatorTimePosition,
  ENavigationMetaPageType,
  IPathWithAsyncState,
  TAsyncNavigatorResolver,
} from "@gt/gt-frontend/build/utils/NavigationUtils/AsyncNavigator";
import { TAllStores_VibescoutSpecial } from "./PSC_VibescoutSpecial";
import { _navOldSiteRedirects, _navRedirectOldPosts } from "./navigation/_navOldSiteRedirects";
import { _navLocalityScout } from "./navigation/_navLocalityContent";
import { _navCoronavirusReports, _redirects_navCoronavirusReports } from "./navigation/_navCoronavirusReports";
import { _navPostPage } from "./navigation/_navPostPage";
import { IUnavailableRoutes } from "@gt/gt-frontend/build/utils/NavigationUtils/AsyncNavigatorTypes";
import { render } from "tplv";
import _ from "lodash";
import { StringUtils } from "@gt/common-utils/build/datatypes/StringUtils";
import { _navLocalityIndex } from "./navigation/_navLocalityIndex";
import { VibescoutSpecialErrorReporter } from "../FrontendConfig_VibescoutSpecial";
import { TaskFunctionError, TaskFunctionUtils } from "@gt/common-utils/build/taskFunction/TaskFunctionUtils";
import { TFRFailure } from "@gt/common-utils/build/taskFunction/TaskFunctionResponses";
import { ETaskFunctionEndId } from "@gt/common-utils/build/taskFunction/TaskFunctionTypes";
import { _navCinemaPage, _navMoviePage } from "./navigation/_navMoviePages";
import { _psNav_userLocationToLocality } from "./pseudoNavigation/_psNav_userLocationToLocality";
import queryString from "query-string";
import { IUIStorePageMeta } from "@vs/core/build/frontend/state/common/stores/UIStore/UIStore.Types";

export interface IAsyncNavigatorState {
  instance: IPullstateInstanceConsumable<TAllStores_VibescoutSpecial>;
}

export type TVibescoutSpecialResolverFunction = TAsyncNavigatorResolver<
  IAsyncNavigatorState,
  Partial<IVibescoutCustomPageMeta & IUIStorePageMeta>,
  any
>;
export type TVibescoutSpecialResolver = IPathWithAsyncState<
  IAsyncNavigatorState,
  Partial<IVibescoutCustomPageMeta & IUIStorePageMeta>,
  any
>;

export interface IVibescoutCustomPageMeta {
  showTitleBar?: boolean;
  fallbackSearchTerm?: string;
  noSharing?: boolean;
  shareUrl?: string;
  // pageType?: ENavigationMetaPageType;
  // title?: string;
  // metaTitle?: string;
  // metaDescription?: string;
  // searchTerm?: string;
  // ogImage?: { url: string; width: string; height: string };
}

export const Vibescout_RoutesUnavailable: IUnavailableRoutes<Partial<IVibescoutCustomPageMeta & IUIStorePageMeta>>[] = [
  { paths: ["/za/:city/top-events-this-month"], meta: { fallbackSearchTerm: "events ${city}" } },
  { paths: ["/za/municipality/:municipality"], meta: { fallbackSearchTerm: "events ${municipality}" } },
  { paths: ["/za/province/:province"], meta: { fallbackSearchTerm: "events ${province}" } },
  {
    paths: [/*"/za/movies*",*/ "/za/cinemas", "/za/cinemas/:brandId/:cinemaId/:movieId" /*, "/za/cinemas*"*/],
    meta: { fallbackSearchTerm: "movies" },
  },
  { paths: ["/admin*", "/sign-in*"], meta: { fallbackSearchTerm: "" } },
  // { paths: ["/locality-index*"], meta: { fallbackSearchTerm: "places" } },
  { paths: ["/things-to-do-in-*", "/things-to-do-around-me*"], meta: { fallbackSearchTerm: "things to do" } },
  { paths: ["/host*", "/event*"], meta: { fallbackSearchTerm: "events" } },
];

export const ASN_VibescoutSpecial = new AsyncNavigator<
  IAsyncNavigatorState,
  Partial<IVibescoutCustomPageMeta & IUIStorePageMeta>
>({
  getDefaultMeta: () => ({}),
  onFinishResolve: ({ ctx, matchInfo, state: { instance } }) => {
    if (typeof document !== "undefined") {
      const cleverAdElements = document.querySelectorAll(`[id^="clever"]`);
      console.log(cleverAdElements); // 👉️ [div#box1, div#box2, div#box3]
      cleverAdElements.forEach((ele) => {
        ele.remove();
      });
    }

    let defaultPageType: ENavigationMetaPageType = ENavigationMetaPageType.regular;

    if (ctx.status !== 200) {
      if (ctx.status === 404) {
        defaultPageType = ENavigationMetaPageType.not_found;
      } else {
        defaultPageType = ENavigationMetaPageType.unknown_error;
      }
    }

    const {
      meta: {
        showTitleBar = true,
        fallbackSearchTerm = "things to do",
        noSharing = !(defaultPageType === ENavigationMetaPageType.regular),
        pageType = defaultPageType,
        metaDescription,
        metaTitle,
        shareUrl,
        title,
        searchTerm,
        ogImage,
      },
      skipped,
    } = matchInfo[matchInfo.length - 1];

    const navigationMeta: IVibescoutCustomPageMeta = {
      showTitleBar,
      fallbackSearchTerm,
      noSharing,
      // pageType,
    };

    if (skipped) {
      throw new TaskFunctionError(
        TFRFailure(ETaskFunctionEndId.ERROR, `Async Navigation: Can't have skipped the final resolver that matched`),
      );
    }

    if (ctx.errors.length === 0) {
      console.log(`Setting navigation meta (STATUS: ${ctx.status})`, navigationMeta);

      instance.stores.UIStore.update((s, o) => {
        s.customPageMeta = navigationMeta;
        s.pageMeta = {
          pageType,
          shareUrl: shareUrl ?? `https://www.vibescout.com${matchInfo[0].match.pathname}`,
          title: title ?? o.pageMeta.title,
          metaTitle: metaTitle ?? o.pageMeta.metaTitle,
          metaDescription: metaDescription ?? o.pageMeta.metaDescription,
          ogImage: ogImage ?? o.pageMeta.ogImage,
          searchTerm: searchTerm ?? o.pageMeta.searchTerm,
        };
      });
    } else {
      console.error(`Async Navigation Errors occurred - skipping meta updates`);
    }
  },
  getReactState: () => {
    return { instance: useInstance() };
  },
});

ASN_VibescoutSpecial.addRoutes([
  _psNav_userLocationToLocality,
  ...Vibescout_RoutesUnavailable.map(({ paths, meta }) => ({
    parallel: false,
    paths,
    meta: {
      pageType: ENavigationMetaPageType.maintenance,
      noSharing: true,
    },
    resolve: (async ({
      ctx,
      info,
      state: {
        instance: {
          stores: { UIStore },
        },
      },
      updateMeta,
    }) => {
      console.log(`Hit unavailable path: ${info.pathname}`);
      let searchTerm: string;

      try {
        searchTerm = render(
          meta.fallbackSearchTerm!,
          _.mapValues(info.params, (val) => StringUtils.convertToSlug(val, { slugDivider: " " })),
        );
      } catch (e) {
        searchTerm = meta.fallbackSearchTerm!;
      }

      console.log(`Searching for "${searchTerm}"`);

      updateMeta((m) => {
        m.fallbackSearchTerm = searchTerm;
      });

      /*UIStore.update((s) => {
        s.pageMeta = {
          shareUrl: "",
          title: "Temporarily Unavailable",
          metaTitle: "Temporarily Unavailable",
          metaDescription: "This page is currently under maintenance. Check back soon.",
        };
      });*/
      ctx.status = 503;
    }) as TVibescoutSpecialResolverFunction,
  })),
  _navMoviePage,
  _navCinemaPage,
  _navLocalityScout,
  _navPostPage,
  _redirects_navCoronavirusReports,
  _navOldSiteRedirects,
  _navRedirectOldPosts,
  _navLocalityIndex,
  {
    meta: {
      showTitleBar: false,
    },
    paths: ["/"],
    resolve: async ({
      state: {
        instance: {
          stores: { UIStore },
        },
      },
      updateMeta,
    }) => {
      /*UIStore.update((s) => {
        s.pageMeta = {
          title: "Vibescout",
          metaTitle: "Vibescout",
          metaDescription: "Things to do, near you",
          shareUrl: "https://www.vibescout.com",
        };
      });*/
      updateMeta((m) => {
        m.title = "Vibescout";
        m.metaTitle = "Vibescout";
        m.metaDescription = "Things to do, near you";
        m.shareUrl = "https://www.vibescout.com";
      });
    },
  },
  {
    paths: ["*"],
    resolve: async ({ ctx }) => {
      ctx.redirect = { path: "/" };
      ctx.status = 302;
    },
  },
]);

ASN_VibescoutSpecial.setOnClientNavigate(({ path, query }) => {
  console.log(`Navigating to path: ${path}`);
  browserHistory.push({
    pathname: path,
    search: query != null ? queryString.stringify(query) : "",
  });
});

ASN_VibescoutSpecial.listen(({ ctx, pos, matchInfo }) => {
  if (pos === EAsyncNavigatorTimePosition.END && ctx.errors.length > 0) {
    const resolverErrors = ctx.errors.map(
      (e) =>
        `${ctx.status}: pathname(${e.info.match.pathname}) matched_path(${
          e.info.match.path
        }) TaskFunctionError: ${TaskFunctionUtils.printTaskFunctionError(e.error.taskFunctionResponse)}`,
    );

    VibescoutSpecialErrorReporter.report(`Async Nav Error: [ ${resolverErrors.join(", ")} ]`);
  }
});

export const AsyncNavLink = ASN_VibescoutSpecial.Link;
