import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { LegendItem } from "../components/Covid19Components";
import { colorsCovidPage } from "../Covid19Static";
import React from "react";
import { MyResponsiveBar } from "../NivoBar";
import _ from "lodash";
import { IDMCovid19DailyFlat } from "@vs/core/build/data/models/covid19/Covid19DailyFlatModel";
import { MyResponsiveLine } from "../NivoChartTest";

export const GraphNewDeaths: React.FC<{ caseItems: IDMCovid19DailyFlat[] }> = ({ caseItems }) => {
  /*let deathTicks: number[] | undefined;

  const maxDeaths = caseItems.reduce((cur, item) => {
    if (item.deathsNew! > cur) {
      cur = item.deathsNew!;
    }

    return cur;
  }, 0);

  // console.log(maxDeaths);

  if (maxDeaths <= 10) {
    const maxDeathTicks = Math.min(10, maxDeaths);
    const deathTickInterval: number = maxDeaths / maxDeathTicks;
    deathTicks = _.times(maxDeathTicks, (pos) => {
      return Math.round((pos + 1) * deathTickInterval);
    });

    deathTicks.unshift(0);
  }*/

  console.log(caseItems);

  return (
    <>
      <FelaFlexbox align={"center"} padding={0.5}>
        <LegendItem name={"New Deaths Per Day"} color={colorsCovidPage.newDeaths} />
      </FelaFlexbox>
      <FelaFlexbox style={{ width: "100%", height: "15vh", minHeight: "13em", maxHeight: "18em" }}>
        <MyResponsiveLine
          yScaleExtras={{
            min: 0,
          }}
          data={[
            {
              id: "Deaths:",
              color: "hsl(262,96%,68%)",
              data: caseItems.map((item) => {
                const deathsNew = item.deathsNew ?? 0;
                return {
                  y: deathsNew > 0 ? deathsNew : 0.1,
                  x: item.date.slice(5),
                  // Deaths: item.deathsNew,
                  // date: item.date.slice(5),
                };
              }),
            },
          ]}
        />
      </FelaFlexbox>
    </>
  );
};
