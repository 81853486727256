import wildcardClient from "@wildcard-api/client";
import { TWildcardApi_Endpoints_VibescoutSpecial } from "../../server/WildcardApi_VibescoutSpecial";

wildcardClient.baseUrl = "/_vs_api/wildcard";
wildcardClient.argumentsAlwaysInHttpBody = true;

/*
if (!ExecutionEnvironmentUtils.isNode()) {
  wildcardClient.serverUrl = `${window?.location?.origin}/reports/_api`;
}
// wildcardClient.serverUrl = `${process.env.BASE_HOST_URL}/reports/_api`;*/

export const wildcard: TWildcardApi_Endpoints_VibescoutSpecial = wildcardClient.endpoints;
