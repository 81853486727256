import { TVibescoutSpecialResolver } from "../ASN_VibescoutSpecial";
import { GeoLevelAsyncActions } from "../asyncActions/GeoLevelAsyncActions";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";

export const _navOldSiteRedirects: TVibescoutSpecialResolver = {
  paths: ["/za/:city", "za/:city/suburb/:suburb"],
  resolve: async ({ ctx, info, state: { instance } }) => {
    const oldId = info.params.suburb ? `za/${info.params.city}/${info.params.suburb}` : `za/${info.params.city}`;

    const oldToGeoLevelResponse = await instance.runAsyncAction(
      GeoLevelAsyncActions.oldLocalityIdToGeoLevel,
      { oldId },
      { respectCache: true },
    );

    if (oldToGeoLevelResponse.error) {
      console.error(`Couldn't find geolevel for old locality ID: ${oldId}`);
      ctx.redirect = {
        path: info.params.suburb
          ? `/za/city/${info.params.city}/suburb/${info.params.suburb}`
          : `/za/city/${info.params.city}`,
      };
      ctx.status = 302;
    } else {
      const { geoLvlShort } = oldToGeoLevelResponse.payload;
      console.log(`Found geolevel for old ID: ${oldId} -> ${geoLvlShort}`);
      ctx.redirect = {
        path: `/${GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: geoLvlShort })}`,
      };
      ctx.status = 302;
    }
  },
};

export const _navRedirectOldPosts: TVibescoutSpecialResolver = {
  paths: ["za/:city/:post"],
  resolve: async ({ ctx, info, state: { instance } }) => {
    const oldId = `za/${info.params.city}`;

    const oldToGeoLevelResponse = await instance.runAsyncAction(
      GeoLevelAsyncActions.oldLocalityIdToGeoLevel,
      { oldId },
      { respectCache: true },
    );

    if (oldToGeoLevelResponse.error) {
      console.error(`Couldn't find geolevel for old locality ID: ${oldId}`);
      ctx.redirect = {
        path: `/za/city/${info.params.city}/post/${info.params.post}`,
      };
      ctx.status = 302;
    } else {
      const { geoLvlShort } = oldToGeoLevelResponse.payload;
      console.log(`Found geolevel for old ID: ${oldId} -> ${geoLvlShort}`);
      ctx.redirect = {
        path: `/${GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: geoLvlShort })}/post/${info.params.post}`,
      };
      ctx.status = 302;
    }
  },
};
