import { TVibescoutSpecialResolver } from "../ASN_VibescoutSpecial";
import { PostAsyncActions } from "../asyncActions/PostAsyncActions";
import { OldPostUtils } from "@vs/core/build/data/models/oldVibescout/oldPost/OldPostUtils";
import { TaskFunctionError } from "@gt/common-utils/build/taskFunction/TaskFunctionUtils";
import { PullstateAsyncUtils } from "@gt/gt-frontend/build/utils/PullstateAsyncUtils";

export const _navPostPage: TVibescoutSpecialResolver = {
  paths: ["/za/post/:post", "/za/city/:city/post/:post"],
  resolve: async ({ ctx, info, state: { instance } }) => {
    console.log(`Got new locality post route`, info);
    const ci = info.params.city?.split("_")[0] ?? undefined;

    let postResponse = await instance.runAsyncAction(
      PostAsyncActions.getPost,
      {
        cc: "za",
        ci,
        id: info.params.post,
      },
      {
        respectCache: true,
      },
    );

    if (!postResponse.error) {
      // console.log(postResponse.payload);
      const { redirectId, post } = postResponse.payload;

      if (redirectId) {
        // const { cc, ci, id } = OldPostUtils.getPartsForOldPostId(postResponse.payload.redirectId);

        ctx.redirect = {
          path: `/${OldPostUtils.getNewUrlForOldPostId(redirectId)}`,
        };
        ctx.status = 302;

        /*postResponse = await instance.runAsyncAction(PostAsyncActions.getPost, {
          cc,
          ci: rest.length > 0 ? ci : undefined,
          id,
        });*/
      } else if (post) {
        instance.stores.UIStore.update((s) => {
          s.pageMeta = {
            shareUrl: `https://www.vibescout.com${info.pathname}`,
            title: post.title,
            metaDescription: post.subtitle,
            metaTitle: post.title,
            ogImage: {
              url: post.titleImage.url,
              width: `${post.titleImage.meta.width}`,
              height: `${post.titleImage.meta.height}`,
            },
          };
        });
      }
    } else {
      throw new TaskFunctionError(PullstateAsyncUtils.asyncResponseToTaskFunctionErrorResponse(postResponse));
    }
  },
};
