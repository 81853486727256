import tinycolor, { Instance } from "tinycolor2";

export function optimizeColorForTextBackground(
  rgbObjectOrArray,
  tiny: Instance | null = null,
  colorRgbObject = false,
  colorArray = false,
):
  | {
      color: Instance;
      isLight: boolean;
    }
  | [number, number, number] {
  let color;

  if (tiny) {
    color = tiny;
  } else if (Array.isArray(rgbObjectOrArray)) {
    const [r, g, b] = rgbObjectOrArray;
    color = tinycolor({ r, g, b });
  } else {
    color = tinycolor(rgbObjectOrArray);
  }

  const brightness = color.getBrightness();
  let isLight = false;

  // console.log(`brightness: ${brightness}`);

  if (brightness > 200) {
    isLight = true;

    if (brightness > 245) {
      const difference = (brightness - 245) / 2.25;
      color = color.darken(difference);
    }
  } else if (brightness > 170) {
    const difference = (brightness - 170) / 2.25;

    color = color.darken(difference);
  } else if (brightness < 60) {
    const difference = (60 - brightness) / 2.25;

    color = color.brighten(difference);
  }

  if (colorRgbObject) {
    return {
      color: color.toRgb(),
      isLight,
    };
  }

  if (colorArray) {
    const { r, g, b } = color.toRgb();
    return [r, g, b];
  }

  return {
    color,
    isLight,
  };
}

export function createUISaturatedColor(tinyColor) {
  const hsl = tinyColor.toHsl();
  // const brightness = color.getBrightness();

  if (hsl.s < 0.4) {
    tinyColor.saturate((0.4 - hsl.s) * 100);
  } else if (hsl.s > 0.6) {
    tinyColor.desaturate((hsl.s - 0.6) * 100);
  }

  if (hsl.l < 0.6) {
    tinyColor.lighten((0.6 - hsl.l) * 100);
  } else {
    tinyColor.darken((hsl.l - 0.6) * 100);
  }

  return tinyColor;
}

const lightnessLimit = 360;
const darknessLimit = 260;

// DON'T TOUCH
const lightnessArea = 765 - lightnessLimit;
const darknessArea = 765 - darknessLimit;
const lightnessRelativeArea = (255 / lightnessArea) * 255;
const darknessRelativeArea = (255 / darknessArea) * 255;

export function createUISaturatedColorFromRgbArray(colorArray) {
  const [str, ...rgb] = colorArray;

  const total = rgb.reduce((accum, val) => accum + val, 0);

  let newRgb = rgb;

  if (total > lightnessLimit) {
    // GET 0 - 1 fraction for lightness amount and
    // multiply by the colour spectrum out of 255 (160 = (255 / 405) * 255)
    // that this lightness area falls into
    const lightnessAmount = ((total - lightnessLimit) / lightnessArea) * lightnessRelativeArea;
    // console.log(`Editing color total: ${total} - amount to reduce: ${lightnessAmount}`);
    newRgb = rgb.map((val) => Math.round(Math.max(0, val - lightnessAmount)));
  }

  if (total < darknessLimit) {
    const darknessAmount = (1 - total / darknessLimit) * darknessRelativeArea;
    // console.log(`Editing color total: ${total} - amount to reduce: ${darknessAmount}`);
    newRgb = rgb.map((val) => Math.round(Math.min(255, val + darknessAmount)));
  }

  console.log(`rgb: (${str}) -> (${newRgb.join(", ")})`);

  return [newRgb.join(", "), ...newRgb];
}
