import { TObjectKeyEnumSet } from "@gt/common-utils/build/typescript/SpecialTypes";

/*export enum EPageSizeRange {
  smallMobile = "smallMobile",
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
  bigDesktop = "bigDesktop",
}*/

export enum EPageSizeRange {
  smallMobile = 0,
  mobile = 1,
  tablet = 2,
  desktop = 3,
  bigDesktop = 4,
}

/*export const CValueForPageSizeRange: TObjectKeyEnumSet<EPageSizeRange, number> = {
  [EPageSizeRange.smallMobile]: 0,
  [EPageSizeRange.mobile]: 1,
  [EPageSizeRange.tablet]: 2,
  [EPageSizeRange.desktop]: 3,
  [EPageSizeRange.bigDesktop]: 4,
};*/

/*export const CMaxWidthForPageSizeRange: TObjectKeyEnumSet<EPageSizeRange, number> = {
  [EPageSizeRange.smallMobile]: 290,
  [EPageSizeRange.mobile]: 585,
  [EPageSizeRange.tablet]: 1023,
  [EPageSizeRange.desktop]: 2000,
  [EPageSizeRange.bigDesktop]: 200000,
};*/

export const CMaxWidthForPageSizeRange: { [pageSize: number]: number } = {
  [EPageSizeRange.smallMobile]: 290,
  [EPageSizeRange.mobile]: 585,
  [EPageSizeRange.tablet]: 1023,
  [EPageSizeRange.desktop]: 2000,
  [EPageSizeRange.bigDesktop]: 200000,
};

/*interface IPageSizeArrayItem {
  size: EPageSizeRange;
  maxWidth: number;
}

const pageSizeRangeArray: IPageSizeArrayItem[] = Object.keys(CMaxWidthForPageSizeRange)
  .map(
    (k): IPageSizeArrayItem => ({
      size: Number(k) as EPageSizeRange,
      maxWidth: CMaxWidthForPageSizeRange[k],
    }),
  )
  .sort((a, b) => a.maxWidth - b.maxWidth);

*/

/*export function pageSizeValue(size: EPageSizeRange): number {
  return CValueForPageSizeRange[size];
}*/

export enum EUDI {
  MOUSE = "MOUSE",
  TOUCH = "TOUCH",
}

function initializeAndDetectUserDeviceInterface(detected: (udi: EUDI) => void) {
  function onMouseMove() {
    console.log(`Detected MOUSE!`);
    window.removeEventListener("mousemove", onMouseMove);
    detected(EUDI.MOUSE);
  }

  function onTouchStart(e) {
    console.log(`Detected TOUCH!`);
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("touchstart", onTouchStart);
    e.preventDefault();
    e.stopImmediatePropagation();
    detected(EUDI.TOUCH);
  }

  window.addEventListener("mousemove", onMouseMove);
  window.addEventListener("touchstart", onTouchStart);
}

function initializeAndDetectUserDevicePageSize(detected: (size: EPageSizeRange) => void) {
  function getSize() {
    for (const val of Object.keys(CMaxWidthForPageSizeRange)) {
      if (window.innerWidth <= CMaxWidthForPageSizeRange[val]) {
        detected(Number(val) as EPageSizeRange);
        break;
      }
    }
  }

  window.addEventListener("resize", getSize);
  getSize();
}

export const DetectDeviceUtils = {
  initializeAndDetectUserDeviceInterface,
  initializeAndDetectUserDevicePageSize,
};
