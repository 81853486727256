import { createRenderer, IRenderer } from "fela";

import importantPlugin from "fela-plugin-important";
import fallbackValue from "fela-plugin-fallback-value";
import unit, { Unit } from "fela-plugin-unit";
import extend from "fela-plugin-extend";
import namedKeys from "fela-plugin-named-keys";
import monolithic from "fela-monolithic";
import prefixer from "fela-plugin-prefixer";

// import validatorPlugin from "fela-plugin-validator";

export enum EMediaDimensions {
  DesktopStart = 1024,
  TabletStart = 585,
  MobileSmallEnd = 290,
}

const mediaQueries = {
  desktopOnly: `@media (min-width: ${EMediaDimensions.DesktopStart}px)`,
  tabletOnly: `@media (min-width: ${EMediaDimensions.TabletStart}px) and (max-width: ${
    EMediaDimensions.DesktopStart - 1
  }px)`,
  tabletUpwards: `@media (min-width: ${EMediaDimensions.TabletStart}px)`,
  tabletDownwards: `@media (max-width: ${EMediaDimensions.DesktopStart - 1}px)`,
  mobileOnly: `@media (max-width: ${EMediaDimensions.TabletStart - 1}px)`,
  smallMobileOnly: `@media (max-width: ${EMediaDimensions.MobileSmallEnd}px)`,
};

console.log(namedKeys);

const namedKeysPlugin = namedKeys(mediaQueries);

let unitPlugin = unit("em");

export interface IOGetRendererInput {
  usePlugins?: {
    important?: boolean;
  };
  extraStyles?: string;
  defaultUnit?: Unit;
}

export function getFelaRenderer({
  usePlugins: { important = false } = {},
  extraStyles,
  defaultUnit,
}: IOGetRendererInput = {}): IRenderer {
  let renderer;

  if (defaultUnit) {
    unitPlugin = unit(defaultUnit);
  }

  if (process.env.NODE_ENV !== "production") {
    renderer = createRenderer({
      plugins: [
        extend(),
        ...(important ? [importantPlugin()] : []),
        prefixer(),
        fallbackValue(),
        unitPlugin,
        namedKeysPlugin,
        // logger(),
        // validatorPlugin(),
      ],
      enhancers: [monolithic()],
      // enhancers: [perf()],
    });
  } else {
    renderer = createRenderer({
      plugins: [
        extend(),
        ...(important ? [importantPlugin()] : []),
        // importantPlugin(),
        prefixer(),
        fallbackValue(),
        unitPlugin,
        namedKeysPlugin,
        // logger(),
      ],
    });
  }

  if (extraStyles) {
    renderer.renderStatic(extraStyles);
  }

  return renderer;
}
