import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { FelaRuleFlexbox, IFelaFlexboxProps, ruleKeysFlexbox } from "../fela/FelaFlexbox";
import { useFela } from "react-fela";
import { IFelaComponentBaseProps } from "../fela/FelaCommon";
import { FelaRuleLayer, IFelaLayerProps, ruleKeysFlexLayer } from "../fela/FelaLayers";
import { combineRulesWithName, useFelaCache } from "../../fela/FelaCache";
import { combineRules } from "fela";
import { TFelaRule } from "../fela/types";
import { FelaRuleGrid, IFelaGridProps, ruleKeysFelaGrid } from "../fela/FelaGrid";

// React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const FullAbsDiv: React.FC<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props) => (
  <div
    {...(props as any)}
    style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, content: "", ...props.style }}
  />
);

export interface ICPFullAbsFlexbox {
  position?: "absolute" | "fixed";
  rootProps?: any;
  pointerEvents?: boolean;
}

const FelaRuleFullAbs: TFelaRule<{ pointerEvents: "all" | "none" }> = ({ pointerEvents }) => ({
  width: "100%",
  height: "100%",
  pointerEvents,
});

const FelaFullAbsFlexboxSuperRule: any = combineRulesWithName(
  [FelaRuleFlexbox, FelaRuleFullAbs],
  "FelaFullAbsFlexboxSuperRule",
);
const ruleKeysFelaAbsFlexboxSuper = [...new Set([...ruleKeysFlexbox, "pointerEvents"])];

export const FullAbsFlexbox: React.FC<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement> & ICPFullAbsFlexbox, HTMLDivElement> &
    IFelaFlexboxProps &
    IFelaComponentBaseProps
> = ({
  className,
  children,
  pointerEvents = true,
  position = "absolute",
  rootProps,
  style,
  cssExtra = [],
  ...props
}) => {
  const { cachedClass, inlineCss } = useFelaCache();

  const { top = 0, right, left = 0, bottom } = props;

  return (
    <div
      {...rootProps}
      style={style}
      className={`${className ?? ""} ${cachedClass(
        FelaFullAbsFlexboxSuperRule,
        {
          position,
          top,
          left,
          right,
          bottom,
          pointerEvents: pointerEvents ? "all" : "none",
          ...props,
        },
        ruleKeysFelaAbsFlexboxSuper,
        // ...cssExtra,
      )} ${inlineCss(cssExtra)}`}
    >
      {children}
    </div>
  );
};

const FelaFullAbsFlexLayerSuperRule: any = combineRulesWithName(
  [FelaRuleFlexbox, FelaRuleLayer, FelaRuleFullAbs],
  "FelaFullAbsFlexLayerSuperRule",
);

const ruleKeysFelaFullAbsFlexLayerSuper = [...new Set([...ruleKeysFlexbox, ...ruleKeysFlexLayer, "pointerEvents"])];

export const FullAbsFlexLayer: React.FC<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement> & ICPFullAbsFlexbox, HTMLDivElement> &
    IFelaLayerProps &
    IFelaFlexboxProps &
    IFelaComponentBaseProps
> = ({
  className,
  children,
  pointerEvents = true,
  position = "absolute",
  rootProps,
  style,
  cssExtra = [],
  ...props
}) => {
  const { cachedClass, inlineCss } = useFelaCache();

  const { top = 0, right, left = 0, bottom } = props;

  return (
    <div
      {...rootProps}
      style={style}
      className={`${className ?? ""} ${cachedClass(
        FelaFullAbsFlexLayerSuperRule,
        {
          position,
          top,
          left,
          right,
          bottom,
          pointerEvents: pointerEvents ? "all" : "none",
          ...props,
        },
        ruleKeysFelaFullAbsFlexLayerSuper,
      )} ${inlineCss(cssExtra)}`}
    >
      {children}
    </div>
  );
};

const FelaFullAbsFlexGridSuperRule: any = combineRulesWithName(
  [FelaRuleGrid, FelaRuleFullAbs],
  "FelaFullAbsFlexGridSuperRule",
);
const ruleKeysFelaAbsFlexGridSuper = [...new Set([...ruleKeysFelaGrid, "pointerEvents"])];

export const FullAbsFlexGrid: React.FC<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement> & ICPFullAbsFlexbox, HTMLDivElement> &
    IFelaGridProps &
    IFelaComponentBaseProps
> = ({
  className,
  children,
  pointerEvents = true,
  position = "absolute",
  rootProps,
  style,
  cssExtra = [],
  ...props
}) => {
  const { cachedClass, inlineCss } = useFelaCache();

  const { top = 0, right, left = 0, bottom } = props;

  return (
    <div
      {...rootProps}
      style={style}
      className={`${className ?? ""} ${cachedClass(
        FelaFullAbsFlexGridSuperRule,
        {
          position,
          top,
          left,
          right,
          bottom,
          pointerEvents: pointerEvents ? "all" : "none",
          ...props,
        },
        ruleKeysFelaAbsFlexGridSuper,
      )} ${inlineCss(cssExtra)}`}
    >
      {children}
    </div>
  );
};

const FelaFullAbsFlexGridLayerSuperRule: any = combineRulesWithName(
  [FelaRuleGrid, FelaRuleLayer, FelaRuleFullAbs],
  "FelaFullAbsFlexGridLayerSuperRule",
);
const ruleKeysFelaAbsFlexGridLayerSuper = [...new Set([...ruleKeysFelaGrid, ...ruleKeysFlexLayer, "pointerEvents"])];

export const FullAbsFlexGridLayer: React.FC<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement> & ICPFullAbsFlexbox, HTMLDivElement> &
    IFelaGridProps &
    IFelaLayerProps &
    IFelaComponentBaseProps
> = ({
  className,
  children,
  pointerEvents = true,
  position = "absolute",
  rootProps,
  style,
  cssExtra = [],
  ...props
}) => {
  const { cachedClass, inlineCss } = useFelaCache();

  const { top = 0, right, left = 0, bottom } = props;

  return (
    <div
      {...rootProps}
      style={style}
      className={`${className ?? ""} ${cachedClass(
        FelaFullAbsFlexGridLayerSuperRule,
        {
          position,
          top,
          left,
          right,
          bottom,
          pointerEvents: pointerEvents ? "all" : "none",
          ...props,
        },
        ruleKeysFelaAbsFlexGridLayerSuper,
      )} ${inlineCss(cssExtra)}`}
    >
      {children}
    </div>
  );
};
