import { EPositionError } from "./LocationStore";

const unavailable = "It seems your device is not able to give an accurate location at the moment. Try again in a bit.";

const messageForErrorCode = {
  [EPositionError.POSITION_UNAVAILABLE]: unavailable,
  [EPositionError.TIMEOUT]: unavailable,
  [EPositionError.PERMISSION_DENIED]:
    "You have denied permission for using your location. Clear the site settings and try again.",
};

export const LocationStatic = {
  messageForErrorCode,
};
