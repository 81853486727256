import React from "react";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { MovieSectionAsyncActions } from "../../state/navigation/_navMoviePages";
import { useParams } from "react-router";
import { SimpleImage } from "@gt/gt-frontend/build/react/vibescout-ui/media/SimpleImage";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
  ETextTypographyType,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import Rating from "react-rating";
import { FaRegStar, FaStar, FaTicketAlt } from "react-icons/fa";
import { FelaFlexLayer, FelaGridLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FelaGrid } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaGrid";
import { FiMap, FiPhone } from "react-icons/fi";
import { MovieUtils } from "./MovieUtils";
import { useFelaCache } from "@gt/gt-frontend/build/react/fela/FelaCache";
import { FelaButton } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaButton";
import { GoogleMapsCommonUtils } from "@gt/services/build/gcloud/googlemaps/GoogleMapsCommonUtils";
import { ICinemaMovieScheduleItem } from "@vs/core/build/data/gcloud/datastore/models/CinemaPage";
import { EMovieItemLinkType, MovieListItem } from "./components/MovieListItem";
import { MovieShowtimes } from "./components/MovieShowtimes";
import { SubHeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { PageTitleImage } from "./components/PageTitleImage";
import { maxWidthPageStyles } from "../../config/StaticStyleConfig_VibescoutSpecial";
import { EGradientType } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";

/*const pigeonMapsAsyncAction = createAsyncActionDirect(async () =>
  Promise.all([import("pigeon-maps"), import("pigeon-marker")]),
);*/

export interface ICinemaPageParams {
  cinemaId: string;
  brandId: string;
}

export const CinemaPage = React.memo(() => {
  const { theme } = useFelaCache();
  const params = useParams<ICinemaPageParams>();
  const cinemaPageData = MovieSectionAsyncActions.getCinemaPage.use(params);
  let headerSeed = 0;

  return cinemaPageData.renderPayload(
    ({
      page: {
        name,
        screens,
        titleImage,
        cinemaBrandName,
        cinemaBrandID,
        aggregateRatingValue,
        aggregateRatingTotalVotes,
        about,
        phoneNumber,
        ticketPriceInformation,
        address,
        coordinates,
        movies,
        id: cinemaId,
      },
    }) => {
      const betterName = MovieUtils.betterName(name);
      const nowShowing: ICinemaMovieScheduleItem[] = movies?.nowShowing ?? [];
      const showingSoon: ICinemaMovieScheduleItem[] = movies?.showingSoon ?? [];
      const hasMovies = nowShowing.length + showingSoon.length > 0;

      return (
        <FelaFlexbox direction={"column"} rootProps={{ itemScope: true, itemType: "http://schema.org/MovieTheater" }}>
          <meta itemProp="name" content={name} />
          <meta itemProp="screenCount" content={`${screens}`} />
          {titleImage && (
            <PageTitleImage
              aspect={
                titleImage.variationSizes[0]
                  ? titleImage.variationSizes[0].width / titleImage.variationSizes[0].height
                  : 27 / 10
              }
              image={{
                colorRgbArray: titleImage?.metaColor?.preferredColorRgbArray,
                srcUrl: `https://storage.googleapis.com/vibescout-images-europe/images/${titleImage.imageId}/variation-${titleImage.variations[0]}.jpg`,
              }}
            >
              <FullAbsFlexbox align={"flex-start"}>
                <FelaFlexbox position={"relative"} padding={0.5}>
                  <FelaFlexLayer
                    opacity={0.85}
                    forceTheme={EFrontendStyleConfigBaseTheme.LIGHT}
                    height={ESurfaceHeight.highest}
                    padding={0.5}
                    radius={0.5}
                  >
                    <SimpleImage
                      imageFit={"contain"}
                      alt={`${cinemaBrandName} logo`}
                      outerProps={{ style: { width: "8em" } }}
                      aspect={78 / 24}
                      src={`https://storage.googleapis.com/vibescout-movies/images/brands/${cinemaBrandID}/logo-normal.jpg`}
                    />
                  </FelaFlexLayer>
                </FelaFlexbox>
              </FullAbsFlexbox>
              <FullAbsFlexbox align={"flex-end"}>
                {aggregateRatingValue != null && (
                  <FelaFlexbox padding={0.5}>
                    <FelaFlexLayer
                      height={ESurfaceHeight.highest}
                      backgroundOpacity={0.75}
                      cssExtra={[
                        ({ theme }) => ({
                          "& .full-star": {
                            "> svg": {
                              fill: "#e9c100",
                            },
                          },
                          "& svg": {
                            width: theme.isSmall ? "0.75em" : "1em",
                            height: "1em",
                            marginLeft: "0.2em",
                            fill: theme.dark ? "rgba(255,255,255,0.50)" : "rgba(38,61,92,0.5)",
                          },
                        }),
                      ]}
                      padding={"0.3em 0.45em"}
                      rootProps={{
                        itemType: "http://schema.org/AggregateRating",
                        itemProp: "aggregateRating",
                        itemScope: true,
                      }}
                    >
                      <meta itemProp="bestRating" content="5" />
                      <meta itemProp="worstRating" content="1" />
                      <meta itemProp="ratingCount" content={`${aggregateRatingTotalVotes}`} />
                      <FelaText
                        rootProps={{
                          itemProp: "ratingValue",
                        }}
                        weight={ETextWeightType.black}
                        margin={"0 0.5em 0 0"}
                        typography={ETextTypographyType.mono}
                      >
                        {aggregateRatingValue}
                      </FelaText>
                      <Rating
                        readonly
                        emptySymbol={<FaRegStar />}
                        fullSymbol={
                          <span className={"full-star"}>
                            <FaStar />
                          </span>
                        }
                        fractions={10}
                        initialRating={aggregateRatingValue}
                      />
                    </FelaFlexLayer>
                  </FelaFlexbox>
                )}
              </FullAbsFlexbox>
            </PageTitleImage>
          )}
          <FelaGrid
            alignSelf={"center"}
            style={maxWidthPageStyles}
            gridTemplateColumns={"1fr"}
            gap={theme.isSmall ? 0 : 1}
          >
            <FelaGrid
              // padding={theme.isSmall ? 0 : 1}
              alignItems={"start"}
              gap={theme.isSmall ? 0 : 1}
              gridTemplateColumns={"1fr"}
            >
              <FelaFlexLayer backgroundOpacity={0.25} radius={"0 0 1em 1em"} overflow={"hidden"} direction={"column"}>
                <FelaFlexbox
                  margin={theme.isSmall ? "-0.1em 0" : 0}
                  align={"flex-start"}
                  padding={theme.isSmall ? 0 : 0.5}
                  direction={"row"}
                  wrap={"wrap"}
                >
                  <TopPartWrapper isSmall={theme.isSmall}>
                    <FelaText
                      size={ETextSize.smallHeader}
                      weight={ETextWeightType.black}
                      contrast={EForeContrast.strong}
                      transform={"uppercase"}
                    >
                      {betterName}
                    </FelaText>
                    {about != null && (
                      <FelaText whiteSpace={"pre-line"} size={ETextSize.small}>
                        {about}
                      </FelaText>
                    )}
                  </TopPartWrapper>
                  <TopPartWrapper isSmall={theme.isSmall}>
                    <FelaFlexbox direction={"row"} align={"center"} gap={0.5} justify={"space-between"}>
                      <FelaFlexbox align={"center"} wrap={"wrap"} gap={0.5}>
                        <FelaIconWrap
                          contrast={EForeContrast.strong}
                          size={ETextSize.small}
                          noBackground
                          noPadding
                          margin={"0 0.5em 0 0"}
                        >
                          <FiMap />
                        </FelaIconWrap>
                        <FelaText weight={ETextWeightType.black} contrast={EForeContrast.extra}>
                          Locate {betterName}
                        </FelaText>
                      </FelaFlexbox>
                      <FelaButton
                        margin={0}
                        neutralColor={EAppColor.go}
                        linkHref={GoogleMapsCommonUtils.getMapDirectionsUrl({
                          lat: coordinates.latitude,
                          lon: coordinates.longitude,
                        })}
                      >
                        <span>Get Directions</span>
                      </FelaButton>
                    </FelaFlexbox>
                    {address && (
                      <FelaText whiteSpace={"pre-line"} size={ETextSize.small}>
                        {address}
                      </FelaText>
                    )}
                  </TopPartWrapper>
                  {phoneNumber && (
                    <TopPartWrapper isSmall={theme.isSmall}>
                      <FelaFlexbox direction={"row"} align={"center"} justify={"space-between"}>
                        <FelaFlexbox align={"center"}>
                          <FelaIconWrap
                            contrast={EForeContrast.strong}
                            size={ETextSize.small}
                            noBackground
                            noPadding
                            margin={"0 0.5em 0 0"}
                          >
                            <FiPhone />
                          </FelaIconWrap>
                          <FelaText weight={ETextWeightType.black} contrast={EForeContrast.extra}>
                            Phone
                          </FelaText>
                        </FelaFlexbox>
                        <a href={`tel:${phoneNumber}`}>
                          <FelaFlexLayer height={ESurfaceHeight.high} padding={"0.6em 1em"} radius={0.5}>
                            <FelaText neutralColor={EAppColor.accent}>{phoneNumber}</FelaText>
                          </FelaFlexLayer>
                        </a>
                      </FelaFlexbox>
                    </TopPartWrapper>
                  )}
                  {ticketPriceInformation && (
                    <TopPartWrapper isSmall={theme.isSmall}>
                      <FelaFlexbox align={"center"}>
                        <FelaIconWrap
                          contrast={EForeContrast.strong}
                          size={ETextSize.small}
                          noBackground
                          noPadding
                          margin={"0 0.5em 0 0"}
                        >
                          <FaTicketAlt />
                        </FelaIconWrap>
                        <FelaText weight={ETextWeightType.black} contrast={EForeContrast.extra}>
                          Tickets
                        </FelaText>
                      </FelaFlexbox>
                      <FelaText whiteSpace={"pre-line"} size={ETextSize.small}>
                        {ticketPriceInformation}
                      </FelaText>
                    </TopPartWrapper>
                  )}
                </FelaFlexbox>
              </FelaFlexLayer>
            </FelaGrid>
            <FelaGrid gridTemplateColumns={theme.isSmall ? "1fr" : "1fr 1fr"} gap={1}>
              <CinemaMovieList
                cinemaName={betterName}
                movies={nowShowing}
                title={"Now Showing"}
                isSmall={theme.isSmall}
                cinemaId={cinemaId}
                cinemaBrandId={cinemaBrandID}
                headerSeed={headerSeed}
              />
              {showingSoon.length > 0 && (
                <CinemaMovieList
                  cinemaName={betterName}
                  movies={showingSoon}
                  title={"Showing Soon"}
                  isSmall={theme.isSmall}
                  cinemaId={cinemaId}
                  cinemaBrandId={cinemaBrandID}
                  headerSeed={headerSeed}
                />
              )}
            </FelaGrid>
          </FelaGrid>
        </FelaFlexbox>
      );
    },
  );
});

const TopPartWrapper: React.FC<{ isSmall: boolean }> = ({ children, isSmall }) => {
  return (
    <FelaGridLayer
      height={ESurfaceHeight.high}
      padding={1}
      radius={isSmall ? 0 : 0.5}
      basis={25}
      grow={1}
      margin={isSmall ? "0.1em 0" : 0.5}
      gap={1}
      gridTemplateColumns={"1fr"}
    >
      {children}
    </FelaGridLayer>
  );
};

interface ICPCinemaMovieList {
  movies: ICinemaMovieScheduleItem[];
  title: string;
  isSmall: boolean;
  headerSeed: number;
  cinemaBrandId: string;
  cinemaId: string;
  cinemaName: string;
}

const CinemaMovieList: React.FC<ICPCinemaMovieList> = ({
  title,
  movies,
  isSmall,
  headerSeed,
  cinemaBrandId,
  cinemaId,
  cinemaName,
}) => {
  return (
    <FelaFlexbox
      direction={"column"}
      height={ESurfaceHeight.deepest}
      /*gridTemplateColumns={"1fr"}
      gap={1}*/
      radius={isSmall ? 0 : 1.5}
      overflow={"hidden"}
    >
      <FelaFlexLayer
        margin={"1em 0 0 0"}
        radius={"1em 1em 0 0"}
        backgroundOpacity={0.5}
        height={ESurfaceHeight.shadow}
        padding={1}
        direction={"column"}
      >
        <FelaText
          lineHeight={1}
          size={ETextSize.huge}
          transform={"uppercase"}
          weight={ETextWeightType.regular}
          forceTheme={EFrontendStyleConfigBaseTheme.DARK}
          contrast={EForeContrast.strong}
          margin={"0 0.45em 0 0"}
        >
          {title}
        </FelaText>
        <FelaText
          style={{ textShadow: "0 1px 1px rgba(0, 10, 30, 0.35)" }}
          // neutralColor={EColorsNeutralName.accent}
          colorHex={"rgba(150, 200, 255, 1)"}
          lineHeight={1}
          size={ETextSize.smallHeader}
          transform={"uppercase"}
          weight={ETextWeightType.bold}
          forceTheme={EFrontendStyleConfigBaseTheme.DARK}
          contrast={EForeContrast.strong}
        >
          <i>{cinemaName}</i>
        </FelaText>
      </FelaFlexLayer>
      {/*<PageSectionHeader seed={headerSeed++} text={title} />*/}
      {movies.length > 0 ? (
        <FelaGridLayer
          radius={isSmall ? 0 : "0 0 1.5em 1.5em"}
          backgroundOpacity={0.5}
          height={ESurfaceHeight.shadow}
          padding={isSmall ? 0.7 : 1}
          gap={1}
          gridTemplateColumns={"1fr"}
          overflow={"hidden"}
        >
          {movies.map((movie) => {
            const bookingUrl = MovieUtils.getBookNowUrlForMovieSchedule({
              ...movie,
              cinemaTruthId: cinemaId,
            });

            return (
              <FelaFlexLayer
                key={movie.movieTruthID}
                padding={"0.3em"}
                radius={1.3}
                direction={"column"}
                gradient={[
                  {
                    // def: "45deg",
                    type: EGradientType.radial,
                    stops: [
                      {
                        height: ESurfaceHeight.highest,
                        opacity: 0.5,
                      },
                      {
                        height: ESurfaceHeight.highest,
                        opacity: 0.1,
                        def: "95%",
                      },
                    ],
                  },
                ]}
              >
                <FelaFlexLayer key={movie.id} extraSoftShadow direction={"column"} overflow={"hidden"} radius={1}>
                  <MovieListItem
                    linkType={EMovieItemLinkType.MOVIE_PAGE}
                    key={movie.id}
                    linkToCinemaMovie={{
                      brandId: cinemaBrandId,
                      cinemaId: cinemaId,
                    }}
                    disableLink
                    movie={movie as any}
                    showReleaseDate={false}
                  >
                    <FelaFlexLayer
                      borderWidth={"0.3em 0 0 0"}
                      borderHeight={ESurfaceHeight.deepest}
                      direction={"column"}
                      overflow={"hidden"}
                    >
                      <MovieShowtimes
                        bookNowUrl={bookingUrl}
                        scheduleTimes={movie.scheduleTimes}
                        cinemaBrandId={cinemaBrandId}
                        cinemaId={cinemaId}
                        movieId={movie.id}
                      />
                    </FelaFlexLayer>
                  </MovieListItem>
                </FelaFlexLayer>
              </FelaFlexLayer>
            );
          })}
        </FelaGridLayer>
      ) : (
        <FelaFlexLayer padding={1}>
          <SubHeaderText>Awaiting updates from cinema</SubHeaderText>
        </FelaFlexLayer>
      )}
    </FelaFlexbox>
  );
};
