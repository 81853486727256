import { IESPostSearch } from "@vs/core/build/data/models/oldVibescout/oldPost/elasticsearch/ESMappingPostSearch";
import React from "react";
import { OldPostUtils } from "@vs/core/build/data/models/oldVibescout/oldPost/OldPostUtils";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import {
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight, ETextSize, ETextTypographyType, ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { AsyncNavLink } from "../../state/ASN_VibescoutSpecial";
import { SimpleImage } from "@gt/gt-frontend/build/react/vibescout-ui/media/SimpleImage";
import { FullAbsFlexbox, FullAbsFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { EGradientType } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";

interface ICPDisplayOldPostSearch {
  post: IESPostSearch;
}

export const TempPostSearchSnippet: React.FC<ICPDisplayOldPostSearch> = ({ post }) => {
  const postUrl = `/${OldPostUtils.getNewUrlForOldPostId(post.publishedUrl)}`;

  return (
    <FelaFlexLayer
      shadow
      height={ESurfaceHeight.high}
      grow={1}
      direction={"column"}
      hoverable
      clickable
      overflow={"hidden"}
      position={"relative"}
    >
      <AsyncNavLink key={post.publishedUrl} to={postUrl}>
        <SimpleImage src={post.thumbnailImageUrl!} aspect={2.67}>
          <FullAbsFlexLayer
            forceTheme={EFrontendStyleConfigBaseTheme.DARK}
            gradient={[
              {
                stops: [
                  { height: ESurfaceHeight.highest, opacity: 0.25 },
                  { height: ESurfaceHeight.highest, opacity: 0.05, def: "50%" },
                  { height: ESurfaceHeight.low, opacity: 0.35, def: "98%" },
                  { height: ESurfaceHeight.low, opacity: 0.95 },
                ],
              },
            ]}
          />
        </SimpleImage>
        <FelaFlexbox padding={"0.75em"} style={{ height: "4em" }}>
          <FelaText
            weight={"bold"}
            typography={ETextTypographyType.sansSerif}
            transform={"uppercase"}
            size={ETextSize.content}
          >
            {post.titleText}
          </FelaText>
        </FelaFlexbox>
        <FelaFlexLayer
          height={ESurfaceHeight.upper}
          grow={1}
          justify={"flex-end"}
          direction={"column"}
          // padding={`0.5em ${0.75 - PostSnippetStatic.borderWidth}em`}
          padding={`0.5em 0.75em`}
        >
          <FelaFlexbox style={{ maxHeight: "2.5em" }} overflow={"hidden"} position={"relative"}>
            <FullAbsFlexbox justify={"flex-end"} align={"flex-end"}>
              <FelaFlexLayer
                padding={"0 0 0 3.5em"}
                gradient={[
                  {
                    type: EGradientType.radial,
                    def: "3em 2em at right bottom",
                    stops: [{ height: ESurfaceHeight.mid }, { height: ESurfaceHeight.mid, def: "50%" }, {}],
                  },
                ]}
              >
                <FelaText weight={"bold"} contrast={EForeContrast.strong} size={ETextSize.content}>
                  ...
                </FelaText>
              </FelaFlexLayer>
            </FullAbsFlexbox>
            <FelaText
              lineHeight={1.5}
              weight={ETextWeightType.regular}
              style={{ textOverflow: "ellipsis" }}
              contrast={EForeContrast.extra}
              // weight={"bold"}
              // colorHex={`rgb(${newRgbArray[0]})`}
              typography={ETextTypographyType.sansSerif}
              transform={"none"}
              size={ETextSize.small}
            >
              {post.subtitleText}
            </FelaText>
          </FelaFlexbox>
        </FelaFlexLayer>
      </AsyncNavLink>
    </FelaFlexLayer>
  );
};
