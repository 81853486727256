import { TVibescoutSpecialResolver } from "../ASN_VibescoutSpecial";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { CovidAsyncActions } from "../stores/CovidStore/CovidAsyncActions";
import { DynamicImports } from "../../loadables/VibescoutSpecial_LoadableImports";

export const _navCoronavirusReports: TVibescoutSpecialResolver = {
  paths: ["/reports/coronavirus/:state", "/reports/coronavirus"],
  meta: {
    showTitleBar: false,
  },
  resolve: async ({ state: { instance }, info }) => {
    const statePart = info.params["state"];
    const stateGeoLevel = GeoLevelCompressUtils.convertUrlToCompressed({
      continentOrCountry: "za",
      statePart: statePart,
    });
    console.log(`Should be resolving state for path: "/reports/coronavirus/:state"`);

    if (statePart) {
      await Promise.all([
        DynamicImports.PageSpecial_Covid19State(),
        instance.runAsyncAction(CovidAsyncActions.getCovidPageData, { geoLvl: stateGeoLevel }, { respectCache: true }),
        instance.runAsyncAction(
          CovidAsyncActions.getRtForGeoLvl,
          { geoLvl: stateGeoLevel, limit: 60 },
          { respectCache: true },
        ),
      ]);
    } else {
      await Promise.all([
        DynamicImports.PageSpecial_Covid19(),
        instance.runAsyncAction(CovidAsyncActions.getCovidPageData, {}, { respectCache: true }),
        instance.runAsyncAction(
          CovidAsyncActions.getRtForGeoLvl,
          {
            geoLvl: "za",
            limit: 60,
          },
          { respectCache: true },
        ),
      ]);
    }
  },
};

export const _redirects_navCoronavirusReports: TVibescoutSpecialResolver = {
  paths: ["/reports/coronavirus/:state", "/reports/coronavirus"],
  meta: {
    showTitleBar: false,
  },
  resolve: async ({ state: { instance }, info, ctx }) => {
    const statePart = info.params["state"];

    console.log(`Should be redirecting for path: "/reports/coronavirus/:state"`);
    if (statePart) {
      ctx.redirect = {
        path: `/locality-index/za/${statePart}`,
      };
    } else {
      ctx.redirect = {
        path: "/",
      };
    }

    ctx.status = 302;
  },
};
