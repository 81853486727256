import React, { Component } from 'react';
import PropTypes from "prop-types";

export const CImageState = {
  UNLOADED: "UNLOADED",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

export class ReactImage extends Component<any, any> {
  checkIfLoadedTimeout: any;
  getHeightInterval: any;
  watcher: any;
  effectWatcher: any;
  img: any;

  constructor(props) {
    super(props);

    this.img = {
      style: {},
    };

    const startingState = (props.lazyLoad || (typeof window !== 'undefined' && (window as any).firstJavascriptRenderComplete)) ?
      CImageState.UNLOADED :
      CImageState.LOADED;

    this.state = {
      imageState: !props.src || props.src === "UNKNOWN" ? CImageState.ERROR : startingState,
      minHeight: 0,
    };

    this.checkIfLoadedTimeout = null;
    this.getHeightInterval = null;
  }

  componentWillUnmount() {
    window.clearInterval(this.getHeightInterval);
    window.clearTimeout(this.checkIfLoadedTimeout);

    if (this.watcher) {
      this.watcher.disconnect();
    }

    if (this.effectWatcher) {
      this.effectWatcher.disconnect();
    }

    if (this.img.addedListeners) {
      this.img.removeEventListener('load', this.imageLoaded);
      this.img.removeEventListener('error', this.imageError);
    }
  }

  getDomNode = (ref) => {
    if (ref) {
      if (this.state.imageState === CImageState.UNLOADED) {
        if (this.props.ignoreWatcher) {
          this.startLoadingImage();
        } else {
          this.watcher = new IntersectionObserver(this.imageIntersected, {
            threshold: 0,
            rootMargin: "1000px",
          });

          // console.log(`Trying to observer image ${this.props.alt}`);
          // console.dir(ref);

          this.watcher.observe(ref);
        }
      }
    }
  };

  startLoadingImage = () => {
    if (this.state.imageState === CImageState.UNLOADED) {
      console.log(`Loading image`);
      this.img = new Image();
      this.img.setAttribute('itemprop', 'image');
      this.img.src = this.props.src || this.props.fillerSrc;
      this.img.alt = this.props.alt;
      this.img.addEventListener('load', this.imageLoaded);
      this.img.addEventListener('error', this.imageError);
      this.img.addedListeners = true;
      this.getImageHeight();
    }

    this.setState({
      imageState: CImageState.LOADING,
    });

    // console.log(`Starting load for ${this.props.alt}`);
  };

  imageIntersected = (entries) => {
    if (entries[0].intersectionRatio <= 0) return;

    this.startLoadingImage();
    this.watcher.disconnect();
  };

  getImageHeight = () => {
    if (this.img.naturalHeight > 0 || this.state.imageState !== CImageState.UNLOADED) {
      this.setState({
        minHeight: this.img.naturalHeight,
      });
    }
  };

  imageLoaded = () => {
    console.log(`Setting image loaded state`);
    this.setState({
      imageState: CImageState.LOADED,
    });
  };

  imageError = () => {
    console.log(`Image failed to load with src: ${this.img.src}`);

    this.setState({
      imageState: CImageState.ERROR,
    });
  };

  render() {
    const blockStyle: any = {
      opacity: 1,
      position: "relative",
      width: "100%",
      display: "block",
      minHeight: (this.props.ignoreHeight || this.state.imageState !== CImageState.LOADING) ? "2px" : `${this.state.minHeight}px`,
    };

    const imageStyle = Object.assign({}, this.props.imgStyle, {
      opacity: 0,
      transition: "opacity 0.55s ease-out",
    });

    if (this.props.aspectRatio) {
      blockStyle.paddingBottom = `${100 / this.props.aspectRatio}%`;

      imageStyle.position = "absolute";
      imageStyle.top = 0;
      imageStyle.left = 0;
    }

    if (this.props.dominantColorRgbString) {
      blockStyle.background = `rgb(${this.props.dominantColorRgbString})`;
    }

    switch (this.state.imageState) {
      case CImageState.LOADING:
        imageStyle.opacity = this.props.noFade ? this.props.maxOpacity : 0;
        break;
      case CImageState.LOADED:
        imageStyle.opacity = this.props.maxOpacity;
        break;
      case CImageState.ERROR:
        if (this.props.fillerSrc) {
          const { src, ...otherProps } = this.props;
          return <ReactImage src={this.props.fillerSrc} {...otherProps} />;
        }
        break;
      default:
        break;
    }

    const blockProps = {
      style: blockStyle,
      className: `react-image react-image-${this.state.imageState.toLowerCase()} ${this.props.className}`,
      ref: this.getDomNode,
    };

    if (this.state.imageState === CImageState.LOADING || this.state.imageState === CImageState.LOADED) {
      return (
        <span itemProp="image" itemScope itemType="https://schema.org/ImageObject" {...blockProps}>
          {this.state.imageState === CImageState.LOADING && (
            <span className="loader-box">
              <span className="loader" />
            </span>
          )}
          <img itemProp="contentUrl" src={this.props.src} alt={this.props.alt} className={this.props.imgClass} style={imageStyle} />
          <meta itemProp="url" content={this.props.src} />
          {this.props.metaWidth && <meta itemProp="width" content={this.props.metaWidth} />}
          {this.props.metaHeight && <meta itemProp="height" content={this.props.metaHeight} />}
        </span>
      );
    }

    return (
      <span {...blockProps} />
    );
  }
}

(ReactImage as any).propTypes = {
  src: PropTypes.string,
  metaHeight: PropTypes.any,
  metaWidth: PropTypes.any,
  imgStyle: PropTypes.object,
  imgClass: PropTypes.string,
  className: PropTypes.string,
  fillerSrc: PropTypes.string,
  alt: PropTypes.string,
  ignoreHeight: PropTypes.bool,
  ignoreWatcher: PropTypes.bool,
  dominantColorRgbString: PropTypes.string,
  dominantColorRgbArray: PropTypes.array,
  affectDominantColor: PropTypes.bool,
  noFade: PropTypes.bool,
  aspectRatio: PropTypes.number,
  lazyLoad: PropTypes.bool,
  maxOpacity: PropTypes.number,
};

(ReactImage as any).defaultProps = {
  src: null,
  fillerSrc: "https://storage.googleapis.com/vibescoutapp.appspot.com/graphics/vibescout_filler_pattern_small_square.png",
  alt: "",
  imgStyle: {},
  imgClass: "",
  className: "",
  metaWidth: null,
  metaHeight: null,
  ignoreHeight: false,
  ignoreWatcher: false,
  dominantColorRgbString: "",
  dominantColorRgbArray: null,
  affectDominantColor: false,
  noFade: false,
  aspectRatio: null,
  lazyLoad: false,
  maxOpacity: 1,
};
