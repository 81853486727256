import { default as React, FunctionComponent } from "react";
import { useFela } from "react-fela";
import { IFelaComponentBaseProps } from "./FelaCommon";
import { Properties, Property } from "csstype";
import { TFelaRule } from "./types";
import { useFelaCache } from "../../fela/FelaCache";
import { ICssPositionalProps } from "./FelaFlexbox";

export interface IFelaGridProps extends ICssPositionalProps {
  basis?: Property.FlexBasis<any>;
  align?: Property.AlignContent;
  justify?: Property.JustifyContent;
  alignItems?: Property.AlignItems;
  justifyItems?: Property.JustifyItems;
  grow?: number | "unset" | "initial" | "inherit";
  shrink?: number | "unset" | "initial" | "inherit";
  overflow?: Property.Overflow;
  position?: Property.Position;
  zIndex?: Property.ZIndex;
  alignSelf?: Property.AlignSelf;
  justifySelf?: Property.JustifySelf;
  gridTemplateColumns?: Property.GridTemplateColumns<any>;
  gridTemplateRows?: Property.GridTemplateRows<any>;
  gridAutoColumns?: Property.GridAutoColumns<any>;
  gridAutoFlow?: Property.GridAutoFlow;
  gap?: Property.GridGap<any>;
  gridColumn?: Property.GridColumn;
  gridRow?: Property.GridRow;
  margin?: Property.Margin<any>;
  padding?: Property.Padding<any>;
}

export const FelaRuleGrid: TFelaRule<IFelaGridProps & IFelaComponentBaseProps> = ({
  basis,
  align,
  justify,
  alignItems,
  justifyItems,
  grow,
  shrink,
  overflow,
  zIndex,
  position = "relative",
  alignSelf,
  justifySelf,
  gridTemplateColumns,
  gridTemplateRows,
  gridAutoFlow,
  gridAutoColumns,
  gap,
  padding,
  margin,
  gridColumn = "",
  gridRow = "",
  top,
  bottom,
  left,
  right,
}) =>
  ({
    position,
    zIndex,
    display: "grid",
    overflow,
    flexBasis: basis,
    alignContent: align,
    justifyContent: justify,
    gridAutoFlow,
    flexGrow: grow,
    flexShrink: shrink,
    transition: "background 0.15s ease-out, box-shadow 0.15s ease-out, color 0.15s ease-out",
    alignSelf,
    justifySelf,
    gridTemplateColumns,
    gridTemplateRows,
    gridAutoColumns,
    gap,
    padding,
    margin,
    gridColumn,
    gridRow,
    alignItems,
    justifyItems,
    top,
    bottom,
    left,
    right,
  } as Properties);

export const ruleKeysFelaGrid: (keyof IFelaGridProps)[] = [
  "basis",
  "align",
  "justify",
  "alignItems",
  "justifyItems",
  "grow",
  "shrink",
  "overflow",
  "zIndex",
  "position",
  "alignSelf",
  "justifySelf",
  "gridTemplateColumns",
  "gridTemplateRows",
  "gridAutoFlow",
  "gridAutoColumns",
  "gap",
  "padding",
  "margin",
  "gridColumn",
  "gridRow",
  "top",
  "bottom",
  "left",
  "right",
];

export const FelaGrid: FunctionComponent<IFelaGridProps & IFelaComponentBaseProps> = ({
  children,
  cssExtra = [],
  className = "",
  rootProps,
  style,
  ...props
}) => {
  // const { css } = useFela(props);
  const { cachedClass, inlineCss } = useFelaCache();

  return (
    <div
      style={style}
      className={`${className} ${cachedClass(FelaRuleGrid, props, ruleKeysFelaGrid)} ${inlineCss(cssExtra)}`}
      {...rootProps}
    >
      {children}
    </div>
  );
};
