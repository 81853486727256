import { useMeasure } from "react-use";
import { useEffect, useRef, useState } from "react";

interface IOUseShowMoreInput {
  blockMaxHeight?: number;
  defaultClosed?: boolean;
  disabled?: boolean;
}

interface IOUseShowMoreOutput {
  onClickButton: () => void;
  buttonRef: any;
  blockRef: any;
  applicable: boolean;
  closed: boolean;
}

export const useShowMore = (
  { blockMaxHeight = 400, defaultClosed = true, disabled = false }: IOUseShowMoreInput = {},
  deps: any[] = [],
): IOUseShowMoreOutput => {
  const [ruleBlockRef, { height: ruleBlockHeight }] = useMeasure();
  const showMoreButtonRef = useRef<HTMLDivElement>();

  const [lastClosedY, setLastClosedY] = useState(0);
  const [lastOpenY, setLastOpenY] = useState(0);
  const [ruleBlockOpen, setRuleBlockOpen] = useState(false);
  const hasShowMoreButton = ruleBlockHeight > blockMaxHeight;
  const [codeLoaded, setCodeLoaded] = useState(false);

  useEffect(() => {
    if (!disabled) {
      if (!codeLoaded) {
        setCodeLoaded(true);
      }

      if (hasShowMoreButton) {
        if (!ruleBlockOpen) {
          setLastClosedY(showMoreButtonRef.current!.offsetTop);
        } else {
          setLastOpenY(showMoreButtonRef.current!.offsetTop);
        }
      }
    }
  }, [disabled, hasShowMoreButton, ruleBlockOpen, ...deps]);

  return {
    onClickButton: () => {
      if (ruleBlockOpen) {
        window.scrollBy(0, lastClosedY - lastOpenY);
      }
      setRuleBlockOpen(!ruleBlockOpen);
    },
    blockRef: ruleBlockRef,
    buttonRef: showMoreButtonRef,
    closed: !disabled && ((!codeLoaded && defaultClosed) || (hasShowMoreButton && !ruleBlockOpen)),
    applicable: !disabled && hasShowMoreButton,
  };
};
