import React from "react";
import {
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { AsyncNavLink } from "../../../state/ASN_VibescoutSpecial";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import { EGeoLevelLevelType } from "@vs/core/build/data/crossover/geoLevels/GeoLevelTypes";
import { TGeoLevelBasics } from "@vs/core/build/data/models/geolevels/GeoLevelModelTypes";

export interface ICPLocalityBreadcrumbs {
  geoLevel: TGeoLevelBasics;
  isSmall: boolean;
}

export const LocalityBreadcrumbs: React.FC<ICPLocalityBreadcrumbs> = ({ geoLevel, isSmall }) => {
  const isSubSuburb = geoLevel.lvlType === EGeoLevelLevelType.sub_suburb;

  return (
    <FelaFlexLayer backgroundOpacity={0.12} height={ESurfaceHeight.highest} align={"center"} padding={0.5} gap={0.25}>
      <AsyncNavLink
        key={geoLevel.ciId}
        to={`/${GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: geoLevel.ciShort! })}`}
      >
        <FelaFlexLayer
          clickable
          hoverable
          height={ESurfaceHeight.shadow}
          backgroundOpacity={0.25}
          margin={"0 0 0.1em 0"}
          padding={"0.15em 0.3em"}
          radius={0.5}
          pop
          popAmount={0.1}
          popOpacity={0.5}
        >
          <FelaText
            align={"center"}
            contrast={EForeContrast.strong}
            forceTheme={EFrontendStyleConfigBaseTheme.DARK}
            size={isSubSuburb && isSmall ? ETextSize.tiny : ETextSize.small}
          >
            {geoLevel.ciName}
          </FelaText>
        </FelaFlexLayer>
      </AsyncNavLink>
      <FelaIconWrap forceTheme={EFrontendStyleConfigBaseTheme.DARK} size={ETextSize.small} noPadding noBackground>
        <MdKeyboardArrowRight />
      </FelaIconWrap>
      {isSubSuburb ? (
        <AsyncNavLink
          key={geoLevel.suId}
          to={`/${GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: geoLevel.suShort! })}`}
        >
          <FelaFlexLayer
            clickable
            hoverable
            height={ESurfaceHeight.shadow}
            backgroundOpacity={0.25}
            padding={"0.15em 0.3em"}
            margin={"0 0 0.1em 0"}
            radius={0.5}
            pop
            popAmount={0.1}
            popOpacity={0.5}
          >
            <FelaText
              align={"center"}
              contrast={EForeContrast.strong}
              forceTheme={EFrontendStyleConfigBaseTheme.DARK}
              size={isSubSuburb && isSmall ? ETextSize.tiny : ETextSize.small}
            >
              {geoLevel.suName}
            </FelaText>
          </FelaFlexLayer>
        </AsyncNavLink>
      ) : (
        <FelaText
          contrast={EForeContrast.strong}
          forceTheme={EFrontendStyleConfigBaseTheme.DARK}
          weight={ETextWeightType.bold}
          size={ETextSize.small}
        >
          {geoLevel.suName}
        </FelaText>
      )}
      {isSubSuburb && (
        <>
          <FelaIconWrap forceTheme={EFrontendStyleConfigBaseTheme.DARK} size={ETextSize.small} noPadding noBackground>
            <MdKeyboardArrowRight />
          </FelaIconWrap>
          <FelaText
            contrast={EForeContrast.strong}
            forceTheme={EFrontendStyleConfigBaseTheme.DARK}
            weight={ETextWeightType.bold}
            size={ETextSize.small}
          >
            {geoLevel.ssName}
          </FelaText>
        </>
      )}
    </FelaFlexLayer>
  );
};
