import React from "react";

export const DeathCrossIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <switch>
      <g>
        <path d="M77 79H58V37h17c1 0 3-1 3-3V23l-3-2H58V5l-2-2H44l-2 2v16H25l-2 2v11c0 2 1 3 2 3h17v43H23c-6 0-10 4-10 9v9h74v-9c0-5-4-10-10-10z" />
      </g>
    </switch>
  </svg>
);

export const DeathFlatLineIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path d="M61 31H26l-4-6h-1-1v1l-4 8-3-13a1 1 0 00-1-1 1 1 0 00-1 1L7 31H3a1 1 0 100 2h6v-1l3-8 3 13a1 1 0 000 1h2v-1l4-9 3 5h37a1 1 0 100-2z" />
  </svg>
);
