import {
  ETaskFunctionEndId,
  ITaskFunctionNegativeResponse,
  ITaskFunctionResponse,
} from "@gt/common-utils/build/taskFunction/TaskFunctionTypes";
import { errorResult, successResult, TAsyncActionResult } from "pullstate";
import { TFRFailureDefaults } from "@gt/common-utils/build/taskFunction/TaskFunctionResponses";
import { TaskFunctionError } from "@gt/common-utils/build/taskFunction/TaskFunctionUtils";

function taskFunctionResponseToAsyncResponse<A = any, R = any, N = any>(
  response: ITaskFunctionResponse<R>,
): TAsyncActionResult<R, string, N> {
  return response.positive
    ? successResult(response.payload)
    : errorResult([response.endId, ...response.endTags], response.endMessage);
}

function asyncResponseToTaskFunctionErrorResponse(
  result: TAsyncActionResult<any, string, any>,
): ITaskFunctionNegativeResponse<any> {
  return TFRFailureDefaults({
    endId: (result.tags?.[1] as ETaskFunctionEndId) ?? ETaskFunctionEndId.ERROR,
    endMessage: result.message,
    errorPayload: result,
    endTags: result.tags,
  });
}

export function throwAsyncResult(result: TAsyncActionResult<any, string, any>) {
  throw new TaskFunctionError(asyncResponseToTaskFunctionErrorResponse(result));
}

export function throwOrSuccessPayload<T, N>(result: TAsyncActionResult<T, string, N>): T {
  if (result.error) {
    throwAsyncResult(result);
  }

  return result.payload!;
}

export const PullstateAsyncUtils = {
  taskFunctionResponseToAsyncResponse,
  asyncResponseToTaskFunctionErrorResponse,
  throwResult: throwAsyncResult,
};
