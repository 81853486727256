import { ETaskFunctionEndId, ITaskFunctionResponse } from "@gt/common-utils/build/taskFunction/TaskFunctionTypes";
import { TFRFailure, TFRSuccessPayload } from "@gt/common-utils/build/taskFunction/TaskFunctionResponses";

export interface IOStartWatchingPositionOutput {
  watchId: number;
  position: Position;
}

export interface IOStartWatchingPositionOutputError {
  error: PositionError;
  watchId: number;
}

async function startWatchingPosition(
  onChange: (
    response: ITaskFunctionResponse<IOStartWatchingPositionOutput, IOStartWatchingPositionOutputError>,
  ) => void,
): Promise<ITaskFunctionResponse<IOStartWatchingPositionOutput, IOStartWatchingPositionOutputError>> {
  return new Promise((resolve) => {
    let resolved = false;

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const response = TFRSuccessPayload({ watchId, position });

        if (!resolved) {
          resolve(response);
          resolved = true;
        }

        onChange(response);
        console.log(position);
      },
      (error) => {
        const response = TFRFailure(ETaskFunctionEndId.ERROR, "Something went wrong getting user location", {
          error,
          watchId,
        });

        if (!resolved) {
          resolve(response);
          resolved = true;
        }

        onChange(response);
        console.log(error);
      },
    );
  });
}

export const GeoLocationUtils = {
  startWatchingPosition,
};
