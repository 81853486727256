import { Properties, Property } from "csstype";
import { default as React, FunctionComponent } from "react";
import { IFelaComponentBaseProps, IGradient } from "./FelaCommon";
import { FelaRuleFlexbox, IFelaFlexboxProps, ruleKeysFlexbox } from "./FelaFlexbox";
import { useFela } from "react-fela";
import { FelaRuleGrid, IFelaGridProps, ruleKeysFelaGrid } from "./FelaGrid";
import { ColorUtils, TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { TFelaRule } from "./types";
import { ZIndexes } from "../../../styling/VibescoutZIndexes";
import { EAppColor, EFrontendStyleConfigBaseTheme, ESurfaceHeight } from "../config/VibescoutFrontendStyleConfig";
import { FelaUtility } from "./FelaUtility";
import { useFelaCache } from "../../fela/FelaCache";

export enum EFelaLayerHeight {
  // LIT = "LIT",
  HIGH = "HIGH",
  MID = "MID",
  LOW = "LOW",
  // SHADOW = "SHADOW",
}

export interface IFelaLayerProps {
  tag?: string;
  opacity?: number;
  height?: EFelaLayerHeight | ESurfaceHeight;
  shadow?: boolean;
  pop?: boolean;
  popAmount?: number;
  popOpacity?: number;
  popBlur?: number;
  unpop?: boolean;
  backgroundColor?: TColorRgbArray;
  backgroundString?: string;
  backgroundNeutralColor?: EAppColor;
  backgroundOpacity?: number;
  extraSoftShadow?: boolean;
  gradient?: IGradient[];
  hoverable?: boolean;
  clickable?: boolean;
  margin?: Property.Margin<any>;
  padding?: Property.Padding<any>;
  radius?: Property.BorderRadius<any>;
  borderStyle?: Property.BorderStyle;
  borderWidth?: string;
  borderColorString?: string;
  borderNeutralColor?: EAppColor;
  borderHeight?: ESurfaceHeight;
  borderOpacity?: number;
  innerHtml?: string;
  forceTheme?: EFrontendStyleConfigBaseTheme | string;
}

/*function getBackgroundGradient({ deg = 90, colors }: IGradientColors, theme: IFelaTheme, height: EFelaLayerHeight) {
  return `linear-gradient(${deg}deg, black, white)`;
}*/

const translateOldHeightToNew: {
  [key in EFelaLayerHeight | ESurfaceHeight]: ESurfaceHeight;
} = {
  [EFelaLayerHeight.HIGH]: ESurfaceHeight.high,
  [EFelaLayerHeight.MID]: ESurfaceHeight.mid,
  [EFelaLayerHeight.LOW]: ESurfaceHeight.deep,
  [ESurfaceHeight.highest]: ESurfaceHeight.highest,
  [ESurfaceHeight.high]: ESurfaceHeight.high,
  [ESurfaceHeight.upper]: ESurfaceHeight.upper,
  [ESurfaceHeight.mid]: ESurfaceHeight.mid,
  [ESurfaceHeight.low]: ESurfaceHeight.low,
  [ESurfaceHeight.deep]: ESurfaceHeight.deep,
  [ESurfaceHeight.deepest]: ESurfaceHeight.deepest,
  [ESurfaceHeight.shadow]: ESurfaceHeight.shadow,
};

export const FelaRuleLayer: TFelaRule<IFelaLayerProps> = ({
  theme,
  forceTheme,
  height = ESurfaceHeight.mid,
  shadow = false,
  extraSoftShadow = false,
  pop = false,
  popAmount,
  popOpacity = 1,
  popBlur = 0.05,
  unpop = false,
  hoverable = false,
  clickable = false,
  margin,
  padding,
  gradient,
  radius,
  backgroundColor,
  backgroundNeutralColor,
  backgroundString,
  borderWidth,
  borderNeutralColor,
  borderColorString,
  borderOpacity,
  borderHeight,
  borderStyle,
  opacity,
  backgroundOpacity,
}): Properties => {
  if (extraSoftShadow) {
    shadow = true;
  }

  const surfaceHeight: ESurfaceHeight = translateOldHeightToNew[height];
  const shadowColor = `rgba(${theme.styles.Themes[theme.currentId].shadow.base[0]}, ${
    theme.styles.Themes[theme.currentId].shadow.intensity
  })`;

  const setBackgroundColor: TColorRgbArray | undefined = backgroundNeutralColor
    ? theme.styles.Themes[theme.currentId].Colors.neutral[backgroundNeutralColor]
    : backgroundColor;

  const shadows: string[] = [];

  if (shadow) {
    shadows.push(`0 0.1em 0.3em 0 ${shadowColor}`);

    if (extraSoftShadow) {
      shadows.push(`0 0.2em 1em 0.1em ${shadowColor}`);
    }
  }

  const rules: any = {
    opacity,
    transition: "all 0.2s ease-out",
    background: gradient
      ? FelaUtility.createGradients(theme, forceTheme ?? theme.currentId, gradient)
      : backgroundString
      ? backgroundString
      : setBackgroundColor !== undefined
      ? `rgba(${setBackgroundColor[0]}, ${backgroundOpacity ?? 1})`
      : `rgba(${theme.styles.Themes[forceTheme ?? theme.currentId].Colors.surface[surfaceHeight][0]}, ${
          backgroundOpacity ?? 1
        })`,
    margin,
    padding,
    borderRadius: radius,
  };

  if (pop) {
    const popCol = ColorUtils.darkenRgbArray(
      setBackgroundColor ?? theme.styles.Themes[forceTheme ?? theme.currentId].Colors.surface[surfaceHeight],
      25,
    );

    shadows.push(
      `${unpop ? "inset " : ""}0 ${popAmount ?? 0.18}em ${popBlur}em 0.05em rgba(${popCol[0]}, ${popOpacity})`,
    );
    /*if (unpop) {
      rules.transform = "translateY(0.18em)";
      shadows.push(`0 -0.18em 0.05em 0.05em rgba(${popCol[0]}, 1)`);
    } else {

    }*/
  }

  rules.boxShadow = shadows.length > 0 ? shadows.join(", ") : undefined;

  if (borderWidth) {
    const bbCol: TColorRgbArray | undefined = borderNeutralColor
      ? theme.styles.Colors.neutral[borderNeutralColor]
      : borderHeight
      ? theme.styles.Themes[theme.currentId].Colors.surface[borderHeight]
      : setBackgroundColor;

    rules.borderWidth = borderWidth;
    rules.borderStyle = borderStyle ?? "solid";
    rules.borderColor = borderColorString
      ? borderColorString
      : bbCol !== undefined
      ? `rgba(${bbCol[0]}, ${borderOpacity ?? 1})`
      : theme.styles.Themes[forceTheme ?? theme.currentId].RgbColors.surface[surfaceHeight];

    /*rules.border = `${borderWidth} solid ${
      borderColorString
        ? borderColorString
        : bbCol !== undefined
        ? `rgba(${bbCol[0]}, ${borderOpacity ?? 1})`
        : theme.styles.Themes[forceTheme ?? theme.currentId].RgbColors.surface[surfaceHeight]
    }`;*/
  }

  if (clickable) {
    rules.cursor = "pointer";
  }

  if (hoverable) {
    rules[":hover"] =
      backgroundString != null
        ? {
            filter: "contrast(90%) brightness(115%)",
          }
        : {
            background: `rgb(${
              ColorUtils.lightenRgbArray(
                setBackgroundColor !== undefined
                  ? setBackgroundColor
                  : theme.styles.Themes[forceTheme ?? theme.currentId].Colors.surface[surfaceHeight],
                20,
              )[0]
            })`,
          };
  }

  return rules;
};

export type TFelaFlexLayerProps = IFelaFlexboxProps &
  IFelaLayerProps &
  IFelaComponentBaseProps & { [prop: string]: any };

interface IFelaTempProps {
  borderWidth?: string;
  borderNeutralColorString?: string;
  borderNeutralColor?: EAppColor;
}

export const ruleKeysFlexLayer: (keyof IFelaLayerProps)[] = [
  "forceTheme",
  "height",
  "shadow",
  "extraSoftShadow",
  "pop",
  "popAmount",
  "popOpacity",
  "popBlur",
  "unpop",
  "hoverable",
  "clickable",
  "margin",
  "padding",
  "gradient",
  "radius",
  "backgroundColor",
  "backgroundNeutralColor",
  "backgroundString",
  "borderStyle",
  "borderWidth",
  "borderNeutralColor",
  "borderColorString",
  "borderOpacity",
  "borderHeight",
  "opacity",
  "backgroundOpacity",
];

export const FelaFlexLayer: FunctionComponent<TFelaFlexLayerProps> = ({
  children,
  cssExtra = [],
  className = "",
  onClick,
  onMouseEnter,
  onMouseLeave,
  style,
  rootProps = {},
  innerHtml,
  tag,
  cssKey,
  title,
  ...props
}) => {
  // const { css } = useFela(props);
  const { cachedClass, inlineCss } = useFelaCache();

  /*const divProps: any = {
    onMouseEnter,
    onMouseLeave,
    onClick,
  };*/

  if (innerHtml) {
    rootProps.dangerouslySetInnerHTML = { __html: innerHtml };
  }

  return React.createElement(
    tag ?? "div",
    {
      title,
      onClick,
      onMouseEnter,
      onMouseLeave,
      style,
      // className: `${className} ${css(FelaRuleFlexbox, FelaRuleLayer, ...cssExtra)}`,
      className: `${className} ${cachedClass(FelaRuleFlexbox, props, cssKey ?? ruleKeysFlexbox)} ${cachedClass(
        FelaRuleLayer,
        props,
        cssKey ?? ruleKeysFlexLayer,
      )} ${inlineCss(cssExtra)}`,
      // dangerouslySetInnerHTML: innerHtml ? { __html: innerHtml } : undefined,
      ...rootProps,
    },
    children,
  );

  /*return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      className={`${className} ${css(FelaRuleFlexbox, FelaRuleLayer, ...cssExtra)}`}
      // dangerouslySetInnerHTML={innerHtml ? { __html: innerHtml! } : undefined}
      {...rootProps}
    >
      {children}
    </div>
  );*/
};

export const FelaGridLayer: FunctionComponent<IFelaGridProps & IFelaLayerProps & IFelaComponentBaseProps> = ({
  children,
  cssExtra = [],
  className = "",
  style,
  onClick,
  rootProps,
  title,
  ...props
}) => {
  // const { css } = useFela(props);
  const { cachedClass, inlineCss } = useFelaCache();

  return (
    <div
      title={title}
      onClick={onClick}
      style={style}
      // className={`${className} ${css(FelaRuleGrid, FelaRuleLayer, ...cssExtra)}`}
      className={`${className} ${cachedClass(FelaRuleGrid, props, ruleKeysFelaGrid)} ${cachedClass(
        FelaRuleLayer,
        props,
        ruleKeysFlexLayer,
      )} ${inlineCss(cssExtra)}`}
      {...rootProps}
    >
      {children}
    </div>
  );
};

export interface IFelaHeaderBarProps {
  size?: number;
  padding?: number | string;
  sticky?: boolean;
}

export const FelaHeaderBar: FunctionComponent<
  IFelaHeaderBarProps & IFelaFlexboxProps & IFelaLayerProps & IFelaComponentBaseProps
> = ({ children, cssExtra = [], className = "", size = 2, padding, sticky = false, ...props }) => {
  return (
    <FelaFlexLayer
      shadow={true}
      zIndex={ZIndexes.topBar}
      height={ESurfaceHeight.highest}
      className={className}
      align={"center"}
      position={sticky ? "sticky" : "relative"}
      cssExtra={[
        {
          padding: padding ? padding : size / 4,
          minHeight: size * 2,
        },
        cssExtra,
      ]}
      {...props}
    >
      {children}
    </FelaFlexLayer>
  );
};
