import React, { useState } from "react";
import Tippy, { TippyProps } from "@tippyjs/react/headless";
import { FelaIconWrap, TCPFelaIconWrap } from "../fela/FelaIconWrap";
import { FiShare2 } from "react-icons/fi";
import { FaFacebook, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { FelaText } from "../fela/FelaText";
import { EFelaLayerHeight, FelaFlexLayer } from "../fela/FelaLayers";
import { ShareUtils } from "../../../utils/ShareUtils";
import { GoogleAnalyticsEventUtils } from "../../../analytics/GoogleAnalyticsEventUtils";
import { ESurfaceHeight, ETextSize } from "../config/VibescoutFrontendStyleConfig";

export enum EShareProvider {
  WHATSAPP = "WHATSAPP",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
}

interface ICPShareButton {
  url: string;
  title: string;
  iconProps?: TCPFelaIconWrap;
  tippyProps?: TippyProps;
  providers?: EShareProvider[];
  onShare?: (provider: EShareProvider) => void;
}

const colors = {
  whatsapp: "#25D366",
  facebook: "#507cd7",
  twitter: "#55acee",
};

function runShareEvent(provider: EShareProvider, url: string) {
  GoogleAnalyticsEventUtils.runAnalyticEvent(GoogleAnalyticsEventUtils.ANALYTICS_EVENT_TYPES.REGULAR_EVENT, {
    action: `share-click-${provider}`,
    category: "Sharing",
    label: url,
  });
}

export const ShareButton: React.FC<ICPShareButton> = ({
  title,
  url,
  iconProps,
  tippyProps,
  providers = [EShareProvider.WHATSAPP, EShareProvider.TWITTER, EShareProvider.FACEBOOK],
}) => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <div>
      <Tippy
        visible={visible}
        onClickOutside={hide}
        // hideOnClick={true}
        interactive={true}
        delay={100}
        render={(attrs) => {
          return (
            <div className="box" {...attrs}>
              <FelaFlexLayer height={EFelaLayerHeight.MID} padding={0.25} shadow extraSoftShadow direction={"column"}>
                {providers.includes(EShareProvider.WHATSAPP) && (
                  <a href={`whatsapp://send?text=${url}`} onClick={() => runShareEvent(EShareProvider.WHATSAPP, url)}>
                    <FelaFlexLayer
                      radius={0.25}
                      margin={0.25}
                      height={EFelaLayerHeight.HIGH}
                      hoverable
                      clickable
                      padding={0.5}
                      align={"center"}
                    >
                      <FelaIconWrap noBackground colorString={colors.whatsapp}>
                        <FaWhatsapp />
                      </FelaIconWrap>
                      <FelaText
                        colorHex={colors.whatsapp}
                        weight={"500"}
                        margin={"0 0 0 0.25em"}
                        size={ETextSize.content}
                      >
                        WhatsApp
                      </FelaText>
                    </FelaFlexLayer>
                  </a>
                )}
                {providers.includes(EShareProvider.TWITTER) && (
                  <FelaFlexLayer
                    radius={0.25}
                    margin={0.25}
                    height={EFelaLayerHeight.HIGH}
                    hoverable
                    clickable
                    padding={0.5}
                    align={"center"}
                    onClick={() => {
                      ShareUtils.clickTwitterShare(url, { title });
                      runShareEvent(EShareProvider.TWITTER, url);
                    }}
                  >
                    <FelaIconWrap noBackground colorString={colors.twitter}>
                      <FaTwitter />
                    </FelaIconWrap>
                    <FelaText colorHex={colors.twitter} weight={"500"} margin={"0 0 0 0.25em"} size={ETextSize.content}>
                      Twitter
                    </FelaText>
                  </FelaFlexLayer>
                )}
                {providers.includes(EShareProvider.FACEBOOK) && (
                  <FelaFlexLayer
                    radius={0.25}
                    margin={0.25}
                    height={EFelaLayerHeight.HIGH}
                    hoverable
                    clickable
                    padding={0.5}
                    align={"center"}
                    onClick={() => {
                      ShareUtils.clickFacebookShare(url, { quote: title });
                      runShareEvent(EShareProvider.FACEBOOK, url);
                    }}
                  >
                    <FelaIconWrap noBackground colorString={colors.facebook}>
                      <FaFacebook />
                    </FelaIconWrap>
                    <FelaText
                      colorHex={colors.facebook}
                      weight={"500"}
                      margin={"0 0 0 0.25em"}
                      size={ETextSize.content}
                    >
                      Facebook
                    </FelaText>
                  </FelaFlexLayer>
                )}
              </FelaFlexLayer>
            </div>
          );
        }}
        {...tippyProps}
      >
        <div
          onClick={() => {
            visible ? hide() : show();
          }}
        >
          <FelaIconWrap {...iconProps}>
            <FiShare2 />
          </FelaIconWrap>
        </div>
      </Tippy>
    </div>
  );
};
