import { EImageFormatExtension } from "../../../domains/media/models/ImageV2Model";
import { EImageSizeQuality, EImageVariationType, IImageSvgMetaData } from "../../../types/media/VibescoutImageTypes";
import { TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { IElasticPropertyGeoPoint } from "@gt/services/build/elasticsearch/ElasticSearchTypes";
import { EGeoLevelLevelType, ENodeSubTypeGeoLevel } from "../../crossover/geoLevels/GeoLevelTypes";
import { EImageUsageCapacity } from "../../../domains/media/edges/UsedMediaEdge";

export interface INodeWithId {
  id: string;
}

export interface INodeCreatedByUser {
  createdUserId: string;
}

export interface INodeWithName {
  name: string;
}

export interface INodeWithOneLine {
  oneLine?: string;
}

export interface INodeWithDescription {
  desc?: string;
}

export interface INodeWithSlateDescription extends INodeWithDescription {
  descSlate?: string;
}

export enum ENodeMetaType {
  contact = "co",
  location = "lo",
}

export enum ENodeMetaLocationSubType {
  streetAddress = "lo_ad",
  // streetNumber = "no",
  // streetName = "st",
  // suburbName = "su",
  // cityName = "ci",
}

export enum ENodeMetaContactSubType {
  phoneNumber = "co_ph",
  email = "co_em",
}

export interface INodeMetaInfo {
  // Type
  ty: ENodeMetaType;
  // Sub-Type
  st: string;
  // Meta Label (optional, indexed keyword)
  la: string[];
  // Value (un-indexed)
  vu: string[];
  // Value (Keyword)
  vk: string[];
  // Value (Numeric)
  vn: number[];
}

export interface INodeContactMetaInfo extends INodeMetaInfo {
  st: ENodeMetaContactSubType;
}

export interface INodeLocationMetaInfo extends INodeMetaInfo {
  st: ENodeMetaLocationSubType;
}

export interface INodeWithNodeMeta {
  nodeMet: (INodeContactMetaInfo | INodeLocationMetaInfo)[];
}

export interface INodeLink {
  id: string;
  /** Linked User Account ID (as canonical page for this linked / parent node) */
  // uid?: string;
  ty: string[];
}

export enum EPlaceNodeInfoLinkType {
  isBrand = "i_br",
  isComplex = "i_co",
}

export interface IPlaceNodeInfoLink extends INodeLink {
  ty: EPlaceNodeInfoLinkType[];
}

export enum ENodeParentType {
  // Grouping Types
  isParentOrg = "p_gr",
  isParentComplex = "p_co",

  // Administration types
  // isAdmin = "p_ad",
  // isEditor = "p_ed",
  // createdBy = "p_cr",
}

export enum ENodeAdminType {
  isAdmin = "a_ad",
  isEditor = "a_ed",
}

export enum ENodePermissionType {
  ADMIN = "admin",
  EDIT = "edit_details",
  EDIT_TIME = "edit_time",
}

export interface INodeAdminLink extends INodeLink {
  id: string;
  ty: ENodeAdminType[];
  pe: ENodePermissionType[];
}

export interface INodeParentLink extends INodeLink {
  ty: ENodeParentType[];
}

export interface ILinkedNode {
  nodePar: INodeParentLink[]; // nested
  nodeLin: IPlaceNodeInfoLink[]; // nested
  nodeAdm: INodeAdminLink[]; // nested
}

export interface INodeImgTitlePart_Old {
  /** title variation id */
  ti?: string; // indexed
  /** square source id */
  tiSid?: string;
  tiAs: number; // NOT ind.
  /** sizes */
  tiT?: EImageFormatExtension;
  tiS: EImageSizeQuality[]; // indexed
  /** title UI colors (3 of them max) */
  tiUi: TColorRgbArray[]; // NOT ind.
  /** Data URI */
  tiUri?: string;
  tiSvg?: IImageSvgMetaData;
}

export interface INodeImagePosterPart_Old {
  /** poster variation id */
  po?: string; // indexed
  /** square source id */
  poSid?: string;
  poAs: number; // NOT ind.
  poT?: EImageFormatExtension; // indexed
  poS: EImageSizeQuality[]; // indexed
  /** poster UI colors (3 of them max) */
  poUi: TColorRgbArray[]; // NOT ind.
  poUri?: string;
  poSvg?: IImageSvgMetaData;
}

export interface INodeImageSquarePart_Old {
  /** square variation id */
  sq?: string; // indexed
  /** square source id */
  sqSid?: string;
  sqT?: EImageFormatExtension; // indexed
  sqS: EImageSizeQuality[]; // indexed
  /** square UI colors (3 of them max) */
  sqUi: TColorRgbArray[]; // NOT ind.
  sqUri?: string;
  sqSvg?: IImageSvgMetaData;
}

export interface INodeImgPart_Old extends INodeImgTitlePart_Old, INodeImagePosterPart_Old, INodeImageSquarePart_Old {}

export interface INodeWithImages_Old {
  tagMed: string[];
  tagMedId: string[];
  img: INodeImgPart_Old;
}

export interface INodeImg {
  /** Source ID */
  sid: string;
  /** Might be equal to the SID */
  id: string;
  /** Available image sizes */
  siz: EImageSizeQuality[];
  use: EImageUsageCapacity;
  var: EImageVariationType;
  ext: EImageFormatExtension;
  trans: boolean;
  /** Real aspect ratio (width / height), because its unindexed
   *  and UI needs it - rounded to 3 decimal places */
  as: number;
  ui: TColorRgbArray[];
  uri: string;
  svg: IImageSvgMetaData;
}

export interface INodeWithImages {
  img: {
    /** Title Image */
    [EImageUsageCapacity.title]?: INodeImg;
    /** Poster */
    [EImageUsageCapacity.poster]?: INodeImg;
    /** Square / Profile */
    [EImageUsageCapacity.profile]?: INodeImg;
    /** Logo */
    [EImageUsageCapacity.logo]?: INodeImg;
  };
}

export interface INodeWithContentImages {
  imgCon: INodeImg[];
}

export interface ITaggedNode {
  tagCat: string[];
  tagAtt: string[];
}

export enum ENodeType {
  /** User */
  user = "us",
  /** Team */
  team = "tm",
  /** Geo Level */
  geoLevel = "gl",
  /** Tag Item Node */
  tagOrTemplate = "ta",
  /** Template Item Node */
  // templateItem = "te",
  /** Template Item Suggestion */
  templateItemSuggestion = "ts",
  /** Place Node */
  place = "pl",
  /** Host Node */
  host = "ho",
  /** Info Node */
  infoNode = "in",
  /** Host Item */
  hostItem = "hi",
  /** Temporary Host Item */
  temporaryHostItem = "th",
  /** Host Menu */
  hostMenu = "hm",
  /** Listed Node Blueprint */
  listedNodeBlueprint = "lb",
  /** Listed Node */
  listedNode = "li",
  /** Term or Condition */
  termOrCondition = "co",
  /** Host Place Context */
  placeContext = "pc",
}

export enum ENodeStatus {
  draft = "draft",
  live = "live",
  redirected = "redirected",
  disabled = "disabled",
  removed = "removed",
}

export interface INodeWithDates {
  //** Date this node data was created */
  dateCreated: Date;
  /** Date this node data was updated */
  dateUpdated: Date;
}

export interface INodeWithStatus {
  /** Node status */
  status: ENodeStatus;
}

export interface INodeWithNodeType<T extends ENodeType = ENodeType> {
  /** Node Type - a two letter code representing this node type (will usually have its own dedicated index grouping) */
  nType: T;
}

export interface INodeWithSubType<T extends string | string[]> {
  /** Node Sub-Type - namespaced by nType, this represents sub types within this node type (usually in the same index grouping) */
  nSubType: T;
}

export interface INodeBase<T extends string | string[], R extends string>
  extends INodeWithNodeType,
    INodeWithDates,
    INodeWithStatus,
    INodeWithSubType<T> {
  /** CUID unique backend identifier */
  cuid: string;
  /** Node-type specific redirect term */
  redirect?: R;
  /** Versions of the tags on this node */
  tagVer: string[];
  /** Ids which link to this node (wikipedia pages, internal image nodes, imdb ids)  */
  tagId: string[];
}

export interface INodeBaseArango<T extends string>
  extends INodeWithNodeType,
    INodeWithSubType<T>,
    INodeWithDates,
    INodeWithStatus {
  /** CUID unique backend identifier */
  cuid: string;
  /** For smartly locating vertexes on our graph database */
  shardId: string;
}

export enum EGeoCastingType {
  point = "_point",
  shape = "_shape",
  /** For example: delivery geo boundaries which are more strict and only need
   to cover an exact radius / shape */
  exact_shape = "_exact_shape",
}

type TNodeGranularity = ENodeSubTypeGeoLevel | EGeoCastingType;

export interface IDirectGeoLevelInfo_Old {
  ccName?: string;
  ciName?: string;
  ciShort?: string;
  ciType?: EGeoLevelLevelType;
  muName: string;
  muType: EGeoLevelLevelType;
  short: string;
  ssName?: string;
  stName: string;
  suName?: string;
  type: ENodeSubTypeGeoLevel;
  val: string;
}

export interface IDirectGeoLevelPartBase {
  name: string;
  p: string;
  id: string;
}

/*interface IDirectGeoLevelPartInfo extends IDirectGeoLevelPartBase {
  // type: EGeoLevelLevelType;
}*/

interface IDirectGeoLevelPartInfoCity extends IDirectGeoLevelPartBase {
  short: string;
  type: EGeoLevelLevelType;
}

interface IDirectGeoLevelPartInfoMunicipal {
  name: string[];
  type: EGeoLevelLevelType[];
  p: string[];
  id: string[];
  val: number[];
}

export enum EGeoLevelShortType {
  cn = "cn",
  cc = "cc",
  st = "st",
  mu = "mu",
  ci = "ci",
  su = "su",
  ss = "ss",
}

export interface IDirectGeoLevelInfo {
  // How deep is this node relevant
  gran: TNodeGranularity;
  /** Lowest SINGLE geo-level (the lowest granularity we can
   *  directly represent this place in without ambiguity) */
  type: ENodeSubTypeGeoLevel;
  val: number;
  /** The lowest possible type of geo-level represented for
   *  this place- even if it crosses over multiple of them */
  lowest: ENodeSubTypeGeoLevel;
  [EGeoLevelShortType.cc]: IDirectGeoLevelPartBase[];
  [EGeoLevelShortType.st]: IDirectGeoLevelPartBase[];
  /** If granularity is municipal - Must only include at the
   same municipal granularity level (lvl value) and higher. */
  [EGeoLevelShortType.mu]: IDirectGeoLevelPartInfoMunicipal[];
  [EGeoLevelShortType.ci]: IDirectGeoLevelPartInfoCity[];
  [EGeoLevelShortType.su]: IDirectGeoLevelPartBase[];
  [EGeoLevelShortType.ss]: IDirectGeoLevelPartBase[];
}

export interface IGeoLevelLocatedNode_Old {
  glDi: string[];
  glAr: string[];
  glRe: string[];
  geo: IDirectGeoLevelInfo_Old;
  addr?: string;
}

export interface IGeoLevelLocatedNode {
  /** The absolute lowest geo levels, at the same granularity level
   - only applies to nodes with granularity not set to `_point`, `_shape` or `_exact_shape` (casting types) */
  glGr: string[];
  glDi: string[];
  glAr: string[];
  glRe: string[];
  geo: IDirectGeoLevelInfo;
  // addr?: string;
}

/** Good for things like stats at various granular geo levels */
export interface IGeoLevelLocatedNodeDirectOnly {
  glDi: string[];
  geo: Partial<IDirectGeoLevelInfo>;
}

export interface INodeWithLatLon {
  latLon: IElasticPropertyGeoPoint;
}

/*
export enum ENodeGeoCatchmentType {
  point = "point",
  shape = "shape",
  exact_shape = "exact_shape",
  granular_geo = "granular_geo",
}

export interface INodeGeoCatchment {
  type: ENodeGeoCatchmentType;
}
*/
