import React, { useEffect, useState } from "react";
import { EPositionError, IUserPosition } from "../../state/stores/LocationStore/LocationStore";
import { LocationActions } from "../../state/stores/LocationStore/LocationActions";
import { SectionCoverModalNoChildren } from "@gt/gt-frontend/build/react/vibescout-ui/components/SectionCoverModalNoChildren";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import { LocationStatic } from "../../state/stores/LocationStore/LocationStatic";
import { EModalFeedbackState } from "@gt/gt-frontend/build/react/vibescout-ui/components/SimpleModalComponent";

interface ICPUseLocationComponentChild {
  tryGetLocation: () => void;
  triedBefore: boolean;
  triedNow: boolean;
  errorCode?: EPositionError;
  position?: IUserPosition;
}

export interface ICPUseLocationComponent {
  onSuccess?: () => void;
  children: (inputs: ICPUseLocationComponentChild) => React.ReactElement;
}

export const UseLocationComponent: React.FC<ICPUseLocationComponent> = ({ children, onSuccess }) => {
  const { LocationStore } = PSC_VibescoutSpecial.useStores();
  const locationResponse = LocationActions.initiateLocationWatcher.useDefer();
  const [triedBefore, position, errorCode] = LocationStore.useState(
    (s) => [s.attemptedGeoPosition, s.accuratePos, s.errorCode] as const,
  );
  const [triedNow, setTriedNow] = useState(false);
  const [modalDismissed, setModalDismissed] = useState(false);

  return (
    <>
      {triedNow && !modalDismissed && errorCode != null && (
        <SectionCoverModalNoChildren
          getStateAndMessage={() => {
            return [EModalFeedbackState.ERROR, LocationStatic.messageForErrorCode[errorCode]];
          }}
          onClose={() => {
            setModalDismissed(true);
          }}
        />
      )}
      {children({
        errorCode,
        position,
        triedBefore,
        triedNow,
        tryGetLocation: () => {
          setTriedNow(false);
          setModalDismissed(false);
          locationResponse.execute({}, {}).then((response) => {
            if (!response.error) {
              onSuccess?.();
            }
            setTriedNow(true);
          });
        },
      })}
    </>
  );
};
