import React from "react";
import { TObjectKeyEnumSet } from "@gt/common-utils/build/typescript/SpecialTypes";

export interface ISelectItem<T extends string | number = string> {
  id: T;
  name: string;
  description?: string;
}

export type TIconForSelectItem<T extends string = string> = {
  [key in T]: React.ReactElement;
};

export function createMetaObjectFromArray<E extends string | number, T extends ISelectItem<E> = ISelectItem<E>>(
  metaArray: T[],
): TObjectKeyEnumSet<`${E}`, T> {
  const obj: TObjectKeyEnumSet<`${E}`, T> = {} as TObjectKeyEnumSet<`${E}`, T>;

  for (const item of metaArray) {
    obj[`${item.id}`] = item;
  }

  return obj;
}
