import React, { FunctionComponent } from "react";
import { FelaText } from "./FelaText";
import { useFela } from "react-fela";
import { TFelaRule } from "./types";
import { ETextSize } from "../config/VibescoutFrontendStyleConfig";

interface ICPFelaSimpleCheck {
  checked: boolean;
  onChange?: () => void;
  disabled?: boolean;
  size?: number;
  text?: string;
  textComponent?: React.ReactElement;
  textAfter?: boolean;
  type?: "checkbox" | "radio";
}

const FelaRuleSimpleCheck: TFelaRule<{ enabled: boolean; size: number }> = ({ enabled, size }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: size * 0.25,
    "> input": {
      margin: "0.25em",
      width: size,
      height: size,
    },
  };
};

export const FelaSimpleCheck: FunctionComponent<ICPFelaSimpleCheck> = ({
  disabled = false,
  checked,
  onChange,
  size = 1,
  text = "Enabled",
  textComponent,
  textAfter = false,
  type = "checkbox",
}) => {
  const { css } = useFela({ enabled: checked, size });

  const t = textComponent ? (
    textComponent
  ) : (
    <FelaText
      // transform={"uppercase"}
      size={ETextSize.small}
      margin={textAfter ? "0 0 0 0.3em" : "0 0.3em 0 0"}
      weight={500}
    >
      {text}
    </FelaText>
  );

  return (
    <label className={css(FelaRuleSimpleCheck)}>
      {!textAfter && t}
      <input disabled={disabled} type={type} name="enabled" checked={checked} onChange={onChange} />
      {textAfter && t}
    </label>
  );
};
