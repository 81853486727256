import { PSC_VibescoutSpecial } from "../PSC_VibescoutSpecial";
import { WildcardUtilsClient } from "@gt/gt-frontend/build/utils/WildcardUtilsClient";
import { wildcard } from "../../wildcard/VibescoutSpecial_WildcardClient";
import { createPostIndexArrayFromState } from "../../sections/old-vibescout/OldPostIndex";
import { IDMOldPostSnippet } from "@vs/core/build/data/models/oldVibescout/oldPost/OldPostSnippet";
import { IOldPostSnippetWithColorInformation } from "../stores/LocalityStore/LocalityStore";
import { TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";

export function transformOldPostWithColorInfo(post: IDMOldPostSnippet): IOldPostSnippetWithColorInformation {
  const colorPaletteHydrated = JSON.parse(post.colorInformation.colorPalette);
  const [r, g, b] = colorPaletteHydrated[post.colorInformation.preferredColorIndex];
  const colorRgbArray: TColorRgbArray = [`${r}, ${g}, ${b}`, r, g, b];
  return { ...post, colorPaletteHydrated, colorRgbArray, colorString: `rgb(${colorRgbArray[0]})` };
}

const getPostsForLatLon = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getPostsForLatLon, "Get Posts For LatLon"),
  {
    postActionHook: ({ result, stores }) => {
      if (!result.error) {
        stores.LocalityStore.update((s) => {
          s.oldLocalityPosts.guides = result.payload.infoGuides.map(transformOldPostWithColorInfo);
          s.oldLocalityPosts.press = result.payload.pressReleases.map(transformOldPostWithColorInfo);
          s.oldLocalityPosts.food = result.payload.foodArticles.map(transformOldPostWithColorInfo);
        });
      }
    },
  },
);

const getPost = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.getPost, "Get Post"),
  {
    postActionHook: ({ result, stores: { OldPostPageStore } }) => {
      if (!result.error && result.payload.post) {
        const body: any | null = JSON.parse(result.payload.post.body ?? "null");
        const postIndexArray = createPostIndexArrayFromState(body);
        OldPostPageStore.update((s) => {
          s.postIndexArray = postIndexArray;
          s.body = body;
        });
      }
    },
  },
);

const searchPost = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.postSearchQuery, "Post Search"),
);

export const PostAsyncActions = {
  getPostsForLatLon,
  getPost,
  searchPost,
};
