import { IElasticPropertyGeoPoint } from "@gt/services/build/elasticsearch/ElasticSearchTypes";
import { IDirectGeoLevelInfo } from "@vs/core/build/data/models/nodes/BaseNodeModel";
import { Store } from "pullstate";

export enum ELocationSetFrom {
  PICKED_GEO_LEVEL = "PICKED",
  GEOLOCATION_API = "GEOLOCATION",
  NONE = "NONE",
}

export interface IUserPosition {
  latLon: IElasticPropertyGeoPoint;
  lastUpdated: Date;
}

export type TRecentGeoLevel = IDirectGeoLevelInfo & { latLon: IElasticPropertyGeoPoint };

export enum EPositionError {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}

export interface ILocationStore {
  currentSetFrom: ELocationSetFrom;
  accuratePos?: IUserPosition;
  attemptedGeoPosition: boolean;
  recentGeoLevels: TRecentGeoLevel[];
  watchId?: any;
  errorCode?: EPositionError;
  hasNavigator: boolean;
}

export const LocationStore = new Store<ILocationStore>({
  currentSetFrom: ELocationSetFrom.NONE,
  recentGeoLevels: [],
  attemptedGeoPosition: false,
  hasNavigator: false,
});
