import React from "react";

export const OldLinkOrPlainText: React.FC<{
  url?: string;
  text?: string;
  className?: string;
  allowFollow?: boolean;
}> = ({ url, className = "", text, children, allowFollow = false }) => {
  if (url) {
    if (url.indexOf("http") === -1) {
      url = `//${url}`;
    }

    return (
      <a target="_blank" rel={allowFollow ? "" : "noopener noreferrer nofollow"} className={className} href={url}>
        {children != null ? children : <span className="text">{text}</span>}
      </a>
    );
  }

  return <span className={className}>{text}</span>;
};
