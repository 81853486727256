import React, { Fragment } from "react";
import { FelaIconWrap, IFelaRuleIconWrapProps } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FelaText, TFelaTextProps } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { EGraphicContrast } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import {
  EFelaLayerHeight,
  FelaFlexLayer,
  FelaGridLayer,
} from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { PSC_VibescoutSpecial } from "../../../state/PSC_VibescoutSpecial";
import { EPageSizeRange } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { HeaderText, UnderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import {
  ESurfaceHeight,
  ETextSize,
  ETextTypographyType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";

export const CircleIcon: React.FC = () => (
  <svg viewBox={"0 0 150 150"} width="3.5em" height="4em" xmlns="http://www.w3.org/2000/svg" baseProfile="full">
    <circle cx={75} cy={75} r={"75"} />
  </svg>
);

export const LegendItem: React.FC<{
  color: string;
  name: string | React.ReactElement;
  icon?: React.ReactElement;
  extraValue?: any;
  iconProps?: IFelaRuleIconWrapProps;
}> = ({ color, name, icon = <CircleIcon />, iconProps, extraValue }) => {
  return (
    <FelaFlexbox align={"center"}>
      <FelaIconWrap fillAlso size={ETextSize.content} colorString={color} {...iconProps}>
        {icon}
      </FelaIconWrap>
      <HeaderText margin={"0 0.3em"} colorHex={color} size={ETextSize.small}>
        {name}
      </HeaderText>
      {extraValue != null && (
        <FelaText
          margin={"0 0 0 0.3em"}
          contrast={EGraphicContrast.HIGH}
          typography={ETextTypographyType.mono}
          weight={500}
        >
          {extraValue}
        </FelaText>
      )}
    </FelaFlexbox>
  );
};

interface ICPGraphGrid {}

const gridTemplateMinWidthForPageSize: { [pageSize: number]: string } = {
  [EPageSizeRange.bigDesktop]: "35em",
  [EPageSizeRange.desktop]: "35em",
  [EPageSizeRange.smallMobile]: "20em",
  [EPageSizeRange.mobile]: "20em",
  [EPageSizeRange.tablet]: "28em",
};

export const GraphGrid: React.FC<ICPGraphGrid> = ({ children }) => {
  const { UIStore } = PSC_VibescoutSpecial.useStores();

  const pageSize = UIStore.useState((s) => s.pageSize);
  const isSmall = pageSize <= EPageSizeRange.mobile;

  return (
    <FelaGridLayer
      gap={1}
      position={"relative"}
      gridAutoFlow={"row dense"}
      gridTemplateColumns={`repeat(auto-fit, minmax(${gridTemplateMinWidthForPageSize[pageSize]}, 1fr))`}
      padding={isSmall ? 0 : 1}
      gradient={[
        { def: "to bottom", stops: [{ height: ESurfaceHeight.deep }, { height: ESurfaceHeight.deepest, def: "20em" }] },
      ]}
      height={EFelaLayerHeight.LOW}
    >
      {children}
    </FelaGridLayer>
  );
};

export const GraphSection: React.FC<{
  rowSpan?: number | string;
  colSpan?: number | string;
  title: string | React.ReactElement;
  subtext?: string | React.ReactElement;
  customHeader?: any;
  extraValue?: any;
  id?: string;
}> = ({ children, title, subtext, extraValue, rowSpan = 1, colSpan = 1, id, customHeader }) => {
  return (
    <FelaFlexbox id={id} direction={"column"} style={{ gridRow: `span ${rowSpan}`, gridColumn: `span ${colSpan}` }}>
      <FelaFlexbox justify={"space-between"} padding={0.5} align={"flex-start"}>
        <FelaFlexbox align={"center"}>
          <HeaderText margin={"0.25em 0.35em 0.25em 0"}>{title}</HeaderText>
          {customHeader}
          {extraValue != null && (
            <FelaText
              margin={"0 0 0 0.5em"}
              contrast={EGraphicContrast.MID}
              typography={ETextTypographyType.mono}
              weight={500}
            >
              {extraValue}
            </FelaText>
          )}
        </FelaFlexbox>
        {subtext && (
          <UnderText align={"right"} margin={"0.25em 0"}>
            {subtext}
          </UnderText>
        )}
      </FelaFlexbox>
      <FelaFlexLayer
        height={ESurfaceHeight.upper}
        direction={"column"}
        padding={0.5}
        radius={0.5}
        style={{ width: "100%" }}
      >
        {children}
      </FelaFlexLayer>
    </FelaFlexbox>
  );
};

interface ICPQuickStat {
  stats: {
    header?: string;
    icon?: React.ReactElement;
    color: string;
    value: any;
  }[];
  mainHeader?: any;
  mainIcon?: any;
  isSmall: boolean;
}

export const QuickStat: React.FC<ICPQuickStat> = ({ stats, isSmall, mainHeader, mainIcon }) => {
  return (
    <FelaFlexLayer
      direction={"column"}
      shadow
      height={ESurfaceHeight.high}
      padding={isSmall ? 0.35 : 0.5}
      radius={0.5}
      margin={isSmall ? 0.25 : 0.5}
      justify={"space-between"}
      align={"flex-end"}
    >
      {mainHeader && (
        <FelaFlexbox align={"center"}>
          <FelaIconWrap
            size={isSmall ? ETextSize.smallHeader : ETextSize.bigHeader}
            contrast={EGraphicContrast.MID}
            margin={`0 ${!isSmall ? "0.5em" : "0.3em"} 0 0`}
            noPadding
            noBackground
            fillAlso
          >
            {mainIcon}
          </FelaIconWrap>
          <HeaderText size={isSmall ? ETextSize.small : undefined}>{mainHeader}</HeaderText>
        </FelaFlexbox>
      )}
      <FelaFlexbox direction={"row"} justify={"flex-end"}>
        {stats.map(({ icon, color, header, value }, index) => (
          <Fragment key={`${header}-${value}`}>
            <FelaFlexbox direction={"column"} align={"flex-end"}>
              {header && (
                <FelaFlexbox margin={"0.5em 0 0 0"} align={"center"}>
                  {icon && (
                    <FelaIconWrap
                      size={isSmall ? ETextSize.smallHeader : ETextSize.bigHeader}
                      contrast={EGraphicContrast.MID}
                      margin={`0 ${!isSmall ? "0.5em" : "0.3em"} 0 0`}
                      noPadding
                      noBackground
                      fillAlso
                    >
                      {icon}
                    </FelaIconWrap>
                  )}
                  <HeaderText contrast={EGraphicContrast.MID} size={isSmall ? ETextSize.tiny : ETextSize.small}>
                    {header}
                  </HeaderText>
                </FelaFlexbox>
              )}
              <FelaText colorHex={color} weight={"bold"} fontSize={isSmall ? 1.35 : 1.65}>
                {value}
              </FelaText>
            </FelaFlexbox>
            {index !== stats.length - 1 && <FelaFlexbox margin={0.4} />}
          </Fragment>
        ))}
      </FelaFlexbox>
    </FelaFlexLayer>
  );
};

export const LinkSource: React.FC<TFelaTextProps & { link: string }> = ({ link, children, ...props }) => {
  return (
    <a target="_blank" rel={"noopener noreferrer nofollow"} href={link}>
      <FelaText typography={ETextTypographyType.mono} weight={500} size={ETextSize.small} {...props}>
        {children}
      </FelaText>
    </a>
  );
};
