export const DateFormatStrings = {
  shortMonthAndDay: "d MMM",
  fullDate: "yyyy-MM-dd",
  hourAndMinute: {
    withAmPm: "hh:mm a",
    military: "HH:mm",
  },
};

/*
export class BetterDate extends Date {
    private timezoneOffset = 0;

    public setTimezoneOffset(offset: number) {
        this.timezoneOffset = offset;
    }

    public getYearForTZ() {
        return this.getUTCFullYear() + this.getTimezoneUTCYearOffset();
    }

    getMonthForTZ() {
        const monthOffset = this.getTimezoneUTCMonthOffset();
        const utcMonth = this.getUTCMonth();

        if (monthOffset !== 0) {
            return (12 + (utcMonth + monthOffset)) % 12;
        }

        return utcMonth;
    }

    public getDayOfMonthForTZ() {
        const hours = (this.getUTCHours() + this.timezoneOffset);
        const utcMonthDay = this.getUTCDate();

        if (hours >= 24) {
            // next day

            const utcDaysInMonth = getMonthDays(this.getUTCMonth(), this.getUTCFullYear());
            if (utcMonthDay + 1 > utcDaysInMonth) {
                // next month
                return 1;
            }

            return utcMonthDay + 1;
        } else if (hours < 0) {
            // previous day

            if (utcMonthDay - 1 < 1) {
                // previous month
                return getMonthDays((12 + (this.getUTCMonth() - 1)) % 12, this.getUTCFullYear());
            }

            return utcMonthDay - 1;
        }

        return this.getUTCDate();
    }

    public getHourOfDayForTZ() {
        const hours = (this.getUTCHours() + this.timezoneOffset) % 24;
        return hours >= 0 ? hours : hours + 24;
    }

    public getTimezoneUTCDayOffset() {
        const hours = (this.getUTCHours() + this.timezoneOffset);

        if (hours >= 24) {
            return 1;
        } else if (hours < 0) {
            return -1;
        }

        return 0;
    }

    public getTimezoneUTCMonthOffset() {
        const dayOffset = this.getTimezoneUTCDayOffset();

        if (dayOffset !== 0) {
            const utcMonthDay = this.getUTCDate();
            const utcDaysInMonth = getMonthDays(this.getUTCMonth(), this.getUTCFullYear());

            const day = utcMonthDay + dayOffset;

            if (day <= 0) {
                return -1;
            } else if (day > utcDaysInMonth) {
                return 1;
            }

            return 0;
        }

        return 0;
    }

    public getTimezoneUTCYearOffset() {
        const monthOffset = this.getTimezoneUTCMonthOffset();

        if (monthOffset !== 0) {
            const utcMonth = this.getUTCMonth();
            const month = utcMonth + monthOffset;

            if (month < 0) {
                return -1;
            } else if (month >= 12) {
                return 1;
            }

            return 0;
        }

        return 0;
    }
}
*/
