import React, { useState } from "react";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { GoSearch } from "react-icons/go";
import { TextInputWithButton } from "@gt/gt-frontend/build/react/vibescout-ui/components/TextInputWithButton";
import { useDelayedValue } from "@gt/gt-frontend/build/react/hooks/useDelayedValue";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { GeoLevelAsyncActions } from "../../../state/asyncActions/GeoLevelAsyncActions";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { GeoLevelStatic } from "@vs/core/build/data/crossover/geoLevels/GeoLevelStatic";
import { useCombobox } from "downshift";
import { ENodeSubTypeGeoLevel } from "@vs/core/build/data/crossover/geoLevels/GeoLevelTypes";
import { IDMGeoLevel, IESGeoLevelNode } from "@vs/core/build/data/models/geolevels/GeoLevelModelTypes";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { ASN_VibescoutSpecial } from "../../../state/ASN_VibescoutSpecial";
import {
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { LoadingStrip } from "@gt/gt-frontend/build/react/vibescout-ui/common/LoadingStrip";
import { useFelaCache } from "@gt/gt-frontend/build/react/fela/FelaCache";

interface ICPLandingSearchBar {
  size?: ETextSize;
}

export const GeoLevelSearchBar: React.FC<ICPLandingSearchBar> = ({ size }) => {
  const { theme } = useFelaCache();

  const [typedText, setTypedText] = useState("");
  const searchText = useDelayedValue(typedText, theme.isSmall ? 400 : 250);

  const searchGeoLevels = GeoLevelAsyncActions.searchGeoLevelBasics.use(
    {
      countryCode: "za",
      text: searchText,
      nSubType: [ENodeSubTypeGeoLevel.gl_ci, ENodeSubTypeGeoLevel.gl_su, ENodeSubTypeGeoLevel.gl_ss],
    },
    {
      dormant: searchText.length === 0,
      holdPrevious: true,
    },
  );

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    getComboboxProps,
    setInputValue,
    highlightedIndex,
    isOpen,
    selectItem,
    reset,
    openMenu,
  } = useCombobox({
    id: "geo_level_search",
    items: searchGeoLevels.payload?.geoLevels ?? ([] as (IESGeoLevelNode | undefined)[]),
    onInputValueChange: ({ inputValue }) => {
      // console.log(inputValue);
      setTypedText(inputValue ?? "");
    },
    itemToString: (item: IDMGeoLevel) => item.name,
    stateReducer: (state, actionAndChanges) => {
      console.log(actionAndChanges);

      return {
        ...actionAndChanges.changes,
        // selectedItem: undefined,
        inputValue: [
          useCombobox.stateChangeTypes.InputChange,
          useCombobox.stateChangeTypes.FunctionSetInputValue,
        ].includes(actionAndChanges.type as any)
          ? actionAndChanges.changes.inputValue
          : state.inputValue,
      };
    },
    onSelectedItemChange: ({ selectedItem }) => {
      console.log(selectedItem);

      if (selectedItem) {
        console.log(`Selected ITEM : ${selectedItem.fullName}`);
        const path = GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: selectedItem.geoLvlShort });
        ASN_VibescoutSpecial.go(`/${path}`);
        reset();
      }
    },
  });

  const maxResultHeight = theme.isSmall ? "15em" : "20em";

  return (
    <FelaFlexbox rootProps={getComboboxProps()}>
      <TextInputWithButton
        rootProps={{
          ...getInputProps({
            onFocus: () => {
              if (!isOpen) {
                openMenu();
              }
            },
          }),
        }}
        // value={typedText}
        // onChangeText={setTypedText}
        cssExtra={[
          {
            "::placeholder": {
              paddingLeft: "1.5em",
            },
          },
        ]}
        // placeHolder={"City, Town or Suburb"}
        size={size ?? ETextSize.smallHeader}
        rounded
        showButton={typedText.length > 0}
        onClickButton={() => setInputValue("")}
      >
        <FullAbsFlexbox
          cssExtra={[{ maskImage: "linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0))" }]}
          radius={2}
          style={{ opacity: typedText.length > 0 ? 0 : 1 }}
          pointerEvents={false}
          align={"center"}
          padding={0.5}
          overflow={"hidden"}
        >
          <FelaIconWrap contrast={EForeContrast.subtle} size={size ?? ETextSize.smallHeader} noBackground>
            <GoSearch />
          </FelaIconWrap>
          <FelaText
            forceTheme={EFrontendStyleConfigBaseTheme.DARK}
            contrast={EForeContrast.regular}
            whiteSpace={"pre"}
            // size={size}
            size={size}
          >
            City, Town or Suburb
          </FelaText>
        </FullAbsFlexbox>
      </TextInputWithButton>
      {
        <FullAbsFlexbox
          pointerEvents={false}
          zIndex={1}
          top={"calc(100%)"}
          overflow={"hidden"}
          padding={"0"}
          style={{
            height: maxResultHeight,
          }}
        >
          <LoadingStrip delay={100} show={typedText.length > 0 && isOpen && searchGeoLevels.isLoading} />
          <FelaFlexLayer
            height={ESurfaceHeight.deepest}
            shadow
            extraSoftShadow
            rootProps={getMenuProps()}
            align={"stretch"}
            direction={"column"}
            style={{
              pointerEvents: "all",
              maxHeight: maxResultHeight,
              overflowY: "scroll",
              width: "100%",
              transition: "transform 0.3s ease-in-out",
              transform: typedText.length > 0 && isOpen ? "translateY(0)" : "translateY(-120%)",
            }}
          >
            {typedText.length > 0 &&
              isOpen &&
              searchGeoLevels.renderPayload(({ geoLevels }) => {
                return geoLevels.map((geoLevel, index) => {
                  // const path = GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: geoLevel.geoLvlShort });
                  // console.log(path);
                  /*<AsyncNavLink key={geoLevel.cuid} to={`/${path}`}>

            </AsyncNavLink>*/

                  let higherLevel: string | undefined;

                  if (geoLevel.lvlValue > GeoLevelStatic.valuesForGeoLvlType["city"].lvlValue) {
                    higherLevel = geoLevel.ciName;

                    if (geoLevel.lvlValue > GeoLevelStatic.valuesForGeoLvlType["suburb"].lvlValue) {
                      higherLevel = `${geoLevel.suName}, ${higherLevel}`;
                    }
                  } else if (geoLevel.lvlValue > GeoLevelStatic.valuesForGeoLvlType["province"].lvlValue) {
                    higherLevel = geoLevel.stName;
                  }

                  return (
                    <FelaFlexLayer
                      shrink={0}
                      position={"relative"}
                      borderWidth={"2px 0 0 0"}
                      borderHeight={ESurfaceHeight.deepest}
                      rootProps={getItemProps({ item: geoLevel, index })}
                      height={highlightedIndex === index ? ESurfaceHeight.high : ESurfaceHeight.mid}
                      direction={"column"}
                      align={"flex-start"}
                      padding={0.5}
                      clickable
                      onClick={() => selectItem(geoLevel)}
                      key={geoLevel.cuid}
                    >
                      <FelaText margin={"0 0 0 0.25em"}>{geoLevel.name}</FelaText>
                      <FelaFlexbox position={"relative"} align={"center"}>
                        <FelaFlexLayer height={ESurfaceHeight.low} padding={"0.1em 0.2em"} radius={0.15}>
                          <FelaText size={ETextSize.tiny}>
                            {GeoLevelStatic.valuesForGeoLvlType[geoLevel.lvlType].text}
                          </FelaText>
                        </FelaFlexLayer>
                        {higherLevel && (
                          <FelaText size={ETextSize.tiny} margin={"0 0 0 0.5em"}>
                            in <b>{higherLevel}</b>
                          </FelaText>
                        )}
                      </FelaFlexbox>
                    </FelaFlexLayer>
                  );
                });
              })}
          </FelaFlexLayer>
        </FullAbsFlexbox>
      }
    </FelaFlexbox>
  );
};
