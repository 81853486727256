import { Property, Properties } from "csstype";
import * as React from "react";
import { IFelaComponentBaseProps } from "./FelaCommon";
import { TFelaRule } from "./types";
import { useFelaCache } from "../../fela/FelaCache";

export interface ICssPositionalProps {
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
}

export interface IFelaFlexboxProps extends ICssPositionalProps {
  basis?: Property.FlexBasis<any>;
  direction?: Property.FlexDirection;
  align?: Property.AlignItems;
  justify?: Property.JustifyContent;
  wrap?: Property.FlexWrap;
  gap?: any;
  grow?: number | "unset" | "initial" | "inherit";
  shrink?: number | "unset" | "initial" | "inherit";
  overflow?: Property.Overflow;
  zIndex?: Property.ZIndex;
  alignSelf?: Property.AlignSelf;
  justifySelf?: Property.JustifySelf;
  gridColumn?: Property.GridColumn;
  gridRow?: Property.GridRow;
  padding?: Property.Padding<any>;
  margin?: Property.Margin<any>;
  innerHtml?: string;
  radius?: Property.BorderRadius<any>;
  aspect?: number;
  pushMaxWidth?: string;
}

export const FelaRuleFlexbox: TFelaRule<IFelaFlexboxProps & Pick<IFelaComponentBaseProps, "position">> = ({
  basis,
  align,
  direction,
  justify,
  wrap,
  grow,
  shrink,
  overflow,
  zIndex,
  position,
  alignSelf,
  justifySelf,
  padding,
  margin,
  top,
  bottom,
  left,
  right,
  gap,
  gridColumn = "",
  gridRow = "",
  radius,
  aspect,
  pushMaxWidth,
}) => {
  const rules = {
    ...{
      minWidth: 0,
      position,
      zIndex,
      display: "flex",
      overflow,
      flexBasis: basis,
      alignItems: align,
      flexDirection: direction,
      justifyContent: justify,
      flexWrap: wrap,
      flexGrow: grow,
      flexShrink: shrink,
      transition: "background 0.15s ease-out, box-shadow 0.15s ease-out, color 0.15s ease-out",
      alignSelf,
      justifySelf,
      padding,
      margin,
      top,
      bottom,
      left,
      right,
      borderRadius: radius,
      gap,
      // ...(gridColumn && { gridColumn }),
      // ...(gridRow && { gridRow }),
      // ...(aspect && { paddingBottom: `${100 / aspect}%` }),
      // ...(position === "sticky" && {
      //   top: 0,
      // }),
    },
  } as Properties;

  if (gridColumn) {
    rules.gridColumn = gridColumn;
  }

  if (gridRow) {
    rules.gridRow = gridRow;
  }

  if (aspect) {
    rules.paddingBottom = `${100 / aspect}%`;
  }

  if (pushMaxWidth) {
    rules.width = "100%";
    rules.maxWidth = pushMaxWidth;
  }

  return rules;
};

export const ruleKeysFlexbox = [
  "basis",
  "align",
  "direction",
  "justify",
  "wrap",
  "grow",
  "shrink",
  "overflow",
  "zIndex",
  "position",
  "alignSelf",
  "justifySelf",
  "padding",
  "margin",
  "top",
  "bottom",
  "left",
  "right",
  "gap",
  "gridColumn",
  "gridRow",
  "radius",
  "aspect",
  "pushMaxWidth",
];

export const FelaFlexbox: React.FC<IFelaFlexboxProps & IFelaComponentBaseProps> = ({
  children,
  cssExtra = [],
  className = "",
  id,
  innerRef,
  style,
  onClick,
  onMouseLeave,
  onMouseEnter,
  rootProps,
  ref,
  cssKey,
  title,
  ...props
}) => {
  // const { css } = useFela(props);
  const { cachedClass, inlineCss } = useFelaCache();

  // const cssClass = cachedClass(FelaRuleFlexbox, props);

  // console.log(`Rendering FlexBox: ${cssClass}`);

  return (
    <div
      title={title}
      id={id}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      style={style}
      // className={`${className} ${css(FelaRuleFlexbox, ...cssExtra)}`}
      className={
        cssExtra.length > 0
          ? `${className} ${cachedClass(FelaRuleFlexbox, props, cssKey ?? ruleKeysFlexbox)} ${inlineCss(cssExtra)}`
          : `${className} ${cachedClass(FelaRuleFlexbox, props, cssKey ?? ruleKeysFlexbox)}`
      }
      {...rootProps}
    >
      {children}
    </div>
  );
};
