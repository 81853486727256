import assert from "assert";

interface IFacebookShareOptions {
  quote?: string;
  hashtag?: string;
}

interface ITwitterShareOptions {
  title?: string;
  via?: string;
  hashtags?: string[];
}

function objectToGetParams(object: { [key: string]: string | number | undefined | null }) {
  const params = Object.entries(object)
    .filter(([, value]) => value != null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join("&")}` : "";
}

function twitterLink(url: string, { title, via, hashtags = [] }: ITwitterShareOptions): string {
  assert(url, "twitter.url");
  assert(Array.isArray(hashtags), "twitter.hashtags is not an array");

  return (
    "https://twitter.com/share" +
    objectToGetParams({
      url,
      text: title,
      via,
      hashtags: hashtags.join(","),
    })
  );
}

function facebookLink(url: string, { quote, hashtag }: IFacebookShareOptions): string {
  assert(url, "facebook.url");

  return (
    "https://www.facebook.com/sharer/sharer.php" +
    objectToGetParams({
      u: url,
      quote,
      hashtag,
    })
  );
}

const getBoxPositionOnWindowCenter = (width: number, height: number) => ({
  left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2,
  top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2,
});

const getBoxPositionOnScreenCenter = (width: number, height: number) => ({
  top: (window.screen.height - height) / 2,
  left: (window.screen.width - width) / 2,
});

function windowOpen(
  url: string,
  { height, width, ...configRest }: { height: number; width: number; [key: string]: any },
  onClose?: (dialog: Window | null) => void,
) {
  const config: { [key: string]: string | number } = {
    height,
    width,
    location: "no",
    toolbar: "no",
    status: "no",
    directories: "no",
    menubar: "no",
    scrollbars: "yes",
    resizable: "no",
    centerscreen: "yes",
    chrome: "yes",
    ...configRest,
  };

  const shareDialog = window.open(
    url,
    "",
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(", "),
  );

  if (onClose) {
    const interval = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(interval);
          onClose(shareDialog);
        }
      } catch (e) {
        /* eslint-disable no-console */
        console.error(e);
        /* eslint-enable no-console */
      }
    }, 1000);
  }

  return shareDialog;
}

export enum EShareNetworkName {
  TWITTER = "twitter",
  FACEBOOK = "facebook",
}

type TClickShareOptions<N extends EShareNetworkName> = N extends EShareNetworkName.FACEBOOK
  ? IFacebookShareOptions
  : ITwitterShareOptions;

type WindowPosition = "windowCenter" | "screenCenter";

interface IClickShareBaseOptions {
  windowWidth?: number;
  windowHeight?: number;
  windowPosition?: WindowPosition;
  onShareWindowClose?: () => void;
}

function clickFacebookShare(shareUrl: string, options: IFacebookShareOptions & IClickShareBaseOptions = {}) {
  clickShare(shareUrl, EShareNetworkName.FACEBOOK, options);
}

function clickTwitterShare(shareUrl: string, options: ITwitterShareOptions & IClickShareBaseOptions = {}) {
  clickShare(shareUrl, EShareNetworkName.TWITTER, options);
}

function clickShare<N extends EShareNetworkName>(
  shareUrl: string,
  network: N,
  options?: TClickShareOptions<N> & IClickShareBaseOptions,
) {
  let link: string;

  switch (network) {
    case EShareNetworkName.FACEBOOK:
      link = facebookLink(shareUrl, (options ?? {}) as IFacebookShareOptions);
      break;
    case EShareNetworkName.TWITTER:
      link = twitterLink(shareUrl, (options ?? {}) as ITwitterShareOptions);
      break;
    default:
      throw new Error(`clickShare(): Need to set a share network to create a share link`);
  }

  const { onShareWindowClose, windowHeight = 400, windowPosition = "windowCenter", windowWidth = 550 } = options ?? {};

  const windowConfig = {
    height: windowHeight,
    width: windowWidth,
    ...(windowPosition === "windowCenter"
      ? getBoxPositionOnWindowCenter(windowWidth, windowHeight)
      : getBoxPositionOnScreenCenter(windowWidth, windowHeight)),
  };

  windowOpen(link, windowConfig, onShareWindowClose);
}

export const ShareUtils = {
  clickFacebookShare,
  clickTwitterShare,
};
