import { INodeWithDates } from "../../../data/models/nodes/BaseNodeModel";
import { IADBEdgeDocumentExtras } from "@gt/services/build/arangodb/ArangodbTypes";

export enum EImageUsageCapacity {
  profile = "pr",
  title = "ti",
  poster = "po",
  content = "co",
  logo = "lo",
}

export enum EVideoUsageCapacity {
  title = "ti",
  content = "co",
}

export enum EMediaUsageStatus {
  active = "active",
  inactive = "inactive",
  hidden = "hidden",
}

export enum EMediaEdgeType {
  image = "image",
  video = "video",
}

interface IDMUsedMediaEdge_Base extends INodeWithDates, IADBEdgeDocumentExtras {
  status: EMediaUsageStatus;
  linkedByTeam?: string;
  linkedByUser: string;
  /** Which part (if any) is being used */
  variationId?: string;
}

export const CUsedMediaEdge_Keys_HistoryItem = [
  "dateUpdated",
  "status",
  "linkedByTeam",
  "linkedByUser",
  "variationId",
] as const;

export interface IDMUsedMediaEdge_Image extends IDMUsedMediaEdge_Base {
  type: EMediaEdgeType.image;
  /** In what capacity is the linked thing used */
  capacity: EImageUsageCapacity;
  history: Pick<IDMUsedMediaEdge_Image, typeof CUsedMediaEdge_Keys_HistoryItem[number]>[];
}

export interface IDMUsedMediaEdge_Video extends IDMUsedMediaEdge_Base {
  type: EMediaEdgeType.video;
  /** In what capacity is the linked thing used */
  capacity: EVideoUsageCapacity;
  history: Pick<IDMUsedMediaEdge_Video, typeof CUsedMediaEdge_Keys_HistoryItem[number]>[];
}

/** Unique: _to, _from, capacity */
export type TDMUsedMediaEdge = IDMUsedMediaEdge_Image | IDMUsedMediaEdge_Video;
