export const fillerImageCover = "https://storage.googleapis.com/vibescoutapp.appspot.com/graphics/vibescout_filler_pattern_cover_image.png";
export const fillerImageSmallSquare = "https://storage.googleapis.com/vibescoutapp.appspot.com/graphics/vibescout_filler_pattern_small_square.png";
export const fillerImageLargeWallpaper = "https://storage.googleapis.com/vibescoutapp.appspot.com/graphics/vibescout_filler_pattern.png";

export const fillerImageLighterLargeWallpaper = "https://storage.googleapis.com/vibescoutapp.appspot.com/graphics/vibescout_filler_pattern_lighter.png";
export const fillerImageLighterLargeSquare = "https://storage.googleapis.com/vibescoutapp.appspot.com/graphics/vibescout_filler_pattern_lighter_largesquare.png";
export const fillerImageLighterCover = "https://storage.googleapis.com/vibescoutapp.appspot.com/graphics/vibescout_filler_pattern_lighter_cover.png";
export const fillerImageLighterSmallSquare = "https://storage.googleapis.com/vibescoutapp.appspot.com/graphics/vibescout_filler_pattern_lighter_smallsquare.png";

export const FillerImages = {
  fillerImageCover,
  fillerImageSmallSquare,
  fillerImageLargeWallpaper,
  fillerImageLighterLargeWallpaper,
  fillerImageLighterLargeSquare,
  fillerImageLighterCover,
  fillerImageLighterSmallSquare,
};
