import { RouteProps, useParams } from "react-router";
import React from "react";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { PostAsyncActions } from "../../state/asyncActions/PostAsyncActions";
import { FelaFlexLayer, FelaGridLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { PSC_VibescoutSpecial } from "../../state/PSC_VibescoutSpecial";
import { ESurfaceHeight } from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { IFelaTheme } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { FiLock, FiMap } from "react-icons/fi";
import { FaRegNewspaper } from "react-icons/fa";
import { PageSectionHeader } from "../../components/content/PageSectionHeader";
import { PostSnippet } from "../../components/post/PostSnippet";
import { PageLocalityScoutAsyncActions, PageLocalityScoutStatic } from "../../state/navigation/_navLocalityContent";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { LockdownLevelsGeoStatic } from "@vs/core/build/data/models/covid19/lockdownLevels/lockdownLevelsGeo/LockdownLevelsGeoStatic";
import { LocalityLockdownLevelInfo } from "./components/LocalityLockdownLevelInfo";
import { LocalityCovid19Stats } from "./components/LocalityCovid19Stats";
import { useFela } from "react-fela";
import { EPageSizeRange } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { VirusIcon } from "@vs/core/build/frontend/graphics/svg-icons/VirusIcon";
import { IOldPostSnippetWithColorInformation } from "../../state/stores/LocalityStore/LocalityStore";
import { useShowMore } from "@gt/gt-frontend/build/react/hooks/useShowMore";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { ButtonSpacer, ShowMoreButton } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicButtonComponents";
import { useFelaCache } from "@gt/gt-frontend/build/react/fela/FelaCache";
import { MdRestaurant } from "react-icons/md";
import { LocalityCinemaScroller } from "./components/LocalityCinemaScroller";
import { FelaGrid } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaGrid";
import { LocalityBreadcrumbs } from "./components/LocalityBreadcrumbs";

export interface ILocalityScoutPageParams {
  city: string;
  suburb?: string;
  area?: string;
}

export const LocalityScoutPage: React.FC<RouteProps> = () => {
  const params = useParams<ILocalityScoutPageParams>();

  const geoLevel = GeoLevelCompressUtils.convertUrlToCompressed({
    continentOrCountry: "za",
    cityPart: params.city,
    suburbPart: params.suburb,
    subSuburbPart: params.area,
  });

  console.log(`Rendering Locality Scout Page (${geoLevel})`, params);

  const { LocalityStore, UIStore } = PSC_VibescoutSpecial.useStores();
  const [
    postsGuides,
    postsPress,
    postsFood,
    {
      lockdownLevels: { current, next, previous, selectedLvl, selectedCategory },
    },
    icons,
    closedBlurb,
  ] = LocalityStore.useState(
    (s) =>
      [
        s.oldLocalityPosts.guides,
        s.oldLocalityPosts.press,
        s.oldLocalityPosts.food,
        s.covid,
        s.icons,
        s.closedBlurb,
      ] as const,
  );

  const isSmall = UIStore.useState((s) => s.isSmall);
  const udi = UIStore.useState((s) => s.udi);
  const { theme } = useFela<IFelaTheme>();

  const localityPageData = PageLocalityScoutAsyncActions.getPageData.use({ geoLvlShort: geoLevel });
  const cinemaPlaceData = PageLocalityScoutAsyncActions.queryForAreaPlaceNodes.use(
    PageLocalityScoutStatic.getDefaultCinemasForGeoLevelArguments({
      gl: localityPageData?.payload?.geoLevel?.id!,
      latLon: localityPageData?.payload?.geoLevel?.latLon!,
    }),
    { dormant: localityPageData.error },
  );
  const postResponse = PostAsyncActions.getPostsForLatLon.use(
    PageLocalityScoutStatic.getDefaultPostsForLatLonArguments({
      lat: localityPageData?.payload?.geoLevel?.latLon?.lat!,
      lon: localityPageData?.payload?.geoLevel?.latLon?.lon!,
    }),
    { dormant: localityPageData.error, postActionEnabled: true },
  );

  let headerSeed = 0;

  // console.log({ current, next });

  return localityPageData.renderPayload(({ geoLevel, lockdownCategories, lockdownRules }) =>
    postResponse.renderPayload(() => {
      const hasFoodPosts = postsFood.length > 0;

      console.log(geoLevel);
      const isMobileOrTablet = theme.pageSizeValue < EPageSizeRange.desktop;

      console.log(isMobileOrTablet);

      return (
        <FelaGrid
          style={{ maxWidth: "100vw" }}
          position={"relative"}
          gridTemplateColumns={"1fr minmax(auto, 86em) 1fr"}
        >
          <FelaFlexbox gridColumn={"1 / -1"} direction={"column"}>
            {geoLevel.lvlValue > 80 && <LocalityBreadcrumbs isSmall={isSmall} geoLevel={geoLevel} />}
          </FelaFlexbox>
          <FelaFlexbox gridColumn={isMobileOrTablet ? "1 / -1" : "2 / -1"} direction={"column"}>
            {cinemaPlaceData.isSuccess && cinemaPlaceData.payload.items.length > 0 && (
              <LocalityCinemaScroller
                key={geoLevel.id}
                items={cinemaPlaceData.payload.items}
                geoLevel={geoLevel}
                isSmall={isSmall}
                udi={udi}
              />
            )}
          </FelaFlexbox>
          <FelaGrid
            gridColumn={isMobileOrTablet ? "1 / -1" : "2 / 2"}
            padding={isSmall ? 0 : 1}
            gap={isSmall ? 0 : 1}
            direction={"column"}
            gridTemplateColumns={theme.pageSizeValue < EPageSizeRange.desktop ? "1fr" : "1fr 1fr"}
          >
            {/*{current && (
              <FelaFlexbox direction={"column"}>
                <PageSectionHeader
                  isSmall={isSmall}
                  padding={"1em 0.5em 1em 0.5em"}
                  seed={headerSeed++}
                  text={`Lockdown Info in ${geoLevel.name}`}
                  icon={<FiLock />}
                />
                <FelaFlexLayer radius={isSmall ? 0 : 0.35} overflow={"hidden"} direction={"column"}>
                  <LocalityLockdownLevelInfo
                    next={next}
                    current={current}
                    previous={previous}
                    rules={lockdownRules}
                    categories={lockdownCategories}
                    icons={icons}
                    isSmall={isSmall}
                    selectedLevel={selectedLvl}
                    selectedCategory={selectedCategory}
                    onChangeCategory={(cat) => {
                      LocalityStore.update((s) => {
                        s.covid.lockdownLevels.selectedCategory = cat;
                      });
                    }}
                    onChangeLevel={(lvl) => {
                      LocalityStore.update((s) => {
                        s.covid.lockdownLevels.selectedLvl =
                          LockdownLevelsGeoStatic.funnelLevelValueToBasicLevelValue[lvl];
                      });
                    }}
                  />
                </FelaFlexLayer>
              </FelaFlexbox>
            )}*/}
            {/*<FelaFlexbox direction={"column"} gridColumn={"1 / -1"}>
              <PageSectionHeader
                isSmall={isSmall}
                padding={"1em 0.5em 1em 0.5em"}
                seed={headerSeed++}
                text={`Covid19 Stats around ${geoLevel.name}`}
                icon={<VirusIcon />}
                fillIcon
              />
              <FelaFlexLayer radius={isSmall ? 0 : 0.35} overflow={"hidden"} direction={"column"}>
                <LocalityCovid19Stats geoLevel={geoLevel} />
              </FelaFlexLayer>
            </FelaFlexbox>*/}
            <LocalityPagePostSection
              posts={postsPress}
              header={
                <PageSectionHeader
                  isSmall={isSmall}
                  padding={"1em 0.5em 1em 0.5em"}
                  seed={headerSeed++}
                  text={`${geoLevel.name} Latest News`}
                  icon={<FaRegNewspaper />}
                />
              }
              gridColumn={hasFoodPosts ? "span 1" : "1 / -1"}
              limitHeight={hasFoodPosts}
            />
            {hasFoodPosts && (
              <LocalityPagePostSection
                posts={postsFood}
                header={
                  <PageSectionHeader
                    isSmall={isSmall}
                    padding={"1em 0.5em 1em 0.5em"}
                    seed={headerSeed++}
                    text={`${geoLevel.name} Food & Drink`}
                    icon={<MdRestaurant />}
                  />
                }
                gridColumn={"span 1"}
              />
            )}
            <LocalityPagePostSection
              posts={postsGuides}
              limitHeight={false}
              header={
                <PageSectionHeader
                  isSmall={isSmall}
                  padding={"1em 0.5em 1em 0.5em"}
                  seed={headerSeed++}
                  text={`Info Guides Around ${geoLevel.name}`}
                  icon={<FiMap />}
                />
              }
              gridColumn={"1 / -1"}
            />
          </FelaGrid>
        </FelaGrid>
      );
    }),
  );
};

interface ICPLocalityPagePostSection {
  header: any;
  posts: IOldPostSnippetWithColorInformation[];
  gridColumn: string;
  limitHeight?: boolean;
}

const LocalityPagePostSection: React.FC<ICPLocalityPagePostSection> = ({
  posts,
  header,
  gridColumn,
  limitHeight = true,
}) => {
  const { theme } = useFelaCache();
  const blockMaxHeight = theme.isSmall ? 600 : 800;
  const { closed, applicable, onClickButton, blockRef, buttonRef } = useShowMore({
    blockMaxHeight,
    disabled: !limitHeight,
  });

  return (
    <FelaFlexbox
      style={{ maxHeight: limitHeight && closed ? blockMaxHeight : undefined }}
      position={"relative"}
      radius={theme.isSmall ? 0 : 0.35}
      overflow={"hidden"}
      gridColumn={gridColumn}
      direction={"column"}
      alignSelf={"start"}
    >
      {header}
      <FelaGridLayer
        radius={1}
        backgroundOpacity={0.5}
        padding={theme.isSmall ? 0.5 : 1}
        height={ESurfaceHeight.shadow}
        rootProps={{ ref: blockRef }}
        gap={theme.isSmall ? 0.5 : 1}
        gridTemplateColumns={theme.isSmall ? "1fr" : "repeat(auto-fill, minmax(18em, 1fr))"}
      >
        {posts.map((post) => (
          <PostSnippet key={post.postUrl} isSmall={theme.isSmall} post={post} />
        ))}
        <ButtonSpacer open={!closed && applicable} />
      </FelaGridLayer>
      {limitHeight && applicable && (
        <FullAbsFlexbox pointerEvents={false} direction={"column"} justify={"flex-end"} position={"absolute"}>
          <ShowMoreButton isOpen={!closed} onClick={onClickButton} buttonRef={buttonRef} />
        </FullAbsFlexbox>
      )}
    </FelaFlexbox>
  );
};
