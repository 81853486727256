import React, { FunctionComponent } from "react";
import ApolloClient from "apollo-client";

export enum EReactStyleProvider {
  FELA = "FELA",
}

export enum EReactRouterProvider {
  REACT_ROUTER = "REACT_ROUTER",
}

export interface ICreateReactTreeFunctionOptions {
  server?: boolean;
  useRouter?: EReactRouterProvider;
  useStyleProvider?: EReactStyleProvider;
  useApollo?: boolean;
  apolloProvider?: any;
  strictMode?: boolean;
}

export interface IReactTreeFunctionOptions {
  apolloClient?: ApolloClient<any>;
  styleProviderProps?: object;
  routerProps?: object;
}

function createReactTreeFunction({
  server = false,
  useRouter,
  useStyleProvider,
  apolloProvider,
  strictMode = false,
}: ICreateReactTreeFunctionOptions = {}) {
  let RouterComponent = ({ children }) => children;
  let StyleProvider = ({ children }) => children;
  let ApolloProviderComponent: FunctionComponent<any> = ({ children }) => children;

  if (useRouter != null) {
    if (useRouter === EReactRouterProvider.REACT_ROUTER) {
      {
        if (!server) {
          RouterComponent = require("react-router-dom").Router;
        } else {
          RouterComponent = require("react-router-dom").StaticRouter;
        }
      }
    }
  }

  if (useStyleProvider != null) {
    if (useStyleProvider === EReactStyleProvider.FELA) {
      {
        StyleProvider = require("react-fela").RendererProvider;
      }
    }
  }

  if (apolloProvider !== undefined) {
    ApolloProviderComponent = apolloProvider as any;
  }

  if (!server) {
    return (
      AppComponent: any,
      { apolloClient, routerProps, styleProviderProps }: IReactTreeFunctionOptions = {}
    ) =>
      apolloClient ? (
        <ApolloProviderComponent client={apolloClient}>
          <StyleProvider {...styleProviderProps}>
            <RouterComponent {...routerProps}>
              <AppComponent />
            </RouterComponent>
          </StyleProvider>
        </ApolloProviderComponent>
      ) : (
        <StyleProvider {...styleProviderProps}>
          <RouterComponent {...routerProps}>
            <AppComponent />
          </RouterComponent>
        </StyleProvider>
      );
  } else {
    return (
      AppComponent: any,
      { apolloClient, styleProviderProps, routerProps }: IReactTreeFunctionOptions = {}
    ) =>
      apolloClient ? (
        <ApolloProviderComponent client={apolloClient}>
          <StyleProvider {...styleProviderProps}>
            <RouterComponent {...routerProps}>
              <AppComponent />
            </RouterComponent>
          </StyleProvider>
        </ApolloProviderComponent>
      ) : (
        <StyleProvider {...styleProviderProps}>
          <RouterComponent {...routerProps}>
            <AppComponent />
          </RouterComponent>
        </StyleProvider>
      );
  }
}

export const ReactSetup = {
  createReactTreeFunction,
};
