import { ReactRouterPathMatch, ReactRouterPathMatching } from "./ReactRouterPathMatching";
import React, { AnchorHTMLAttributes, useEffect, useState } from "react";
import { TaskFunctionError, TaskFunctionUtils } from "@gt/common-utils/build/taskFunction/TaskFunctionUtils";
import {
  ETaskFunctionEndId,
  ITaskFunctionNegativeResponse,
} from "@gt/common-utils/build/taskFunction/TaskFunctionTypes";
import { TFRFailure, TFRFailureDefaults } from "@gt/common-utils/build/taskFunction/TaskFunctionResponses";
import produce from "immer";
import { ExecutionEnvironmentUtils } from "@gt/common-utils/build/general/ExecutionEnvironmentUtils";
import queryString from "query-string";

const { matchPath } = ReactRouterPathMatching;

export enum EEndTags_AsyncNavigator {
  async_navigator_route_not_found = "async_navigator_route_not_found",
}

export enum ENavigationMetaPageType {
  regular = "regular",
  maintenance = "maintenance",
  not_found = "not_found",
  unknown_error = "unknown_error",
}

export interface INavigatorQueryObject {
  [key: string]: string | string[] | undefined;
}

export interface IResolverError<M> {
  index: number;
  error: TaskFunctionError;
  info: IMatchInfoAndMeta<M>;
}

export interface IPathAndQuery {
  path: string;
  query?: INavigatorQueryObject;
}

export interface IAsyncNavigatorContext extends IPathAndQuery {
  redirect?: IPathAndQuery;
  status?: number;
  errors: IResolverError<any>[];
  fallthrough?: boolean;
}

export interface IOAsyncNavigatorResolverInput<S, M> {
  info: ReactRouterPathMatch;
  state: S;
  ctx: IAsyncNavigatorContext;
  updateMeta: (updater: (metaDraft: M, previousMeta: M) => void) => void;
}

export type TAsyncNavigatorResolver<S, M, O = any> = (inputs: IOAsyncNavigatorResolverInput<S, M>) => Promise<O>;
export type TRunAfterFunction<S, M, O> = (response: O, inputs: IOAsyncNavigatorResolverInput<S, M>) => void;

export interface IPathWithAsyncState<S, M extends any, O extends any = any> {
  paths: string[];
  resolve?: TAsyncNavigatorResolver<S, M, O>;
  runAfter?: TRunAfterFunction<S, M, O>;
  meta?: Partial<M>;
  shouldSkip?: (inputs: IOAsyncNavigatorResolverInput<S, M>) => boolean;
  parallel?: boolean | string | number;
}

export interface IGoOptions {
  nav?: boolean;
  query?: INavigatorQueryObject;
}

export interface ICPLink {
  style?: any;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  query?: INavigatorQueryObject;
}

export interface IMatchInfoAndMeta<M extends any, P extends any = any> {
  match: ReactRouterPathMatch;
  meta: M;
  skipped: boolean;
}

export interface IOAsyncNavigatorExecutionOutput<M extends any> {
  ctx: IAsyncNavigatorContext;
  matchInfo: IMatchInfoAndMeta<M>[];
  results: any[];
}

export type TGoFunc<M> = (path: string, options?: IGoOptions) => Promise<IOAsyncNavigatorExecutionOutput<M>>;

export type TExecuteFunc<S, M> = (
  path: string,
  state?: S,
  options?: IGoOptions,
) => Promise<IOAsyncNavigatorExecutionOutput<M>>;

export enum EAsyncNavigatorTimePosition {
  THROW = "THROW",
  START = "START",
  STEP = "STEP",
  END_REDIRECT = "END_REDIRECT",
  END = "END",
}

export interface IOAsyncNavigatorListenerState<S, M extends any> extends IOAsyncNavigatorExecutionOutput<M> {
  ord: number;
  pos: EAsyncNavigatorTimePosition;
  state: S;
}

export type TAsyncNavigatorListener<S, M extends any> = (state: IOAsyncNavigatorListenerState<S, M>) => void;

export interface IUseAsyncNavigatorStateOutput {
  isLoading: boolean;
  errors: ITaskFunctionNegativeResponse<any>[];
  clearErrors: () => void;
}

export interface IOAsyncNavigatorConstructor_Inputs<S, M extends any> {
  getReactState?: () => S;
  onFinishResolve?: (navData: IOAsyncNavigatorExecutionOutput<M> & { state: S }) => void;
  getDefaultMeta: () => M;
}

export interface IAsyncNavigator<S, M extends any> {
  Link: React.FC<ICPLink & AnchorHTMLAttributes<HTMLAnchorElement>>;
  useAsyncNavigation: () => { go: (path: string, options?: IGoOptions) => Promise<IOAsyncNavigatorExecutionOutput<M>> };
  execute: TExecuteFunc<S, M>;
  go: TGoFunc<M>;
  setDefaultState: (state: S) => void;
  listen: (listener: TAsyncNavigatorListener<S, M>) => () => void;
  useAsyncNavigatorState: () => IUseAsyncNavigatorStateOutput;
  clientRefresh: () => Promise<IOAsyncNavigatorExecutionOutput<M>>;
}

let AsyncNavigatorSingleton: AsyncNavigator<any> | undefined;

export const getClientAsyncNavigator = <T extends AsyncNavigator<any> = AsyncNavigator<any>>(): T => {
  if (AsyncNavigatorSingleton == null) {
    throw Error(`Async Navigator: Tried to run getAsyncNavigator(), but it hasn't been created yet.`);
  }

  if (ExecutionEnvironmentUtils.isNode()) {
    throw Error(`Async Navigator: Can't run getAsyncNavigator() on the server. This is a client-only function.`);
  }

  return AsyncNavigatorSingleton as T;
};

export class AsyncNavigator<S, M extends any = any> implements IAsyncNavigator<S, M> {
  private resolvers: IPathWithAsyncState<S, M, any>[] = [];
  private readonly getReactState?: () => S;
  private readonly onFinishResolve?: (navData: IOAsyncNavigatorExecutionOutput<M> & { state: S }) => void;
  private onClientNavigate: (pathAndQuery: IPathAndQuery) => void;
  private readonly getDefaultMeta: () => M;

  private asyncState: {
    currentActionOrd: number;
    defaultState: undefined | S;
  } = { currentActionOrd: 0, defaultState: undefined };

  private listeners: TAsyncNavigatorListener<S, M>[] = [];
  private currentState: IOAsyncNavigatorListenerState<S, M> | undefined;

  constructor({ getReactState, onFinishResolve, getDefaultMeta }: IOAsyncNavigatorConstructor_Inputs<S, M>) {
    this.getReactState = getReactState;
    this.onFinishResolve = onFinishResolve;
    this.getDefaultMeta = getDefaultMeta;
    AsyncNavigatorSingleton = this;
  }

  public Link: React.FC<ICPLink> = ({ to, query, children, onClick, style, ...rootProps }) => {
    const { go } = this.useAsyncNavigation();

    function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
      if (onClick) {
        onClick(event);
      }
      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 // Ignore everything but left clicks
      ) {
        event.preventDefault();
        go(to, { query });
      }
    }

    return (
      <a href={to} style={{ display: "contents", ...style }} onClick={handleClick} {...rootProps}>
        {children}
      </a>
    );
  };

  /*createReactContext(): React.Context<AsyncNavigator<S, M>> {
    return React.createContext<AsyncNavigator<S, M>>(this);
  }*/

  setOnClientNavigate(onClientNavigate: (pathAndQuery: IPathAndQuery) => void) {
    this.onClientNavigate = onClientNavigate;
  }

  private async _go(
    path: string,
    state: S,
    { nav = true, query }: IGoOptions = {},
  ): Promise<IOAsyncNavigatorExecutionOutput<M>> {
    while (true) {
      const ctx: IAsyncNavigatorContext = {
        path,
        query,
        errors: [],
        status: 200,
      };

      console.log(`ASYNC NAV: Starting`, ctx);

      this.asyncState.currentActionOrd += 1;
      const thisActionOrd = this.asyncState.currentActionOrd;

      let started = false;

      const matchInfo: IMatchInfoAndMeta<M>[] = [];
      const resolverErrors: IResolverError<M>[] = [];
      const results: any[] = [];
      const toRunAfterInputsAndFuncs: {
        inputs: IOAsyncNavigatorResolverInput<S, M>;
        func?: TRunAfterFunction<S, M, any>;
      }[] = [];
      let matchInfoAndMeta: IMatchInfoAndMeta<M> | undefined = undefined;

      const alreadyMatchedIndexes: number[] = [];

      let fallthrough = true;

      while (fallthrough) {
        ctx.fallthrough = false;
        const toResolve: Promise<any>[] = [];

        const preMatchedIndexesLength = alreadyMatchedIndexes.length;
        let resolverIndex = -1;
        let currentParallelIdentifier: string | number | undefined;

        for (const { resolve, runAfter, paths, meta, parallel = false, shouldSkip } of this.resolvers) {
          resolverIndex += 1;
          let match: ReactRouterPathMatch | null = null;

          for (const mPath of paths) {
            match = matchPath(mPath, path);
            if (match != null) {
              break;
            }
          }

          if (match != null && !alreadyMatchedIndexes.includes(resolverIndex)) {
            alreadyMatchedIndexes.push(resolverIndex);

            if (matchInfoAndMeta == null) {
              matchInfoAndMeta = { match, meta: Object.assign(this.getDefaultMeta(), meta), skipped: false };
            } else {
              matchInfoAndMeta.match = match;
              matchInfoAndMeta.meta = Object.assign(matchInfoAndMeta.meta, meta);
            }

            const resolverArgs: IOAsyncNavigatorResolverInput<S, M> = {
              info: match,
              state,
              ctx,
              updateMeta: (updater) => {
                matchInfoAndMeta!.meta = produce(matchInfoAndMeta!.meta, (s) => {
                  updater(s as any, matchInfoAndMeta!.meta);
                });
              },
            };

            if (!(shouldSkip?.(resolverArgs) ?? false) && resolve != null) {
              toRunAfterInputsAndFuncs.push({ inputs: resolverArgs, func: runAfter });
              toResolve.push(
                resolve(resolverArgs).catch((e) => {
                  resolverErrors.push({
                    index: resolverIndex,
                    error:
                      e instanceof TaskFunctionError
                        ? e
                        : new TaskFunctionError(
                            TFRFailure(
                              ETaskFunctionEndId.ERROR,
                              `Async Navigation Error pathname(${match?.pathname}), path(${match?.path}): ${e.message}`,
                              e,
                            ),
                          ),
                    info: matchInfoAndMeta!,
                  });
                }),
              );
              if (typeof parallel === "boolean") {
                if (!parallel) {
                  matchInfo.push(matchInfoAndMeta);
                  break;
                }
              } else if (currentParallelIdentifier === undefined) {
                currentParallelIdentifier = parallel;
              } else if (currentParallelIdentifier !== parallel) {
                matchInfo.push(matchInfoAndMeta);
                break;
              }
            } else {
              matchInfoAndMeta.skipped = true;
            }

            matchInfo.push(matchInfoAndMeta);
          }
        }

        if (alreadyMatchedIndexes.length === 0) {
          const error = new TaskFunctionError(
            TFRFailureDefaults({
              endId: ETaskFunctionEndId.NOT_FOUND,
              endMessage: `Async Navigation Error: No resolver matched for path`,
              endTags: [EEndTags_AsyncNavigator.async_navigator_route_not_found],
            }),
          );

          ctx.errors = [
            {
              error,
              index: -1,
              info: {} as any,
            },
          ];
          ctx.status = TaskFunctionUtils.getHttpStatusCodeForEndId(ETaskFunctionEndId.NOT_FOUND);

          this.listeners.forEach((l) =>
            l({
              results,
              matchInfo,
              state,
              ctx,
              ord: this.asyncState.currentActionOrd,
              pos: EAsyncNavigatorTimePosition.THROW,
            }),
          );
          throw error;
        }

        if (preMatchedIndexesLength === alreadyMatchedIndexes.length) {
          console.warn(
            `Async Navigator Warning: Router "fell through"- but didn't match any other routes further down`,
          );
        }

        this.listeners.forEach((l) =>
          l({
            results,
            matchInfo,
            state,
            ctx,
            ord: this.asyncState.currentActionOrd,
            pos: !started ? EAsyncNavigatorTimePosition.START : EAsyncNavigatorTimePosition.STEP,
          }),
        );

        const currentResults = await Promise.all(toResolve);
        results.push(...currentResults);

        started = true;

        if (ctx.redirect == null) {
          fallthrough = ctx.fallthrough;
        } else {
          fallthrough = false;
        }
      }

      if (resolverErrors.length > 0) {
        console.error(`ASYNC NAV: Some resolvers failed to resolve state correctly for path ${path}`);
        console.error(
          resolverErrors.map((e) => TaskFunctionUtils.printTaskFunctionError(e.error.taskFunctionResponse)).join(", "),
        );
        ctx.errors = resolverErrors;
        ctx.status = TaskFunctionUtils.getHttpStatusCodeForEndId(resolverErrors[0].error.taskFunctionResponse.endId);

        if (thisActionOrd === this.asyncState.currentActionOrd) {
          this.onFinishResolve?.({
            state,
            ctx,
            matchInfo,
            results,
          });
        }
      } else {
        const withRedirect = ctx.redirect ? ` and REDIRECTING to: [ ${ctx.redirect.path} ]` : "";

        console.log(
          `ASYNC NAV: Resolved for path: [ ${
            path === ctx.path ? path : `${path} -REWRITTEN-> ${ctx.path}`
          } ]${withRedirect}`,
        );

        if (thisActionOrd === this.asyncState.currentActionOrd) {
          if (ctx.redirect) {
            this.listeners.forEach((l) =>
              l({
                results,
                matchInfo,
                state,
                ctx,
                ord: this.asyncState.currentActionOrd,
                pos: EAsyncNavigatorTimePosition.END_REDIRECT,
              }),
            );

            if (nav) {
              path = ctx.redirect.path;
              query = ctx.redirect.query;
              continue;
            }
          } else if (nav) {
            this.onClientNavigate({
              path: ctx.path,
              query: ctx.query,
            });
          }

          toRunAfterInputsAndFuncs.forEach(({ func, inputs }, index) => {
            func?.(results[index], inputs);
          });

          this.onFinishResolve?.({
            state,
            ctx,
            matchInfo,
            results,
          });
        }
      }

      this.listeners.forEach((l) =>
        l({
          results,
          matchInfo,
          state,
          ctx,
          ord: this.asyncState.currentActionOrd,
          pos: EAsyncNavigatorTimePosition.END,
        }),
      );

      return {
        ctx,
        matchInfo,
        results,
      };
    }
  }

  go(path: string, options?: IGoOptions) {
    return this._go(path, this.asyncState.defaultState != null ? this.asyncState.defaultState : ({} as S), options);
  }

  useAsyncNavigation() {
    const state = this.getReactState?.() ?? ({} as S);

    return {
      go: (path: string, options?: IGoOptions) => this._go(path, state, options),
    };
  }

  setDefaultState(state: S) {
    this.asyncState.defaultState = state;
  }

  listen(listener: TAsyncNavigatorListener<S, M>, ssrListener: boolean = false) {
    if (typeof document !== "undefined" || ssrListener) {
      this.listeners.push(listener);
    }

    return () => {
      this.listeners.filter((l) => l !== listener);
    };
  }

  execute(
    path,
    state = this.asyncState.defaultState != null ? this.asyncState.defaultState : ({} as S),
    options: IGoOptions = {},
  ) {
    return this._go(path, state, {
      nav: false,
      ...options,
    });
  }

  clientRefresh() {
    if (ExecutionEnvironmentUtils.isBrowser()) {
      // window.location
      return this._go(window.location.pathname, this.asyncState.defaultState ?? ({} as S), {
        query: queryString.parse(window.location.search) as INavigatorQueryObject,
      });
    } else {
      throw new Error(
        `Can't run Async Navigator "clientRefresh()" on Node.js - this is meant as a client-side only method`,
      );
    }
  }

  useAsyncNavigatorState(): IUseAsyncNavigatorStateOutput {
    const [isLoading, setIsLoading] = useState(this.currentState?.pos === EAsyncNavigatorTimePosition.START ?? false);
    const [errors, setErrors] = useState<ITaskFunctionNegativeResponse<any>[]>(
      this.currentState?.ctx.errors?.map((e) => e.error.taskFunctionResponse) ?? [],
    );

    useEffect(() => {
      const effectState: any = {
        ord: this.currentState?.ord ?? -1,
        shouldUpdate: true,
        timeout: null,
      };
      // let timeout: any;
      // let ord = -1;
      const unregister = this.listen((nav) => {
        if (nav.pos === EAsyncNavigatorTimePosition.THROW && nav.ord >= effectState.ord) {
          effectState.ord = nav.ord;
          clearTimeout(effectState.timeout);
          if (effectState.shouldUpdate) {
            setIsLoading(false);

            if (nav.ctx.errors.length > 0) {
              setErrors(nav.ctx.errors.map((e) => e.error.taskFunctionResponse));
            }
          }
        } else {
          if (nav.pos === EAsyncNavigatorTimePosition.START) {
            effectState.ord = nav.ord;
            clearTimeout(effectState.timeout);
            effectState.timeout = setTimeout(() => {
              if (effectState.shouldUpdate) {
                setIsLoading(true);
              }
            }, 100);
          } else if (nav.pos === EAsyncNavigatorTimePosition.END && nav.ord === effectState.ord) {
            clearTimeout(effectState.timeout);
            if (effectState.shouldUpdate) {
              setIsLoading(false);

              if (nav.ctx.errors.length > 0) {
                setErrors(nav.ctx.errors.map((e) => e.error.taskFunctionResponse));
              }
            }
          }
        }
      });

      return () => {
        effectState.shouldUpdate = false;
        clearTimeout(effectState.timeout);
        unregister();
      };
    }, []);

    return {
      errors,
      isLoading,
      clearErrors: () => setErrors([]),
    };
  }

  addRoute<O extends any = any>(resolver: IPathWithAsyncState<S, M, O>) {
    this.resolvers.push(resolver);
  }

  addRedirect(paths: string[], redirect: IPathAndQuery) {
    this.resolvers.push({
      paths,
      resolve: async ({ ctx }) => {
        if (ctx.path !== redirect.path) {
          ctx.redirect = redirect;
        }
      },
    });
  }

  addRoutes(resolvers: IPathWithAsyncState<S, M, any>[]) {
    this.resolvers.push(...resolvers);
  }
}
