import { IRenderer } from "fela";

export interface IFelaKeyframes_VibescoutSpecial {
  gradientSlide: string;
}

const registerKeyframes = (renderer: IRenderer): IFelaKeyframes_VibescoutSpecial => {
  const keyFrames: IFelaKeyframes_VibescoutSpecial = {} as IFelaKeyframes_VibescoutSpecial;
  keyFrames.gradientSlide = renderer.renderKeyframe(() => {
    return {
      "0%": {
        backgroundPosition: "0% 0%",
      },
      "50%": {
        backgroundPosition: "50% 50%",
      },
      "100%": {
        backgroundPosition: "0% 0%",
      },
    };
  }, {});
  return keyFrames;
};

export const FelaConfig_VibescoutSpecial = {
  registerKeyframes,
};
