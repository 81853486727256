import { TObjectKeyEnumSet } from "@gt/common-utils/build/typescript/SpecialTypes";
import { EGeoLevelDataAction, EGeoLevelLevelType, ENodeSubTypeGeoLevel } from "./GeoLevelTypes";
import { EGeoLevelShortType } from "../../models/nodes/BaseNodeModel";

interface IMetaBasic {
  text: string;
}

interface IDataActionMeta extends IMetaBasic {
  excludeSubTypes: ENodeSubTypeGeoLevel[];
}

interface IGeoNodeSubTypeMeta extends IMetaBasic {
  lvlTypes: EGeoLevelLevelType[];
  lowerSubTypes: ENodeSubTypeGeoLevel[];
  plural: string;
}

const subTypesAboveCity: ENodeSubTypeGeoLevel[] = [
  ENodeSubTypeGeoLevel.gl_cn,
  ENodeSubTypeGeoLevel.gl_cc,
  ENodeSubTypeGeoLevel.gl_st,
  ENodeSubTypeGeoLevel.gl_mu,
];

const GeoLevelTypeToGeoShortKey = {
  gl_cn: EGeoLevelShortType.cn,
  gl_cc: EGeoLevelShortType.cc,
  gl_st: EGeoLevelShortType.st,
  gl_mu: EGeoLevelShortType.mu,
  gl_ci: EGeoLevelShortType.ci,
  gl_su: EGeoLevelShortType.su,
  gl_ss: EGeoLevelShortType.ss,
};

const geoNodeSubTypeMeta: TObjectKeyEnumSet<ENodeSubTypeGeoLevel, IGeoNodeSubTypeMeta> = {
  gl_cn: {
    text: "Continent",
    plural: "Continents",
    lvlTypes: [EGeoLevelLevelType.continent],
    lowerSubTypes: [
      ENodeSubTypeGeoLevel.gl_cc,
      ENodeSubTypeGeoLevel.gl_st,
      ENodeSubTypeGeoLevel.gl_mu,
      ENodeSubTypeGeoLevel.gl_ci,
      ENodeSubTypeGeoLevel.gl_su,
      ENodeSubTypeGeoLevel.gl_ss,
    ],
  },
  gl_cc: {
    text: "Country",
    plural: "Countries",
    lvlTypes: [EGeoLevelLevelType.country],
    lowerSubTypes: [
      ENodeSubTypeGeoLevel.gl_st,
      ENodeSubTypeGeoLevel.gl_mu,
      ENodeSubTypeGeoLevel.gl_ci,
      ENodeSubTypeGeoLevel.gl_su,
      ENodeSubTypeGeoLevel.gl_ss,
    ],
  },
  gl_st: {
    text: `Province`,
    plural: "Provinces",
    lvlTypes: [EGeoLevelLevelType.province],
    lowerSubTypes: [
      ENodeSubTypeGeoLevel.gl_mu,
      ENodeSubTypeGeoLevel.gl_ci,
      ENodeSubTypeGeoLevel.gl_su,
      ENodeSubTypeGeoLevel.gl_ss,
    ],
  },
  gl_mu: {
    text: `Municipality`,
    plural: "Municipalities",
    lvlTypes: [
      EGeoLevelLevelType.district_municipality,
      EGeoLevelLevelType.local_municipality,
      EGeoLevelLevelType.metropolitan_municipality,
    ],
    lowerSubTypes: [ENodeSubTypeGeoLevel.gl_ci, ENodeSubTypeGeoLevel.gl_su, ENodeSubTypeGeoLevel.gl_ss],
  },
  gl_ci: {
    text: `City or Town`,
    plural: "Cities or Towns",
    lvlTypes: [EGeoLevelLevelType.city, EGeoLevelLevelType.town],
    lowerSubTypes: [ENodeSubTypeGeoLevel.gl_su, ENodeSubTypeGeoLevel.gl_ss],
  },
  gl_su: {
    text: `Suburb`,
    plural: "Suburbs",
    lvlTypes: [EGeoLevelLevelType.suburb],
    lowerSubTypes: [ENodeSubTypeGeoLevel.gl_ss],
  },
  gl_ss: {
    text: `Sub-Suburb`,
    plural: "Areas",
    lvlTypes: [EGeoLevelLevelType.sub_suburb],
    lowerSubTypes: [],
  },
};

const dataActionMeta: TObjectKeyEnumSet<EGeoLevelDataAction, IDataActionMeta> = {
  [EGeoLevelDataAction.NULL]: {
    text: "No Action",
    excludeSubTypes: [],
  },
  [EGeoLevelDataAction.EDIT_DATA]: {
    text: "Edit Data",
    excludeSubTypes: [],
  },
  [EGeoLevelDataAction.ABSORB_CHILDREN]: {
    text: "Absorb Children",
    excludeSubTypes: [...subTypesAboveCity],
  },
  [EGeoLevelDataAction.SET_GEO_TAG]: {
    text: `Set Geo Tag`,
    excludeSubTypes: [],
  },
  [EGeoLevelDataAction.REMOVE_GEO_TAG]: {
    text: `Remove Geo Tag`,
    excludeSubTypes: [],
  },
  [EGeoLevelDataAction.EDIT_SHAPE]: {
    text: `Edit Shape`,
    excludeSubTypes: [],
  },
  [EGeoLevelDataAction.RAISE_LEVEL_TYPE]: {
    text: `Raise Level Type`,
    excludeSubTypes: [...subTypesAboveCity, ENodeSubTypeGeoLevel.gl_ci],
  },
  [EGeoLevelDataAction.LOWER_LEVEL_TYPE]: {
    text: `Lower Level Type`,
    excludeSubTypes: [...subTypesAboveCity, ENodeSubTypeGeoLevel.gl_ss],
  },
  [EGeoLevelDataAction.DISABLE]: {
    text: `Disable`,
    excludeSubTypes: subTypesAboveCity,
  },
  [EGeoLevelDataAction.CHANGE_LVL_CLASSIFICATION]: {
    text: `Change Level Classification`,
    excludeSubTypes: subTypesAboveCity,
  },
  [EGeoLevelDataAction.REMOVE]: {
    text: `Remove`,
    excludeSubTypes: subTypesAboveCity,
  },
  [EGeoLevelDataAction.RENAME]: {
    text: `Rename`,
    excludeSubTypes: [ENodeSubTypeGeoLevel.gl_cn, ENodeSubTypeGeoLevel.gl_cc, ENodeSubTypeGeoLevel.gl_st],
  },
  [EGeoLevelDataAction.REDIRECT_TO_CUSTOM]: {
    text: `Redirect To Custom`,
    excludeSubTypes: subTypesAboveCity,
  },
  [EGeoLevelDataAction.REDIRECT_TO_PARENT]: {
    text: `Redirect To Parent`,
    excludeSubTypes: subTypesAboveCity,
  },
};

const geoLevelRadius = {
  city: {
    direct_lock: 5000,
    regional: 95000,
    area: 16000,
  },
  suburb: {
    direct_lock: 500,
    regional: 10000,
    area: 3500,
  },
};

const valuesForGeoLvlType: TObjectKeyEnumSet<
  EGeoLevelLevelType,
  {
    adminText: string;
    adjective: string;
    lvlValue: number;
    text: string;
    longText?: string;
    emoji?: string;
  }
> = {
  [EGeoLevelLevelType.sub_suburb]: {
    lvlValue: 110,
    text: "Suburb",
    adminText: "Sub-Suburb",
    adjective: "Suburb",
  },
  [EGeoLevelLevelType.suburb]: {
    lvlValue: 100,
    text: "Suburb",
    adminText: "Suburb",
    longText: "Suburb",
    adjective: "Suburb",
  },
  [EGeoLevelLevelType.city]: {
    lvlValue: 80,
    text: "City",
    adminText: "City",
    longText: "City",
    emoji: "🏙",
    adjective: "City",
  },
  [EGeoLevelLevelType.town]: {
    lvlValue: 80,
    text: "Town",
    adminText: "Town",
    longText: "Town",
    emoji: "⛪",
    adjective: "Town",
  },
  /*[EGeoLevelLevelType.city_or_town]: {
    lvlValue: 80,
    text: "Unclassified",
    longText: "Unclassified (City or Town)",
  },*/
  [EGeoLevelLevelType.metropolitan_municipality]: {
    lvlValue: 70,
    text: "Metropolitan",
    adminText: "Metropolitan",
    longText: "Metropolitan Municipality",
    adjective: "Metro",
  },
  [EGeoLevelLevelType.local_municipality]: {
    lvlValue: 60,
    text: "Local",
    adminText: "Local",
    longText: "Local Municipality",
    adjective: "Local Municipal",
  },
  [EGeoLevelLevelType.district_municipality]: {
    lvlValue: 50,
    text: "District",
    adminText: "District",
    longText: "District Municipality",
    adjective: "District Municipal",
  },
  [EGeoLevelLevelType.province]: {
    lvlValue: 30,
    text: "Province",
    adminText: "Province",
    longText: "Province",
    adjective: "Provincial",
  },
  [EGeoLevelLevelType.country]: {
    lvlValue: 20,
    text: "Country",
    adminText: "Country",
    longText: "Country",
    adjective: "National",
  },
  [EGeoLevelLevelType.continent]: {
    lvlValue: 10,
    text: "Continent",
    adminText: "Continent",
    longText: "Continent",
    adjective: "Continental",
  },
};

interface IContinentMeta {
  code: string;
  name: string;
  urlPart: string;
}

const continentMetaArray: IContinentMeta[] = [
  {
    code: "af",
    name: "Africa",
    urlPart: "africa",
  },
  {
    code: "na",
    name: "North America",
    urlPart: "north-america",
  },
  {
    code: "oc",
    name: "Oceania",
    urlPart: "oceania",
  },
  {
    code: "an",
    name: "Antarctica",
    urlPart: "antarctica",
  },
  {
    code: "as",
    name: "Asia",
    urlPart: "asia",
  },
  {
    code: "eu",
    name: "Europe",
    urlPart: "europe",
  },
  {
    code: "sa",
    name: "South America",
    urlPart: "south-america",
  },
];

const continentCodeToMeta = new Map<string, IContinentMeta>(continentMetaArray.map((meta) => [meta.code, meta]));
const continentUrlToMeta = new Map<string, IContinentMeta>(continentMetaArray.map((meta) => [meta.urlPart, meta]));

/*const continentCodeToName = {
  af: "Africa",
  na: "North America",
  oc: "Oceania",
  an: "Antarctica",
  as: "Asia",
  eu: "Europe",
  sa: "South America",
};

const continentCodeToUrl = {
  af: "africa",
  na: "north-america",
  oc: "oceania",
  an: "antarctica",
  as: "asia",
  eu: "europe",
  sa: "south-america",
};

const continentUrlToCode = {
  africa: "af",
  "north-america": "na",
  oceania: "oc",
  antarctica: "an",
  asia: "as",
  europe: "eu",
  "south-america": "sa",
};*/

export const GeoLevelStatic = {
  areaCircleSteps: 16,
  smallestArea: 100000,
  snapVerticesMaxDistance: 20,
  dataActionMeta,
  valuesForGeoLvlType,
  geoLevelRadius,
  geoNodeSubTypeMeta,
  GeoLevelTypeToGeoShortKey,
  continentMaps: {
    continentCodeToMeta,
    continentUrlToMeta,
    // continentCodeToName,
    // continentCodeToUrl,
    // continentUrlToCode,
  },
};
