import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { LegendItem } from "../components/Covid19Components";
import { colorsCovidPage } from "../Covid19Static";
import { MyResponsiveLine } from "../NivoChartTest";
import React from "react";
import { NumberUtils } from "@gt/common-utils/build/datatypes/NumberUtils";

export const GraphRateOfCasesToTests = ({ testItems }) => {
  const percentages: { x: string; y: number }[] = testItems.map((item) => ({
    x: item.date.slice(5),
    y: NumberUtils.absoluteOrToDecimal((item.casesNew / item.testsNew) * 100),
  }));

  const { min, max } = percentages.reduce(
    (cur, item) => {
      if (cur.min > item.y) {
        cur.min = item.y;
      }

      if (cur.max < item.y) {
        cur.max = item.y;
      }

      return cur;
    },
    { min: 101, max: -1 },
  );

  return (
    <>
      <FelaFlexbox align={"center"} padding={0.5}>
        <LegendItem name={"Cases as Percent of Tests Administered"} color={colorsCovidPage.newCases} />
      </FelaFlexbox>
      <FelaFlexbox style={{ height: "20vh", maxHeight: "15em" }}>
        <MyResponsiveLine
          axisLeftExtras={{
            tickValues: 3,
          }}
          yScaleExtras={{
            min: Math.max(Math.floor(min), 0),
            max: Math.min(Math.ceil(max), 100),
          }}
          data={[
            {
              id: "Percent:",
              color: colorsCovidPage.newCases,
              // data: testItems.map((item) => ({ x: item.date.slice(5), y: (item.casesTotal / item.testsTotal) * 100 })),
              data: percentages,
            },
          ]}
        />
      </FelaFlexbox>
    </>
  );
};
