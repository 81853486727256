import React from "react";
import { RouteProps, useParams } from "react-router";
import { LocalityIndexPageStatic, PageLocalityIndexAsyncActions } from "../../state/navigation/_navLocalityIndex";
import { PageSectionHeader } from "../../components/content/PageSectionHeader";
import { FelaFlexLayer, FelaGridLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import {
  EAppColor,
  EForeContrast,
  ESurfaceHeight,
  ETextSize,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { EPageSizeRange } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { IFelaTheme } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { useFela } from "react-fela";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { ETextTag, FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { AsyncNavLink } from "../../state/ASN_VibescoutSpecial";
import { GeoLevelCompressUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCompressUtils";
import { GeoLevelStatic } from "@vs/core/build/data/crossover/geoLevels/GeoLevelStatic";
import _ from "lodash";
import { EGeoLevelLevelType } from "@vs/core/build/data/crossover/geoLevels/GeoLevelTypes";
import { HeaderText, SubHeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import { GeoLevelCommonUtils } from "@vs/core/build/utils/geoLevels/GeoLevelUtils/GeoLevelCommonUtils";
import { FelaGrid } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaGrid";
import { TGeoLevelBasics } from "@vs/core/build/data/models/geolevels/GeoLevelModelTypes";
import { GiBinoculars } from "react-icons/gi";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { ShowMoreButton } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicButtonComponents";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { useShowMore } from "@gt/gt-frontend/build/react/hooks/useShowMore";
import { StringUtils } from "@gt/common-utils/build/datatypes/StringUtils";
import { PageErrorSection } from "../common/PageErrorSection";

export const LocalityIndexPage = React.memo<RouteProps>(() => {
  const params = useParams<any>();
  const { theme } = useFela<IFelaTheme>();

  const indexPageDataAction = PageLocalityIndexAsyncActions.getPageData.use(
    LocalityIndexPageStatic.getPageDataDefaultArguments(params as any),
  );

  const { buttonRef, blockRef, onClickButton, applicable, closed } = useShowMore({ blockMaxHeight: 260 });

  return (
    <>
      {indexPageDataAction.isFailure && <PageErrorSection />}
      {indexPageDataAction.renderPayload(({ geoLevel, lowerIndexes, topCities: tc }) => {
        const topCities = tc.filter((gl) => gl.id !== geoLevel.id);

        const lowerSubTypeMeta =
          GeoLevelStatic.geoNodeSubTypeMeta[GeoLevelStatic.geoNodeSubTypeMeta[geoLevel.nSubType].lowerSubTypes[0] ?? ""]
            ?.plural;

        const groupedLowerLevels = _.groupBy(lowerIndexes, "lvlType");

        // console.log(groupedLowerLevels);

        const groupedKeys: EGeoLevelLevelType[] = Object.keys(groupedLowerLevels) as EGeoLevelLevelType[];

        const breadcrumbs = GeoLevelCommonUtils.createBreadcrumbInfo(geoLevel);

        return (
          <FelaGrid
            grow={1}
            height={ESurfaceHeight.shadow}
            padding={theme.isSmall ? 0 : 1}
            alignItems={"start"}
            gap={1}
            gridTemplateColumns={theme.pageSizeValue < EPageSizeRange.desktop ? "1fr" : "1fr 1fr"}
          >
            <FelaFlexbox alignSelf={"start"} overflow={"hidden"} direction={"column"}>
              <PageSectionHeader
                text={`${geoLevel.name} Locality Info`}
                isSmall={theme.isSmall}
                padding={"1em 0.5em"}
              />
              <FelaFlexLayer radius={theme.isSmall ? 0 : 0.5} overflow={"hidden"} direction={"column"} grow={1}>
                {breadcrumbs.map((breadcrumb) => {
                  return (
                    <AsyncNavLink key={breadcrumb.id} to={`/locality-index/${breadcrumb.allLvlParts.join("/")}`}>
                      <FelaFlexLayer
                        justify={theme.isSmall ? "space-between" : undefined}
                        align={"center"}
                        padding={1}
                        hoverable
                        clickable
                      >
                        <SubHeaderText margin={"0 1em 0 0"}>
                          {GeoLevelStatic.valuesForGeoLvlType[breadcrumb.lvlType].longText}
                        </SubHeaderText>
                        <HeaderText align={"right"}>{breadcrumb.name}</HeaderText>
                      </FelaFlexLayer>
                    </AsyncNavLink>
                  );
                })}
              </FelaFlexLayer>
              {geoLevel.lvlValue >= GeoLevelStatic.valuesForGeoLvlType["city"].lvlValue && (
                <AsyncNavLink to={`/${GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: geoLevel.geoLvlShort })}`}>
                  <FelaFlexLayer
                    zIndex={1}
                    shadown
                    extraSoftShadow
                    align={"center"}
                    alignSelf={"stretch"}
                    hoverable
                    clickable
                    height={ESurfaceHeight.high}
                    padding={"0.5em"}
                  >
                    <FelaIconWrap
                      noBackground
                      noPadding
                      margin={"0 0.35em 0 0"}
                      neutralColor={EAppColor.primary}
                    >
                      <GiBinoculars />
                    </FelaIconWrap>
                    <SubHeaderText neutralColor={EAppColor.primary} spacing={0.1} margin={"0 0.5em 0 0"}>
                      Scout
                    </SubHeaderText>
                    <SubHeaderText>{geoLevel.name}</SubHeaderText>
                  </FelaFlexLayer>
                </AsyncNavLink>
              )}
            </FelaFlexbox>
            {topCities.length > 0 && (
              <FelaFlexbox alignSelf={"start"} grow={1} overflow={"hidden"} direction={"column"}>
                <PageSectionHeader
                  seed={1}
                  text={`Top Places in ${geoLevel.name}`}
                  isSmall={theme.isSmall}
                  padding={"1em 0.5em"}
                />
                <FelaFlexLayer
                  radius={theme.isSmall ? 0 : 0.5}
                  style={{ maxHeight: closed ? 210 : undefined }}
                  overflow={"hidden"}
                  direction={"column"}
                  position={"relative"}
                >
                  <FelaGrid
                    rootProps={{ ref: blockRef }}
                    gridTemplateColumns={theme.isSmall ? "1fr" : `repeat(auto-fill, minmax(15em, 1fr))`}
                  >
                    {topCities.map((city) => (
                      <AsyncNavLink
                        key={city.id}
                        to={`/${GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: city.geoLvlShort })}`}
                      >
                        <FelaFlexLayer hoverable clickable padding={theme.isSmall ? "0.5em 0.6em" : "0.45em 0.55em"}>
                          <FelaText tag={ETextTag.h3}>{city.name}</FelaText>
                        </FelaFlexLayer>
                      </AsyncNavLink>
                    ))}
                    <FelaFlexbox style={{ height: applicable && !closed ? "4em" : undefined }} gridColumn={"1 / -1"} />
                  </FelaGrid>
                  <FullAbsFlexbox pointerEvents={false} direction={"column"} justify={"flex-end"}>
                    {applicable && <ShowMoreButton onClick={onClickButton} buttonRef={buttonRef} isOpen={!closed} />}
                  </FullAbsFlexbox>
                </FelaFlexLayer>
              </FelaFlexbox>
            )}
            {lowerIndexes.length > 0 && (
              <FelaFlexbox
                gridColumn={"1 / -1"}
                overflow={"hidden"}
                radius={theme.isSmall ? 0 : 0.5}
                direction={"column"}
              >
                <PageSectionHeader
                  padding={"0.5em 0.5em 1em 0.5em"}
                  isSmall={theme.isSmall}
                  seed={0}
                  text={`${lowerSubTypeMeta ?? "areas"} in ${geoLevel.name}`}
                />
                {groupedKeys.length > 1 ? (
                  groupedKeys.map((key) => {
                    return (
                      <React.Fragment key={key}>
                        <FelaFlexLayer height={ESurfaceHeight.high} shadow zIndex={1} padding={1}>
                          <HeaderText>{GeoLevelStatic.valuesForGeoLvlType[key].text}</HeaderText>
                        </FelaFlexLayer>
                        <FelaGridLayer
                          gridTemplateColumns={theme.isSmall ? "1fr" : `repeat(auto-fill, minmax(17em, 1fr))`}
                        >
                          {groupedLowerLevels[key]
                            .sort((a, b) => StringUtils.sortCompareStrings(a.name, b.name))
                            .map((gl) => {
                              return <DisplayGeoIndexItem isSmall={theme.isSmall} key={gl.id} geoLevel={gl} />;
                            })}
                        </FelaGridLayer>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <FelaGridLayer gridTemplateColumns={theme.isSmall ? "1fr" : `repeat(auto-fill, minmax(17em, 1fr))`}>
                    {(groupedLowerLevels?.[groupedKeys[0]] ?? ([] as any[]))
                      .sort((a, b) => StringUtils.sortCompareStrings(a.name, b.name))
                      .map((gl) => {
                        return <DisplayGeoIndexItem isSmall={theme.isSmall} key={gl.id} geoLevel={gl} />;
                      })}
                  </FelaGridLayer>
                )}
              </FelaFlexbox>
            )}
          </FelaGrid>
        );
      })}
    </>
  );
});

interface ICPDisplayGeoIndexItem {
  geoLevel: TGeoLevelBasics;
  isSmall: boolean;
}

const DisplayGeoIndexItem = React.memo<ICPDisplayGeoIndexItem>(({ geoLevel: gl, isSmall }) => {
  const hasPage = gl.lvlValue >= GeoLevelStatic.valuesForGeoLvlType["city"].lvlValue;

  const blockPadding = isSmall ? "0.5em 0.6em" : "0.35em 0.5em";
  const isScoutable = gl.lvlValue >= GeoLevelStatic.valuesForGeoLvlType["city"].lvlValue;

  return (
    <FelaFlexLayer
      cssKey={`geo_index_item_outer_${hasPage}`}
      align={"stretch"}
      justify={"stretch"}
      borderWidth={hasPage ? "1px 3px 3px 1px" : "none"}
      borderHeight={ESurfaceHeight.deep}
    >
      {gl.lvlValue <= GeoLevelStatic.valuesForGeoLvlType["town"].lvlValue ? (
        <>
          {isScoutable && (
            <AsyncNavLink to={`/${GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: gl.geoLvlShort })}`}>
              <FelaFlexLayer
                cssKey={`geo_index_item_inner_1_${isSmall}`}
                grow={1}
                align={"center"}
                // grow={1}
                alignSelf={"stretch"}
                hoverable
                clickable
                // pop
                // popAmount={0.1}
                height={ESurfaceHeight.high}
                // radius={0.2}
                padding={blockPadding}
              >
                <FelaIconWrap
                  cssKey={"geo_index_item_binocs"}
                  noBackground
                  noPadding
                  margin={"0 0.35em 0 0"}
                  neutralColor={EAppColor.primary}
                >
                  <GiBinoculars />
                </FelaIconWrap>
                <FelaFlexbox direction={"column"}>
                  <SubHeaderText neutralColor={EAppColor.primary} size={ETextSize.tiny} spacing={0.1}>
                    Scout
                  </SubHeaderText>
                  <SubHeaderText contrast={EForeContrast.extra} tag={ETextTag.h3} size={ETextSize.content}>
                    {gl.name}
                  </SubHeaderText>
                </FelaFlexbox>
              </FelaFlexLayer>
            </AsyncNavLink>
          )}
          <AsyncNavLink to={`/locality-index/${[gl.cc, gl.st, gl.mu, gl.ci].filter((part) => part != null).join("/")}`}>
            <FelaFlexLayer
              cssKey={`geo_index_item_inner_2_${isScoutable}_${isSmall}`}
              grow={isScoutable ? 0 : 1}
              align={"center"}
              alignSelf={"stretch"}
              hoverable
              clickable
              padding={blockPadding}
            >
              <FelaText tag={ETextTag.h3}>{!isScoutable ? gl.name : "Index Page"}</FelaText>
            </FelaFlexLayer>
          </AsyncNavLink>
        </>
      ) : (
        <AsyncNavLink to={`/${GeoLevelCompressUtils.convertCompressedToUrl({ geoLvl: gl.geoLvlShort })}`}>
          <FelaFlexLayer
            cssKey={"geo_index_item_inner_3"}
            alignSelf={"stretch"}
            align={"center"}
            grow={1}
            hoverable
            clickable
            padding={blockPadding}
          >
            <FelaText tag={ETextTag.h3}>{gl.name}</FelaText>
          </FelaFlexLayer>
        </AsyncNavLink>
      )}
    </FelaFlexLayer>
  );
});
