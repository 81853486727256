import React from "react";
import { EFelaLayerHeight, FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { PSC_VibescoutSpecial } from "../../../state/PSC_VibescoutSpecial";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { TObjectKeyEnumSet } from "@gt/common-utils/build/typescript/SpecialTypes";
import { ECovidPageContextButton } from "../../../state/stores/CovidStore/CovidStore";
import { FaMapMarkedAlt, FaRegCommentDots } from "react-icons/fa";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { FullAbsFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { Link } from "react-router-dom";
import { ETextSize } from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";

const bottomBarTextColorLight = "#ffffde";
const bottomBarTextColorDark = "#082036";

const buttonTypeInformation: TObjectKeyEnumSet<
  ECovidPageContextButton,
  {
    label: (color: string) => any;
    url: string;
    icon: React.ReactElement;
  }
> = {
  [ECovidPageContextButton.COUNTRY_STATS]: {
    url: "/reports/coronavirus",
    label: (color) => (
      <>
        <FelaText colorHex={color} weight={500} margin={"0.5em 0 0 0"} size={ETextSize.tiny} transform={"uppercase"}>
          Country
        </FelaText>
        <FelaText colorHex={color} weight={500} size={ETextSize.tiny} transform={"uppercase"}>
          Stats
        </FelaText>
      </>
    ),
    icon: (
      <>
        <FullAbsFlexbox>
          <img
            src={"/_static/vs-special/images/sa_flag_square.png"}
            style={{ objectFit: "cover", width: "100%", objectPosition: "top", opacity: 0.8 }}
          />
        </FullAbsFlexbox>
      </>
    ),
  },
  [ECovidPageContextButton.GIVE_FEEDBACK]: {
    label: (color) => (
      <>
        <FelaText colorHex={color} weight={500} margin={"0.5em 0 0 0"} size={ETextSize.tiny} transform={"uppercase"}>
          Give
        </FelaText>
        <FelaText colorHex={color} weight={500} size={ETextSize.tiny} transform={"uppercase"}>
          Feedback
        </FelaText>
      </>
    ),
    url: "#feedback",
    icon: <FaRegCommentDots />,
  },
  [ECovidPageContextButton.GO_TO_PROVINCE]: {
    label: (color) => (
      <>
        <FelaText colorHex={color} weight={500} margin={"0.5em 0 0 0"} size={ETextSize.tiny} transform={"uppercase"}>
          Go To
        </FelaText>
        <FelaText colorHex={color} weight={500} size={ETextSize.tiny} transform={"uppercase"}>
          Province
        </FelaText>
      </>
    ),
    url: "#provinces",
    icon: <FaMapMarkedAlt />,
  },
};

interface ICPBottomBar {
  buttons: ECovidPageContextButton[];
  disableButtons: ECovidPageContextButton[];
}

export const CovidReportBottomBar = React.memo<ICPBottomBar>(({ buttons, disableButtons }) => {
  const { UIStore } = PSC_VibescoutSpecial.useStores();

  // const bottomButtons = CovidStore.useState((s) => s.bottomBar);
  const isSmall = UIStore.useState((s) => s.isSmall);
  const theme = UIStore.useState((s) => s.theme);

  if (!isSmall) {
    return <></>;
  }

  const color = theme.dark ? bottomBarTextColorDark : bottomBarTextColorLight;

  return (
    <FelaFlexLayer
      backgroundColor={theme.dark ? theme.styles.Colors.lightBack.lig : theme.styles.Colors.darkBack.lig}
      height={EFelaLayerHeight.HIGH}
      style={{ borderTop: `2px solid rgba(105, 105, 105, 0.5)` }}
      shadow
      extraSoftShadow
      zIndex={10}
      position={"sticky"}
      bottom={0}
      align={"stretch"}
    >
      {buttons.map((type) => {
        const disabled = disableButtons.includes(type);

        const inner = (
          <FelaFlexbox
            // padding={isSmall ? "0.3em 0.55em" : "0.5em 0.65em"}
            padding={0.2}
            radius={0.1}
            align={"center"}
            direction={"row"}
            style={{
              pointerEvents: disabled ? "none" : "all",
              opacity: disabled ? 0.5 : 1,
              transition: "opacity 0.15s ease-in-out",
              borderRight: "2px solid rgba(12, 20, 35, 0.15)",
            }}
          >
            <FelaIconWrap
              // size={ETextSize.header}
              sizeNum={1.7}
              style={{ position: "relative", overflow: "hidden", flexShrink: 0 }}
              noBackground
              colorString={color}
              isButton
              margin={0.15}
            >
              {buttonTypeInformation[type].icon}
            </FelaIconWrap>
            <FelaFlexbox direction={"column"} align={"center"} padding={0.15}>
              {buttonTypeInformation[type].label(color)}
            </FelaFlexbox>
          </FelaFlexbox>
        );

        if (buttonTypeInformation[type].url.includes("#")) {
          return <a href={buttonTypeInformation[type].url}>{inner}</a>;
        }

        return <Link to={buttonTypeInformation[type].url}>{inner}</Link>;
      })}
    </FelaFlexLayer>
  );
});
