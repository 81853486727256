import { createAsyncAction, successResult } from "pullstate";

async function loadImageFully(src: string) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = src;
  });
}

export const AsyncActionImageLoad = createAsyncAction<{ src: string }>(async ({ src }) => {
  await loadImageFully(src);
  return successResult();
});
