export const colorsCovidPage = {
  totalCases: "hsl(192,91%,59%)",
  totalTests: "hsl(78,84%,45%)",
  activeCases: "hsl(47,85%,49%)",
  newTests: "hsl(47,85%,49%)",
  newCases: "hsl(22,100%,57%)",
  newDeaths: "hsl(251,97%,69%)",
  totalDeaths: "hsl(290,100%,49%)",
  totalRecoveries: "hsl(149,91%,54%)",
  female: "hsl(341,94%,53%)",
  male: "hsl(213,92%,54%)",
  neutralGrey: "hsl(197,14%,65%)",
  positive: "hsl(149,91%,59%)",
  negative: "rgb(244, 117, 96)",
};

export const colorsCovidSeries = {
  1: "hsl(186,67%,51%)",
  2: "hsl(211,83%,55%)",
  3: "hsl(263,96%,68%)",
  4: "hsl(301,90%,55%)",
  5: "hsl(332,100%,56%)",
  6: "hsl(12,81%,53%)",
  7: "hsl(35,93%,53%)",
  8: "hsl(62,72%,46%)",
  9: "hsl(118,73%,54%)",
};
