import {
  EAppColor,
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  EPaletteColor,
  ESurfaceHeight,
  IFrontendStyleConfigUnzipped,
} from "../config/VibescoutFrontendStyleConfig";
import { CSSProperties } from "react";
import { Property } from "csstype";
import { TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { EPageSizeRange } from "../../../utils/DetectDeviceUtils";

export enum EGraphicContrast {
  HIGH = "HIGH",
  MID = "MID",
  LOW = "LOW",
}

export const contrastToFore: { [key in EGraphicContrast | EForeContrast]: EForeContrast } = {
  [EGraphicContrast.HIGH]: EForeContrast.strong,
  [EGraphicContrast.MID]: EForeContrast.regular,
  [EGraphicContrast.LOW]: EForeContrast.subtle,
  [EForeContrast.strong]: EForeContrast.strong,
  [EForeContrast.regular]: EForeContrast.regular,
  [EForeContrast.subtle]: EForeContrast.subtle,
  [EForeContrast.extra]: EForeContrast.extra,
};

export interface IGradientStop {
  palette?: EPaletteColor;
  height?: ESurfaceHeight;
  neutral?: EAppColor;
  colorString?: string;
  color?: TColorRgbArray;
  opacity?: number;
  def?: string;
  darken?: number;
  lighten?: number;
}

export enum EGradientType {
  none = "none",
  flat = "flat",
  radial = "radial",
  linear = "linear",
  repeatingRadial = "repeating-radial",
  repeatingLinear = "repeating-linear",
}

export interface IGradient {
  def?: string;
  type?: EGradientType;
  stops: IGradientStop[];
  // colors?: TColorRgbArray[];
}

export interface IFelaComponentBaseProps<
  T extends HTMLElement = HTMLDivElement,
  A extends React.HTMLAttributes<T> = React.HTMLAttributes<T>,
> {
  cssKey?: string;
  ref?: any;
  cssExtra?: any[];
  className?: string;
  title?: string;
  position?: Property.Position;
  id?: string;
  style?: CSSProperties;
  onClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  onMouseDown?: any;
  onMouseUp?: any;
  rootProps?: React.DetailedHTMLProps<A, T>;

  [props: string]: any;
}

export interface IFelaTheme {
  currentId: EFrontendStyleConfigBaseTheme | string;
  dark: boolean;
  isSmall: boolean;
  pageSizeValue: EPageSizeRange;
  styles: IFrontendStyleConfigUnzipped;
  // current: IFrontendStyleConfigThemeUnzipped;
}
