import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { GraphSection, LegendItem } from "../components/Covid19Components";
import { colorsCovidPage } from "../Covid19Static";
import { MyResponsiveLine } from "../NivoChartTest";
import React from "react";
import { MyResponsiveBar } from "../NivoBar";
import { ParentSize } from "@visx/responsive";
import { VisxLineGraph } from "../graphsAndCharts/VisxLineGraph";

export const GraphNewCases = ({ caseItems }) => {
  return (
    <>
      <FelaFlexbox align={"center"} padding={0.5}>
        <LegendItem name={"New Cases Per Day"} color={colorsCovidPage.newCases} />
      </FelaFlexbox>
      <FelaFlexbox style={{ width: "100%", height: "20vh", minHeight: "18em", maxHeight: "20em" }}>
        {/*<ParentSize>{({ height, width }) => <VisxLineGraph height={height} width={width} />}</ParentSize>*/}
        <MyResponsiveLine
          yScaleExtras={{ min: 0 }}
          // keys={["Cases"]}
          data={[
            {
              id: "New Cases:",
              color: "hsl(22,100%,55%)",
              data: caseItems.map((item) => ({
                x: item.date.slice(5),
                y: item.casesNew,
              })),
            },
          ]}
        />
      </FelaFlexbox>
    </>
  );
};

/*<MyResponsiveLine
          yScaleExtras={{ min: 0 }}
          // keys={["Cases"]}
          data={[
            {
              id: "New Cases:",
              color: "hsl(22,100%,55%)",
              data: caseItems.map((item) => ({
  x: item.date.slice(5),
    y: item.casesNew,
})),
},
]}
/>*/
