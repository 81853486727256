import React from "react";
import { EFelaLayerHeight, FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import { HeaderText } from "@gt/gt-frontend/build/react/vibescout-ui/common/BasicTextComponents";
import { ETextSize } from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";

interface INewsStore {
  titleImage: string;
  title: string;
  subTitle?: string;
  url: string;
}

interface ICPNewStoryBlock {
  doFollow?: boolean;
  story: INewsStore;
  openBlank?: boolean;
}

export const NewsStoryBlock: React.FC<ICPNewStoryBlock> = ({
  story: { title, titleImage, url, subTitle },
  doFollow = false,
  openBlank = false,
}) => {
  return (
    <FelaFlexLayer height={EFelaLayerHeight.LOW} direction={"column"} position={"relative"}>
      <a href={url} target={openBlank ? "_blank" : undefined} rel={doFollow ? "dofollow" : "nofollow"}>
        <FelaFlexLayer
          shadow
          style={{ width: "100%", height: "20vh", maxHeight: "8em", borderRadius: "0.25em", overflow: "hidden" }}
        >
          <img style={{ objectFit: "cover", width: "100%", objectPosition: "top left" }} src={titleImage} />
        </FelaFlexLayer>
      </a>
      <FelaFlexLayer direction={"column"} height={EFelaLayerHeight.HIGH} padding={"0.75em 0 0.5em 0"}>
        <a href={url} target={openBlank ? "_blank" : undefined} rel={doFollow ? "dofollow" : "nofollow"}>
          <HeaderText>{title}</HeaderText>
        </a>
        {subTitle && (
          <FelaText size={ETextSize.small} margin={"0.5em 0"}>
            {subTitle}
          </FelaText>
        )}
      </FelaFlexLayer>
    </FelaFlexLayer>
  );
};
