import { IADBDocumentExtras } from "@gt/services/build/arangodb/ArangodbTypes";

export type TLockdownLevelValueActualLevels =
  | EGSLockdownLevelValue.LVL_0
  | EGSLockdownLevelValue.LVL_1
  | EGSLockdownLevelValue.LVL_2
  | EGSLockdownLevelValue.LVL_3_ADV
  | EGSLockdownLevelValue.LVL_3
  | EGSLockdownLevelValue.LVL_4
  | EGSLockdownLevelValue.LVL_5;

export enum EGSLockdownLevelValue {
  LVL_0 = "LVL_0",
  LVL_1 = "LVL_1",
  LVL_2 = "LVL_2",
  LVL_3 = "LVL_3",
  LVL_4 = "LVL_4",
  LVL_5 = "LVL_5",
  IGNORE = "IGNORE",
  LVL_1_HOT = "LVL_1_HOT",
  LVL_2_HOT = "LVL_2_HOT",
  LVL_3_HOT = "LVL_3_HOT",
  LVL_4_HOT = "LVL_4_HOT",
  LVL_5_HOT = "LVL_5_HOT",
  LVL_3_ADV = "LVL_3_ADV",
}

export enum EGSLockdownLevelGeoSheetTitle {
  geolevels = "geolevels",
  input = "input",
  lvl_type_ref = "lvl_type_ref",
}

export interface IGSLockdownLevelGeoSheet_GeoLevels {
  id: string;
  type: string;
  province: string;
  district_or_metro: string;
  local: string;
}

export interface IGSLockdownLevelGeoSheet_Input extends IGSLockdownLevelGeoSheet_GeoLevels {
  date: string;
  lvl: EGSLockdownLevelValue;
}

export interface IDMLockdownLevelsGeo extends IGSLockdownLevelGeoSheet_Input, IADBDocumentExtras {}
