import React from "react";
import { IMovieCreditCastObject } from "@gt/services/build/tmdb/TmdbTypes";
import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { FelaText } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaText";
import {
  EForeContrast,
  EFrontendStyleConfigBaseTheme,
  ESurfaceHeight,
  ETextSize,
  ETextWeightType,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { SimpleImage } from "@gt/gt-frontend/build/react/vibescout-ui/media/SimpleImage";
import { notNullEmpty } from "@gt/common-utils/build/datatypes/StringUtils";
import { FelaFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaLayers";
import { FullAbsFlexbox, FullAbsFlexLayer } from "@gt/gt-frontend/build/react/vibescout-ui/common/FullAbsDiv";
import { FelaGrid } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaGrid";
import { FelaIconWrap } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaIconWrap";
import { GiRoundStar } from "react-icons/gi";

export interface ICPMovieActorsBlock {
  cast: IMovieCreditCastObject[];
  isSmall: boolean;
}

export const MovieActorsBlock: React.FC<ICPMovieActorsBlock> = ({ cast, isSmall }) => {
  console.log("Rendering actors block", cast);

  return (
    <FelaGrid gridTemplateColumns={"repeat(4, minmax(2em, 6em))"} gap={1}>
      {cast.map((actor) => {
        return (
          <FelaFlexbox key={`${actor.id}`} direction={"column"} gap={0.5}>
            <FelaFlexLayer
              height={ESurfaceHeight.shadow}
              radius={"0.5"}
              position={"relative"}
              overflow={"hidden"}
              align={"center"}
              justify={"center"}
            >
              {notNullEmpty(actor.profile_path) ? (
                <SimpleImage
                  imgStyle={{
                    filter: "saturate(120%)",
                  }}
                  imageFit={"cover"}
                  objectPosition={"center"}
                  aspect={0.66}
                  src={`https://image.tmdb.org/t/p/w185${actor.profile_path}`}
                />
              ) : (
                <FelaFlexbox aspect={0.66} position={"relative"}>
                  <FullAbsFlexbox align={"center"} justify={"center"}>
                    <FelaIconWrap sizeNum={4} noBackground noPadding>
                      <GiRoundStar />
                    </FelaIconWrap>
                  </FullAbsFlexbox>
                </FelaFlexbox>
              )}
              <FullAbsFlexLayer
                backgroundOpacity={0}
                height={ESurfaceHeight.shadow}
                pop
                unpop
                popAmount={0.2}
                popBlur={0.5}
                popOpacity={0.3}
              />
            </FelaFlexLayer>
            <FelaFlexbox wrap={"wrap"} justify={"center"}>
              <FelaText
                align={"center"}
                weight={ETextWeightType.light}
                size={ETextSize.small}
                forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                contrast={EForeContrast.strong}
              >
                <b>{actor.name}</b>{" "}
              </FelaText>
              {notNullEmpty(actor.character) && (
                <FelaText
                  align={"center"}
                  contrast={EForeContrast.extra}
                  forceTheme={EFrontendStyleConfigBaseTheme.DARK}
                  size={ETextSize.tiny}
                >
                  as <i>{actor.character}</i>
                </FelaText>
              )}
            </FelaFlexbox>
          </FelaFlexbox>
        );
      })}
    </FelaGrid>
  );
};
