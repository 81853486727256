import { notNullEmpty } from "../datatypes/StringUtils";

function isNode() {
  return typeof process !== "undefined" && notNullEmpty(process?.versions?.node);
}

function ensureNodeOnly() {
  if (typeof document !== "undefined") {
    throw new Error("Incorrect code execution environment");
  }
}

function isBrowser() {
  return typeof document !== "undefined" && !isNode();
}

function ensureProcessEnvExists() {
  if (typeof process === "undefined") {
    globalThis["process"] = {} as any;
  }

  if (typeof process.env === "undefined") {
    process.env = {} as any;
  }
}

export const ExecutionEnvironmentUtils = {
  isNode,
  isBrowser,
  ensureNodeOnly,
  ensureProcessEnvExists,
};
