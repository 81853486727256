import { IFelaComponentBaseProps } from "./FelaCommon";
import { default as React, FunctionComponent } from "react";
import { ColorUtils, TColorRgbArray } from "@gt/common-utils/build/general/ColorUtils";
import { Property } from "csstype";
import { TFelaRule } from "./types";
import { EAppColor, ETextTypographyType } from "../config/VibescoutFrontendStyleConfig";
import { useFelaCache } from "../../fela/FelaCache";

export enum EForceTheme {
  DARK = "DARK",
  LIGHT = "LIGHT",
  NO_FORCE = "NO_FORCE",
}

export enum EButtonSize {
  mainPageAction = "mainPageAction",
  regular = "regular",
  smallOptional = "smallOptional",
  tiny = "tiny",
}

export interface IFelaRuleButtonProps {
  sizeNum?: number;
  size?: EButtonSize;
  iconLeft?: boolean;
  color?: TColorRgbArray;
  neutralColor?: EAppColor;
  disabled?: boolean;
  colorless?: boolean;
  isPressed?: boolean;
  isSwitch?: boolean;
  popAmount?: number;
  buttonShadow?: boolean;
  borderRadius?: Property.BorderRadius<any>;
  margin?: Property.Margin<any>;
  forceTheme?: EForceTheme;
  svgFill?: boolean;
}

const FelaRuleButtonWrap: TFelaRule<IFelaRuleButtonProps> = ({
  theme,
  sizeNum,
  size,
  iconLeft = true,
  color = theme.styles.Colors.neutral.primary,
  colorless = false,
  neutralColor,
  disabled = false,
  isPressed = false,
  isSwitch = false,
  popAmount,
  forceTheme = EForceTheme.NO_FORCE,
  borderRadius: bordRad,
  margin,
  buttonShadow = true,
  svgFill = false,
}) => {
  if (size) {
    sizeNum = theme.styles.Button.sizes[size];
  } else {
    sizeNum = sizeNum ?? theme.styles.Button.sizes[EButtonSize.regular];
  }

  if (neutralColor) {
    color = theme.styles.Themes[theme.currentId].Colors.neutral[neutralColor];
  } else if (colorless) {
    color = theme.styles.Themes[theme.currentId].Colors.surface.low;
  }

  const sizeByFourteen = (sizeNum / 14).toFixed(1);
  const sizeByTwelve = (sizeNum / 12).toFixed(1);
  const sizeByTen = (sizeNum / 10).toFixed(1);
  const sizeByEight = (sizeNum / 8).toFixed(1);
  const sizeBySix = (sizeNum / 6).toFixed(1);
  const sizeByFour = (sizeNum / 4).toFixed(1);
  const sizeByThree = (sizeNum / 3).toFixed(1);
  const sizeByTwo = (sizeNum / 2).toFixed(1);
  const fontSizeNum = sizeNum / 2.4;
  const fontSize = fontSizeNum.toFixed(1);

  const buttonPopAmount = popAmount ?? sizeByTwelve;
  const shadowY = isPressed ? `-${buttonPopAmount}em` : `${buttonPopAmount}em`;

  // console.log(theme.dark);

  // const isDark = forceTheme === EForceTheme.DARK || (theme.dark && forceTheme !== EForceTheme.LIGHT);
  const isDark =
    forceTheme === EForceTheme.DARK ||
    (theme.styles.Themes[theme.currentId].isDark && forceTheme !== EForceTheme.LIGHT);

  // console.log(isDark);

  /*let boxShadow = disabled
    ? `0 ${shadowY} 0 0 rgb(120, 130, 140)`
    : `0 ${shadowY} 0 0 rgba(${
        isPressed
          ? isDark
            ? theme.styles.Colors.darkBack.shadow[0]
            : ColorUtils.darkenRgbArray(theme.styles.Colors.lightBack.shadow, 10)[0]
          : isSwitch
          ? ColorUtils.lightenRgbArray(ColorUtils.desaturateRgbArray(color, 35), 10)[0]
          : ColorUtils.darkenRgbArray(color, 35)[0]
      }, ${isSwitch && !isPressed ? `0.9` : `1`})`;*/
  let boxShadow = disabled
    ? `0 ${shadowY} 0 0 rgb(120, 130, 140)`
    : `0 ${shadowY} 0 0 ${
        isPressed
          ? `rgba(${theme.styles.Themes[theme.currentId].shadow.base[0]}, ${
              theme.styles.Themes[theme.currentId].shadow.intensity * 2.5
            })`
          : `rgba(${
              isSwitch
                ? ColorUtils.lightenRgbArray(ColorUtils.desaturateRgbArray(color, 35), 10)[0]
                : ColorUtils.darkenRgbArray(color, 35)[0]
            }, ${isSwitch && !isPressed ? `0.9` : /*theme.styles.Themes[theme.currentId].shadow.intensity * 6*/ "0.9"})`
      }`;

  if (!isPressed) {
    /*if (!isSwitch) {
      boxShadow = buttonShadow
        ? `${boxShadow}, 0 ${shadowY} ${sizeByEight}em 1px ${
            isDark ? theme.styles.RgbColors.darkBack.shadow : theme.styles.RgbaColors.shadowLight
          }`
        : `none`;
    }*/
    if (!isSwitch) {
      boxShadow = buttonShadow
        ? `${boxShadow}, 0 ${shadowY} ${sizeByEight}em 1px rgba(${
            theme.styles.Themes[theme.currentId].shadow.base[0]
          }, ${theme.styles.Themes[theme.currentId].shadow.intensity * 2})`
        : `none`;
    }
  } else {
    boxShadow = `${boxShadow}, inset 0 ${sizeByTwelve}em ${sizeBySix}em ${sizeByEight}em rgba(${
      ColorUtils.darkenRgbArray(color, 33)[0]
    }, 0.75)`;
  }

  /*`linear-gradient(36deg, rgb(${ColorUtils.lightenRgbArray(color, 20)[0]}) -25%, rgb(${
        ColorUtils.lightenRgbArray(color, 80)[0]
      }) 220%);`*/

  const background = disabled
    ? `linear-gradient(36deg, rgb(145, 155, 170) -25%, rgb(180, 190, 200) 220%);`
    : isSwitch && !isPressed
    ? `linear-gradient(36deg, rgb(${
        ColorUtils.lightenRgbArray(ColorUtils.desaturateRgbArray(color, 58), 48)[0]
      }) -25%, rgb(${ColorUtils.lightenRgbArray(color, 100)[0]}) 150%);`
    : `linear-gradient(36deg, rgb(${color[0]}), rgb(${ColorUtils.lightenRgbArray(color, 60)[0]}) 220%);`;

  let foregroundColor;

  if (colorless) {
    foregroundColor = isDark ? "rgb(0, 10, 20)" : "rgb(30, 40, 50)";
  } else {
    foregroundColor =
      isSwitch && !isPressed
        ? `rgba(${ColorUtils.darkenRgbArray(color, 130)[0]}, 0.7)`
        : isDark
        ? "rgb(0, 10, 20)"
        : "rgb(250, 250, 255)";
  }

  const borderRadius = bordRad ?? sizeBySix;

  return {
    position: "relative",
    // margin: margin !== undefined ? margin : sizeBySix,
    margin,
    display: "flex",
    flexDirection: "row",
    zIndex: isPressed ? 0 : 1,
    alignItems: "center",
    justifyContent: "flex-start",
    boxSizing: "content-box",
    paddingLeft: sizeByThree,
    paddingRight: sizeByThree,
    paddingTop: isPressed ? sizeByEight : sizeBySix,
    paddingBottom: isPressed ? sizeByEight : sizeBySix,
    borderRadius,
    // fontFamily: "Roboto, sans-serif",
    fontFamily: theme.styles.Font.family.sansSerif,
    background,
    opacity: 0.95,
    transition:
      "transform 0.15s ease-out, background 0.15s ease-out, color 0.15s ease-out, opacity 0.15s ease-out, box-shadow 0.15s ease-out",
    boxShadow: isPressed ? `none` : boxShadow,
    cursor: !disabled ? "pointer" : "normal",
    userSelect: "none",
    "& span": {
      "&.mini": {
        fontSize: (fontSizeNum / 1.25).toFixed(1),
      },
      boxSizing: "content-box",
      zIndex: 2,
      marginTop: sizeByFourteen,
      // marginBottom: sizeByFourteen,
      marginBottom: sizeByFourteen,
      fontWeight: theme.styles.Font.weights[ETextTypographyType.sansSerif].bold,
      transition: "color 0.15s ease-out",
      color: foregroundColor,
      textShadow:
        disabled || (isSwitch && !isPressed)
          ? "none"
          : isDark
          ? "none"
          : `0 0 ${sizeByFourteen}em rgb(${ColorUtils.darkenRgbArray(color, 30)[0]})`,
      verticalAlign: "center",
      display: "inline-block",
      textTransform: "uppercase",
      lineHeight: 1,
      fontSize,
      letterSpacing: theme.styles.Font.defaultSpacing[ETextTypographyType.sansSerif],
      ...(disabled && { opacity: 0.6 }),
    },
    "& svg": {
      zIndex: 1,
      width: sizeByTwo,
      height: sizeByTwo,
      color: foregroundColor,
      fill: svgFill ? foregroundColor : undefined,
      transition: "color 0.15s ease-in-out",
      marginLeft: iconLeft ? 0 : sizeByEight,
      marginRight: iconLeft ? sizeByEight : 0,
      ...(disabled && { opacity: 0.6 }),
    },
    ...(!disabled &&
      !isPressed && {
        ":hover": {
          ":before": {
            opacity: 1,
          },
          opacity: 1,
          // background: !isSwitch
          //   ? `linear-gradient(36deg, rgb(${color[0]}) -5%, rgb(${ColorUtils.lightenRgbArray(color, 80)[0]}) 130%);`
          //   : `linear-gradient(36deg, rgb(195, 205, 220) -25%, rgb(${
          //       ColorUtils.lightenRgbArray(color, 130)[0]
          //     }) 150%);`,
          "> span": {
            color: isDark || colorless || isSwitch ? "black" : "white",
          },
          "> svg": {
            color: isDark || colorless || isSwitch ? "black" : "white",
            fill: svgFill ? (isDark || colorless || isSwitch ? "black" : "white") : undefined,
          },
          ...(!isSwitch && {
            boxShadow: buttonShadow
              ? `0 ${buttonPopAmount}em 0 0 rgb(${
                  ColorUtils.darkenRgbArray(color, 35)[0]
                }), 0 ${buttonPopAmount}em ${sizeByEight}em 2px ${
                  isDark ? theme.styles.RgbColors.darkBack.shadow : theme.styles.RgbaColors.shadowLight
                }`
              : "none",
          }),
        },
      }),
    ...(isPressed && {
      transform: `translateY(${sizeByEight}em)`,
    }),
    ":before": {
      content: `" "`,
      position: "absolute",
      top: 0,
      left: 0,
      opacity: 0,
      transition: "opacity 0.15s ease-out",
      background: !isSwitch
        ? `linear-gradient(36deg, rgb(${color[0]}) 0, rgb(${ColorUtils.lightenRgbArray(color, 80)[0]}) 100%);`
        : `linear-gradient(36deg, rgb(${
            ColorUtils.lightenRgbArray(ColorUtils.desaturateRgbArray(color, 10), 80)[0]
          }) -25%, rgb(${ColorUtils.lightenRgbArray(color, 100)[0]}) 130%);`,
      borderRadius,
      zIndex: 1,
      width: "100%",
      height: "100%",
    },
    ":after": {
      transition: "box-shadow 0.15s ease-out",
      content: `" "`,
      position: "absolute",
      top: 0,
      left: 0,
      borderRadius,
      zIndex: 1,
      width: "100%",
      height: "100%",
      boxShadow: isPressed ? boxShadow : "none",
    },
  };
};

export type TFelaButtonProps = IFelaRuleButtonProps & {
  onClick?: any;
  linkHref?: string;
  linkTarget?: string;
  type?: "submit";
} & IFelaComponentBaseProps;

const ruleKeysFelaButton: (keyof IFelaRuleButtonProps)[] = [
  "sizeNum",
  "size",
  "iconLeft",
  "color",
  "popAmount",
  "colorless",
  "neutralColor",
  "disabled",
  "isPressed",
  "isSwitch",
  "forceTheme",
  "borderRadius",
  "margin",
  "buttonShadow",
];

export const FelaButton: FunctionComponent<TFelaButtonProps> = ({
  children,
  className = "",
  cssExtra = [],
  onClick,
  linkHref,
  linkTarget = "_blank",
  style,
  type,
  ...props
}) => {
  const { cachedClass, inlineCss } = useFelaCache();
  // const { css } = useFela(props);
  const { disabled = false } = props;

  /* ${css(
          FelaRuleButtonWrap,
          ...cssExtra,
        )}*/

  if (!disabled && linkHref != null) {
    return (
      <a
        href={linkHref}
        target={linkTarget}
        className={`${className} ${cachedClass(FelaRuleButtonWrap, props, ruleKeysFelaButton)} ${inlineCss(cssExtra)}`}
        onClick={disabled ? undefined : onClick}
        onMouseDown={props.onMouseDown}
        style={style}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      type={type}
      style={style}
      className={`${className} ${cachedClass(FelaRuleButtonWrap, props, ruleKeysFelaButton)} ${inlineCss(cssExtra)}`}
      onClick={disabled ? undefined : onClick}
      onMouseDown={props.onMouseDown}
    >
      {children}
    </button>
  );
};
