import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { GraphSection, LegendItem } from "../components/Covid19Components";
import { colorsCovidPage } from "../Covid19Static";
import { MyResponsiveLine } from "../NivoChartTest";
import React from "react";
import _ from "lodash";

export const GraphTotalDeaths = React.memo<any>(({ latestDeaths, caseItems }) => {
  let deathTicks: number[] | undefined;

  if (latestDeaths <= 10) {
    const maxDeathTicks = Math.min(10, latestDeaths);
    const deathTickInterval: number = latestDeaths / maxDeathTicks;
    deathTicks = _.times(maxDeathTicks, (pos) => {
      return Math.round((pos + 1) * deathTickInterval);
    });

    deathTicks.unshift(0);
  }

  const deathData = [
    {
      id: "Deaths:",
      color: colorsCovidPage.totalDeaths,
      data: caseItems.map((item) => ({ x: item.date.slice(5), y: item.deathsTotal })),
    },
  ];

  return (
    <>
      <FelaFlexbox align={"center"} justify={"space-between"} padding={0.5}>
        <LegendItem name={"Total Deaths"} color={colorsCovidPage.totalDeaths} />
      </FelaFlexbox>
      <FelaFlexbox style={{ width: "100%", height: "20vh", minHeight: "16em", maxHeight: "22em" }}>
        <MyResponsiveLine
          curve={"linear"}
          axisLeftExtras={{
            tickValues: deathTicks,
          }}
          yScaleExtras={{ stacked: false, min: 0, max: latestDeaths }}
          // enableArea
          // areaOpacity={0.65}
          // enablePoints={false}
          data={deathData}
        />
      </FelaFlexbox>
    </>
  );
});
