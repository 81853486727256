import { FelaFlexbox } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaFlexbox";
import { GraphSection, LegendItem } from "../components/Covid19Components";
import { colorsCovidPage, colorsCovidSeries } from "../Covid19Static";
import { MyResponsiveLine } from "../NivoChartTest";
import React from "react";
import { IDMCovid19DailyFlatState } from "@vs/core/build/data/models/covid19/Covid19DailyFlatModel";
import { ArrayUtils } from "@gt/common-utils/build/datatypes/ArrayUtils";
import { IESGeoLevelNode } from "@vs/core/build/data/models/geolevels/GeoLevelModelTypes";

interface ICPGraphStateCasesComparison {
  highlightLvl?: string;
  provinceGeoLevels: Pick<IESGeoLevelNode, "geoLvlShort" | "name" | "fullName" | "lowPolyGeoShape">[];
  statesDaily: { [stateGeoLvl: string]: IDMCovid19DailyFlatState[] };
  withLegend?: boolean;
}

export const GraphStateCasesComparison = React.memo<ICPGraphStateCasesComparison>(
  ({ provinceGeoLevels, statesDaily, withLegend = false, highlightLvl }) => {
    // const hasDataGeoLevels = provinceGeoLevels.filter(geo => statesDaily[geo.geoLvlShort] != null);

    // const reversedGeoLevels = [...provinceGeoLevels].reverse();

    return (
      <>
        {withLegend && (
          <FelaFlexbox align={"center"} padding={0.5} wrap={"wrap"}>
            {provinceGeoLevels.map((geo, index) => (
              <LegendItem name={geo.name} color={colorsCovidSeries[index + 1]} />
            ))}
          </FelaFlexbox>
        )}
        <FelaFlexbox style={{ height: "30vh", maxHeight: "22em" }}>
          <MyResponsiveLine
            yScaleExtras={{ min: 0 }}
            data={ArrayUtils.safeFmap(provinceGeoLevels, (geo, index) => {
              if (statesDaily[geo.geoLvlShort] == null) {
                return null;
              }

              return {
                lineStyle:
                  highlightLvl != null
                    ? highlightLvl === geo.geoLvlShort
                      ? { strokeWidth: 3 }
                      : { strokeWidth: 2, strokeDasharray: "1, 6", strokeLinejoin: "round", strokeLinecap: "round" }
                    : undefined,
                id: geo.name,
                color: colorsCovidSeries[index + 1],
                data: statesDaily[geo.geoLvlShort].map(item => ({ x: item.date.slice(5), y: item.casesTotal })),
              };
            })}
          />
        </FelaFlexbox>
      </>
    );
  },
);
