import { errorResult, Store, successResult, TAsyncActionResult } from "pullstate";
import { ITaskFunctionResponse } from "@gt/common-utils/build/taskFunction/TaskFunctionTypes";
import { ExecutionEnvironmentUtils } from "@gt/common-utils/build/general/ExecutionEnvironmentUtils";

interface IPullstateAllStores {
  [storeName: string]: Store;
}

export type TPullstateAsyncActionWrapped<A, R, T extends string, S extends IPullstateAllStores, N = any> = (
  args: A,
  stores?: S,
) => Promise<TAsyncActionResult<R, T, N>>;

function wrapTaskFunctionEndpoint<A, R, N>(
  endpoint: (args: A) => Promise<ITaskFunctionResponse<R, N>>,
  name: string = "",
): TPullstateAsyncActionWrapped<A, R, string, any, N> {
  return async (args) => {
    if (name.length > 0) {
      console.log(`Running async action "${name}"`, args);
    }

    if (ExecutionEnvironmentUtils.isNode()) {
      endpoint = endpoint.bind({ session: undefined });
    }

    const response = await endpoint(args);

    if (name.length > 0) {
      console.log(`Finished async action "${name}"`);
    }

    return response.positive
      ? successResult(response.payload, [response.endId, ...response.endTags], response.endMessage)
      : errorResult([response.endId, ...response.endTags], response.endMessage, response.errorPayload);
  };
}

/*function wrapTaskFunctionEndpointDirect<A, R>(
  endpoint: (args: A) => Promise<ITaskFunctionResponse<R>>,
  name: string = "",
): Promise<R> {
  return async args => {
    if (name.length > 0) {
      console.log(`Running (direct) async action "${name}"`);
    }

    const response = await endpoint(args);

    if (!response.positive) {
      throw new TaskFunctionError()
    }
  };
}*/

function wrapAuthenticatedTaskFunctionEndpoint<A = any, R = any>(
  endpoint: (args: A) => Promise<ITaskFunctionResponse<R>>,
  name: string = "",
): TPullstateAsyncActionWrapped<A, R, string, any> {
  return async (args, { UserStore }) => {
    // console.log(rest);

    if (name.length > 0) {
      console.log(`Running (authed) async action "${name}"`);
    }

    if (ExecutionEnvironmentUtils.isNode()) {
      const jwt = UserStore.getRawState().jwt;
      endpoint = endpoint.bind({ __at: jwt, session: undefined });
    }

    /*
    if (jwt) {

    }*/
    const response = await endpoint(args);

    return response.positive
      ? successResult(response.payload, [response.endId, ...response.endTags], response.endMessage)
      : errorResult([response.endId, ...response.endTags], response.endMessage);
  };
}

export const WildcardUtilsClient = {
  wrapTaskFunctionEndpoint,
  wrapAuthenticatedTaskFunctionEndpoint,
};
