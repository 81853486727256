import { Store } from "pullstate";
import {
  EFrontendStyleConfigBaseTheme,
  unzipFrontendStyleConfig,
  VibescoutFrontendStyleConfig,
} from "@gt/gt-frontend/build/react/vibescout-ui/config/VibescoutFrontendStyleConfig";
import { IFelaTheme } from "@gt/gt-frontend/build/react/vibescout-ui/fela/FelaCommon";
import { DetectDeviceUtils, EPageSizeRange, EUDI } from "@gt/gt-frontend/build/utils/DetectDeviceUtils";
import { CommonAsyncActions } from "../../common/CommonAsyncActions";
import { LocalityStore } from "../LocalityStore/LocalityStore";
import { IFelaKeyframes_VibescoutSpecial } from "../../../config/FelaConfig_VibescoutSpecial";
import { IVibescoutCustomPageMeta } from "../../ASN_VibescoutSpecial";
import { hasNavigator } from "@gt/gt-frontend/build/react/hooks/hasNavigator";
import { LocationStore } from "../LocationStore/LocationStore";
import { ENavigationMetaPageType } from "@gt/gt-frontend/build/utils/NavigationUtils/AsyncNavigator";

export interface IUIStore<M = any> {
  theme: IFelaTheme;
  udi: EUDI;
  pageSize: EPageSizeRange;
  isSmall: boolean;
  pageMeta: {
    metaTitle: string;
    metaDescription: string;
    title: string;
    shareUrl: string;
    pageType: ENavigationMetaPageType;
    searchTerm?: string;
    ogImage?: { url: string; width: string; height: string };
  };
  customPageMeta: M;
  // navigationMeta: IVibescoutNavigationMeta;
  keyFrames: IFelaKeyframes_VibescoutSpecial;
}

const styles = unzipFrontendStyleConfig(VibescoutFrontendStyleConfig);
styles.Font.family.mono = "'Jetbrains Mono', 'Inconsolata', monospace";
styles.Font.family.sansSerif = "'Roboto', sans-serif";

export const UIStore = new Store<IUIStore<IVibescoutCustomPageMeta>>({
  theme: {
    currentId: EFrontendStyleConfigBaseTheme.LIGHT,
    styles,
    dark: false,
    isSmall: true,
    pageSizeValue: EPageSizeRange.mobile,
  },
  udi: EUDI.TOUCH,
  pageSize: EPageSizeRange.mobile,
  isSmall: true,
  pageMeta: {
    pageType: ENavigationMetaPageType.regular,
    title: "",
    metaTitle: "",
    metaDescription: "",
    shareUrl: "",
  },
  customPageMeta: {},
  keyFrames: {
    gradientSlide: "",
  },
});

UIStore.createReaction(
  (s) => s.theme.currentId,
  (id, draft, original) => {
    // draft.theme.current = original.theme.styles.Themes[id];
    draft.theme.dark = original.theme.styles.Themes[id].isDark;
  },
);

UIStore.createReaction(
  (s) => s.pageSize,
  (watched, draft) => {
    // const pageSizeVal = pageSizeValue(watched);
    const isSmall = watched <= EPageSizeRange.mobile;
    draft.isSmall = isSmall;
    draft.theme.isSmall = isSmall;
    draft.theme.pageSizeValue = watched;
  },
);

export function initializeClientSideUIListeners() {
  console.log(`Initializing Client Side UI Listeners`);

  UIStore.subscribe(
    (s) => s.theme.currentId,
    (watched) => CommonAsyncActions.updateSession.run({ path: ["theme", "currentId"], val: watched }),
  );

  UIStore.subscribe(
    (s) => s.pageSize,
    (watched) => CommonAsyncActions.updateSession.run({ path: ["pageSize"], val: watched }),
  );

  LocalityStore.subscribe(
    (s) => s.closedBlurb,
    (watched) => CommonAsyncActions.updateSession.run({ path: ["closedBlurb"], val: watched }),
  );

  UIStore.update((s) => {
    s.theme.styles = unzipFrontendStyleConfig(VibescoutFrontendStyleConfig);
  });

  DetectDeviceUtils.initializeAndDetectUserDeviceInterface((udi) => {
    if (UIStore.getRawState().udi !== udi) {
      UIStore.update((s) => {
        s.udi = udi;
      });
    }
  });

  DetectDeviceUtils.initializeAndDetectUserDevicePageSize((size) => {
    if (UIStore.getRawState().pageSize !== size) {
      const isSmall = size <= EPageSizeRange.mobile;
      UIStore.update((s) => {
        s.pageSize = size;
        s.isSmall = isSmall;
      });
    }
  });

  console.log(hasNavigator());
  if (hasNavigator()) {
    LocationStore.update((s) => {
      s.hasNavigator = true;
    });
  }
}
