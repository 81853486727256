import { TVibescoutSpecialResolver } from "../ASN_VibescoutSpecial";
import { PSC_VibescoutSpecial } from "../PSC_VibescoutSpecial";
import { WildcardUtilsClient } from "@gt/gt-frontend/build/utils/WildcardUtilsClient";
import { wildcard } from "../../wildcard/VibescoutSpecial_WildcardClient";
import { GeoLevelStatic } from "@vs/core/build/data/crossover/geoLevels/GeoLevelStatic";
import { TGeoLevelBasics } from "@vs/core/build/data/models/geolevels/GeoLevelModelTypes";
import { IOGetLocalityIndexPageDataInput } from "@vs/core/build/pageDataRequests/Vibescout/LocalityIndex/LocalityIndexPageRequest";
import { throwAsyncResult } from "@gt/gt-frontend/build/utils/PullstateAsyncUtils";

const getPageData = PSC_VibescoutSpecial.createAsyncAction(
  WildcardUtilsClient.wrapTaskFunctionEndpoint(wildcard.pageData_LocalityIndex),
);

export const PageLocalityIndexAsyncActions = {
  getPageData,
};

function getPageDataDefaultArguments(
  params: Pick<IOGetLocalityIndexPageDataInput, "cc" | "st" | "mu" | "ci">,
): IOGetLocalityIndexPageDataInput {
  return {
    cc: params.cc,
    st: params.st,
    mu: params.mu,
    ci: params.ci,
    lowerIndexPage: 0,
    lowerIndexLimit: 100,
  };
}

export const LocalityIndexPageStatic = {
  getPageDataDefaultArguments,
};

export const _navLocalityIndex: TVibescoutSpecialResolver = {
  paths: [
    "/locality-index/:cc",
    "/locality-index/:cc/:st",
    "/locality-index/:cc/:st/:mu",
    "/locality-index/:cc/:st/:mu/:ci",
  ],
  resolve: async ({ ctx, info: { params, pathname }, state: { instance } }) => {
    const pageData = await instance.runAsyncAction(
      PageLocalityIndexAsyncActions.getPageData,
      getPageDataDefaultArguments(params as any),
      { respectCache: true },
    );

    if (pageData.error) {
      throwAsyncResult(pageData);
    }

    if (!pageData.error) {
      const { geoLevel } = pageData.payload;

      const title = `${geoLevel.name} ${
        GeoLevelStatic.valuesForGeoLvlType[(geoLevel as TGeoLevelBasics).lvlType].longText
      } Locality Index`;

      instance.stores.UIStore.update((s) => {
        s.pageMeta.title = title;
        s.pageMeta.metaDescription = `Location directory for ${geoLevel.name} on Vibescout`;
        s.pageMeta.metaTitle = title;
        s.pageMeta.shareUrl = `https://www.vibescout.com${pathname}`;
      });
    }
  },
};
