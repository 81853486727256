import { addDays, isSameDay } from "date-fns";

function getBookNowUrlForMovieSchedule({
  scheduleSourceBrandID,
  scheduleSourceMovieID,
  scheduleSourceCinemaID,
  cinemaTruthId = "",
  cinemaName = "",
}): string | undefined {
  switch (scheduleSourceBrandID) {
    case "sterkinekor": {
      return `https://www.sterkinekor.com/details/${scheduleSourceMovieID}/cinema/${cinemaTruthId}`;
      // return `https://movies.sterkinekor.co.za/Browsing/Movies/Details/h-${scheduleSourceMovieID}`;
    }
    case "epic-cinemas": {
      return `https://ticketing.us.veezi.com/sessions/?siteToken=${scheduleSourceCinemaID}`;
    }
    case "ster-kalahari": {
      return "https://www.facebook.com/SterKalahari";
    }
    case "minimax-cine": {
      return `http://www.minimaxcinema.co.za/${scheduleSourceMovieID}/`;
    }
    case "capitol-cinemas": {
      return "http://www.capitolcinemas.co.za/";
    }
    case "bioscope": {
      return "http://tickets.tixsa.co.za/events/20508";
    }
    case "labia": {
      return getWebTicketsAffiliateLink(`http://www.webtickets.co.za/event.aspx?itemid=${scheduleSourceMovieID}`);
    }
    case "cinecentre": {
      return `https://cinecentre.co.za/CinemalocationsByCid.aspx?cid=${scheduleSourceCinemaID}`;
    }
    case "numetro": {
      return `https://www.numetro.co.za/movie/${scheduleSourceMovieID}`;
    }
    case "tsogo-sun-movies-at": {
      if (scheduleSourceCinemaID === "hemingways") {
        return null;
      }
      return `https://${scheduleSourceCinemaID}movies.tsogosun.com/`;
    }
    default: {
    }
  }
}

function getWebTicketsAffiliateLink(ticketUrl): string {
  if (ticketUrl) {
    if (ticketUrl.match(/webtickets/i)) {
      if (ticketUrl.indexOf("?") !== -1) {
        return `${ticketUrl}&affiliateid=1468563005`;
      }

      return `${ticketUrl}?affiliateid=1468563005`;
    }
  } else {
    console.log(`Isn't a link, or isn't WebTickets: ${ticketUrl}`);
  }

  return ticketUrl;
}

function betterName(name: string): string {
  return capitalize(name.replace(/\b\d\s*-\s*\d+\b/gi, "").toLowerCase()).trim();
}

function capitalize(text) {
  return text.replace(/\b\w/g, (m) => m.toUpperCase());
}

export const indexToMonthMMM = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const indexToMonthFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const indexToWeekdayDDD = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const indexToWeekdayFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function getWeekday(date: Date, full = false) {
  if (full) return indexToWeekdayFull[date.getDay()];
  return indexToWeekdayDDD[date.getDay()];
}

function getWeekdayOrTodayTomorrow(date: Date, full = false) {
  if (isSameDay(new Date(), date)) {
    return "today";
  }

  if (isSameDay(addDays(new Date(), 1), date)) {
    return "tomorrow";
  }

  return getWeekday(date, full);
}

export const MovieUtils = {
  getWebTicketsAffiliateLink,
  getBookNowUrlForMovieSchedule,
  betterName,
  getWeekdayOrTodayTomorrow,
  getWeekday,
};
