import { PSC_VibescoutSpecial } from "../../PSC_VibescoutSpecial";
import { GeoLocationUtils } from "@gt/gt-frontend/build/utils/GeoLocationUtils";
import { errorResult, successResult } from "pullstate";
import { IElasticPropertyGeoPoint } from "@gt/services/build/elasticsearch/ElasticSearchTypes";
import { LocationStatic } from "./LocationStatic";

const initiateLocationWatcher = PSC_VibescoutSpecial.createAsyncAction<{}, IElasticPropertyGeoPoint>(
  async function initiateLocationWatcher({}, { LocationStore }) {
    const { watchId } = LocationStore.getRawState();

    if (watchId) {
      navigator.geolocation.clearWatch(watchId);
    }

    const response = await GeoLocationUtils.startWatchingPosition((response) => {
      const incomingWatchId = response.payload?.watchId ?? response.errorPayload?.watchId;
      const { watchId } = LocationStore.getRawState();

      if (incomingWatchId === watchId) {
        if (response.positive) {
          LocationStore.update((s) => {
            s.attemptedGeoPosition = true;
            s.accuratePos = {
              latLon: {
                lon: response.payload.position.coords.longitude,
                lat: response.payload.position.coords.latitude,
              },
              lastUpdated: new Date(),
            };
            s.errorCode = undefined;
          });
        } else {
          LocationStore.update((s) => {
            s.attemptedGeoPosition = true;
            s.errorCode = response.errorPayload.error.code;
            s.accuratePos = undefined;
          });
        }
      }
    });

    if (response.positive) {
      const latLon: IElasticPropertyGeoPoint = {
        lon: response.payload.position.coords.longitude,
        lat: response.payload.position.coords.latitude,
      };

      LocationStore.update((s) => {
        s.attemptedGeoPosition = true;
        s.watchId = response.payload.watchId;
        s.accuratePos = {
          latLon,
          lastUpdated: new Date(),
        };
        s.errorCode = undefined;
      });
      return successResult(latLon);
    } else {
      LocationStore.update((s) => {
        s.attemptedGeoPosition = true;
        s.errorCode = response.errorPayload.error.code;
        s.watchId = response.errorPayload.watchId;
        s.accuratePos = undefined;
      });
      return errorResult([], LocationStatic.messageForErrorCode[response.errorPayload.error.code]);
    }
  },
);

export const LocationActions = {
  initiateLocationWatcher,
};
