import loadable from "@loadable/component";
import { PageSpecial_Covid19 } from "../sections/covid19/PageSpecial_Covid19";
import { PageSpecial_Covid19State } from "../sections/covid19/PageSpecial_Covid19State";

const fillerLoadable = loadable(() => import("./Filler"), {
  resolveComponent: (lib) => lib.Filler,
});

export const DynamicImports = {
  PageSpecial_Covid19: async () => {
    // await import("../sections/covid19/PageSpecial_Covid19");
  },
  PageSpecial_Covid19State: async () => {
    // await import("../sections/covid19/PageSpecial_Covid19State");
  },
};

/*export const LoadableComponents = {
  PageSpecial_Covid19: loadable(() => import("../sections/covid19/PageSpecial_Covid19"), {
    resolveComponent: (lib) => lib.PageSpecial_Covid19,
  }),
  PageSpecial_Covid19State: loadable(() => import("../sections/covid19/PageSpecial_Covid19State"), {
    resolveComponent: (lib) => lib.PageSpecial_Covid19State,
  }),
};*/

export const LoadableComponents = {
  PageSpecial_Covid19: PageSpecial_Covid19,
  PageSpecial_Covid19State: PageSpecial_Covid19State,
};
